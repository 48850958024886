import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import qs from "query-string";

import ConfirmationModal from "../common/components/ConfirmationModal";
import Portal from "../common/components/Portal";
import initativeAction from "../initiatives/actions";

import CommonActions, { getTableConfig, saveTableConfig } from "../common/actions";
import regionActions from "../region/actions";
import projectAction from "../project/actions";
import ViewExportModal from "./components/ExportSettingsModal";
import ViewModal from "../common/components/ViewModal";
import AssignClientUserModal from "./components/AssignClientUserModal";
import AssignConsultancyUserModal from "./components/AssignConsultancyUserModal";
import recommendationsActions from "./actions";
import siteActions from "../site/actions";
import buildingActions from "../building/actions";
import _, { cloneDeep } from "lodash";
import RecommendationsMain from "./components/RecommendationsMain";
import Form from "./components/Form";
import Loader from "../common/components/Loader";
import { recommendationsTableData } from "../../config/tableData";
import RecommendationsInfo from "./components/RecommendationsInfo";
import CutPasteModal from "./components/CopyModal";
import {
    addToBreadCrumpData,
    findPrevPathFromBreadCrumpData,
    findPrevPathFromBreadCrumpRecData,
    popBreadCrumpRecData,
    checkPermission,
    popBreadCrumpOnPageClose,
    findPrevPathFromBreadCrump,
    getTableSliderData,
    addTableSliderDataToBreadcrumb,
    updateBreadCrumpData
} from "../../config/utils";
import "../../assets/css/add-recommendation.css";
import MultiSelectFormModal from "./components/MultiSelectFormModal";
import { energy_fields } from "./components/Bands/EnergyBand";
import { water_fields } from "./components/Bands/WaterBand";
import { lockRecommendation, normalRecomGrouping } from "./services";
import { getPinnedColumnLeftPositions, insertFields, removeFields } from "../../utils/tableUtils";
import { entities, LOCK_STATUS, RECOMMENDATION_SOURCE_TYPES } from "../common/constants";
import { DynamicColumnModal } from "../common/DynamicColumnModal";
import { MyFilters } from "../dashboard/components/FilterModal/MyFilters";
import { ShiftCspModal } from "./components/ShiftCspModal";
import { AppliedFilters } from "../common/AppliedFilters";
import { getAppliedFiltersCount } from "../../utils/filtersUtils";
import { permissions } from "../../config/permissions";
import { getUpdatedCommonFilters, getUpdatedSortFilters, getUpdatedWildCardFilters } from "./components/GroupingFunctions";
import smartChartAction from "../smartCharts/actions";
class index extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.pinnedColumnsRef = {};
        const {
            match: {
                params: { section }
            },
            location
        } = this.props;
        const { filterValues = {}, tempChartFilters = {}, tempQueryChartFilters = {} } = this.props.dashboardReducer;
        this.exportTableXl = this.exportTableXl.bind(this);
        this.isDashboardFiltered = false;
        let bcData = findPrevPathFromBreadCrumpRecData();
        if (bcData && bcData[0]) {
            this.isDashboardFiltered = bcData[0].name === "Dashboard" ? true : false;
        }
        this.state = {
            isLoading: true,
            errorMessage: "",
            recommendationsList: [],
            exportWordLoading: false,
            paginationParams: this.props.recommendationsReducer.entityParams[section]?.paginationParams || {
                totalPages: 0,
                perPage: 40,
                currentPage: 0,
                totalCount: 0
            },
            currentViewAllUsers: null,
            currentActions: null,
            tableLoading: false,
            exportAllTradesLoading: false,
            showViewModal: false,
            showViewModalExport: { show: false, type: "" },
            showWildCardFilter: false,
            showAssignConsultancyUsers: false,
            showNewOrExistingConfirmModal: false,
            showMergeOrReplaceModal: false,
            recommendationsData: {},
            clients: [],
            regionList: [],
            consultancy_users: [],
            confirmUnAssign: false,
            selectedRowId: this.props.recommendationsReducer.entityParams[section]?.selectedRowId,
            initiative_ids: this.props.recommendationsReducer.entityParams[section]?.initiative_ids,
            params: this.props.recommendationsReducer.entityParams[section]?.params || {
                limit: 40,
                offset: 0,
                search: "",
                filters: null,
                list: null,
                year: [],
                index: [],
                active: true,
                surveyor: null,
                image_or_not: null,
                review_status: null,
                qa_passed: null,
                infrastructure_request: null,
                water: null,
                energy: null,
                facility_master_plan: null,
                recommendation_type: null,
                budget_priority: null,
                dynamic_joins: []
            },
            capital_type: this.props.recommendationsReducer.entityParams[section]?.capital_type,
            dashboard: this.props.recommendationsReducer.entityParams[section]?.dashboard,
            start_year: this.props.recommendationsReducer.entityParams[section]?.start_year,
            end_year: this.props.recommendationsReducer.entityParams[section]?.end_year,
            infrastructure_requests: this.props.recommendationsReducer.entityParams[section]?.infrastructure_requests,
            year: this.props.recommendationsReducer.entityParams[section]?.year,
            selectedClient: {},
            selectedRecommendations: this.props.projectIdDashboard
                ? this.props.projectIdDashboard
                : this.props.match.params.id || this.props.recommendationsReducer.entityParams[section]?.selectedEntity,
            tableData: {
                keys: _.cloneDeep(recommendationsTableData.keys),
                config: this.props.recommendationsReducer.entityParams[section]?.tableConfig || _.cloneDeep(recommendationsTableData.config)
            },
            initialTableConfig: _.cloneDeep(recommendationsTableData.config),
            wildCardFilterParams: this.props.recommendationsReducer.entityParams[section]?.wildCardFilterParams,
            filterParams: this.props.recommendationsReducer.entityParams[section]?.filterParams,
            building_ids: this.props.recommendationsReducer.entityParams[section]?.building_ids,
            selectedYear: "",
            showCutPasteModal: false,
            maintenance_years: [],
            summaryRowData: {
                crv_total: "",
                year_totals: {}
            },
            showRestoreConfirmModal: false,
            isDeleted: false,
            showConfirmCopyPasteModal: false,
            target_year: "",
            historyPaginationParams: this.props.recommendationsReducer.entityParams[section]?.historyPaginationParams || {
                totalPages: 0,
                perPage: 40,
                currentPage: 0,
                totalCount: 0
            },
            historyParams: this.props.recommendationsReducer.entityParams[section]?.historyParams || {
                limit: 40,
                offset: 0,
                search: ""
            },
            logData: {
                count: "",
                data: []
            },
            showConfirmModalLog: false,
            selectedLog: "",
            isRestoreOrDelete: "",
            selectedMainItem: "",
            selectedDropdown: this.props.recommendationsReducer.entityParams[section]?.selectedDropdown || "active",
            selectedDropdownInitiaive: this.props.recommendationsReducer.entityParams[section]?.selectedDropdownInitiaive || "assigned",
            alertMessage: "",
            permissions: {},
            logPermission: {},
            imageResponse: [],
            selectedRecomIds: this.props.recommendationsReducer.entityParams[section]?.selectedRecomIds || [],
            tempRecommendationsList: this.props.recommendationsReducer?.entityParams[section]?.recommendationList || [],
            showMultiSelectEditForm: false,
            currentFilterParams: {},
            exportLoader: false,
            multiExportPdfLoader: false,
            multiExportWordLoader: false,
            showHardDeleteConfirmationModal: false,
            dashboardFilterParams: this.isDashboardFiltered
                ? { ...filterValues, ...tempChartFilters, ...tempQueryChartFilters, dashboard: true }
                : {},
            parentSectionId: this.props.recommendationsReducer.entityParams[section]?.parentSectionId || this.props.match.params.id,
            tableDataExportFilters: this.props.recommendationsReducer?.entityParams[section]?.tableDataExportFilters || {},
            isInputMode: {
                rowIndex: "",
                keyItem: ""
            },
            lineEditingEnabled: false,
            initialFiltersSetForSmartChart: false,
            showFiltersPopup: false,
            myFilters: this.props.recommendationsReducer.entityParams[section]?.myFilters || {},
            showShiftCspModal: false,
            showAppliedFilters: false,
            isTableResetting: false,
            showAssignConfirmationModal: false,
            normalRecomGrouping: {},
            isNormalRecomGroupingLoading: false,
            tableConfigChanged: false,
            lineEditDropdownValue: [],
            isDropdownLoading: {}
        };
    }

    componentDidMount = async () => {
        console.log("enterrr");
        const {
            location: { search, state }
        } = this.props;
        const query = qs.parse(search);
        const projectId = this.props.projectIdDashboard || this.props?.projectIdForSmartChart || query.pid || this.props.match.params.id || "";
        if (
            projectId &&
            this.props.match.path !== "/recommendations/:section/:id/:tab" &&
            this.props.match.path !== "/recommendations/:section/:id"
        ) {
            this.setState({ isTableResetting: true });
            await Promise.all([
                this.props.getProjectById(projectId),
                this.props.getPriorityElementDropDownData(projectId),
                this.props.getDynamicFields({ project_id: projectId })
            ]);

            await this.setTableDataConfigBasedOnProject();
            await this.setDynamicJoinParam();
            this.setState({ isTableResetting: false });
        }
        if (this.props.match.params.tab === "recommendation") {
            if (this.state.parentSectionId !== this.props.match.params.id || state?.from === "dashboard") {
                await this.resetEntityParams();
                this.props.setRecommendationScrollPosition(1);
            }
            this.setState(
                {
                    selectedDropdownInitiaive: "assigned",
                    selectedDropdown: "active",
                    params: {
                        ...this.state.params,
                        view: "assigned",
                        active: true,
                        deleted: null,
                        unlocked: null,
                        locked: null,
                        on_hold: null,
                        completed: null
                    },
                    parentSectionId: this.props.match.params.id
                },
                async () => {
                    await this.updateEntityParams();
                    await this.refreshRecommendationsList(true);
                }
            );
        }
        // -----------------------------------
        else if (
            this.props.match.params.tab === "recommendations" ||
            this.props.match.params.tab === "linked-recommendations" ||
            this.props.match.params.tab === "linked-cr-recommendations"
        ) {
            if (this.state.parentSectionId !== this.props.match.params.id || state?.from === "dashboard") {
                const CrDataupdatedchanges =
                    this.state.tableData.config &&
                    Object.keys(this.state?.tableData?.config)?.reduce((acc, key) => {
                        acc[key] = { ...this.state?.tableData?.config?.[key], pinned: false };
                        return acc;
                    }, {});

                await this.resetEntityParams(this.props.match.params.tab === "linked-cr-recommendations" ? CrDataupdatedchanges : "");
                this.props.setRecommendationScrollPosition(1);
            }
            this.setState(
                {
                    selectedDropdown: this.props.recommendationsReducer.entityParams[this.props.match.params.section]?.selectedDropdown || "active",
                    params: {
                        ...this.state.params,
                        view: null
                    },
                    parentSectionId: this.props.match.params.id
                },
                async () => {
                    await this.updateEntityParams();
                    await this.refreshRecommendationsList();

                    if (this.props.match.params.tab === "recommendations") {
                        const savedScrollPosition = this.props.recommendationsReducer.scrollPosition;
                        if (savedScrollPosition !== null && this.tableRef.current) {
                            if (this?.tableRef?.current) {
                                this.tableRef.current.scrollLeft = savedScrollPosition;
                            }
                        }
                    } else {
                        if (this?.tableRef?.current) {
                            this.tableRef.current.scrollLeft = 1;
                        }
                    }
                }
            );
        } else if (this.props.match.path === "/recommendations/:section/:id/:tab" || this.props.match.path === "/recommendations/:section/:id") {
            this.setState({ isLoading: false });
        } else {
            //setting initial filters for smart chart recom tiles
            if (this.props?.isSmartChartView) {
                let selectedWildCardFilters = null;
                let tempSelectedRecomIds = [];
                let selectedCommonFilters = null;
                let selectedTableDataExportFilters = {};
                let selectedGlobalSearchValue = "";
                let selectedOrderFilter = null;
                let selectedMaintenanceYear = [];
                let selectedsurveyorValue = "";
                let selectedImageValue = "";
                let selectedinfrastructureValue = "";
                let selectedWaterValue = "";
                let selectedBudgetValue = "";
                let selectedEnergyValue = "";
                let selectedFacilityValue = "";
                let selectedRecommendationValue = "";
                let selectedRecommendationIds = [];
                tempSelectedRecomIds = this.props?.selectedRecomIdList?.map(recomId => {
                    return {
                        id: recomId
                    };
                });
                if (this.props?.smartChartTileConfig?.band1?.config_filter || this.props?.smartChartTileConfig?.config_filter) {
                    let configFilterData =
                        this.props?.smartChartTileConfig?.band1?.config_filter || this.props?.smartChartTileConfig?.config_filter || {};
                    let configFilterDataFilters =
                        this.props?.smartChartTileConfig?.band1?.config_filter_names || this.props?.smartChartTileConfig?.config_filter_names || {};
                    const {
                        wildcard_filters = {},
                        global_search = "",
                        sort_by_order = {},
                        surveyor = null,
                        image_or_not = null,
                        infrastructure_request = null,
                        water = null,
                        energy = null,
                        facility_master_plan = null,
                        recommendation_type = null,
                        budget_priority = null,
                        recommendation_ids = [],
                        ...common_filters
                    } = configFilterData;
                    if (recommendation_ids?.length > 0) {
                        selectedRecommendationIds = recommendation_ids;
                    }
                    if (Object.keys(wildcard_filters).length) {
                        selectedWildCardFilters = configFilterDataFilters?.filters || null;
                    }
                    if (Object.keys(common_filters || {}).length) {
                        selectedCommonFilters = configFilterDataFilters?.list || null;
                    }
                    if (Object.keys(this.props?.smartChartTileConfig?.band1?.config_filter_names?.tableDataExportFilters || {}).length) {
                        selectedTableDataExportFilters = configFilterDataFilters?.tableDataExportFilters || {};
                    }
                    if (global_search) {
                        selectedGlobalSearchValue = global_search;
                    }
                    if (surveyor) {
                        selectedsurveyorValue = surveyor;
                    }
                    if (image_or_not) {
                        selectedImageValue = image_or_not;
                    }
                    if (infrastructure_request) {
                        selectedinfrastructureValue = infrastructure_request;
                    }
                    if (water) {
                        selectedWaterValue = water;
                    }
                    if (energy) {
                        selectedEnergyValue = energy;
                    }
                    if (facility_master_plan) {
                        selectedFacilityValue = facility_master_plan;
                    }
                    if (recommendation_type) {
                        selectedRecommendationValue = recommendation_type;
                    }
                    if (budget_priority) {
                        selectedBudgetValue = budget_priority;
                    }
                    if (Object.keys(sort_by_order).length) {
                        selectedOrderFilter = configFilterDataFilters?.order || null;
                        selectedMaintenanceYear = configFilterDataFilters?.maintenance_year || [];
                    }
                }
                await this.setState({
                    selectedRecomIds: tempSelectedRecomIds,
                    params: {
                        ...this.state.params,
                        ...this.props?.smartChartTileConfig?.config_filter,
                        filters: selectedWildCardFilters ? { ...selectedWildCardFilters } : null,
                        list: selectedCommonFilters ? { ...selectedCommonFilters } : null,
                        search: selectedGlobalSearchValue,
                        water: selectedWaterValue,
                        energy: selectedEnergyValue,
                        infrastructure_request: selectedinfrastructureValue,
                        budget_priority: selectedBudgetValue,
                        recommendation_type: selectedRecommendationValue,
                        facility_master_plan: selectedFacilityValue,
                        surveyor: selectedsurveyorValue,
                        image_or_not: selectedImageValue,
                        order: selectedOrderFilter,
                        maintenance_year: selectedMaintenanceYear,
                        recommendation_ids: selectedRecommendationIds
                    },
                    tableDataExportFilters: selectedTableDataExportFilters,
                    initialFiltersSetForSmartChart: true
                });
                this.updateEntityParams();
            }
            await this.refreshRecommendationsList();
        }
        if (this.props.match.params.tab !== "recommendations" && this?.tableRef?.current) {
            this.tableRef.current.scrollLeft = 1;
        }
    };
    // handleScroll = () => {
    //     if (this.tableRef.current && this.props.match.params.tab === "recommendations") {
    //         if (this.tableRef.current.scrollLeft) {
    //             this.props.setRecommendationScrollPosition(this.tableRef.current.scrollLeft);
    //         }
    //     }
    // };

    componentDidUpdate = async (prevProps, prevState) => {
        if (
            (this.props.match.params.tab !== prevProps.match.params.tab || this.props.match.params.id !== prevProps.match.params.id) &&
            this?.tableRef?.current
        ) {
            this.tableRef.current.scrollLeft = 1;
        }
        if (
            // this.props.location.search !== prevProps.location.search ||
            (this.props.match.params.id !== prevProps.match.params.id || this.props.match.params.tab !== prevProps.match.params.tab) &&
            this.props.match.path !== "/recommendations/:section/:id/:tab" &&
            this.props.match.path !== "/recommendations/:section/:id"
        ) {
            this.setState({ isTableResetting: true, isLoading: true, tableConfigChanged: false });
            if (this.props.match.params.section === "projectinfo") {
                await this.resetEntityParams();
            }

            await this.setTableDataConfigBasedOnProject();
            await this.setDynamicJoinParam();
            this.setState({ isTableResetting: false });
            await this.refreshRecommendationsList();
        }
        if (prevProps.submitAssign !== this.props.submitAssign) {
            if (this.props.submitAssign) {
                await this.refreshRecommendationsList();
            }
        }
        if (this.props.isBudgetPriority && prevProps.getDashboardValue !== this.props.getDashboardValue) {
            await this.refreshRecommendationsList();
        }
        if (
            this.props.match.params.id !== prevProps.match.params.id &&
            (this.props.match.params.tab === "linked-cr-recommendations" || this.props.match.params.tab === "linked-recommendations")
        ) {
            this.refreshRecommendationsList();
        }
    };

    handleScrollPosition = () => {
        const scrollPosition = this.props.recommendationsReducer.scrollPosition;
        if (scrollPosition && this.tableRef?.current) {
            this.tableRef.current.scrollTo(0, parseInt(scrollPosition));
            this.props.setRecommendationScrollPosition(0);
        }
    };

    refreshRecommendationsList = async (isRecom, noLoading = false) => {
        !noLoading && this.setState({ isLoading: true });
        const { params, paginationParams, myFilters, dashboardFilterParams } = this.state;
        let recommendationsList = [];
        let totalCount = 0;
        let crv_total = "";
        let project_total = "";
        let year_totals = {};
        let tempKeys = [...this.state.tableData.keys];
        let tempConfig = { ...this.state.tableData.config };
        const {
            match: {
                params: { section, tab }
            },
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        let updatedParams = { ...params };
        const projectTotalFilter = updatedParams?.filters?.["recommendations.project_total"] || {};
        if (projectTotalFilter?.filters?.[0] === "top_n" && projectTotalFilter?.key < updatedParams.limit) {
            updatedParams.limit = projectTotalFilter?.key;
        }
        let tempSelectedRecomIds = this.props.recommendationsReducer.entityParams[section]?.selectedRecomIds || [];

        // Getting All Recommendations by Id
        if (this.props.isChartView) {
            await this.props.getProjectById(this.props.projectIdDashboard);
            if (this.props.dataView === "building") {
                let chartParams = {
                    buildingId: this.props.buildingIdDashboard,
                    projectId: this.props.projectIdDashboard,
                    building_ids: (this.isDashboardFiltered ? this.state.building_ids : null) || null
                };
                await this.props.getChartsBuilding(chartParams, updatedParams);
                this.props.handleFilterValues(updatedParams);
                recommendationsList = this.props.buildingReducer.graphDetails.recommendations;
                totalCount = this.props.buildingReducer.graphDetails.count;
                crv_total = this.props.buildingReducer.graphDetails.crv_total;
                year_totals = this.props.buildingReducer.graphDetails.year_totals;
                project_total = this.props.buildingReducer.graphDetails.project_total;
            } else if (this.props.dataView === "region") {
                let chartParams = {
                    regionId: this.props.regionId
                };
                let tempParams = {
                    ...updatedParams,
                    project_id: this.props.projectIdDashboard
                };
                await this.props.getChartsByRegion(chartParams, tempParams);
                this.props.handleFilterValues(updatedParams);
                recommendationsList = this.props.regionReducer.graphDetails.recommendations;
                totalCount = this.props.regionReducer.graphDetails.count;
                crv_total = this.props.regionReducer.graphDetails.crv_total;
                year_totals = this.props.regionReducer.graphDetails.year_totals;
                project_total = this.props.regionReducer.graphDetails.project_total;
            } else if (this.props.dataView === "project") {
                let chartParams = {
                    projectId: this.props.projectIdChart
                };
                let tempParams = {
                    ...updatedParams,
                    project_id: this.props.projectIdDashboard,
                    building_ids: (this.isDashboardFiltered ? this.state.building_ids : null) || null,
                    capital_type: (this.isDashboardFiltered ? this.state.capital_type : null) || null,
                    start_year: (this.isDashboardFiltered ? this.state.start_year : null) || null,
                    end_year: (this.isDashboardFiltered ? this.state.end_year : null) || null,
                    infrastructure_requests: (this.isDashboardFiltered ? this.state.infrastructure_requests : null) || null,
                    dashboard: (this.isDashboardFiltered ? this.state.dashboard : null) || null,
                    year: (this.isDashboardFiltered ? this.state.year : null) || null
                };

                await this.props.getChartByProject(chartParams, tempParams);
                this.props.handleFilterValues(updatedParams);
                recommendationsList = this.props.projectReducer.graphDetails.recommendations;
                totalCount = this.props.projectReducer.graphDetails.count;
                crv_total = this.props.projectReducer.graphDetails.crv_total;
                year_totals = this.props.projectReducer.graphDetails.year_totals;
                project_total = this.props.projectReducer.graphDetails.project_total;
            } else {
                let chartParams = {
                    siteId: this.props.siteId,
                    projectId: this.props.projectIdDashboard
                };
                await this.props.getChartData(chartParams, updatedParams);

                this.props.handleFilterValues(updatedParams);
                recommendationsList = this.props.siteReducer.graphDetails.recommendations;
                totalCount = this.props.siteReducer.graphDetails.count;
                crv_total = this.props.siteReducer.graphDetails.crv_total;
                year_totals = this.props.siteReducer.graphDetails.year_totals;
                project_total = this.props.siteReducer.graphDetails.project_total;
            }
        } else {
            if (section === "regioninfo") {
                let regionParam = {
                    region_id: this.props.match.params.id,
                    project_id: query.pid,
                    ...updatedParams,
                    ...dashboardFilterParams,
                    ...myFilters,
                    filters: { ...updatedParams.filters, ...myFilters.filters, ...dashboardFilterParams.filters }
                };
                await this.props.getAllRecommendationsRegion(regionParam);
            } else if (section === "buildinginfo") {
                let buildingParam = {
                    building_id: this.props.match.params.id || null,
                    project_id: query.pid,
                    ...updatedParams,
                    ...dashboardFilterParams,
                    ...myFilters,
                    filters: { ...updatedParams.filters, ...myFilters.filters, ...dashboardFilterParams.filters }
                };
                await this.props.getAllRecommendationsRegion(buildingParam);
            } else if (section === "siteinfo") {
                let siteParam = {
                    site_id: this.props.match.params.id,
                    project_id: query.pid,
                    ...updatedParams,
                    ...dashboardFilterParams,
                    ...myFilters,
                    filters: { ...updatedParams.filters, ...myFilters.filters, ...dashboardFilterParams.filters }
                };
                await this.props.getAllRecommendationsRegion(siteParam);
            } else if (section === "initiativeInfo") {
                const { view } = updatedParams || {};
                let params = {
                    initiative_id: this.props.match.params.id,
                    project_id: view !== "assigned" ? query.pid : null,
                    initiative_ids:
                        view === "assigned" && this.props.match.params.tab === "recommendations"
                            ? this.props.recommendationsReducer.entityParams[this.props.match.params.section]?.initiative_ids
                            : [],
                    ...updatedParams,
                    ...myFilters,
                    filters: { ...updatedParams.filters, ...myFilters.filters, ...dashboardFilterParams.filters }
                };
                await this.props.getAllRecommendationsRegion(params);
            } else if (section === "imageInfo") {
                let imageRecomParams = {
                    image_id: this.props.match.params.id,
                    ...updatedParams
                };
                await this.props.getAllRecommendationsRegion(imageRecomParams);
            } else if (section === "assetInfo") {
                let imageRecomParams = {
                    ...updatedParams,
                    asset_id: this.props.match.params.id
                };
                await this.props.getAllRecommendationsRegion(imageRecomParams);
            } else if (this.props.isBudgetPriority) {
                let recomParams = {
                    ...updatedParams,
                    ...this.props.dashboardFilterParams
                };
                await this.props.getAllBudgetPriorityRecommendations(recomParams);
            } else if (this.props.isSmartChartView) {
                const { smartChartFilters = {} } = this.props;

                await this.props.getAllRecommendations(
                    {
                        ...updatedParams,
                        ...smartChartFilters
                    },
                    this.props?.projectIdForSmartChart
                );
            } else if (section === "recommendationsinfo" && tab === "linked-recommendations") {
                let params = {
                    ...updatedParams,
                    ...dashboardFilterParams,
                    ...myFilters,
                    filters: { ...updatedParams.filters, ...myFilters.filters, ...dashboardFilterParams.filters },
                    capital_request_id: this.props.match.params.id
                };
                let project_id = this.props.recommendationDetails?.project?.id;

                await this.props.getAllRecommendations(params, project_id);
            } else if (section === "recommendationsinfo" && tab === "linked-cr-recommendations") {
                let recommendationsData = await this.getDataById(this.props.match.params.id);
                let params = {
                    ...updatedParams,
                    ...dashboardFilterParams,
                    ...myFilters,
                    filters: { ...updatedParams.filters, ...myFilters.filters, ...dashboardFilterParams.filters },
                    child_cr_recommendation_id: this.props.match.params.id,
                    is_cr: true
                };
                let project_id = recommendationsData?.project?.id;

                await this.props.getAllRecommendations(params, project_id);
            } else {
                let params = {
                    ...updatedParams,
                    ...dashboardFilterParams,
                    ...myFilters,
                    filters: { ...updatedParams.filters, ...myFilters.filters, ...dashboardFilterParams.filters }
                };
                if (this.props.isCapitalRequest) {
                    params.is_cr = true;
                }
                await this.props.getAllRecommendations(params, this.props.match.params.id);
            }
            recommendationsList = this.props.recommendationsReducer.getAllRecommendationsResponse.recommendations;
            totalCount = this.props.recommendationsReducer.getAllRecommendationsResponse.count;
            crv_total = this.props.recommendationsReducer.getAllRecommendationsResponse.crv_total;
            year_totals = this.props.recommendationsReducer.getAllRecommendationsResponse.year_totals;
            project_total = this.props.recommendationsReducer.getAllRecommendationsResponse.project_total;
        }

        let tempRecommendationsList = recommendationsList;
        // For creating dynamic keys and config data for recommendation table data
        if (recommendationsList && recommendationsList.length) {
            // setting maintenance years
            // Year totals
            if (year_totals && Object.keys(year_totals)?.length) {
                const validYearKeys = new Set();

                Object.keys(year_totals).forEach(item => {
                    const yearKey = `year_${item}`;
                    const indexToInsertBefore = tempKeys.length > 1 ? tempKeys.length - 2 : 0;

                    if (!tempKeys.includes(yearKey)) {
                        tempKeys.splice(indexToInsertBefore, 0, yearKey);
                    }

                    tempConfig[yearKey] = {
                        isVisible: tempConfig[yearKey]?.isVisible ?? true,
                        label: item,
                        class: "width-160px txt-box-table",
                        searchKey: `maintenance_years.${item}`,
                        type: "number",
                        hasWildCardSearch: true,
                        hasCommonSearch: false,
                        hasCutPaste: true,
                        getListTable: "maintenance_year",
                        commonSearchKey: "maintenance_years",
                        commonSearchObjectKey: `${item}`,
                        hasLineEdit: true,
                        pinned: tempConfig?.[yearKey]?.pinned,
                        style: tempConfig?.[yearKey]?.style
                    };

                    // Add the key to the valid set
                    validYearKeys.add(yearKey);
                });

                // Remove any year keys from tempKeys that are not in validYearKeys
                tempKeys = tempKeys.filter(key => {
                    if (key.startsWith("year_")) {
                        return validYearKeys.has(key);
                    }
                    return true;
                });

                // Remove any year keys from tempConfig that are not in validYearKeys
                Object.keys(tempConfig).forEach(key => {
                    if (key.startsWith("year_") && !validYearKeys.has(key)) {
                        delete tempConfig[key];
                    }
                });
            } else {
                // If year_totals is absent or empty, remove all year-related keys from tempKeys and tempConfig
                tempKeys = tempKeys.filter(key => !key.startsWith("year_"));

                Object.keys(tempConfig).forEach(key => {
                    if (key.startsWith("year_")) {
                        delete tempConfig[key];
                    }
                });
            }

            //setting recommendation id selection for smart chart tiles
            if (
                this.props?.isSmartChartView &&
                ((this.props?.smartChartTileConfig?.band1?.config_filter &&
                    Object.keys(this.props?.smartChartTileConfig?.band1?.config_filter).length > 0) ||
                    (this.props?.smartChartTileConfig?.config_filter && Object.keys(this.props?.smartChartTileConfig?.config_filter).length > 0) ||
                    this.props?.smartChartTileConfig?.band1?.mfilter?.recommendations?.length === 0 ||
                    this.props?.smartChartTileConfig?.recommendations?.length === 0) &&
                this.state.initialFiltersSetForSmartChart
            ) {
                this.selectWholeRecommendation();
            }
        }

        // go to previous page is the last record of the current page is deleted
        if (recommendationsList && !recommendationsList.length && paginationParams.currentPage) {
            this.setState({
                params: {
                    ...params
                    // offset: (paginationParams.currentPage - 1) * paginationParams.perPage
                }
            });
            await this.props.getAllRecommendations({
                ...this.state.params,
                year: this.state.year || null,
                capital_type: (this.isDashboardFiltered ? this.state.capital_type : null) || null,
                start_year: (this.isDashboardFiltered ? this.state.start_year : null) || null,
                end_year: (this.isDashboardFiltered ? this.state.end_year : null) || null,
                infrastructure_requests: (this.isDashboardFiltered ? this.state.infrastructure_requests : null) || null,
                dashboard: (this.isDashboardFiltered ? this.state.dashboard : null) || null,
                building_ids: (this.isDashboardFiltered ? this.state.building_ids : null) || null
            });
        }
        if (recommendationsList && !recommendationsList?.length && this.props.recommendationsReducer?.getAllRecommendationsResponse?.error) {
            await this.setState({ alertMessage: this.props.recommendationsReducer.getAllRecommendationsResponse.error });
            this.showAlert();
        }

        recommendationsList.forEach((item, i) => {
            if (item.priority_elements && item.priority_elements.length) {
                item.priority_elements.forEach(yearItem => {
                    tempRecommendationsList[i][`priority_element${yearItem.index}`] = yearItem.option_id || yearItem.element || null;
                });
            }
            if (item.maintenance_years && item.maintenance_years.length) {
                item.maintenance_years.forEach(yearItem => {
                    tempRecommendationsList[i][`year_${yearItem.year}`] = parseInt(yearItem.amount) ?? "";
                });
            }
        });

        tempKeys = _.uniq(tempKeys);
        this.setState({
            tableData: {
                ...this.state.tableData,
                keys: tempKeys,
                config: tempConfig,
                data: tempRecommendationsList
            },
            maintenance_years: year_totals ? Object.keys(year_totals) : [],
            tempRecommendationsList,
            paginationParams: {
                ...paginationParams,
                totalCount: totalCount,
                totalPages: Math.ceil(totalCount / paginationParams.perPage)
            },
            summaryRowData: {
                ...this.state.summaryRowData,
                crv_total: crv_total,
                year_totals: year_totals,
                project_total: project_total
            },
            showWildCardFilter: this.state.params.filters ? true : false,
            isLoading: false,
            selectedRecomIds: tempSelectedRecomIds,
            initialFiltersSetForSmartChart: false
        });
        if (!isRecom) this.updateEntityParams();
        return true;
    };

    updateWildCardFilter = async newFilter => {
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                filters: newFilter,
                recommendation_ids: null
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            selectedRecomIds: []
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };

    updateEntityParams = async value => {
        let entityParams = {};
        if (this.props?.isSmartChartView) {
            entityParams = {
                selectedRecomIds: this.state.selectedRecomIds,
                params: this.state.params,
                tableConfig: this.state.tableData.config,
                tableDataExportFilters: this.state.tableDataExportFilters
            };
        } else {
            entityParams = {
                entity: "Recommendation",
                selectedEntity: this.state.selectedRegion,
                paginationParams: this.state.paginationParams,
                params: this.state.params,
                wildCardFilterParams: this.state.wildCardFilterParams,
                filterParams: this.state.filterParams,
                tableConfig: value || this.state.tableData.config,
                selectedRowId: this.state.selectedRowId,
                historyPaginationParams: this.state.historyPaginationParams,
                historyParams: this.state.historyParams,
                selectedDropdown: this.state.selectedDropdown,
                selectedDropdownInitiaive: this.state.selectedDropdownInitiaive,
                building_ids: this.isDashboardFiltered ? this.state.building_ids : null,
                dashboard: this.isDashboardFiltered ? this.state.dashboard : null,
                capital_type: this.isDashboardFiltered ? this.state.capital_type : null,
                start_year: this.state.start_year || null,
                end_year: this.state.end_year || null,
                infrastructure_requests: this.state.infrastructure_requests || null,
                year: this.isDashboardFiltered ? this.state.year : null,
                initiative_ids: this.state.initiative_ids,
                recommendationList: this.state.tempRecommendationsList,
                initialTableConfig: this.state.initialTableConfig,
                selectedRecomIds: this.state.selectedRecomIds,
                dashboardFilterParams: this.state.dashboardFilterParams,
                parentSectionId: this.state.parentSectionId,
                tableDataExportFilters: this.state.tableDataExportFilters,
                myFilters: this.state.myFilters
            };
        }
        await this.props.updateRecommendationEntityParams(entityParams, this.props.match.params.section);
    };

    getListForCommonFilter = async params => {
        if (this.props.isBudgetPriority) {
            await this.getListForBudgetPriorityFilter(params);
        } else {
            const {
                match: {
                    params: { section, tab }
                }
            } = this.props;
            const { myFilters, dashboardFilterParams } = this.state;
            const {
                search,
                filters,
                list,
                view,
                deleted,
                on_hold,
                completed,
                locked,
                unlocked,
                active,
                surveyor,
                image_or_not,
                qa_passed,
                review_status,
                infrastructure_request,
                water,
                energy,
                facility_master_plan,
                recommendation_type,
                budget_priority,
                recommendation_ids,
                dynamic_joins
            } = this.state.params;
            const projectId = this.props.projectIdDashboard || this.props?.projectIdForSmartChart || this.props.match.params.id;
            if (section === "initiativeInfo" && view !== "assigned") {
                const {
                    location: { search }
                } = this.props;
                const query = qs.parse(search);
                params.project_id = query.pid;
            } else if (section !== "imageInfo" && section !== "initiativeInfo" && section !== "assetInfo") {
                params.project_id = projectId;
            }
            if (section === "initiativeInfo" && view === "assigned") {
                params.initiative_id = this.props.match.params.id || null;
            }

            if (this.isDashboardFiltered) {
                params.dashboard = this.state.dashboard;
                params.capital_type = this.state.capital_type;
                params.building_ids = this.state.building_ids;
                params.year = this.state.year;
            }

            params.search = search;
            params.filters = filters;
            params.list = list ? Object.fromEntries(Object.entries(list)?.filter(([key, value]) => !params.searchKey?.includes(key))) : null;
            params.view = view;
            params.deleted = deleted;
            params.on_hold = on_hold;
            params.completed = completed;
            params.locked = locked;
            params.unlocked = unlocked;
            params.active = active;
            params.surveyor = surveyor;
            params.image_or_not = image_or_not;
            params.qa_passed = qa_passed;
            params.review_status = review_status;
            params.water = water;
            params.energy = energy;
            params.facility_master_plan = facility_master_plan;
            params.recommendation_type = recommendation_type;
            params.budget_priority = budget_priority;
            params.infrastructure_request = infrastructure_request;
            params.start_year = this.state.start_year;
            params.end_year = this.state.end_year;
            params.infrastructure_requests = this.state.infrastructure_requests;
            params.recommendation_ids = recommendation_ids;
            params.dynamic_joins = dynamic_joins;
            if (section === "buildinginfo") {
                params.building_id = this.props.match.params.id;
            } else if (section === "siteinfo") {
                params.site_id = this.props.match.params.id;
            } else if (section === "regioninfo") {
                params.region_id = this.props.match.params.id;
            } else if (section === "imageInfo") {
                params.image_id = this.props.match.params.id;
            } else if (section === "assetInfo") {
                params.asset_id = this.props.match.params.id;
            } else if (this.props?.isSmartChartView) {
                const { smartChartFilters = {} } = this.props;
                params = { ...params, ...smartChartFilters };
            } else if (section === "recommendationsinfo" && tab === "linked-recommendations") {
                params.capital_request_id = this.props.match.params.id;
            } else if (section === "recommendationsinfo" && tab === "linked-cr-recommendations") {
                params.recommendation_id = this.props.match.params.id;
                params.is_cr = true;
            } else if (this.props?.isCapitalRequest) {
                params.is_cr = true;
            }
            await this.props.getListForCommonFilterrecommendation({
                ...params,
                ...dashboardFilterParams,
                ...myFilters,
                filters: { ...params.filters, ...myFilters.filters, ...dashboardFilterParams.filters }
            });
        }
        return this.props.recommendationsReducer.getListForCommonFilterResponse || [];
    };

    getListForBudgetPriorityFilter = async params => {
        const {
            search,
            filters,
            list,
            view,
            deleted,
            on_hold,
            completed,
            locked,
            unlocked,
            active,
            surveyor,
            image_or_not,
            qa_passed,
            review_status,
            infrastructure_request,
            water,
            energy,
            facility_master_plan,
            recommendation_type,
            budget_priority
        } = this.state.params;
        params.search = search;
        params.filters = filters;
        params.list = list ? Object.fromEntries(Object.entries(list)?.filter(([key, value]) => key !== params?.field)) : null;
        params.view = view;
        params.deleted = deleted;
        params.on_hold = on_hold;
        params.completed = completed;
        params.locked = locked;
        params.unlocked = unlocked;
        params.active = active;
        params.surveyor = surveyor;
        params.image_or_not = image_or_not;
        params.qa_passed = qa_passed;
        params.review_status = review_status;
        params.infrastructure_request = infrastructure_request;
        params.water = water;
        params.energy = energy;
        params.facility_master_plan = facility_master_plan;
        params.recommendation_type = recommendation_type;
        params.budget_priority = budget_priority;
        await this.props.getListForBudgetPriorityFilter({ ...params, ...this.props.dashboardFilterParams });
    };

    resetAllFilters = async () => {
        await this.setState({
            selectedRegion: null,
            paginationParams: {
                totalPages: 0,
                perPage: 40,
                currentPage: 0,
                totalCount: 0
            },
            params: {
                ...this.state.params,
                limit: 40,
                offset: 0,
                search: "",
                project_id: null,
                filters: null,
                list: null,
                deleted: null,
                view: this.props.match.params.tab === "recommendation" ? "assigned" : null,
                locked: null,
                unlocked: null,
                active: this.props.match.params.section === "initiativeInfo" ? null : true,
                on_hold: null,
                completed: null,
                image_or_not: null,
                infrastructure_request: null,
                water: null,
                energy: null,
                facility_master_plan: null,
                recommendation_type: null,
                budget_priority: null,
                recommendation_ids: null,
                surveyor: null,
                review_status: null,
                qa_passed: null
            },
            wildCardFilterParams: {},
            filterParams: {},
            selectedRowId: null,
            selectedDropdown: "active",
            selectedDropdownInitiaive: "assigned",
            tableDataExportFilters: {},
            myFilters: {}
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };

    resetSort = async () => {
        await this.setState({
            params: {
                ...this.state.params,
                order: null,
                index: [],
                year: [],
                maintenance_year: [],
                recommendation_ids: null
            },
            selectedRecomIds: []
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };

    updateCommonFilter = async (commonFilters, exportFilters) => {
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                list: commonFilters,
                recommendation_ids: null
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            tableDataExportFilters: exportFilters,
            selectedRecomIds: []
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };

    updateTableSortFilters = async (searchKey, val) => {
        let tempYear = [];
        let tempIndex = [];
        let main_key = searchKey.split(".")[0];
        if (main_key === "maintenance_years") {
            if (!tempYear.includes(val)) {
                tempYear.push(val);
            }
            if (this.state.params.order) {
                await this.setState({
                    params: {
                        ...this.state.params,
                        order: {
                            ...this.state.params.order,
                            ["maintenance_years.amount"]: this.state.params.order["maintenance_years.amount"] === "desc" ? "asc" : "desc"
                        },
                        maintenance_year: tempYear
                    }
                });
            } else {
                await this.setState({
                    params: {
                        ...this.state.params,
                        order: { ["maintenance_years.amount"]: "asc" },
                        maintenance_year: tempYear
                    }
                });
            }
        } else if (main_key === "priority_elements") {
            //changes of priority elements
            if (!tempIndex.includes(searchKey.split(".")[1])) {
                tempIndex.push(searchKey.split(".")[1]);
            }
            if (this.state.params.order) {
                await this.setState({
                    params: {
                        ...this.state.params,
                        order: {
                            ...this.state.params.order,
                            ["options.name"]: this.state.params.order["options.name"] === "desc" ? "asc" : "desc"
                        },
                        index: tempIndex
                    }
                });
            } else {
                await this.setState({
                    params: {
                        ...this.state.params,
                        order: { ["options.name"]: "asc" },
                        index: tempIndex
                    }
                });
            }
        } else {
            if (this.state.params.order) {
                if (searchKey === "recommendations.usefull_life_remaining") {
                    await this.setState({
                        params: {
                            ...this.state.params,
                            order: {
                                ...this.state.params.order,
                                [searchKey]: this.state.params.order[searchKey] === "desc" ? "asc" : "desc",
                                "assets.usefull_life_remaining": this.state.params.order[searchKey] === "desc" ? "asc" : "desc"
                            }
                        }
                    });
                } else {
                    await this.setState({
                        params: {
                            ...this.state.params,
                            order: {
                                ...this.state.params.order,
                                [searchKey]: this.state.params.order[searchKey] === "desc" ? "asc" : "desc"
                            }
                        }
                    });
                }
            } else {
                if (searchKey === "recommendations.usefull_life_remaining") {
                    await this.setState({
                        params: {
                            ...this.state.params,
                            order: { [searchKey]: "asc", "assets.usefull_life_remaining": "asc" }
                        }
                    });
                } else {
                    await this.setState({
                        params: {
                            ...this.state.params,
                            order: { [searchKey]: "asc" }
                        }
                    });
                }
            }
        }
        await this.setState({ params: { ...this.state.params, recommendation_ids: null }, selectedRecomIds: [] });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };

    updateLastSortFilter = async (searchKey, val) => {
        if (this.state.params.order && this.state.params?.order[searchKey]) {
            await this.setState({
                params: {
                    ...this.state.params,
                    order: {
                        [searchKey]: this.state.params.order[searchKey] === "asc" ? "desc" : "asc"
                    }
                }
            });
        } else {
            await this.setState({
                params: {
                    ...this.state.params,
                    order: { [searchKey]: "desc" }
                }
            });
        }
        await this.setState({ params: { ...this.state.params, recommendation_ids: null }, selectedRecomIds: [] });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };

    handleHideColumn = async tableConfig => {
        //unpin hidden columns
        const { keys, config } = tableConfig;
        Object.keys(config)
            .filter(key => !config[key]?.isVisible && config[key]?.pinned)
            .forEach(key => {
                config[key] = {
                    ...config[key],
                    pinned: false
                };
            });
        const pinnedColumns = keys.filter(key => config[key]?.isVisible && config[key]?.pinned);
        const columnLeftValues = getPinnedColumnLeftPositions(pinnedColumns, this.pinnedColumnsRef);

        pinnedColumns.forEach(key => {
            config[key] = {
                ...config[key],
                style: {
                    ...config[key]?.style,
                    left: columnLeftValues[key] || "auto"
                }
            };
        });
        await this.setState({
            tableData: {
                ...this.state.tableData,
                keys,
                config
            }
        });

        await this.setDynamicJoinParam();
        await this.updateEntityParams();
        this.saveUserTableConfig();
        this.refreshRecommendationsList();
    };

    updateSelectedRow = async rowId => {
        await this.setState({
            selectedRowId: rowId
        });
        await this.updateEntityParams();
    };

    handlePerPageChange = async e => {
        const { paginationParams } = this.state;
        await this.setState({
            paginationParams: {
                ...paginationParams,
                perPage: e.target.value,
                currentPage: 0
            },
            params: {
                ...this.state.params,
                offset: 0,
                limit: e.target.value
            }
        });
        await this.refreshRecommendationsList();
    };

    handlePageClick = async page => {
        const { paginationParams, params } = this.state;
        await this.setState({
            paginationParams: {
                ...paginationParams,
                currentPage: page.selected
            },
            params: {
                ...params,
                // offset: page.selected * paginationParams.perPage
                offset: page.selected + 1
            }
        });
        await this.refreshRecommendationsList();
    };

    updateCurrentActions = async key => {
        const { currentActions } = this.state;
        await this.setState({
            currentActions: currentActions === key ? null : key
        });
        return true;
    };

    updateCurrentViewAllUsers = async key => {
        const { currentViewAllUsers } = this.state;
        await this.setState({
            currentViewAllUsers: currentViewAllUsers === key ? null : key
        });
        return true;
    };

    showEditPage = recommendationsId => {
        if (this?.tableRef?.current?.scrollLeft || this?.tableRef?.current?.scrollLeft === 0) {
            this.props.setRecommendationScrollPosition(this?.tableRef?.current?.scrollLeft === 0 ? 1 : this?.tableRef?.current?.scrollLeft);
        }
        // if (this.tableRef?.current?.scrollTop) {
        //     this.props.setRecommendationScrollPosition(this.tableRef.current.scrollTop);
        // }

        const {
            history,
            location: { search = "" },
            match: {
                params: { section, id: matchId, tab }
            },
            isCapitalRequest,
            projectIdDashboard,
            projectReducer,
            recommendationsReducer,
            isBudgetPriority,
            recommendationDetails
        } = this.props;
        this.setState({
            selectedRecommendations: recommendationsId
        });

        const project_id = projectIdDashboard || matchId;
        const client_id = projectReducer?.getProjectByIdResponse?.client?.id || "";

        let projectid_budget = recommendationsReducer.getAllRecommendationsResponse.recommendations
            ?.filter(item => item.id === recommendationsId)
            .map(item => item.project_id);
        let clientid_budget = recommendationsReducer.getAllRecommendationsResponse.recommendations
            ?.filter(item => item.id === recommendationsId)
            .map(item => item.client?.id);

        const currentParams = qs.parse(search);
        const newParams = { ...currentParams };

        if (isCapitalRequest) {
            newParams.is_cr = true;
        }

        let newPath;
        if (section === "initiativeInfo") {
            newPath = `/recommendations/edit/${recommendationsId}`;
        } else if (projectid_budget && clientid_budget && isBudgetPriority) {
            newParams.p_id = projectid_budget[0] ? projectid_budget[0] : matchId;
            newParams.c_id = clientid_budget[0] ? clientid_budget[0] : "";
            newPath = `/recommendations/edit/${recommendationsId}?${qs.stringify(newParams)}`;
        } else if (tab === "linked-recommendations") {
            const { project, client } = recommendationDetails || {};
            newParams.is_cr = false;
            newParams.p_id = project?.id;
            newParams.c_id = client?.id;
            newPath = `/recommendations/edit/${recommendationsId}?${qs.stringify(newParams)}`;
        } else {
            newParams.p_id = project_id;
            newParams.c_id = client_id;
            newPath = `/recommendations/edit/${recommendationsId}?${qs.stringify(newParams)}`;
        }

        addToBreadCrumpData({
            key: "edit",
            name: `Edit ${isCapitalRequest ? "Capital Request" : "Recommendations"}`,
            path: newPath
        });

        history.push(newPath);
    };

    showAddForm = () => {
        const project_id = this.props.projectIdDashboard || this.props.match.params.id;
        const client_id = this.props.projectReducer?.getProjectByIdResponse?.client?.id;
        if (this?.tableRef?.current?.scrollLeft || this?.tableRef?.current?.scrollLeft === 0) {
            this.props.setRecommendationScrollPosition(this?.tableRef?.current?.scrollLeft === 0 ? 1 : this?.tableRef?.current?.scrollLeft);
        }
        if (project_id && client_id) {
            const {
                history,
                location: { search = "" },
                isCapitalRequest
            } = this.props;

            this.setState({
                selectedRecommendations: null
            });

            const currentParams = qs.parse(search);
            let newParams = {
                ...currentParams,
                p_id: project_id,
                c_id: client_id,
                type: "Regular"
            };
            if (isCapitalRequest) {
                newParams.is_cr = true;
            }
            const newPath = `/recommendations/add?${qs.stringify(newParams)}`;
            addToBreadCrumpData({
                key: "add",
                name: `Add ${isCapitalRequest ? "Capital Request" : "Recommendation"}`,
                path: newPath
            });
            history.push(newPath);
        }
    };

    showViewModal = () => {
        if (this.props.isSmartChartView && this.props?.recomType === "normal") {
            this.showViewModalExport();
        } else {
            this.setState({
                showViewModal: true
            });
        }
    };

    // showViewImport = () => {
    //     this.setState({
    //         showViewImport: true
    //     });
    // };

    showViewModalExport = type => {
        this.setState({
            showViewModalExport: { show: true, type: type }
        });
    };

    getSiteListBasedOnRegion = async (recommendationsId, params) => {
        await this.props.getSitesBasedOnRegion(recommendationsId, params);
        const {
            buildingReducer: {
                getSitesBasedOnRegionResponse: { sites: siteList }
            }
        } = this.props;
        return siteList;
    };

    getDepartmentByProject = async projectId => {
        const department = await this.props.getDepartmentByProject(projectId);
    };

    getSystemBasedOnProject = async projectId => {
        const system = await this.props.getSystemBasedOnProject(projectId);
        return system;
    };

    getFloorBasedOnBuilding = async buildingId => {
        const system = await this.props.getFloorBasedOnBuilding(buildingId);
        return system;
    };
    getSubSystemBasedOnProject = async (projectId, systemId) => {
        const system = await this.props.getSubSystemBasedOnProject(projectId, systemId);
    };
    getTradeBasedOnProject = async projectId => {
        const system = await this.props.getTradeBasedOnProject(projectId);
    };

    getCategoryBasedOnProject = async projectId => {
        const department = await this.props.getCategoryBasedOnProject(projectId);
        const {
            buildingReducer: {
                getSitesBasedOnRegionResponse: { sites: siteList }
            }
        } = this.props;
        return siteList;
    };

    getRegionListBasedOnClient = async clientId => {
        await this.props.getRegionsBasedOnClient(clientId);
        const {
            recommendationsReducer: {
                getRegionsBasedOnClientResponse: { regions: regionList }
            }
        } = this.props;
        return regionList;
    };

    toggleWildCardFilter = () => {
        const { showWildCardFilter } = this.state;
        this.setState({
            showWildCardFilter: !showWildCardFilter
        });
    };

    handleAssignConsultancyUsersModal = async recommendationsData => {
        this.setState({
            showAssignConsultancyUsers: true
        });
    };

    handleAssignClientUsersModal = async recommendationsData => {
        this.setState({
            showAssignClientUsers: true
        });
    };

    handleAddRecommendations = async recommendation => {
        const { history } = this.props;
        await this.props.addRecommendation(recommendation);
        const { success, recommendation_code, recommendation_id, error, message } = this.props.recommendationsReducer.addRecommendationResponse || {};
        if (!success || error) {
            await this.setState({
                alertMessage: error || message
            });
            this.showAlert();
        } else {
            await this.setState({
                alertMessage: message
            });
            this.showAlert();
            if (findPrevPathFromBreadCrumpData()?.split("/")[2] === "recommendationsinfo" && !recommendation?.recommendation_capital_type_id) {
                popBreadCrumpOnPageClose();
            }
            let currentBreadCrumpData = JSON.parse(sessionStorage.getItem("bc-data"));
            const lastInfoIndex = currentBreadCrumpData.reduceRight((acc, item) => {
                if (acc === null && item.key === "Name") {
                    return item;
                }
                return acc;
            }, null);
            if (lastInfoIndex) {
                const isCrInPath = lastInfoIndex?.path.includes("is_cr=true");
                if (
                    ((!isCrInPath && !recommendation?.is_cr) || (recommendation?.is_cr && isCrInPath)) &&
                    lastInfoIndex?.path?.startsWith("/recommendations/recommendationsinfo/")
                ) {
                    updateBreadCrumpData(lastInfoIndex?.index);
                }
            }
            addToBreadCrumpData({
                key: "Name",
                name: recommendation_code,
                path: `/recommendations/recommendationsinfo/${recommendation_id}/maindetails${recommendation?.is_cr ? "?is_cr=true" : ""}`
            });
            addToBreadCrumpData({
                key: "info",
                name: "Main Details",
                path: `/recommendations/recommendationsinfo/${recommendation_id}/maindetails${recommendation?.is_cr ? "?is_cr=true" : ""}`
            });
            sessionStorage.removeItem("currentRecommendationData");
            this.showInfoPage(recommendation_id, null, null, recommendation?.is_cr);
        }
    };

    handleUpdateRecommendations = async (recommendation, selectedImage, recId = null) => {
        const { history } = this.props;
        const { selectedRecommendations } = this.state;
        await this.props.updateRecommendation(recommendation, recId ? recId : selectedRecommendations, selectedImage);
        const { success, error, message } = this.props.recommendationsReducer.updateRecommendationResponse;
        if (!success || error) {
            await this.setState({
                alertMessage: message || "Failed to update Recommendation!"
            });
            this.showAlert();
        } else {
            this.setState({
                currentActions: null
            });
            await this.setState({
                alertMessage: this.props.recommendationsReducer.updateRecommendationResponse?.message
            });
            this.showAlert();
            this.props.history.push(findPrevPathFromBreadCrump() || "/recommendations");
            popBreadCrumpOnPageClose();
        }
    };

    handleDeleteRecommendations = async (id, isDeleted) => {
        const entity = this.props.isCapitalRequest ? permissions.CR_RECOMMENDATIONS : permissions.RECOMMENDATIONS;
        if (!checkPermission("forms", entity, "hard_delete") && isDeleted) {
            await this.setState({
                alertMessage: "You are not authorised to perform the Hard Delete !"
            });
            this.showAlert();
        } else {
            await this.setState({
                showConfirmModal: true,
                selectedRecommendations: id,
                isDeleted: isDeleted
            });
        }
    };

    renderConfirmationModal = () => {
        const { showConfirmModal, isDeleted } = this.state;
        if (!showConfirmModal) return null;
        const entity = this.props.isCapitalRequest ? permissions.CR_RECOMMENDATIONS : permissions.RECOMMENDATIONS;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Do you want to delete this ${this.props.isCapitalRequest ? "Capital Request" : "Recommendation"}?`}
                        message={""}
                        onNo={() => this.setState({ showConfirmModal: false })}
                        onYes={ishardDelete => {
                            ishardDelete
                                ? this.setState({ showHardDeleteConfirmationModal: true })
                                : this.deleteRecommendationOnConfirm(ishardDelete);
                        }}
                        onHardDelete={this.deleteRecommendationOnConfirm}
                        isHard={checkPermission("forms", entity, "hard_delete")}
                        isDeleted={isDeleted}
                    />
                }
                onCancel={() => this.setState({ showConfirmModal: false })}
            />
        );
    };
    renderHardDeleteConfirmationModal = () => {
        const { showHardDeleteConfirmationModal } = this.state;
        if (!showHardDeleteConfirmationModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Are you sure you want to permanently (Hard) delete this ${
                            this.props.isCapitalRequest ? "Capital Request" : "Recommendation"
                        }?`}
                        type="cancel"
                        onNo={() => this.setState({ showHardDeleteConfirmationModal: false })}
                        onYes={() => {
                            this.setState({ showHardDeleteConfirmationModal: false });
                            this.deleteRecommendationOnConfirm(true);
                        }}
                    />
                }
                onCancel={() => this.setState({ showHardDeleteConfirmationModal: false })}
            />
        );
    };

    deleteRecommendationOnConfirm = async (ishardDelete = false) => {
        const { selectedRecommendations, selectedRecomIds, params } = this.state;
        const {
            match: {
                params: { tab }
            }
        } = this.props;

        const idToBeDeleted = tab === "maindetails" ? this.props.match.params.id : selectedRecommendations;

        if (ishardDelete) {
            this.setState({
                showConfirmModal: false,
                isLoading: true
            });
            await this.props.deleteRecommendation(idToBeDeleted, true);
        } else {
            this.setState({
                showConfirmModal: false,
                isLoading: true
            });
            await this.props.deleteRecommendation(idToBeDeleted);
        }
        if (this.props.recommendationsReducer.deleteRecommendationResponse && this.props.recommendationsReducer.deleteRecommendationResponse.error) {
            await this.setState({
                alertMessage: this.props.recommendationsReducer.deleteRecommendationResponse.error,
                showConfirmModal: false,
                isRedirectionOnDelete: false,
                selectedRecommendations: null
            });
            this.showAlert();
        } else {
            await this.refreshRecommendationsList();
            // await this.props.getMenuItems();
            this.setState(
                {
                    alertMessage:
                        this.props.recommendationsReducer.deleteRecommendationResponse?.message ||
                        `${this.props.isCapitalRequest ? "Capital Request" : "Recommendation"} deleted successfully`,
                    showConfirmModal: false,
                    selectedRecommendations: null,
                    selectedRecomIds: selectedRecomIds.filter(elem => elem.id !== selectedRecommendations),
                    params: {
                        ...params,
                        recommendation_ids: params?.recomentation_ids?.length
                            ? params.recommendation_ids?.filter(elem => elem !== selectedRecommendations)
                            : null
                    }
                },
                () => this.showAlert()
            );
            if (this.state.isRedirectionOnDelete) {
                popBreadCrumpRecData();
                const redirectionurl = findPrevPathFromBreadCrumpRecData();
                this.props.history.push(findPrevPathFromBreadCrumpData() || "/recommendations");
                // this.props.history.push(redirectionurl[2].path || "/recommendations");
            }
            this.setState({
                isRedirectionOnDelete: false
            });
        }
        this.setState({
            isLoading: false
        });
    };

    handleGlobalSearch = async search => {
        const { params } = this.state;
        await this.setState({
            params: {
                ...params,
                offset: 0,
                search,
                recommendation_ids: null
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            selectedRecomIds: []
        });
        await this.refreshRecommendationsList();
    };

    showInfoPage = (recommendationsId, omit, rowData, isCR = false) => {
        const {
            history,
            location: { search = "" },
            match: {
                params: { tab }
            },
            isCapitalRequest
        } = this.props;
        if (this?.tableRef?.current?.scrollLeft || this?.tableRef?.current?.scrollLeft === 0) {
            this.props.setRecommendationScrollPosition(this?.tableRef?.current?.scrollLeft === 0 ? 1 : this?.tableRef?.current?.scrollLeft);
        }
        // if (this.tableRef?.current?.scrollTop) {
        //     this.props.setRecommendationScrollPosition(this.tableRef.current.scrollTop);
        // }
        this.setState({
            selectedRecommendations: recommendationsId
        });
        let tabKeyList = [
            "maindetails",
            "additionaldetails",
            "infoimages",
            "assetdetails",
            "documents",
            "capitalrequest",
            "qaqc",
            "linked-recommendations",
            "linked-cr-recommendations"
        ];
        let selectedTab =
            (tab === "linked-recommendations" || tab === "linked-cr-recommendations") && rowData?.id
                ? "maindetails"
                : tabKeyList.includes(tab)
                ? tab
                : "maindetails";
        const currentParams = qs.parse(search);
        const newParams = { ...currentParams };
        if (isCapitalRequest || isCR) {
            newParams.is_cr = true;
        } else {
            delete newParams.is_cr;
        }

        if (rowData?.id) {
            addTableSliderDataToBreadcrumb(this.state.tableData?.data || []);
        }
        const newPath = `/recommendations/recommendationsinfo/${recommendationsId}/${selectedTab}?${qs.stringify(newParams)}`;
        history.push(newPath);
    };

    getDataById = async recommendationsId => {
        await this.props.getRecommendationById(recommendationsId);
        return this.props.recommendationsReducer.getRecommendationByIdResponse || {};
    };

    qaqcPendingStatus = () => {
        const recommedationReviews = this.props.recommendationsReducer.getAllRecommendationReviewsResponse?.recommendation_reviews;
        if (recommedationReviews?.some(({ status }) => status === "pending")) return "pending";
        if (recommedationReviews?.some(({ status }) => status === "resolved")) return "resolved";
        if (this.props.recommendationsReducer.getRecommendationByIdResponse?.qa_passed) return "qaPassed";
        if (!recommedationReviews?.length) return null;
        if (recommedationReviews?.every(({ status }) => status === "approved")) return "approved";
        return null;
    };
    getQaQcData = async params => {
        // if (localStorage.getItem("role") === "client_user") return null;
        await this.props.getAllRecommendationReviews(params);
        return this.props.recommendationsReducer.getAllRecommendationReviewsResponse || {};
    };

    isProjectReviewer = async params => {
        // if (localStorage.getItem("role") === "client_user") return null;
        const projectId = params.project_id;
        // const reviewProjcts = sessionStorage.getItem("reviewProjcts") ? JSON.parse(sessionStorage.getItem("reviewProjcts")) : [];
        // if (reviewProjcts?.includes(projectId)) return true;
        await this.props.getReviewersByProject(params);
        if (!!this.props.projectReducer.getReviewersByProjectData?.reviewer?.length) {
            // sessionStorage.setItem("reviewProjcts", JSON.stringify([...reviewProjcts, projectId]));
            return true;
        }
        return false;
    };
    uploadImages = async (imageData = {}) => {
        const { selectedRecommendations } = this.state;
        await this.props.uploadRecommendationImage(imageData, selectedRecommendations || this.props.match.params.id);
        // await this.getAllImageList(selectedRecommendations);
        return true;
    };

    deleteImages = async imageId => {
        const { selectedRecommendations } = this.state;
        await this.props.deleteRecommendationImage(imageId);
        const { success, message, error = "Something went wrong" } = this.props.recommendationsReducer.deleteImagesResponse || {};
        if (success) {
            await this.setState({
                alertMessage: message
            });
            this.showAlert();
        } else {
            await this.setState({
                alertMessage: error
            });
            this.showAlert();
        }
        // await this.getAllImageList(selectedRecommendations);
        return true;
    };

    getAllImageList = async (recommendationsId, params) => {
        await this.props.getAllRecommendationImages(recommendationsId, params);
        const {
            recommendationsReducer: { getAllImagesResponse }
        } = this.props;
        await this.setState({
            imageResponse: getAllImagesResponse
        });
        return true;
    };

    updateRecommendationImageComment = async imageData => {
        const { selectedRecommendations } = this.state;
        await this.props.updateRecommendationImageComment(imageData);
        // await this.getAllImageList(selectedRecommendations);
        return true;
    };

    openDeleteBox = (isDeleted = false) => {
        this.setState({
            showConfirmModal: true,
            isRedirectionOnDelete: true,
            isDeleted: isDeleted
        });
    };
    openRestoreBox = () => {
        this.setState({
            showRestoreConfirmModal: true,
            isRedirectionOnDelete: true
        });
    };
    handleCutPaste = async current_year => {
        await this.setState({
            selectedYear: current_year.split("_")[1]
        });

        this.toggleShowCutPasteModal();
    };
    toggleShowCutPasteModal = async () => {
        await this.setState({
            showCutPasteModal: !this.state.showCutPasteModal
        });
    };
    ShowCutPasteModalConfirm = async target_year => {
        this.toggleShowCutPasteModal();
        await this.setState({
            showConfirmCopyPasteModal: true,
            target_year: target_year
        });
    };
    renderCutPasteConfirmationModal = () => {
        const { showConfirmCopyPasteModal } = this.state;
        if (!showConfirmCopyPasteModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Do you want to move ${this.props.isCapitalRequest ? "Capital Request" : "Recommendation"} Cost?`}
                        message={
                            "Moving Recommendations costs from one year to another will OVERWRITE these existing Recommendations costs in Target Year,Are you sure you want to continue?"
                        }
                        onNo={() => this.setState({ showConfirmCopyPasteModal: false })}
                        onYes={this.HandleCutPasteSubmit}
                        isCutPaste={true}
                        type={"cutpaste"}
                    />
                }
                onCancel={() => this.setState({ showConfirmCopyPasteModal: false })}
            />
        );
    };
    HandleCutPasteSubmit = async () => {
        const {
            match: {
                params: { section }
            }
        } = this.props;
        const projectId = this.props.match.params.id;
        const { selectedYear, target_year } = this.state;
        let {
            search,
            filters,
            list,
            order,
            view,
            deleted,
            on_hold,
            completed,
            locked,
            unlocked,
            active,
            surveyor,
            image_or_not,
            qa_passed,
            review_status,
            infrastructure_request,
            water,
            energy,
            facility_master_plan,
            recommendation_type,
            budget_priority,
            recommendation_ids
        } = this.state.params;
        let tempFilters = filters ? JSON.stringify(filters) : null;
        let tempList = list ? JSON.stringify(list) : null;
        let tempOrder = order ? JSON.stringify(order) : null;
        recommendation_ids = recommendation_ids ? JSON.stringify(recommendation_ids) : null;
        let cutpasteParams = {};
        if (section === "regioninfo") {
            cutpasteParams = {
                project_id: this.props.projectIdDashboard,
                region_id: this.props.match.params.id,
                from: parseInt(selectedYear),
                to: parseInt(target_year),
                filters: tempFilters,
                search,
                list: tempList,
                order: tempOrder,
                view,
                deleted,
                on_hold,
                completed,
                locked,
                unlocked,
                active,
                surveyor,
                image_or_not,
                qa_passed,
                review_status,
                infrastructure_request,
                water,
                energy,
                facility_master_plan,
                recommendation_type,
                budget_priority,
                recommendation_ids
            };
        } else if (section === "siteinfo") {
            cutpasteParams = {
                project_id: this.props.projectIdDashboard,
                site_id: this.props.match.params.id,
                from: parseInt(selectedYear),
                to: parseInt(target_year),
                filters: tempFilters,
                search,
                list: tempList,
                order: tempOrder,
                view,
                deleted,
                on_hold,
                completed,
                locked,
                unlocked,
                active,
                surveyor,
                image_or_not,
                qa_passed,
                review_status,
                infrastructure_request,
                water,
                energy,
                facility_master_plan,
                recommendation_type,
                budget_priority,
                recommendation_ids
            };
        } else if (section === "buildinginfo") {
            cutpasteParams = {
                project_id: this.props.projectIdDashboard,
                building_id: this.props.match.params.id || null,
                from: parseInt(selectedYear),
                to: parseInt(target_year),
                filters: tempFilters,
                search,
                list: tempList,
                order: tempOrder,
                view,
                deleted,
                on_hold,
                completed,
                locked,
                unlocked,
                active,
                surveyor,
                image_or_not,
                qa_passed,
                review_status,
                infrastructure_request,
                water,
                energy,
                facility_master_plan,
                recommendation_type,
                budget_priority,
                recommendation_ids
            };
        } else {
            cutpasteParams = {
                project_id: projectId,
                from: parseInt(selectedYear),
                to: parseInt(target_year),
                filters: tempFilters,
                search,
                list: tempList,
                order: tempOrder,
                view,
                deleted,
                on_hold,
                completed,
                locked,
                unlocked,
                active,
                surveyor,
                image_or_not,
                qa_passed,
                review_status,
                infrastructure_request,
                water,
                energy,
                facility_master_plan,
                recommendation_type,
                budget_priority,
                recommendation_ids
            };
        }
        await this.props.updateMaintenanceYearCutPaste(cutpasteParams);
        if (
            this.props.recommendationsReducer.updateMaintenanceYearCutPasteResponse &&
            this.props.recommendationsReducer.updateMaintenanceYearCutPasteResponse.error
        ) {
            await this.setState({
                alertMessage: this.props.recommendationsReducer.updateMaintenanceYearCutPasteResponse.error,
                selectedYear: "",
                showConfirmCopyPasteModal: false,
                target_year: ""
            });
            this.showAlert();
        } else {
            this.setState({
                selectedYear: "",
                showConfirmCopyPasteModal: false,
                target_year: ""
            });
            // this.toggleShowCutPasteModal();
            this.refreshRecommendationsList();
        }
    };

    exportTableXl = async type => {
        if (type === "crossWalk") {
            this.exportCrossWalkExcel();
            return;
        }
        const entityId = this.props.match.params.id;
        const {
            match: {
                params: { section }
            },
            location: { search }
        } = this.props;
        const {
            tableData: { keys, config },
            myFilters,
            dashboardFilterParams
        } = this.state;
        const query = qs.parse(search);
        await this.setState({ tableLoading: true });
        let hide_columns = [""];
        keys.forEach((keyItem, i) => {
            if (config && !config[keyItem]?.isVisible) {
                hide_columns.push(config[keyItem]?.label);
            }
        });
        section === "regioninfo"
            ? await this.props.exportRecommendationByRegion({
                  ...this.state.params,
                  project_id: query.pid,
                  region_id: entityId,
                  search: this.state.params.search,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  building_ids: this.state.building_ids,
                  capital_type: this.state.capital_type,
                  start_year: this.state.start_year || null,
                  end_year: this.state.end_year || null,
                  infrastructure_requests: this.state.infrastructure_requests || null,
                  year: this.state.year,
                  dashboard: this.state.dashboard,
                  hide_columns,
                  ...dashboardFilterParams,
                  ...myFilters,
                  filters: { ...this.state.params.filters, ...myFilters.filters, ...dashboardFilterParams.filters }
              })
            : section === "siteinfo"
            ? await this.props.exportRecommendationBySite({
                  ...this.state.params,
                  project_id: query.pid,
                  site_id: entityId,
                  search: this.state.params.search,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  building_ids: this.state.building_ids,
                  capital_type: this.state.capital_type,
                  start_year: this.state.start_year || null,
                  end_year: this.state.end_year || null,
                  infrastructure_requests: this.state.infrastructure_requests || null,
                  year: this.state.year,
                  dashboard: this.state.dashboard,
                  hide_columns,
                  ...dashboardFilterParams,
                  ...myFilters,
                  filters: { ...this.state.params.filters, ...myFilters.filters, ...dashboardFilterParams.filters }
              })
            : section === "buildinginfo"
            ? await this.props.exportRecommendationByBuilding({
                  ...this.state.params,
                  project_id: query.pid,
                  building_id: entityId || null,
                  search: this.state.params.search,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  building_ids: this.state.building_ids,
                  capital_type: this.state.capital_type,
                  start_year: this.state.start_year || null,
                  end_year: this.state.end_year || null,
                  infrastructure_requests: this.state.infrastructure_requests || null,
                  year: this.state.year,
                  dashboard: this.state.dashboard,
                  hide_columns,
                  ...dashboardFilterParams,
                  ...myFilters,
                  filters: { ...this.state.params.filters, ...myFilters.filters, ...dashboardFilterParams.filters }
              })
            : section === "initiativeInfo"
            ? await this.props.exportRecommendations({
                  ...this.state.params,
                  initiative_id: entityId || null,
                  search: this.state.params.search,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  building_ids: this.state.building_ids,
                  capital_type: this.state.capital_type,
                  start_year: this.state.start_year || null,
                  end_year: this.state.end_year || null,
                  infrastructure_requests: this.state.infrastructure_requests || null,
                  year: this.state.year,
                  dashboard: this.state.dashboard,
                  view: this.state.params.view,
                  hide_columns,
                  ...myFilters,
                  filters: { ...this.state.params.filters, ...myFilters.filters, ...dashboardFilterParams.filters }
              })
            : this.props.isImageView
            ? await this.props.exportRecommendations({
                  ...this.state.params,
                  image_id: this.props.imageId,
                  search: this.state.params.search,
                  filters: this.state.params.filters,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  building_ids: this.state.building_ids,
                  capital_type: this.state.capital_type,
                  start_year: this.state.start_year || null,
                  end_year: this.state.end_year || null,
                  infrastructure_requests: this.state.infrastructure_requests || null,
                  year: this.state.year,
                  dashboard: this.state.dashboard,
                  view: this.state.params.view,
                  hide_columns
              })
            : section === "assetInfo"
            ? await this.props.exportRecommendations({
                  ...this.state.params,
                  asset_id: entityId || null,
                  search: this.state.params.search,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  building_ids: this.state.building_ids,
                  capital_type: this.state.capital_type,
                  start_year: this.state.start_year || null,
                  end_year: this.state.end_year || null,
                  infrastructure_requests: this.state.infrastructure_requests || null,
                  year: this.state.year,
                  dashboard: this.state.dashboard,
                  view: this.state.params.view,
                  hide_columns,
                  ...dashboardFilterParams,
                  ...myFilters,
                  filters: { ...this.state.params.filters, ...myFilters.filters, ...dashboardFilterParams.filters }
              })
            : this.props.isBudgetPriority
            ? await this.props.exportBudgetPriorityRecommendations({
                  ...this.state.params,
                  search: this.state.params.search,
                  filters: this.state.params.filters,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  hide_columns,
                  ...this.props.dashboardFilterParams
              })
            : await this.props.exportRecommendations({
                  ...this.state.params,
                  project_id: entityId,
                  search: this.state.params.search,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  building_ids: this.state.building_ids,
                  capital_type: this.state.capital_type,
                  start_year: this.state.start_year || null,
                  end_year: this.state.end_year || null,
                  infrastructure_requests: this.state.infrastructure_requests || null,
                  year: this.state.year,
                  dashboard: this.state.dashboard,
                  hide_columns,
                  ...dashboardFilterParams,
                  ...myFilters,
                  filters: { ...this.state.params.filters, ...myFilters.filters, ...dashboardFilterParams.filters },
                  ...(this.props?.isCapitalRequest
                      ? {
                            is_cr: true
                        }
                      : {})
              });

        this.setState({ tableLoading: false });
        if (this.props.recommendationsReducer.recommendationExportResponse && this.props.recommendationsReducer.recommendationExportResponse.error) {
            await this.setState({ alertMessage: this.props.recommendationsReducer.recommendationExportResponse.error });
            this.showAlert();
        }
    };

    exportAllTrades = async (export_type = "Building") => {
        const entityId = this.props.match.params.id;
        const {
            match: {
                params: { section }
            },
            location: { search }
        } = this.props;
        const {
            tableData: { keys, config }
        } = this.state;
        const query = qs.parse(search);
        await this.setState({ exportAllTradesLoading: true });
        let hide_columns = [""];
        keys.map((keyItem, i) => {
            if (config && !config[keyItem]?.isVisible) {
                hide_columns.push(config[keyItem]?.label);
            }
        });
        let tempParams = {};
        section === "regioninfo"
            ? (tempParams = {
                  ...this.state.params,
                  project_id: query.pid,
                  region_id: entityId,
                  search: this.state.params.search,
                  filters: this.state.params.filters,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  building_ids: this.state.building_ids,
                  capital_type: this.state.capital_type,
                  start_year: this.state.start_year || null,
                  end_year: this.state.end_year || null,
                  infrastructure_requests: this.state.infrastructure_requests || null,
                  year: this.state.year,
                  dashboard: this.state.dashboard,
                  export_type,
                  hide_columns
              })
            : section === "siteinfo"
            ? (tempParams = {
                  ...this.state.params,
                  project_id: query.pid,
                  site_id: entityId,
                  search: this.state.params.search,
                  filters: this.state.params.filters,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  building_ids: this.state.building_ids,
                  capital_type: this.state.capital_type,
                  start_year: this.state.start_year || null,
                  end_year: this.state.end_year || null,
                  infrastructure_requests: this.state.infrastructure_requests || null,
                  year: this.state.year,
                  dashboard: this.state.dashboard,
                  export_type,
                  hide_columns
              })
            : section === "buildinginfo"
            ? (tempParams = {
                  ...this.state.params,
                  project_id: query.pid,
                  building_id: entityId || null,
                  search: this.state.params.search,
                  filters: this.state.params.filters,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  building_ids: this.state.building_ids,
                  capital_type: this.state.capital_type,
                  start_year: this.state.start_year || null,
                  end_year: this.state.end_year || null,
                  infrastructure_requests: this.state.infrastructure_requests || null,
                  year: this.state.year,
                  dashboard: this.state.dashboard,
                  export_type,
                  hide_columns
              })
            : section === "initiativeInfo"
            ? (tempParams = {
                  ...this.state.params,
                  initiative_id: entityId || null,
                  search: this.state.params.search,
                  filters: this.state.params.filters,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  building_ids: this.state.building_ids,
                  capital_type: this.state.capital_type,
                  start_year: this.state.start_year || null,
                  end_year: this.state.end_year || null,
                  infrastructure_requests: this.state.infrastructure_requests || null,
                  year: this.state.year,
                  dashboard: this.state.dashboard,
                  view: this.state.params.view,
                  export_type,
                  hide_columns
              })
            : (tempParams = {
                  ...this.state.params,
                  project_id: entityId,
                  search: this.state.params.search,
                  filters: this.state.params.filters,
                  list: this.state.params.list,
                  order: this.state.params.order,
                  building_ids: this.state.building_ids,
                  capital_type: this.state.capital_type,
                  start_year: this.state.start_year || null,
                  end_year: this.state.end_year || null,
                  infrastructure_requests: this.state.infrastructure_requests || null,
                  year: this.state.year,
                  dashboard: this.state.dashboard,
                  export_type,
                  hide_columns
              });
        await this.props.exportAllTrades(tempParams);
        this.setState({ exportAllTradesLoading: false });
        if (this.props.recommendationsReducer.exportAllTradesResponse && this.props.recommendationsReducer.exportAllTradesResponse.error) {
            await this.setState({ alertMessage: this.props.recommendationsReducer.exportAllTradesResponse.error });
            this.showAlert();
        }
    };

    showRestoreModal = async id => {
        await this.setState({
            showRestoreConfirmModal: true,
            selectedRecommendations: id
        });
    };

    renderRestoreConfirmationModal = () => {
        const { showRestoreConfirmModal } = this.state;
        if (!showRestoreConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Do you want to restore this ${this.props.isCapitalRequest ? "Capital Request" : "Recommendation"}?`}
                        message={""}
                        onNo={() => this.setState({ showRestoreConfirmModal: false })}
                        onYes={this.restoreRecommendationOnConfirm}
                        type={"restore"}
                        isRestore={true}
                    />
                }
                onCancel={() => this.setState({ showRestoreConfirmModal: false })}
            />
        );
    };

    restoreRecommendationOnConfirm = async () => {
        const { selectedRecommendations } = this.state;
        this.setState({
            isLoading: true,
            showRestoreConfirmModal: false
        });
        await this.props.getRestoreRecommendation(selectedRecommendations);
        if (
            this.props.recommendationsReducer.recoverRecommendationResponse &&
            this.props.recommendationsReducer.recoverRecommendationResponse.error
        ) {
            await this.setState({
                alertMessage: this.props.recommendationsReducer.recoverRecommendationResponse.error,
                showRestoreConfirmModal: false
            });
            this.showAlert();
        } else {
            await this.refreshRecommendationsList();
            // await this.props.getMenuItems();
            this.setState(
                {
                    alertMessage: this.props.recommendationsReducer.recoverRecommendationResponse?.message
                },
                () => this.showAlert()
            );
            if (this.state.isRedirectionOnDelete) {
                popBreadCrumpRecData();
                const redirectionurl = findPrevPathFromBreadCrumpRecData();
                this.props.history.push(findPrevPathFromBreadCrumpData() || "/recommendations");
                // this.props.history.push(redirectionurl[2].path || "/recommendations");
            }
            this.setState({
                showRestoreConfirmModal: false,
                isRedirectionOnDelete: false
            });
        }
        this.setState({
            isLoading: false
        });
    };

    getLogData = async buildingId => {
        const { historyParams } = this.state;
        await this.props.getAllRecommendationLogs(buildingId, historyParams);
        const {
            recommendationsReducer: {
                getAllRecommendationLogsResponse: { logs, count }
            }
        } = this.props;
        if (
            this.props.recommendationsReducer.getAllRecommendationLogsResponse &&
            this.props.recommendationsReducer.getAllRecommendationLogsResponse.error
        ) {
            await this.setState({ alertMessage: this.props.recommendationsReducer.getAllRecommendationLogsResponse.error });
            this.showAlert();
        } else {
            await this.setState({
                logData: {
                    ...this.state.logData,
                    data: logs
                },
                historyPaginationParams: {
                    ...this.state.historyPaginationParams,
                    totalCount: count,
                    totalPages: this.state.historyPaginationParams && Math.ceil(count / this.state.historyPaginationParams.perPage)
                }
            });
        }
    };

    handlePerPageChangeHistory = async e => {
        const { historyPaginationParams } = this.state;
        await this.setState({
            historyPaginationParams: {
                ...historyPaginationParams,
                perPage: e.target.value,
                currentPage: 0
            },
            historyParams: {
                ...this.state.historyParams,
                offset: 0,
                limit: e.target.value
            }
        });
        await this.getLogData(this.props.match.params.id);
    };

    handlePageClickHistory = async page => {
        const { historyPaginationParams, historyParams } = this.state;
        await this.setState({
            historyPaginationParams: {
                ...historyPaginationParams,
                currentPage: page.selected
            },
            historyParams: {
                ...historyParams,
                // offset: page.selected * historyPaginationParams.perPage
                offset: page.selected + historyPaginationParams.perPage
            }
        });
        await this.getLogData(this.props.match.params.id);
    };

    handleGlobalSearchHistory = async search => {
        const { historyParams, historyPaginationParams } = this.state;
        await this.setState({
            historyParams: {
                ...historyParams,
                offset: 0,
                search
            },
            historyPaginationParams: {
                ...historyPaginationParams,
                currentPage: 0
            }
        });
        await this.getLogData(this.props.match.params.id);
    };

    handleDeleteLog = async (id, choice = "delete") => {
        await this.setState({
            showConfirmModalLog: true,
            selectedLog: id,
            isRestoreOrDelete: choice
        });
    };

    renderConfirmationModalLog = () => {
        const { showConfirmModalLog, isRestoreOrDelete } = this.state;
        if (!showConfirmModalLog) return null;
        if (isRestoreOrDelete === "delete") {
            return (
                <Portal
                    body={
                        <ConfirmationModal
                            heading={"Do you want to delete this log?"}
                            message={"This action cannot be reverted, are you sure that you need to delete this item?"}
                            onNo={() => this.setState({ showConfirmModalLog: false })}
                            onYes={this.deleteLogOnConfirm}
                        />
                    }
                    onCancel={() => this.setState({ showConfirmModalLog: false })}
                />
            );
        } else {
            return (
                <Portal
                    body={
                        <ConfirmationModal
                            heading={"Do you want to restore this log?"}
                            message={"This action cannot be reverted, are you sure that you need to restore this item?"}
                            onNo={() => this.setState({ showConfirmModalLog: false })}
                            onYes={this.restoreLogOnConfirm}
                            isRestore={true}
                            type={"restore"}
                        />
                    }
                    onCancel={() => this.setState({ showConfirmModalLog: false })}
                />
            );
        }
    };

    deleteLogOnConfirm = async () => {
        const { selectedLog } = this.state;
        await this.props.deleteRecommendationLog(selectedLog);
        if (
            this.props.recommendationsReducer.deleteRecommendationLogResponse &&
            this.props.recommendationsReducer.deleteRecommendationLogResponse.error
        ) {
            await this.setState({ alertMessage: this.props.recommendationsReducer.deleteRecommendationLogResponse.error });
            this.showAlert();
        }
        await this.getLogData(this.props.match.params.id);
        // await this.props.getMenuItems();
        this.setState({
            showConfirmModalLog: false,
            selectedLog: null
        });
    };

    HandleRestoreRegionLog = async id => {
        this.setState({ isLoading: true });
        await this.props.restoreRecommendationLog(id);
        if (
            this.props.recommendationsReducer.restoreRecommendationLogResponse &&
            this.props.recommendationsReducer.restoreRecommendationLogResponse.error
        ) {
            await this.setState({ alertMessage: this.props.recommendationsReducer.restoreRecommendationLogResponse.error, isLoading: false });
            this.showAlert();
        } else {
            await this.refreshRecommendationsList();
            await this.setState({ alertMessage: this.props.recommendationsReducer.restoreRecommendationLogResponse.message, isLoading: false });
            this.showAlert();
        }
    };

    updateLogSortFilters = async searchKey => {
        if (this.state.historyParams.order) {
            await this.setState({
                historyParams: {
                    ...this.state.historyParams,
                    order: {
                        ...this.state.historyParams.order,
                        [searchKey]: this.state.historyParams.order[searchKey] === "desc" ? "asc" : "desc"
                    }
                }
            });
        } else {
            await this.setState({
                historyParams: {
                    ...this.state.historyParams,
                    order: { [searchKey]: "asc" }
                }
            });
        }
        this.updateEntityParams();
        await this.getLogData(this.props.match.params.id);
    };
    selectFilterHandler = async e => {
        switch (e.target.value) {
            case "deleted":
                await this.setState({
                    params: {
                        ...this.state.params,
                        deleted: true,
                        active: null,
                        locked: null,
                        unlocked: null,
                        view: null,
                        on_hold: null,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "active":
                await this.setState({
                    params: {
                        ...this.state.params,
                        active: true,
                        deleted: null,
                        unlocked: null,
                        locked: null,
                        view: null,
                        on_hold: null,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "locked":
                await this.setState({
                    params: {
                        ...this.state.params,
                        locked: true,
                        unlocked: null,
                        deleted: null,
                        active: null,
                        view: null,
                        on_hold: null,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "unlocked":
                await this.setState({
                    params: {
                        ...this.state.params,
                        unlocked: true,
                        deleted: null,
                        active: null,
                        locked: null,
                        view: null,
                        on_hold: null,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "assigned":
                await this.setState({
                    params: {
                        ...this.state.params,
                        view: "assigned",
                        unlocked: null,
                        deleted: null,
                        active: null,
                        locked: null,
                        on_hold: null,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "unassigned":
                await this.setState({
                    params: {
                        ...this.state.params,
                        view: "unassigned",
                        unlocked: null,
                        deleted: null,
                        active: null,
                        locked: null,
                        on_hold: null,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "all":
                await this.setState({
                    params: {
                        ...this.state.params,
                        deleted: null,
                        active: null,
                        locked: null,
                        unlocked: null,
                        view: null,
                        on_hold: null,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "completed":
                await this.setState({
                    params: {
                        ...this.state.params,
                        deleted: null,
                        active: null,
                        locked: null,
                        unlocked: null,
                        view: null,
                        on_hold: null,
                        completed: true
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "on_hold":
                await this.setState({
                    params: {
                        ...this.state.params,
                        deleted: null,
                        active: null,
                        locked: null,
                        unlocked: null,
                        view: null,
                        on_hold: true,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            default:
                break;
        }
        await this.updateEntityParams();
        await this.refreshRecommendationsList();
    };

    selectFilterHandlerInitiative = async e => {
        let isRecom = this.props.match.params.tab === "recommendation" ? true : false;
        switch (e.target.value) {
            case "deleted":
                await this.setState({
                    params: {
                        ...this.state.params,
                        deleted: true,
                        active: null,
                        locked: null,
                        unlocked: null,
                        on_hold: null,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "active":
                await this.setState({
                    params: {
                        ...this.state.params,
                        active: true,
                        deleted: null,
                        unlocked: null,
                        locked: null,
                        on_hold: null,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "locked":
                await this.setState({
                    params: {
                        ...this.state.params,
                        locked: true,
                        unlocked: null,
                        deleted: null,
                        active: null,
                        on_hold: null,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "unlocked":
                await this.setState({
                    params: {
                        ...this.state.params,
                        unlocked: true,
                        deleted: null,
                        active: null,
                        locked: null,
                        on_hold: null,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "assigned":
                await this.setState({
                    params: {
                        ...this.state.params,
                        view: "assigned"
                    },
                    selectedDropdownInitiaive: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "unassigned":
                await this.setState({
                    params: {
                        ...this.state.params,
                        view: "unassigned"
                    },
                    selectedDropdownInitiaive: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "all":
                await this.setState({
                    params: {
                        ...this.state.params,
                        view: null
                    },
                    selectedDropdownInitiaive: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "allInitiative":
                await this.setState({
                    params: {
                        ...this.state.params,
                        deleted: null,
                        active: null,
                        locked: null,
                        unlocked: null,
                        // view: null,
                        on_hold: null,
                        completed: null
                    },
                    // selectedDropdownInitiaive: e.target.value,
                    selectedRecomIds: [],
                    selectedDropdown: e.target.value
                });
                break;
            case "completed":
                await this.setState({
                    params: {
                        ...this.state.params,
                        deleted: null,
                        active: null,
                        locked: null,
                        unlocked: null,
                        // view: null,
                        on_hold: null,
                        completed: true
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            case "on_hold":
                await this.setState({
                    params: {
                        ...this.state.params,
                        deleted: null,
                        active: null,
                        locked: null,
                        unlocked: null,
                        // view: null,
                        on_hold: true,
                        completed: null
                    },
                    selectedDropdown: e.target.value,
                    selectedRecomIds: []
                });
                break;
            default:
                break;
        }
        await this.updateEntityParams();
        await this.refreshRecommendationsList();
    };

    showAlert = () => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show";
            x.innerText = this.state.alertMessage;
            setTimeout(function () {
                x.className = x.className.replace("show", "");
            }, 3000);
        }
    };

    renderConfirmationModalUnassign = () => {
        const { confirmUnAssign, selectedRecomIds } = this.state;
        if (!confirmUnAssign) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Do you want to unassign ${selectedRecomIds?.length > 1 ? "these" : "this"} ${
                            selectedRecomIds?.length > 1 ? "Recommendations" : "Recommendation"
                        }?`}
                        message={""}
                        onNo={() => this.setState({ confirmUnAssign: false })}
                        onYes={this.handleUnassign}
                        type={"unassign"}
                        isUnAssign={true}
                    />
                }
                onCancel={() => this.setState({ confirmUnAssign: false })}
            />
        );
    };

    handleUnassign = async () => {
        const { selectedRecomIds } = this.state;
        this.setState({
            confirmUnAssign: false,
            isLoading: true
        });
        if (this.props.match.params.section === "imageInfo") {
            let params = {
                id: this.props.match.params.id,
                recommendation_ids: selectedRecomIds.map(elem => elem.id)
            };
            await this.props.unAssignImage(params);
            const { unAssignImageResponse } = this.props.recommendationsReducer;
            if (unAssignImageResponse.error) {
                this.setState(
                    {
                        alertMessage: unAssignImageResponse.error,
                        isLoading: false
                    },
                    () => this.showAlert()
                );
            } else if (unAssignImageResponse.success) {
                await this.refreshRecommendationsList();
                this.setState(
                    {
                        alertMessage: unAssignImageResponse.message
                    },
                    () => this.showAlert()
                );
            }
        } else {
            let data = {
                recommendation_ids: selectedRecomIds.map(elem => elem.id)
            };
            this.setState({
                confirmUnAssign: false,
                isLoading: true
            });
            await this.props.unAssignProject(data, this.props.match.params.id);
            const { success, error, message } = this.props.initativeReducer.unAssignProject || {};
            if (success) {
                this.props.refreshInitiativeDetails();
                await this.refreshRecommendationsList();
                this.setState(
                    {
                        alertMessage: message,
                        selectedRecomIds: []
                    },
                    () => {
                        this.updateEntityParams();
                        this.showAlert();
                    }
                );
            } else {
                this.setState(
                    {
                        alertMessage: error,
                        isLoading: false
                    },
                    () => this.showAlert()
                );
            }
        }
    };

    unAassignContent = async () => {
        this.setState({
            confirmUnAssign: true
        });
    };
    renderAssingConfirmationModal = () => {
        const { showAssignConfirmationModal, selectedRecomIds } = this.state;
        if (!showAssignConfirmationModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Do you want to assign ${selectedRecomIds?.length > 1 ? "these" : "this"} ${
                            selectedRecomIds?.length > 1 ? "recommendations" : "recommendation"
                        } to the Initiative?`}
                        message={"Are you sure?"}
                        onNo={() => this.setState({ showAssignConfirmationModal: false })}
                        onYes={this.handleAssignRecommendation}
                        type={"unassign"}
                        isUnAssign={true}
                    />
                }
                onCancel={() => this.setState({ showAssignConfirmationModal: false })}
            />
        );
    };

    handleAssignRecommendation = async () => {
        this.setState({ showAssignConfirmationModal: false, isLoading: true });
        const { selectedRecomIds } = this.state;
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        let data = {
            recommendation_ids: selectedRecomIds.map(elem => elem.id),
            process: "false",
            initiative_id: this.props.match.params.id,
            project_id: query.pid
        };

        await this.props.assignProject(data, this.props.match.params.id);
        const { success, message, error = "Something went wrong" } = this.props.initativeReducer.assignProject || {};
        if (success) {
            this.props.refreshInitiativeDetails();
            await this.refreshRecommendationsList();
            this.setState(
                {
                    alertMessage: message,
                    selectedRecomIds: []
                },
                () => {
                    this.updateEntityParams();
                    this.showAlert();
                }
            );
        } else {
            this.setState(
                {
                    alertMessage: error,
                    isLoading: false
                },
                () => this.showAlert()
            );
        }
    };

    assignInitiative = async () => {
        this.setState({
            showAssignConfirmationModal: true
        });
    };

    downloadPdfReport = async params => {
        this.setState({ isLoading: true });
        await this.props.downloadPdfReport(params);
        await this.props.addUserActivityLog({ text: "Downloaded pdf report." });
        this.setState({ isLoading: false });
        const { success, PDF_URL } = this.props.recommendationsReducer.pdfReportResponse || {};
        if (!success) {
            this.setState(
                {
                    alertMessage: "Narrative Report Not Found"
                },
                () => this.showAlert()
            );
            return false;
        }
        const link = document.createElement("a");
        link.href = PDF_URL;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    assignImagesToRecom = async imageData => {
        const { selectedRecommendations } = this.state;
        await this.props.assignImagesToRecom(imageData, selectedRecommendations || this.props.match.params.id);
        return this.props.recommendationsReducer.assignImageResponse;
    };

    handleSelectRecom = async (data, isChecked) => {
        const {
            tempRecommendationsList,
            selectedRecomIds,
            paginationParams: { currentPage, perPage }
        } = this.state;
        const { id, lock_status } = data;
        const page = currentPage * perPage;
        const index = page + (tempRecommendationsList.findIndex(elem => elem.id === id) + 1);
        await this.setState({
            selectedRecomIds: isChecked
                ? [...selectedRecomIds, { id, index, locked: lock_status === LOCK_STATUS.LOCKED }]
                : selectedRecomIds.filter(t => t.id !== id)
        });
        this.updateEntityParams();
    };

    handleSelectAllRecom = async isChecked => {
        const {
            tempRecommendationsList,
            selectedRecomIds,
            paginationParams: { currentPage, perPage }
        } = this.state;
        let tempSelected = [...this.state.selectedRecomIds];
        const page = currentPage * perPage;
        if (isChecked) {
            tempRecommendationsList.forEach((item, idx) => {
                if (!tempSelected.some(t => t.id === item.id)) {
                    tempSelected.push({ id: item.id, locked: item?.lock_status === LOCK_STATUS.LOCKED, index: page + (idx + 1) });
                }
            });
        } else {
            tempSelected = selectedRecomIds;
            tempRecommendationsList.forEach((item, idx) => {
                tempSelected = tempSelected.filter(t => t.id !== item.id);
            });
        }
        await this.setState({ selectedRecomIds: tempSelected });
        this.updateEntityParams();
    };

    selectWholeRecommendation = async () => {
        this.setState({ isLoading: true });
        const {
            match: {
                params: { section }
            },
            isSmartChartView = false,
            smartChartFilters = {}
        } = this.props;
        let params = this.state.params;
        const { myFilters, dashboardFilterParams } = this.state;
        const projectId = this.props.projectIdDashboard || this.props?.projectIdForSmartChart || this.props.match.params.id;
        if (section === "initiativeInfo" && params.view !== "assigned") {
            const {
                location: { search }
            } = this.props;
            const query = qs.parse(search);
            params.project_id = query.pid;
        } else if (section !== "imageInfo" && section !== "initiativeInfo" && section !== "assetInfo") {
            params.project_id = projectId;
        }
        if (section === "initiativeInfo" && params.view === "assigned") {
            params.initiative_id = this.props.match.params.id || null;
        }
        if (this.isDashboardFiltered) {
            params.dashboard = this.state.dashboard;
            params.capital_type = this.state.capital_type;
            params.building_ids = this.state.building_ids;
            params.year = this.state.year;
        }
        params.start_year = this.state.start_year;
        params.end_year = this.state.end_year;
        params.infrastructure_requests = this.state.infrastructure_requests;
        if (section === "buildinginfo") {
            params.building_id = this.props.match.params.id;
        } else if (section === "siteinfo") {
            params.site_id = this.props.match.params.id;
        } else if (section === "regioninfo") {
            params.region_id = this.props.match.params.id;
        } else if (section === "imageInfo") {
            params.image_id = this.props.match.params.id;
        } else if (section === "assetInfo") {
            params.asset_id = this.props.match.params.id;
        }
        await this.props.getAllRecommendationIds({
            ...params,
            ...dashboardFilterParams,
            ...(isSmartChartView && { ...smartChartFilters }),
            ...myFilters
        });
        const { recommendation_ids } = this.props.recommendationsReducer.getWholeRecommendationIdsResponse;
        let tempSelected = [];
        recommendation_ids?.length &&
            recommendation_ids.forEach((id, index) => {
                tempSelected.push({ id, index });
            });
        await this.setState({ selectedRecomIds: [] });
        await this.setState({ selectedRecomIds: tempSelected, isLoading: false });
        this.updateEntityParams();
    };

    clearSelection = async () => {
        await this.setState({ selectedRecomIds: [] });
        this.updateEntityParams();
        if (this.state.params?.recommendation_ids) {
            await this.setState({
                params: {
                    ...this.state.params,
                    offset: 0,
                    recommendation_ids: null
                },
                paginationParams: {
                    ...this.state.paginationParams,
                    currentPage: 0
                }
            });
            await this.refreshRecommendationsList();
        }
    };

    filterBySurveyor = async () => {
        let surveyor = localStorage.getItem("printed_name");
        if (surveyor && surveyor !== "undefined" && surveyor !== null && surveyor !== "null") {
            await this.setState({
                params: {
                    ...this.state.params,
                    offset: 0,
                    recommendation_ids: null,
                    surveyor: this.state.params.surveyor ? null : surveyor || null
                },
                paginationParams: {
                    ...this.state.paginationParams,
                    currentPage: 0
                },
                selectedRecomIds: []
            });
            this.updateEntityParams();
            await this.refreshRecommendationsList();
        } else {
            this.setState(
                {
                    alertMessage: "You don't have a printed name. please provide a printed name"
                },
                () => this.showAlert()
            );
        }
    };
    filterByImages = async () => {
        const { image_or_not } = this.state.params;
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                image_or_not: image_or_not === "true" ? "false" : image_or_not === "false" ? null : "true",
                recommendation_ids: null
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            selectedRecomIds: []
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };
    filterByReviews = async value => {
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                review_status: value,
                recommendation_ids: null
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            selectedRecomIds: []
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };
    filterByQaPassed = async value => {
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                qa_passed: value,
                recommendation_ids: null
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            selectedRecomIds: []
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };
    filterByIR = async () => {
        const { infrastructure_request } = this.state.params;
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                recommendation_ids: null,
                infrastructure_request: infrastructure_request === "yes" ? "no" : infrastructure_request === "no" ? null : "yes"
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            selectedRecomIds: []
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };
    filterByWater = async () => {
        const { water } = this.state.params;
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                recommendation_ids: null,
                water: water === "yes" ? "no" : water === "no" ? null : "yes"
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            selectedRecomIds: []
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };
    filterByEnergy = async () => {
        const { energy } = this.state.params;
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                recommendation_ids: null,
                energy: energy === "yes" ? "no" : energy === "no" ? null : "yes"
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            selectedRecomIds: []
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };
    filterByBudgetPriority = async () => {
        const { budget_priority } = this.state.params;
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                recommendation_ids: null,
                budget_priority: budget_priority === "yes" ? "no" : budget_priority === "no" ? null : "yes"
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            selectedRecomIds: []
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };

    filterByFmp = async () => {
        const { facility_master_plan } = this.state.params;
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                recommendation_ids: null,
                facility_master_plan: facility_master_plan === "yes" ? "no" : facility_master_plan === "no" ? null : "yes"
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            selectedRecomIds: []
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };
    filterByRecomType = async () => {
        const { recommendation_type } = this.state.params;
        await this.setState({
            params: {
                ...this.state.params,
                offset: 0,
                recommendation_ids: null,
                recommendation_type: recommendation_type === "asset" ? "building" : recommendation_type === "building" ? null : "asset"
            },
            paginationParams: {
                ...this.state.paginationParams,
                currentPage: 0
            },
            selectedRecomIds: []
        });
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };
    resetEntityParams = async CrUpdatedData => {
        await this.setState({
            selectedRegion: null,
            paginationParams: {
                totalPages: 0,
                perPage: 40,
                currentPage: 0,
                totalCount: 0
            },
            params: {
                ...this.state.params,
                limit: 40,
                offset: 0,
                search: "",
                order: null,
                index: [],
                maintenance_year: [],
                filters: null,
                list: null,
                deleted: null,
                view: this.props.match.params.tab === "recommendation" ? "assigned" : null,
                locked: null,
                unlocked: null,
                active: true,
                on_hold: null,
                completed: null,
                image_or_not: null,
                infrastructure_request: null,
                surveyor: null,
                recommendation_ids: null,
                water: null,
                energy: null,
                facility_master_plan: null,
                recommendation_type: null,
                budget_priority: null,
                review_status: null,
                qa_passed: null
            },
            tableData: {
                ...this.state.tableData,
                data: []
            },
            wildCardFilterParams: {},
            filterParams: {},
            tableConfig: null,
            selectedRowId: null,
            selectedDropdown: "active",
            selectedDropdownInitiaive: "assigned",
            selectedRecomIds: [],
            parentSectionId: null,
            tableDataExportFilters: {},
            myFilters: {}
        });
        this.updateEntityParams();
    };

    resetAll = async () => {
        await this.resetEntityParams();
        await this.refreshRecommendationsList();
    };

    isColunmVisibleChanged = () => {
        let flag = false;
        const {
            tableData: { keys, config },
            initialTableConfig,
            tableConfigChanged
        } = this.state;
        keys.forEach(key => {
            if (initialTableConfig[key] && !_.isEqual(config[key]?.isVisible, initialTableConfig[key]?.isVisible)) {
                flag = true;
            }
        });
        return flag || tableConfigChanged;
    };

    toggleMultiSelectEditForm = async () => {
        //if locked selected
        const hasLockRecom = this.state.selectedRecomIds.some(elem => elem.locked);
        if (hasLockRecom) {
            await this.setState({
                alertMessage: "Locked recommendations cannot be edited!"
            });
            this.showAlert();
        } else {
            this.setState({ showMultiSelectEditForm: !this.state.showMultiSelectEditForm });
        }
    };

    renderMultiSelectEditForm = () => {
        const { showMultiSelectEditForm, selectedRecomIds } = this.state;
        if (!showMultiSelectEditForm) return null;
        return (
            <Portal
                body={
                    <MultiSelectFormModal
                        handleUpdateRecommendations={this.handleUpdateMultiRecommendations}
                        getSiteListBasedOnRegion={this.getSiteListBasedOnRegion}
                        getRegionListBasedOnClient={this.getRegionListBasedOnClient}
                        clientId={this.props.projectReducer?.getProjectByIdResponse?.client?.id}
                        projectId={this.props.projectIdDashboard ? this.props.projectIdDashboard : this.props.match.params.id}
                        onCancel={this.toggleMultiSelectEditForm}
                        selectedRecomIds={selectedRecomIds.map(elem => elem.id)}
                        isCapitalRequest={this.props.isCapitalRequest}
                    />
                }
                onCancel={this.toggleMultiSelectEditForm}
            />
        );
    };

    handleUpdateMultiRecommendations = async recommendation => {
        const { selectedRecomIds, selectedDropdown } = this.state;
        await this.props.updateMultipleRecommendations(
            recommendation,
            selectedRecomIds.map(elem => elem.id)
        );
        if (
            this.props.recommendationsReducer.updateMultipleRecommendationsResponse &&
            this.props.recommendationsReducer.updateMultipleRecommendationsResponse.error
        ) {
            await this.setState({
                alertMessage: this.props.recommendationsReducer.updateMultipleRecommendationsResponse.error
            });
            this.showAlert();
        } else {
            this.toggleMultiSelectEditForm();
            // if the recommendation status is changed and current status filter is different clearing selection
            if (recommendation.status && selectedDropdown !== "all" && recommendation.status !== selectedDropdown) {
                this.clearSelection();
            }
            await this.setState({
                params: {
                    ...this.state.params,
                    offset: 0
                },
                paginationParams: {
                    ...this.state.paginationParams,
                    currentPage: 0
                }
            });
            await this.refreshRecommendationsList();
            await this.setState({
                alertMessage: this.props.recommendationsReducer.updateMultipleRecommendationsResponse?.message
            });
            this.showAlert();
        }
    };

    showSelectedRecom = async () => {
        if (this.state.params.recommendation_ids?.length) {
            await this.setState({
                params: {
                    ...this.state.params,
                    offset: 0,
                    recommendation_ids: null
                },
                paginationParams: {
                    ...this.state.paginationParams,
                    currentPage: 0
                }
            });
        } else {
            await this.setState({
                params: {
                    ...this.state.params,
                    offset: 0,
                    recommendation_ids: this.state.selectedRecomIds.map(elem => elem.id)
                },
                paginationParams: {
                    ...this.state.paginationParams,
                    currentPage: 0
                }
            });
        }
        this.updateEntityParams();
        await this.refreshRecommendationsList();
    };

    exportSelectedRecom = async (type, RecomData) => {
        const {
            selectedRecomIds,
            params: { list, infrastructure_request, image_or_not, water, energy, facility_master_plan, recommendation_type, budget_priority },
            tableData: { keys, config }
        } = this.state;
        let sortedRecommendationIds = selectedRecomIds.sort((a, b) => a.index - b.index).map(elem => elem.id);
        const {
            match: {
                params: { section }
            },
            location: { search }
        } = this.props;
        let key_name = {};
        const query = qs.parse(search);
        const project_id = section === "projectinfo" ? this.props.match.params.id : query.pid;
        if (list && Object.keys(list).length) {
            keys.forEach(item => {
                Object.entries(list).forEach(([key, value]) => {
                    if (config[item].searchKey === key) {
                        key_name[config[item].label] = value;
                    }
                });
            });
        }
        if (infrastructure_request) {
            key_name["Infrastructure Reques"] = [infrastructure_request];
        }
        if (water) {
            key_name["Water"] = [water];
        }
        if (energy) {
            key_name["Energy"] = [energy];
        }
        if (facility_master_plan) {
            key_name["FMP"] = [facility_master_plan];
        }
        if (recommendation_type) {
            key_name["Recommendation Type"] = [recommendation_type];
        }
        if (budget_priority) {
            key_name["Budget Priority"] = [budget_priority];
        }
        if (image_or_not) {
            key_name["Images"] = [image_or_not === "true" ? "yes" : "no"];
        }
        key_name = Object.keys(key_name)?.length ? key_name : null;
        if (type === "word") {
            this.setState({ multiExportWordLoader: true });
            await this.props.exportSelectedRecomWord({
                recommendations: sortedRecommendationIds,
                key_name,
                username: localStorage.getItem("user"),
                project_id,
                notes: RecomData?.isChecked,
                notes_in_cell: RecomData?.isSimpleNarrativeText
            });
            this.setState({ multiExportWordLoader: false });
        } else if (type === "pdf") {
            this.setState({ multiExportPdfLoader: true });
            await this.props.exportSelectedRecomPDF({
                recommendations: sortedRecommendationIds,
                key_name,
                username: localStorage.getItem("user"),
                project_id,
                notes: RecomData?.isChecked,
                notes_in_cell: RecomData?.isSimpleNarrativeText
            });
            this.setState({ multiExportPdfLoader: false });
        }
    };

    getPriorityElementDropDownData = async projectId => {
        await this.props.getPriorityElementDropDownData(projectId);
        return this.props.recommendationsReducer.priorityElementsDropDownResponse || {};
    };

    postExport = async (params, exportType) => {
        this.setState({ exportLoader: true });
        await this.props.exportReportPdf(params, exportType);
        this.setState({ exportLoader: false });
    };

    // get sorted recom export params (word/excel)
    getExportParams = () => {
        const {
            match: {
                params: { section }
            },
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        const project_id = section === "projectinfo" ? this.props.match.params.id : query.pid;
        const userId = localStorage.getItem("userId");
        const user_name = localStorage.getItem("user");
        const scope = section.substring(0, section.length - 4);
        const { tableDataExportFilters, myFilters, dashboardFilterParams } = this.state;
        const finalFilters = { ...myFilters, ...dashboardFilterParams };
        const updatedTableDataExportFilters = Object.keys(tableDataExportFilters)?.reduce((newFilters, key) => {
            const value = tableDataExportFilters[key];
            if (key === "buildings_descriptions") {
                newFilters[key] = (this.state?.params?.list && this.state?.params?.list["buildings.description"]) || value;
            } else {
                newFilters[key] = value;
            }
            return newFilters;
        }, {});
        let params = {
            project_id,
            user_id: userId,
            export_from: scope,
            user_name,
            ...updatedTableDataExportFilters
        };
        if (section === "buildinginfo") {
            params.building_id = [this.props.match.params.id];
        } else if (section === "siteinfo") {
            params.site_id = [this.props.match.params.id];
        } else if (section === "regioninfo") {
            params.region_id = [this.props.match.params.id];
        } else if (section === "imageInfo") {
            params.image_id = [this.props.match.params.id];
        } else if (section === "assetInfo") {
            params.asset_id = [this.props.match.params.id];
        }

        //if dashboard filtered
        const { masterFilterList, filterFields } = this.props.dashboardReducer;
        if (this.isDashboardFiltered && filterFields?.length) {
            filterFields.forEach(fil => {
                if (fil.exportFilterKey === "building_type_id" && finalFilters?.[fil.paramKey]?.length) {
                    const building_type_ids =
                        masterFilterList?.building_types
                            ?.filter(item => finalFilters?.building_types?.some(elm => elm === item.name))
                            .map(item => item.id) || [];
                    if (building_type_ids?.length) {
                        params[fil.exportFilterKey] = building_type_ids;
                    }
                } else if (fil.exportFilterKey && finalFilters?.[fil.paramKey]?.length && fil.paramKey !== "project_ids") {
                    params[fil.exportFilterKey] = finalFilters?.[fil.paramKey];
                }
            });
            if (query.DM_ID) {
                params.recommendation_capital_type_id = [query.DM_ID];
            }
            if (finalFilters.year) {
                params.year = finalFilters.year;
            }
        }
        return params;
    };

    exportCrossWalkExcel = async () => {
        this.setState({ exportWordLoading: "crossWalk" });
        let params = this.getExportParams();
        await this.props.exportToExcelFile(params, "crossWalk");
        this.setState({ exportWordLoading: false });
        const res = this.props.recommendationsReducer.exportExcelDataResponse;
        await this.setState({
            alertMessage: res?.message_status ? res.message_status : res?.message || "Something Went Wrong"
        });
        this.showAlert();
    };

    exportToWord = async sort_type => {
        this.setState({ exportWordLoading: "sortedWord" });
        let params = this.getExportParams();
        params.sort_type = sort_type;
        await this.props.exportToWord(params);
        this.setState({ exportWordLoading: false });
        const res = this.props.recommendationsReducer.exportWordDataResponse;
        await this.setState({
            alertMessage: res?.message_status ? res.message_status : res?.message || "Something Went Wrong"
        });
        this.showAlert();
    };
    exportToExcel = async sort_type => {
        this.setState({ exportWordLoading: "sortedExcel" });
        let params = this.getExportParams();
        params.sort_type = sort_type;
        await this.props.exportToExcelFile(params, "sortedExcel");
        this.setState({ exportWordLoading: false });
        const res = this.props.recommendationsReducer.exportExcelDataResponse;
        await this.setState({
            alertMessage: res?.message_status ? res.message_status : res?.message || "Something Went Wrong"
        });
        this.showAlert();
    };

    lockRecommendation = flag => {
        lockRecommendation(this.props.match.params.id, { lock: flag });
    };

    modifyDashboardFilterParams = () => {
        let { dashboardFilterParams } = this.state;
        const { chart_type, horizontal_chart_type } = this.state?.dashboardFilterParams || {};
        const trade_id =
            chart_type?.split("_")[0] === "system"
                ? chart_type?.split("_")[1]
                : horizontal_chart_type?.split("_")[0] === "system"
                ? horizontal_chart_type?.split("_")[1]
                : null;
        if (trade_id) {
            delete dashboardFilterParams.chart_type;
            dashboardFilterParams.trade_id = trade_id;
        }
        this.setState({ dashboardFilterParams });
    };
    toggleLineEditing = async () => {
        this.setState(prevState => {
            const { lineEditingEnabled } = prevState;
            return {
                lineEditingEnabled: !lineEditingEnabled
            };
        });
    };
    handleCellFocus = async (keyItem, rowIndex, rowId = "", isDropdownLineEdit = false, rowData) => {
        if (isDropdownLineEdit) {
            this.setState({ isDropdownLoading: { [rowIndex]: keyItem } });
            let value = [];
            const projectId = this.props.projectIdDashboard || this.props?.projectIdForSmartChart || this.props.match.params.id || "";
            if (keyItem === "criticality") {
                await this.props.getCriticalityData({ project_id: projectId });
                value = this.props.projectReducer.criticalityData?.criticalities || [];
            } else if (keyItem === "category") {
                await this.props.getCategoryBasedOnProject(projectId);
                value = this.props.recommendationsReducer?.getCategoryByProject?.categories;
            } else if (keyItem === "status") {
                value = [
                    { id: "active", name: "Active" },
                    { id: "in_progress", name: "In Progress" },
                    { id: "pending", name: "Pending" },
                    { id: "on_hold", name: "On Hold" },
                    { id: "completed", name: "Completed" }
                ];
            } else if (keyItem === "funding_source") {
                await this.props.getFundingSourceByProject(projectId);
                value = this.props.recommendationsReducer?.getFundingSourceByProject?.funding_sources;
            } else if (keyItem?.startsWith("priority_element")) {
                await this.props.getPriorityElementDropDownData(projectId);
                value = this.props.recommendationsReducer.priorityElementsDropDownResponse.priority_elements?.find(
                    e => e?.display_name === this.state?.tableData.config[keyItem].label
                )?.options;
                await this.props.getCriticalityData({ project_id: projectId });
            } else if (keyItem === "department") {
                await this.props.getDepartmentByProject(projectId);
                value = this.props.recommendationsReducer?.getDepaartmentsByProject?.departments?.filter(item => item.name !== null);
            } else if (keyItem === "condition") {
                await this.props.getConditionBasedOnProject(projectId);
                value = this.props.recommendationsReducer?.getConditionBasedOnProject?.asset_conditions;
            } else if (keyItem === "capital_type_display_name") {
                await this.props.getCapitalTypeDropDownData(projectId);
                value = this.props.recommendationsReducer.capitalTypeDropDownResponse?.capital_types || [];
            } else if (keyItem === "responsibility") {
                await this.props.getResponsibilityByProject(projectId);
                value = this.props.recommendationsReducer?.getResponsibilityByProject?.responsibilities;
            } else if (keyItem === "initiative") {
                await this.props.getInitiativeDropdown({
                    project_id: projectId,
                    client_id: this.props.projectReducer?.getProjectByIdResponse?.client?.id
                });
                value = this.props.recommendationsReducer?.getInitiativeDropdown?.projects;
            } else if (keyItem === "addition") {
                await this.props.getAdditionBasedOnBuilding(rowData?.building.id);
                value = rowData?.building.id ? this.props.recommendationsReducer.getAdditionByBuilding?.additions : [];
            } else if (keyItem === "floor") {
                await this.props.getFloorBasedOnBuilding(rowData?.building.id);
                value = rowData?.building.id ? this.props.recommendationsReducer?.getFloorByBuilding?.floors : [];
            }
            this.setState({ lineEditDropdownValue: value, isDropdownLoading: { [rowIndex]: false } });
        }
        this.setState({ isInputMode: { keyItem, rowIndex, rowId } });
    };

    handleCellValueChange = (data, keyItem, rowIndex, filterIdKey, isDropdownLineEdit = false, name) => {
        const value = data[keyItem];
        let tableData = this.state.tableData.data;
        const year = keyItem?.split("year_")?.[1] ? parseInt(keyItem?.split("year_")?.[1]) : null;
        const findIndex = tableData[rowIndex]?.maintenance_years?.findIndex(elem => elem.year === year);
        if (findIndex !== -1 && tableData[rowIndex].maintenance_years[findIndex]) {
            tableData[rowIndex].maintenance_years[findIndex].amount = parseInt(value) || 0;
        }
        tableData[rowIndex][keyItem] = isDropdownLineEdit ? name || "-" : parseFloat(value) || value || null;
        let params = {};
        let dynamicFields = [];

        Object.entries(data?.dynamic_fields || {})?.forEach(([key, value]) => {
            dynamicFields?.push({ [key]: value?.value });
        });
        params = { ...params, dynamic_fields: dynamicFields };

        if (keyItem?.includes("year_")) {
            params.maintenance_years = tableData[rowIndex]?.maintenance_years;
        } else if (filterIdKey) {
            params[filterIdKey] = value;
        }
        if (keyItem?.startsWith("priority_element")) {
            let priorityElementsData = this.props.recommendationsReducer.priorityElementsDropDownResponse?.priority_elements || [];
            const priority = priorityElementsData?.find(e => e?.display_name === this.state?.tableData.config[keyItem].label);
            const priorityIndex = keyItem?.split("priority_element")?.[1];
            let selectedPriority = priorityElementsData.find(p => p.id === priority?.id);
            let selectedOption = selectedPriority.options.find(o => o.id === value);
            let priorityTotal = 0;
            let pelement = data?.priority_elements;
            pelement.map(i => {
                if (i.index == priorityIndex) {
                    if (!selectedOption) {
                        i.recommendation_priority_id = priority?.id;
                        i.element = value ? parseInt(value) : "";
                        i.option_id = "";
                    } else {
                        i.recommendation_priority_id = priority?.id;
                        i.option_id = value;
                        i.element = parseInt(selectedOption.value);
                    }
                }
            });
            pelement.map(item => (priorityTotal += Number(item.element)));
            params["priority_elements"] = pelement;
            let selectedCriticality = "";
            let criticalityData = this.props.projectReducer.criticalityData?.criticalities || [];
            if (this.props.projectReducer.getProjectByIdResponse?.miscellaneous?.criticality_score !== "year") {
                if (priorityTotal && criticalityData.length) {
                    selectedCriticality =
                        criticalityData.find(c => priorityTotal >= parseFloat(c.start_range) && priorityTotal <= parseFloat(c.end_range)) || "";
                }
            }
            if (selectedCriticality) {
                params["criticality_id"] = selectedCriticality?.id;
            }
            // tableData[rowIndex]["criticality"] = { id: selectedCriticality?.id, name: selectedCriticality?.name };
        }
        if (keyItem === "condition") {
            params["asset_condition_id"] = value;
        } else if (keyItem === "capital_type_display_name") {
            let selectedCapitalType = this.props.recommendationsReducer.capitalTypeDropDownResponse?.capital_types.find(
                cType => cType.name === value
            );
            params["recommendation_capital_type_id"] = selectedCapitalType?.id;
            params["capital_type"] = selectedCapitalType?.name;
        } else if (keyItem === "initiative") {
            params["initiative_id"] = value;
        } else {
            params[keyItem] = value;
        }
        this.setState(
            {
                tableData: {
                    ...this.state.tableData,
                    data: tableData
                },
                summaryRowData: {
                    ...this.state.summaryRowData,
                    project_total: "isLoading",
                    year_totals: {
                        ...this.state.summaryRowData.year_totals,
                        [year]: "isLoading"
                    }
                }
            },
            () => this.debouncedSaveTableValue(rowIndex, params)
        );
    };

    debouncedSaveTableValue = async (rowIndex, params) => {
        let newData = {};
        const { id, building, recommendation_type } = this.state.tableData?.data[rowIndex] || {};
        newData.building_id = building?.id;
        newData.recommendation_type = recommendation_type;
        await this.props.updateRecommendation({ ...newData, ...params }, id);
        const { success, error, message } = this.props.recommendationsReducer.updateRecommendationResponse;
        if (!success || error) {
            await this.setState({
                alertMessage: message || "Failed to update Recommendation!"
            });
            this.showAlert();
        } else {
            this.refreshRecommendationsList(false, true);
        }
    };

    handleColumnPin = keyItem => {
        const { config, keys } = this.state.tableData;
        const updatedConfig = {
            ...config,
            [keyItem]: {
                ...config[keyItem],
                pinned: !config[keyItem]?.pinned
            }
        };

        const pinnedColumns = keys.filter(key => updatedConfig[key]?.pinned);
        const columnLeftValues = getPinnedColumnLeftPositions(pinnedColumns, this.pinnedColumnsRef);

        pinnedColumns.forEach(key => {
            updatedConfig[key] = {
                ...updatedConfig[key],
                style: {
                    ...config[key]?.style,
                    left: columnLeftValues[key] || "auto"
                }
            };
        });
        this.setState({
            tableData: {
                ...this.state.tableData,
                config: updatedConfig
            }
        });
        this.saveUserTableConfig(updatedConfig);
        this.updateEntityParams(updatedConfig);
    };

    updateColumnWidthInConfig = (columnKey, style) => {
        const { keys, config } = this.state.tableData;

        const pinnedColumns = keys.filter(key => config[key]?.isVisible && config[key]?.pinned);
        const columnLeftValues = getPinnedColumnLeftPositions(pinnedColumns, this.pinnedColumnsRef);

        pinnedColumns.forEach(key => {
            config[key] = {
                ...config[key],
                style: {
                    ...config[key]?.style,
                    left: columnLeftValues[key] || "auto"
                }
            };
        });

        const updatedConfig = {
            ...config,
            [columnKey]: {
                ...config[columnKey],
                style: {
                    ...config[columnKey]?.style,
                    ...style
                }
            }
        };
        this.setState({
            tableData: {
                ...this.state.tableData,
                config: updatedConfig
            }
        });
        this.saveUserTableConfig(updatedConfig);
        this.updateEntityParams(updatedConfig);
    };

    unPinAllColumn = async () => {
        const { config, keys } = this.state.tableData;
        const updatedConfig = { ...config };
        keys.forEach(key => {
            updatedConfig[key] = {
                ...updatedConfig[key],
                pinned: false,
                style: {
                    ...config[key]?.style,
                    left: "auto"
                }
            };
        });
        await this.setState({
            tableData: {
                ...this.state.tableData,
                config: updatedConfig
            }
        });
    };

    setDynamicJoinParam = async () => {
        const { params, tableData } = this.state;
        let additionalJoins = [];
        if (
            tableData.keys.every(key => !key.includes("water_band")) ||
            tableData.keys.some(key => key.includes("water_band") && tableData?.config?.[key]?.isVisible)
        ) {
            additionalJoins.push("water_band");
        }
        if (
            tableData.keys.every(key => !key.includes("energy_band")) ||
            tableData.keys.some(key => key.includes("energy_band") && tableData?.config?.[key]?.isVisible)
        ) {
            additionalJoins.push("energy_band");
        }
        if (
            tableData.keys.every(key => !key.includes("priority_element")) ||
            tableData.keys.some(key => key.includes("priority_element") && tableData?.config?.[key]?.isVisible)
        ) {
            additionalJoins.push("priority_elements");
        }
        if (
            tableData.keys.every(key => !key.includes("year_")) ||
            tableData.keys.some(key => key.includes("year_") && tableData?.config?.[key]?.isVisible)
        ) {
            additionalJoins.push("maintenance_years");
        }
        let newJoins = tableData.keys
            .filter(key => tableData?.config?.[key]?.dynamicField && tableData?.config?.[key]?.isVisible)
            .map(key => tableData?.config?.[key]?.dynamicFieldKey || key)
            .concat(additionalJoins)
            .join(",");
        await this.setState({
            params: {
                ...params,
                dynamic_joins: newJoins
            }
        });
    };

    setTableDataConfigBasedOnProject = async () => {
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        const entity = this.props.isCapitalRequest || query?.is_cr === "true" ? entities.CAPITAL_REQUESTS : entities.RECOMMENDATIONS;
        const config = await getTableConfig({
            user_id: localStorage.getItem("userId"),
            project_id: this.props.projectIdDashboard || this.props.match.params.id,
            client_id: this.props.projectReducer?.getProjectByIdResponse?.client?.id,
            table_type: entity
        });
        let tempKeys = [];
        let tempConfig = {};

        if (config?.data?.table_settings) {
            this.setState({ tableConfigChanged: true });
            const backendConfig = config?.data?.table_settings?.config;
            tempConfig = { ...recommendationsTableData.config };
            Object.keys(backendConfig).forEach(key => {
                tempConfig[key] = {
                    ...tempConfig[key],
                    style: backendConfig[key]?.style || tempConfig[key]?.style,
                    isVisible: backendConfig[key]?.isVisible ?? tempConfig[key]?.isVisible,
                    pinned: backendConfig[key]?.pinned ?? tempConfig[key]?.pinned
                };
            });

            tempKeys = [...config?.data?.table_settings?.keys];
        } else {
            this.setState({ tableConfigChanged: false });
            tempConfig = { ...recommendationsTableData.config };
            tempKeys = [...recommendationsTableData.keys];
        }
        if (this.props.isCapitalRequest || this.props.match.params.tab === "linked-cr-recommendations") {
            insertFields(tempKeys, ["requestor", "requested_date", "cr_status", "cr_status_notes"], "status_notes", "after");
            removeFields(tempKeys, ["status", "status_notes", "inspection_date", "surveyor"]);
            tempConfig["description"].label = "Capital Request";
            tempConfig["notes"].label = "Capital Request Narrative";
        } else {
            insertFields(tempKeys, ["status", "status_notes"], "cr_status", "before");
            insertFields(tempKeys, ["surveyor", "inspection_date"], "requestor", "before");
            removeFields(tempKeys, ["cr_status", "cr_status_notes"]);

            tempConfig["description"].label = "Recommendation";
            tempConfig["notes"].label = "Recommendation Narrative";
        }

        // Setting unit fields
        const unitFields = ["unit", "cost_per_unit", "quantity", "options_cost"];
        if (this.props.projectReducer?.getProjectByIdResponse?.display_unit) {
            const fieldsToInsert = unitFields.filter(field => !tempKeys.includes(field));
            if (fieldsToInsert.length > 0) {
                const insertIndex = tempKeys.length > 1 ? tempKeys.length - 2 : 0;
                insertFields(tempKeys, fieldsToInsert, tempKeys[insertIndex]);
            }
        } else {
            removeFields(tempKeys, unitFields);
        }

        // Priority elements
        if (this.props.recommendationsReducer.priorityElementsDropDownResponse?.priority_elements?.length) {
            const validPriorityKeys = new Set();

            this.props.recommendationsReducer.priorityElementsDropDownResponse.priority_elements.forEach((item, i) => {
                const priorityKey = `priority_element${i + 1}`;
                const lastIndex = tempKeys.length > 1 ? tempKeys.length - 2 : 0;
                if (!tempKeys.includes(priorityKey)) {
                    tempKeys.splice(lastIndex, 0, priorityKey);
                }
                tempConfig[priorityKey] = {
                    isVisible: tempConfig?.[priorityKey]?.isVisible ?? true,
                    label: item.display_name ? item.display_name : `Priority Element ${i + 1}`,
                    class: "width-230px",
                    searchKey: `priority_elements.${i + 1}`,
                    type: "number",
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "priority_element",
                    commonSearchKey: "priority_elements",
                    commonSearchObjectKey: `${i + 1}`,
                    pinned: tempConfig?.[priorityKey]?.pinned,
                    style: tempConfig?.[priorityKey]?.style,
                    hasLineEdit: true,
                    isDropdownLineEdit: true
                };
                validPriorityKeys.add(priorityKey);
            });

            tempKeys = tempKeys.filter(key => {
                if (key.startsWith("priority_element")) {
                    return validPriorityKeys.has(key);
                }
                return true;
            });
            Object.keys(tempConfig).forEach(key => {
                if (key.startsWith("priority_element") && !validPriorityKeys.has(key)) {
                    delete tempConfig[key];
                }
            });
        } else {
            tempKeys = tempKeys.filter(key => !key.startsWith("priority_element"));
            Object.keys(tempConfig).forEach(key => {
                if (key.startsWith("priority_element")) {
                    delete tempConfig[key];
                }
            });
        }

        // Settings for energy band
        if (this.props.projectReducer?.getProjectByIdResponse?.show_energy_band) {
            if (!tempKeys.includes("energy_band_show")) {
                const lastIndex = tempKeys.length > 1 ? tempKeys.length - 2 : 0;
                tempKeys.splice(lastIndex, 0, "energy_band_show");
            }

            energy_fields.forEach((item, i) => {
                const energyKey = `energy_band_${i}`;
                if (!tempKeys.includes(energyKey)) {
                    let indexToInsertBefore = tempKeys.length > 1 ? tempKeys.length - 2 : 0;
                    tempKeys.splice(indexToInsertBefore, 0, energyKey);
                }

                tempConfig[energyKey] = {
                    isVisible: tempConfig?.[energyKey]?.isVisible ?? true,
                    label: item.label,
                    class: "width-230px",
                    searchKey: `energy_bands.${item.key}`,
                    type: item.type,
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "",
                    commonSearchKey: "energy_band",
                    commonSearchObjectKey: item.key,
                    pinned: tempConfig?.[energyKey]?.pinned,
                    style: tempConfig?.[energyKey]?.style
                };
            });
        } else {
            tempKeys = tempKeys.filter(key => key !== "energy_band_show" && !key.startsWith("energy_band_"));
            Object.keys(tempConfig).forEach(key => {
                if (key === "energy_band_show" || key.startsWith("energy_band_")) {
                    delete tempConfig[key];
                }
            });
        }

        // Settings for water band
        if (this.props.projectReducer?.getProjectByIdResponse?.show_water_band) {
            if (!tempKeys.includes("water_band_show")) {
                let indexBeforeDate = tempKeys.length > 1 ? tempKeys.length - 2 : 0;
                tempKeys.splice(indexBeforeDate, 0, "water_band_show");
            }

            water_fields.forEach((item, i) => {
                const waterKey = `water_band_${i}`;
                if (!tempKeys.includes(waterKey)) {
                    let indexToInsertBefore = tempKeys.length > 1 ? tempKeys.length - 2 : 0;
                    tempKeys.splice(indexToInsertBefore, 0, waterKey);
                }

                tempConfig[waterKey] = {
                    isVisible: tempConfig?.[waterKey]?.isVisible ?? true,
                    label: item?.tableLabel ? item.tableLabel : item.label,
                    class: "width-230px",
                    searchKey: `water_bands.${item.key}`,
                    type: item.type,
                    hasWildCardSearch: true,
                    hasCommonSearch: false,
                    getListTable: "",
                    commonSearchKey: "water_band",
                    commonSearchObjectKey: item.key,
                    pinned: tempConfig?.[waterKey]?.pinned,
                    style: tempConfig?.[waterKey]?.style
                };
            });
        } else {
            tempKeys = tempKeys.filter(key => key !== "water_band_show" && !key.startsWith("water_band_"));
            Object.keys(tempConfig).forEach(key => {
                if (key === "water_band_show" || key.startsWith("water_band_")) {
                    delete tempConfig[key];
                }
            });
        }

        // Settings for dynamic fields
        if (this.props.recommendationsReducer?.dynamicFields?.recommendation_custom_fields) {
            const { recommendation_custom_fields: dynamicBands = {} } = this.props.recommendationsReducer?.dynamicFields;
            const validDynamicKeys = new Set();

            Object.entries(dynamicBands).forEach(([band, fields], i) => {
                fields.forEach(field => {
                    const dynamicKey = field?.field_name || "";

                    if (!tempKeys.includes(dynamicKey)) {
                        let indexToInsertBefore = tempKeys.length > 1 ? tempKeys.length - 2 : 0;
                        tempKeys.splice(indexToInsertBefore, 0, dynamicKey);
                    }

                    tempConfig[dynamicKey] = {
                        isVisible: tempConfig?.[dynamicKey]?.isVisible ?? field?.rules?.table_default_visible ?? false,
                        label: field?.label,
                        class: "width-230px",
                        searchKey: `dynamic_fields.${field?.field_name}`,
                        type: field?.field_type,
                        hasWildCardSearch: true,
                        hasCommonSearch: false,
                        getListTable: "",
                        commonSearchKey: "dynamic_fields",
                        commonSearchObjectKey: field?.field_name,
                        isDynamicField: true,
                        rules: field?.rules,
                        pinned: tempConfig?.[dynamicKey]?.pinned,
                        style: tempConfig?.[dynamicKey]?.style ?? field?.style
                    };

                    validDynamicKeys.add(dynamicKey);
                });
            });

            tempKeys = tempKeys.filter(key => {
                if (tempConfig[key]?.isDynamicField) {
                    return validDynamicKeys.has(key);
                }
                return true;
            });

            Object.keys(tempConfig).forEach(key => {
                if (tempConfig[key]?.isDynamicField && !validDynamicKeys.has(key)) {
                    delete tempConfig[key];
                }
            });
        } else {
            tempKeys = tempKeys.filter(key => !key.startsWith("dynamic_field_"));
            Object.keys(tempConfig).forEach(key => {
                if (tempConfig[key]?.isDynamicField) {
                    delete tempConfig[key];
                }
            });
        }

        tempKeys = _.uniq(tempKeys);
        await this.setState({
            tableData: {
                ...this.state.tableData,
                keys: tempKeys,
                config: tempConfig
            }
        });
        await this.updateEntityParams();
    };

    createAssetRecommendation = async () => {
        this.setState({ isLoading: true });
        await this.props.createAssetRecommendationAction({ asset_id: this.props.match.params.id });
        const {
            success,
            message,
            error = "Oops..! Recommendation creation failed"
        } = this.props.recommendationsReducer.createAssetRecommendationResponse || {};
        this.setState({ isLoading: false });
        if (success) {
            this.refreshRecommendationsList();
            this.setState({ alertMessage: message }, () => this.showAlert());
        } else {
            this.setState({ alertMessage: message || error }, () => this.showAlert());
        }
    };

    renderFiltersPopup = () => {
        const {
            showFiltersPopup,
            myFilters,
            summaryRowData: { year_totals = {} },
            dashboardFilterParams
        } = this.state;
        if (!showFiltersPopup) return null;
        const yearValues = Object.keys(year_totals);
        let filterCount = 0;
        let dashboardFilterCount = getAppliedFiltersCount(dashboardFilterParams);
        let myFiltersCount = getAppliedFiltersCount(myFilters);
        filterCount += dashboardFilterCount + myFiltersCount;
        return (
            <Portal
                body={
                    <MyFilters
                        dashboardFilterParams={myFilters}
                        yearValues={yearValues}
                        onCancel={() => this.toggleFiltersPopup()}
                        submitMasterFilter={this.submitMasterFilter}
                        getListParams={{ ...this.getListParams() }}
                        isRecommendationTable
                        openAppliedFiltersPopup={() => this.setState({ showAppliedFilters: true })}
                        filterCount={filterCount}
                    />
                }
                onCancel={() => this.toggleFiltersPopup()}
            />
        );
    };

    renderAppliedFilters = () => {
        const { showAppliedFilters, myFilters, params } = this.state;
        if (!showAppliedFilters) return null;
        return (
            <Portal
                body={
                    <AppliedFilters
                        myFilters={myFilters}
                        filterValues={params}
                        openFiltersPopup={() => this.setState({ showFiltersPopup: true })}
                        showFiltersIcon={!this.state.showFiltersPopup}
                        onCancel={() => this.setState({ showAppliedFilters: false })}
                        source={"recommendation"}
                    />
                }
                onCancel={() => this.setState({ showAppliedFilters: false })}
            />
        );
    };

    toggleAppliedFiltersPopup = () => {
        this.setState({ showAppliedFilters: !this.state.showAppliedFilters });
    };

    getListParams = () => {
        const { dashboardFilterParams = {} } = this.state;
        const { limit, offset, order, ...rest } = this.state.params;
        let params = { ...rest, ...dashboardFilterParams };
        const {
            match: {
                params: { section, tab }
            }
        } = this.props;

        const projectId = this.props.projectIdDashboard || this.props?.projectIdForSmartChart || this.props.match.params.id;
        if (section === "initiativeInfo" && this.state.params?.view !== "assigned") {
            const {
                location: { search }
            } = this.props;
            const query = qs.parse(search);
            params.project_id = query.pid;
        } else if (section !== "imageInfo" && section !== "initiativeInfo" && section !== "assetInfo") {
            params.project_id = projectId;
        }
        if (section === "initiativeInfo" && this.state.params?.view === "assigned") {
            params.initiative_id = this.props.match.params.id || null;
        }
        if (section === "buildinginfo") {
            params.building_id = this.props.match.params.id;
        } else if (section === "siteinfo") {
            params.site_id = this.props.match.params.id;
        } else if (section === "regioninfo") {
            params.region_id = this.props.match.params.id;
        } else if (section === "imageInfo") {
            params.image_id = this.props.match.params.id;
        } else if (section === "assetInfo") {
            params.asset_id = this.props.match.params.id;
        } else if (section === "recommendationsinfo" && tab === "linked-recommendations") {
            params.capital_request_id = this.props.match.params.id;
        } else if (section === "recommendationsinfo" && tab === "linked-cr-recommendations") {
            params.recommendation_id = this.props.match.params.id;
            params.is_cr = true;
        } else if (this.props?.isCapitalRequest) {
            params.is_cr = true;
        }

        return params;
    };

    submitMasterFilter = filterData => {
        this.setState({ myFilters: filterData }, async () => this.refreshRecommendationsList());
    };

    toggleFiltersPopup = () => {
        this.setState({ showFiltersPopup: !this.state.showFiltersPopup });
    };

    toggleShiftCspModal = () => {
        this.setState({ showShiftCspModal: !this.state.showShiftCspModal });
    };

    renderShiftCspModal = () => {
        const { showShiftCspModal, selectedRecomIds } = this.state;
        if (!showShiftCspModal) return null;
        const recommendationIds = selectedRecomIds.map(elem => elem.id);
        return (
            <Portal
                body={
                    <ShiftCspModal
                        onShiftCspYear={this.onShiftCspYear}
                        maintenance_years={this.state.maintenance_years}
                        recommendationIds={recommendationIds}
                        onCancel={this.toggleShiftCspModal}
                    />
                }
                onCancel={this.toggleShiftCspModal}
            />
        );
    };

    onShiftCspYear = async data => {
        this.setState({ isLoading: true });
        const query = qs.parse(this.props.location.search);
        const { selectedRecomIds } = this.state;
        const project_id = query.pid || this.props.match.params.id;
        await this.props.shiftCspYears(
            {
                ...data,
                recommendation_ids: selectedRecomIds.map(elem => elem.id)
            },
            project_id
        );
        const { message, success, error } = this.props.recommendationsReducer?.shiftCspYearResponse || {};
        if (!success) {
            await this.setState({
                alertMessage: message || error
            });
            this.showLongAlert();
        } else {
            this.toggleShiftCspModal();
            await this.refreshRecommendationsList();
            this.setState(
                {
                    alertMessage: message
                },
                () => this.showLongAlert()
            );
        }
        this.setState({ isLoading: false });
    };

    showLongAlert = () => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show-long-alert";
            x.innerText = this.state.alertMessage;
            setTimeout(function () {
                x.className = x.className.replace("show-long-alert", "");
            }, 6000);
        }
    };

    createRecommendationFromCr = CrDetailPrope => {
        if (this.tableRef?.current?.scrollLeft) {
            this.props.setRecommendationScrollPosition(this.tableRef?.current?.scrollLeft);
        }
        this.setState({
            selectedRecommendations: null
        });
        const { history, recommendationDetails } = this.props;
        const { id, project, client } = recommendationDetails || CrDetailPrope || {};
        if (id && project?.id && client?.id) {
            sessionStorage.setItem("currentRecommendationData", JSON.stringify(recommendationDetails || CrDetailPrope));
            let newParams = {
                p_id: project?.id || "",
                c_id: client?.id || "",
                capital_request_id: id,
                type: RECOMMENDATION_SOURCE_TYPES.CAPITAL_REQUEST
            };

            const newPath = `/recommendations/add?${qs.stringify(newParams)}`;
            addToBreadCrumpData({
                key: "add",
                name: "Add Recommendation",
                path: newPath
            });
            history.push(newPath);
        } else {
            this.setState({ alertMessage: "Something went wrong" }, () => {
                this.showAlert();
            });
        }
    };
    handleNormalRecomGrouping = async is_excel => {
        this.setState({ isNormalRecomGroupingLoading: true });
        let { exportSettingsData = {} } = this.props.smartChartReducer;
        const entityId = this.props.match.params.id;
        if (!this.state.normalRecomGrouping?.settingsId) {
            await this.props.getExportSettingsForSmartChartRecom({
                project_id: entityId,
                client_id: this.props.projectReducer?.getProjectByIdResponse?.client?.id
            });
            exportSettingsData = this.props.smartChartReducer.exportSettingsData;
            this.setState({ normalRecomGrouping: { settingsId: exportSettingsData?.data?.id } });
        }
        const normal_recom_grouping = {
            normal_recom: {
                band1: {
                    settings_id: this.state?.normalRecomGrouping?.settingsId || "",
                    grouping: this.state?.normalRecomGrouping?.grouping || "",
                    config_filter: {
                        ...this.state?.params,
                        ...getUpdatedCommonFilters(
                            this.state?.tableDataExportFilters || {},
                            this.state?.params?.list,
                            Object.entries(this.props.recommendationsReducer.entityParams[this.props.match.params.section]?.tableConfig || {})
                        ),
                        wildcard_filters: {
                            ...getUpdatedWildCardFilters(
                                this.state.params?.filters || {},
                                Object.entries(this.props.recommendationsReducer.entityParams[this.props.match.params.section]?.tableConfig || {}),
                                this.props.recommendationsReducer.priorityElementsDropDownResponse,
                                exportSettingsData
                            )
                        },
                        global_search: this?.state?.params?.search || "",
                        sort_by_order: {
                            ...getUpdatedSortFilters(
                                this.state?.params?.order || {},
                                Object.entries(this.props.recommendationsReducer.entityParams[this.props.match.params.section]?.tableConfig || {}),
                                this.state?.params,
                                exportSettingsData
                            )
                        },
                        list: this.state.params.list,
                        order: this.state.params.order,
                        capital_type: this.state.capital_type,
                        start_year: this.state.start_year || null,
                        end_year: this.state.end_year || null,
                        infrastructure_requests: this.state.infrastructure_requests || null,
                        year: this.state.year,
                        dashboard: this.state.dashboard,
                        recommendation_ids: this.state?.selectedRecomIds?.map(item => item.id)
                    },
                    config_filter_names: {
                        list: this.state?.params?.list || null,
                        filters: this.state?.params?.filters,
                        tableDataExportFilters: this.state?.tableDataExportFilters,
                        order: this.state?.params?.order || null,
                        maintenance_year: this.state?.params?.maintenance_year || []
                    },
                    mfilter: {
                        ...this.state.params.filters,
                        ...this.state.myFilters.filters,
                        ...this.state.dashboardFilterParams.filters,
                        client_ids: [this.props.projectReducer?.getProjectByIdResponse?.client?.id],
                        project_ids: [entityId],
                        recommendations: this.state?.selectedRecomIds?.map(item => item.id)
                    },
                    number_of_recom: this.state?.selectedRecomIds?.length || 0
                }
            }
        };
        await this.props?.normalRecomGrouping({ ...normal_recom_grouping, project_ids: [entityId], is_excel: is_excel === "excel" });
        if (this.props?.recommendationsReducer?.getNormalRecomDownloadResponse?.success) {
            this.setState({ normalRecomGrouping: {} });
        }
        this.setState({ isNormalRecomGroupingLoading: false });
    };

    saveUserTableConfig = async (config, isReset) => {
        const {
            location: { search }
        } = this.props;
        const query = qs.parse(search);
        const entity = this.props.isCapitalRequest || query?.is_cr === "true" ? entities.CAPITAL_REQUESTS : entities.RECOMMENDATIONS;
        await saveTableConfig({
            user_id: localStorage.getItem("userId"),
            project_id: this.props.projectIdDashboard || this.props.match.params.id,
            client_id: this.props.projectReducer?.getProjectByIdResponse?.client?.id,
            table_type: entity,
            table_settings: isReset ? null : { keys: this.state.tableData.keys, config: config || this.state.tableData.config }
        });
        this.setState({ tableConfigChanged: isReset ? false : true });
    };

    resetAllTableConfig = async () => {
        this.setState({ isTableResetting: true, isLoading: true });
        await this.saveUserTableConfig(null, true);
        await this.setTableDataConfigBasedOnProject();
        await this.setDynamicJoinParam();
        this.setState({ isTableResetting: false });
        this.refreshRecommendationsList();
    };

    render() {
        const {
            showWildCardFilter,
            paginationParams,
            currentViewAllUsers,
            showViewModal,
            showViewModalExport,
            showAssignConsultancyUsers,
            showAssignClientUsers,
            clients,
            consultancy_users,
            tableData,
            selectedRecommendations,
            selectedRowId,
            showCutPasteModal,
            summaryRowData,
            logData,
            historyPaginationParams,
            historyParams,
            // permissions,
            logPermission,
            imageResponse,
            multiExportPdfLoader,
            multiExportWordLoader,
            exportWordLoading,
            tableDataExportFilters,
            isInputMode,
            lineEditingEnabled,
            myFilters,
            dashboardFilterParams
        } = this.state;
        const {
            match: {
                params: { section }
            },
            location: { search }
        } = this.props;
        let tempIds = localStorage.getItem("recommendationIds") ? JSON.parse(localStorage.getItem("recommendationIds")) : [];
        let priorityElementsData = this.props.recommendationsReducer.priorityElementsDropDownResponse?.priority_elements || [];
        let selectedRecomIds = this.state.selectedRecomIds.map(elem => elem.id);
        let filteredCount = Object.entries(myFilters)?.filter(([key, value]) => value.length)?.length;
        if (myFilters?.start_year || myFilters?.end_year) {
            filteredCount += 1;
        }

        const query = qs.parse(search);
        const entity = this.props.isCapitalRequest || query?.is_cr === "true" ? permissions.CR_RECOMMENDATIONS : permissions.RECOMMENDATIONS;
        return (
            <LoadingOverlay active={this.props.parentLoading || this.state.isLoading} spinner={<Loader />} fadeSpeed={10}>
                {section === "add" || section === "edit" ? (
                    <Form
                        projectId={this.props.projectId}
                        selectedRecommendations={selectedRecommendations}
                        refreshRecommendationsList={this.refreshRecommendationsList}
                        handleAddRecommendations={this.handleAddRecommendations}
                        handleUpdateRecommendations={this.handleUpdateRecommendations}
                        getSiteListBasedOnRegion={this.getSiteListBasedOnRegion}
                        getRegionListBasedOnClient={this.getRegionListBasedOnClient}
                        consultancy_users1={consultancy_users}
                        getDataById={this.getDataById}
                        isChartView={this.props.isChartView}
                    />
                ) : section === "recommendationsinfo" && !this.props?.isCrLinkedRecommendations && !this.props.isCapitalRequest ? (
                    <RecommendationsInfo
                        keys={tableData.keys}
                        config={tableData.config}
                        tableData={getTableSliderData()}
                        getQaQcData={this.getQaQcData}
                        qaqcPendingStatus={this.qaqcPendingStatus()}
                        isProjectReviewer={this.isProjectReviewer}
                        getDataById={this.getDataById}
                        handleUpdateData={this.handleUpdateRecommendations}
                        uploadImages={this.uploadImages}
                        getAllImageList={this.getAllImageList}
                        deleteImages={this.deleteImages}
                        showInfoPage={this.showInfoPage}
                        showDeleteBox={this.openDeleteBox}
                        isChartView={this.props.isChartView}
                        isBuildingLocked={this.props.isBuildingLocked}
                        data={this.props.isBuildingEFCI}
                        updateRecommendationImageComment={this.updateRecommendationImageComment}
                        showRestoreBox={this.openRestoreBox}
                        getAllSettingsLogs={this.getLogData}
                        handlePerPageChangeHistory={this.handlePerPageChangeHistory}
                        handlePageClickHistory={this.handlePageClickHistory}
                        handleGlobalSearchHistory={this.handleGlobalSearchHistory}
                        globalSearchKeyHistory={this.state.historyParams && this.state.historyParams.search ? this.state.historyParams.search : ""}
                        logData={logData}
                        handleDeleteLog={this.handleDeleteLog}
                        historyPaginationParams={historyPaginationParams}
                        handleRestoreLog={this.HandleRestoreRegionLog}
                        historyParams={historyParams}
                        updateLogSortFilters={this.updateLogSortFilters}
                        permissions={{}}
                        logPermission={logPermission}
                        imageResponse={imageResponse}
                        handleSelect={this.props.handleSelect}
                        handleSelectAll={this.props.handleSelectAll}
                        assignProjectModal={this.props.assignProjectModal}
                        recomentationIds={this.props.recomentationIds}
                        hasEdit={checkPermission("forms", entity, "edit")}
                        hasDelete={!this.props.isAssetView && checkPermission("forms", entity, "delete")}
                        hasLogView={checkPermission("logs", entity, "view")}
                        hasLogDelete={checkPermission("logs", entity, "delete")}
                        hasLogRestore={checkPermission("logs", entity, "restore")}
                        hasInfoPage={checkPermission("forms", entity, "view")}
                        hasCreate={checkPermission("forms", entity, "create")}
                        downloadPdfReport={this.downloadPdfReport}
                        entity={entity}
                        assignImagesToRecom={this.assignImagesToRecom}
                        updateBudget={this.props.updateBudgetPriority}
                        updateFMP={this.props.updateFMP}
                        updateIR={this.props.updateIR}
                        updateRL={this.props.updateRL}
                        updateSelectedRow={this.updateSelectedRow}
                        getPriorityElementDropDownData={this.getPriorityElementDropDownData}
                        postExport={this.postExport}
                        exportLoader={this.state.exportLoader}
                        lockRecommendation={this.lockRecommendation}
                        getDynamicFields={this.props.getDynamicFields}
                        createRecommendationFromCr={this.createRecommendationFromCr}
                    />
                ) : (
                    <RecommendationsMain
                        showWildCardFilter={showWildCardFilter}
                        paginationParams={paginationParams}
                        currentViewAllUsers={currentViewAllUsers}
                        showViewModal={this.showViewModal}
                        showViewModalExport={this.showViewModalExport}
                        tableData={tableData}
                        handleGlobalSearch={this.handleGlobalSearch}
                        globalSearchKey={this.state.params.search}
                        updateSelectedRow={this.updateSelectedRow}
                        selectedRowId={selectedRowId}
                        toggleWildCardFilter={this.toggleWildCardFilter}
                        updateCurrentViewAllUsers={this.updateCurrentViewAllUsers}
                        handleDeleteRecommendations={this.handleDeleteRecommendations}
                        showEditPage={this.showEditPage}
                        handlePerPageChange={this.handlePerPageChange}
                        handlePageClick={this.handlePageClick}
                        showAddForm={this.showAddForm}
                        showInfoPage={this.showInfoPage}
                        updateWildCardFilter={this.updateWildCardFilter}
                        wildCardFilter={this.state.params.filters}
                        handleHideColumn={this.handleHideColumn}
                        getListForCommonFilterRecommendation={this.getListForCommonFilter}
                        updateCommonFilter={this.updateCommonFilter}
                        commonFilter={this.state.params.list}
                        resetAllFilters={this.resetAllFilters}
                        updateTableSortFilters={this.updateTableSortFilters}
                        updateLastSortFilter={this.updateLastSortFilter}
                        resetSort={this.resetSort}
                        tableParams={this.state.params}
                        isImageView={this.props.isImageView}
                        isBuildingLocked={this.props.isBuildingLocked}
                        handleCutPaste={this.handleCutPaste}
                        summaryRowData={summaryRowData}
                        exportTableXl={this.exportTableXl}
                        tableLoading={this.state.tableLoading}
                        exportExcelAllTrades={this.exportAllTrades}
                        exportAllTradesLoading={this.state.exportAllTradesLoading}
                        showRestoreModal={this.showRestoreModal}
                        selectFilterHandler={this.selectFilterHandler}
                        selectFilterHandlerInitiative={this.selectFilterHandlerInitiative}
                        selectedDropdown={this.state.selectedDropdown}
                        selectedDropdownInitiaive={this.state.selectedDropdownInitiaive}
                        permissions={{}}
                        logPermission={logPermission}
                        handleSelect={this.props.handleSelect}
                        handleSelectAll={this.props.handleSelectAll}
                        assignProjectModal={this.assignInitiative}
                        recomentationIds={this.props.recomentationIds}
                        enableButton={selectedRecomIds?.length}
                        idCount={tempIds.length}
                        unAassignContent={this.unAassignContent}
                        hasExport={checkPermission("forms", entity, "export")}
                        showAddButton={checkPermission("forms", entity, "create")}
                        hasEdit={!this.props.isImageView && !this.props.isAssetView && checkPermission("forms", entity, "edit")}
                        hasDelete={!this.props.isAssetView && checkPermission("forms", entity, "delete")}
                        hasInfoPage={!this.props.isSmartChartView && checkPermission("forms", entity, "view")}
                        entity={entity}
                        handleSelectRecom={this.handleSelectRecom}
                        handleSelectAllRecom={this.handleSelectAllRecom}
                        selectedRecomIds={selectedRecomIds}
                        isBudgetPriority={this.props.isBudgetPriority}
                        isFullscreen={this.props.isFullscreen}
                        handleFullView={this.props.handleFullView}
                        toggleSecondChartView={this.props.toggleSecondChartView}
                        tableRef={this.tableRef}
                        hasNewlyCreated={true}
                        hasNewlyEdited={true}
                        hasViewMyRecommendation={true}
                        hasViewExportModal={true}
                        hasFilterByImages={true}
                        hasFilterByMyQa={!this.props.isSmartChartView}
                        hasFilterByQaPassed={!this.props.isSmartChartView}
                        hasFilterByWater={true}
                        hasFilterByEnergy={true}
                        hasFilterByFmp={true}
                        hasRecomTypeFilter={true}
                        hasFilterByBudgetPriority={true}
                        filterByBudgetPriority={this.filterByBudgetPriority}
                        filterByWater={this.filterByWater}
                        filterByEnergy={this.filterByEnergy}
                        filterByFmp={this.filterByFmp}
                        filterByRecomType={this.filterByRecomType}
                        filterBySurveyor={this.filterBySurveyor}
                        filterByImages={this.filterByImages}
                        filterByReviews={this.filterByReviews}
                        filterByQaPassed={this.filterByQaPassed}
                        filterByIR={this.filterByIR}
                        resetAll={this.resetAll}
                        isColunmVisibleChanged={this.isColunmVisibleChanged}
                        hasMultiAction={!this.props.isBudgetPriority && !this.props.isImageView && !this.props.isAssetView}
                        isAssetView={this.props.isAssetView}
                        handleEditMultiSelectedData={this.toggleMultiSelectEditForm}
                        showSelectedRecom={this.showSelectedRecom}
                        clearSelection={this.clearSelection}
                        everyItemCheckedPerPage={tableData?.data?.every(item => selectedRecomIds.includes(item.id))}
                        selectWholeRecommendation={this.selectWholeRecommendation}
                        hasIrRecommendation={true}
                        priorityElementsData={priorityElementsData}
                        exportSelectedRecom={this.exportSelectedRecom}
                        multiExportPdfLoader={multiExportPdfLoader}
                        multiExportWordLoader={multiExportWordLoader}
                        exportToWordFile={this.exportToWord}
                        exportToExcelFiles={this.exportToExcel}
                        exportWordLoading={exportWordLoading}
                        tableDataExportFilters={tableDataExportFilters}
                        isInputMode={isInputMode}
                        handleCellFocus={this.handleCellFocus}
                        handleCellValueChange={this.handleCellValueChange}
                        lineEditingEnabled={lineEditingEnabled}
                        toggleLineEditing={this.toggleLineEditing}
                        handleColumnPin={this.handleColumnPin}
                        updateColumnWidthInConfig={this.updateColumnWidthInConfig}
                        pinnedColumnsRef={this.pinnedColumnsRef}
                        isSmartChartView={this.props.isSmartChartView}
                        unPinAllColumn={this.unPinAllColumn}
                        createAssetRecommendation={this.createAssetRecommendation}
                        openFiltersPopup={this.toggleFiltersPopup}
                        filteredCount={filteredCount}
                        toggleShiftCspModal={this.toggleShiftCspModal}
                        dashboardFilterParams={dashboardFilterParams}
                        myFilters={myFilters}
                        toggleAppliedFiltersPopup={this.toggleAppliedFiltersPopup}
                        isCapitalRequest={this.props.isCapitalRequest}
                        isCrLinkedRecommendations={this.props?.isCrLinkedRecommendations}
                        createRecommendationFromCr={this.createRecommendationFromCr}
                        isTableResetting={this.state.isTableResetting}
                        handleNormalRecomGrouping={this.handleNormalRecomGrouping}
                        isNormalRecomGroupingLoading={this.state?.isNormalRecomGroupingLoading}
                        lineEditDropdownValue={this.state.lineEditDropdownValue}
                        isDropdownLoading={this.state?.isDropdownLoading}
                        resetAllTableConfig={this.resetAllTableConfig}
                    />
                )}

                {this.renderConfirmationModal()}
                {this.renderRestoreConfirmationModal()}
                {this.renderCutPasteConfirmationModal()}
                {this.renderConfirmationModalLog()}
                {this.renderConfirmationModalUnassign()}
                {this.renderMultiSelectEditForm()}
                {this.renderHardDeleteConfirmationModal()}
                {this.renderFiltersPopup()}
                {this.renderShiftCspModal()}
                {this.renderAppliedFilters()}
                {this.renderAssingConfirmationModal()}
                {showViewModal ? (
                    <Portal
                        body={
                            <DynamicColumnModal
                                tableData={tableData}
                                handleHideColumn={this.handleHideColumn}
                                onCancel={() => {
                                    this.setState({ showViewModal: false });
                                    this.props?.isDraggable && this.props?.isDraggable(false);
                                }}
                                isDraggable={this.props?.isDraggable}
                            />
                        }
                        onCancel={() => {
                            this.setState({ showViewModal: false });
                            this.props?.isDraggable && this.props?.isDraggable(false);
                        }}
                    />
                ) : null}
                {showViewModalExport?.show ? (
                    <Portal
                        body={
                            <ViewExportModal
                                keys={tableData.keys}
                                config={tableData.config}
                                tableData={tableData}
                                handleHideColumn={this.handleHideColumn}
                                onCancel={() => {
                                    this.setState({ showViewModalExport: { show: false, type: "" } });
                                    this.props?.isDraggable && this.props?.isDraggable(false);
                                }}
                                projectIdForSmartChart={
                                    showViewModalExport?.type === "normalRecomGrouping"
                                        ? this.props.match.params.id
                                        : this.props?.projectIdForSmartChart
                                }
                                isSmartChartView={this.props?.isSmartChartView || showViewModalExport?.type === "normalRecomGrouping"}
                                clientIdForSmartChart={
                                    showViewModalExport?.type === "normalRecomGrouping"
                                        ? this.props.projectReducer?.getProjectByIdResponse?.client?.id
                                        : this.props?.clientIdForSmartChart
                                }
                                setNormalRecomData={
                                    showViewModalExport?.type === "normalRecomGrouping"
                                        ? (settingsId, grouping) =>
                                              this.setState({ normalRecomGrouping: { settingsId: settingsId, grouping: grouping } })
                                        : this.props?.setNormalRecomData
                                }
                                smartChartTileConfig={this.props?.smartChartTileConfig}
                                isDraggable={this.props?.isDraggable}
                                grouping={this.props?.isSmartChartView || showViewModalExport?.type === "normalRecomGrouping"}
                                settingsId={showViewModalExport?.type === "normalRecomGrouping" ? this.state?.normalRecomGrouping?.settingsId : null}
                                groupingValue={showViewModalExport?.type === "normalRecomGrouping" ? this.state?.normalRecomGrouping?.grouping : null}
                            />
                        }
                        onCancel={() => {
                            this.setState({ showViewModalExport: { show: false, type: "" } });
                            this.props?.isDraggable && this.props?.isDraggable(false);
                        }}
                    />
                ) : null}
                {/* {showViewImport ? (
                    <Portal
                        body={
                            <ViewImportModal
                                // handleHideColumn={this.handleHideColumn}
                                onCancel={() => this.setState({ showViewImport: false })}
                            />
                        }
                        onCancel={() => this.setState({ showViewImport: false })}
                    />
                ) : null} */}
                {showAssignConsultancyUsers ? (
                    <Portal
                        body={
                            <AssignConsultancyUserModal
                                onCancel={() => this.setState({ showAssignConsultancyUsers: false })}
                                userList={consultancy_users}
                            />
                        }
                        onCancel={() => this.setState({ showAssignConsultancyUsers: false })}
                    />
                ) : null}
                {showAssignClientUsers ? (
                    <Portal
                        body={<AssignClientUserModal onCancel={() => this.setState({ showAssignClientUsers: false })} userList={clients} />}
                        onCancel={() => this.setState({ showAssignClientUsers: false })}
                    />
                ) : null}
                {showCutPasteModal ? (
                    <Portal
                        body={
                            <CutPasteModal
                                HandleCutPasteSubmit={this.ShowCutPasteModalConfirm}
                                startYear={this.state.selectedYear}
                                maintenance_years={this.state.maintenance_years}
                                onCancel={this.toggleShowCutPasteModal}
                            />
                        }
                        onCancel={this.toggleShowCutPasteModal}
                    />
                ) : null}
            </LoadingOverlay>
        );
    }
}

const mapStateToProps = state => {
    const {
        recommendationsReducer,
        initativeReducer,
        siteReducer,
        buildingReducer,
        commonReducer,
        regionReducer,
        projectReducer,
        dashboardReducer,
        smartChartReducer
    } = state;
    return {
        recommendationsReducer,
        initativeReducer,
        siteReducer,
        buildingReducer,
        commonReducer,
        regionReducer,
        projectReducer,
        dashboardReducer,
        smartChartReducer
    };
};

export default withRouter(
    connect(mapStateToProps, {
        ...recommendationsActions,
        ...siteActions,
        ...buildingActions,
        ...CommonActions,
        ...regionActions,
        ...projectAction,
        ...initativeAction,
        ...smartChartAction
    })(index)
);
