const baseUrl = process.env.REACT_APP_BASE_URL;
const originUrl = process.env.REACT_APP_ORIGIN_URL;
const reportUrl = process.env.REACT_APP_REPORT_URL;
const fastApiUrl = process.env.REACT_APP_FAST_API_URL;
const appMode = process.env.REACT_APP_MODE;
const s3HostUrl = process.env.REACT_APP_S3_HOST_URL;

if (!baseUrl) {
    throw new Error("API Url is not provided.");
} else if (!originUrl) {
    throw new Error("API Url is not provided.");
} else if (!reportUrl) {
    throw new Error("Report url is not provided");
} else if (!fastApiUrl) {
    throw new Error("Fast api url is not provided");
}
export const MAIN_ROUTE = `${baseUrl}/`;
export const API_ROUTE = `${baseUrl}`;
export const ORIGIN_URL = originUrl;
export const REPORT_URL = reportUrl;
export const FAST_API_URL = fastApiUrl;
export const APP_MODE = appMode || "tracker";
export const S3_HOST = s3HostUrl || "https://fca-tracker.s3.us-east-2.amazonaws.com";
