// Get all regions
export const GET_MENU_ITEMS_REQUEST = "GET_MENU_ITEMS_REQUEST";
export const GET_MENU_ITEMS_SUCCESS = "GET_MENU_ITEMS_SUCCESS";
export const GET_MENU_ITEMS_FAILURE = "GET_MENU_ITEMS_FAILURE";

export const GET_EXPORT_ITEMS_REQUEST = "GET_EXPORT_ITEMS_REQUEST";
export const GET_EXPORT_ITEMS_SUCCESS = "GET_EXPORT_ITEMS_SUCCESS";
export const GET_EXPORT_ITEMS_FAILURE = "GET_EXPORT_ITEMS_FAILURE";

export const ADD_EXPORT_ITEMS_REQUEST = "ADD_EXPORT_ITEMS_REQUEST";
export const ADD_EXPORT_ITEMS_SUCCESS = "ADD_EXPORT_ITEMS_SUCCESS";
export const ADD_EXPORT_ITEMS_FAILURE = "ADD_EXPORT_ITEMS_FAILURE";

export const GET_PROJECT_MENU_ITEMS_REQUEST = "GET_PROJECT_MENU_ITEMS_REQUEST";
export const GET_PROJECT_MENU_ITEMS_SUCCESS = "GET_PROJECT_MENU_ITEMS_SUCCESS";
export const GET_PROJECT_MENU_ITEMS_FAILURE = "GET_PROJECT_MENU_ITEMS_FAILURE";

// Set form dirty (for detect unsaved changes)
export const SET_FORM_DIRTY_SUCCESS = "SET_FORM_DIRTY_SUCCESS";
export const SET_FORM_DIRTY_FAILURE = "SET_FORM_DIRTY_FAILURE";

export const GET_MENU_LINK_EMAIL_REQUEST = "GET_MENU_LINK_EMAIL_REQUEST";
export const GET_MENU_LINK_EMAIL_SUCCESS = "GET_MENU_LINK_EMAIL_SUCCESS";
export const GET_MENU_LINK_EMAIL_FAILURE = "GET_MENU_LINK_EMAIL_FAILURE";

export const entities = {
    RECOMMENDATIONS: "RECOMMENDATIONS",
    NARRATIVES: "NARRATIVES",
    ASSET_MANAGEMENT: "ASSET_MANAGEMENT",
    SMART_CHARTS: "SMART_CHARTS",
    SITES: "SITES",
    BUILDINGS: "BUILDINGS",
    REGIONS: "REGIONS",
    CAPITAL_REQUESTS: "CAPITAL_REQUESTS"
};

export const LOCK_STATUS = {
    LOCKED: "locked",
    NOT_LOCKED: "not_locked",
    PARTIALLY_LOCKED: "partially_locked"
};

export const FILE_TYPE = {
    WORD: "word",
    PDF: "pdf",
    PPT: "ppt",
    EXCEL: "excel"
};

export const DYNAMIC_FIELD_TYPES = {
    TEXT: "text",
    MEMO: "memo",
    SELECT: "select",
    NUMBER: "number",
    DATE: "date",
    CURRENCY: "currency",
    BOOLEAN: "boolean"
};

export const RECOMMENDATION_SOURCE_TYPES = {
    REGULAR: "Regular",
    SIMILAR: "Similar",
    DUPPLICATE: "Duplicate",
    GEO_HIERARCHY: "Building",
    CAPITAL_REQUEST: "Capital Request"
};
