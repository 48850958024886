import React, { useState, useEffect } from "react";
import { Howl, Howler } from "howler";
import { Dropdown } from "react-bootstrap";

const CustomAudioPlayer = ({ src, duration: audioDuration }) => {
    const [sound, setSound] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        const newSound = new Howl({
            src: [src],
            html5: true,
            onload: () => {
                setDuration(newSound.duration());
                setIsLoading(false);
            },
            onplay: () => {
                setIsPlaying(true);
                setIsLoading(false);
            },
            onpause: () => {
                setIsPlaying(false);
            },
            onstop: () => {
                setIsPlaying(false);
                setCurrentTime(0);
            },
            onend: () => {
                setIsPlaying(false);
                setCurrentTime(duration);
            },
            onseek: () => {
                setCurrentTime(newSound.seek());
            },
            rate: playbackRate
        });

        setSound(newSound);

        return () => {
            newSound.unload();
        };
    }, [src, playbackRate]);

    useEffect(() => {
        if (sound) {
            const interval = setInterval(() => {
                if (sound.playing()) {
                    setCurrentTime(sound.seek());
                }
            }, 100);

            return () => clearInterval(interval);
        }
    }, [sound]);

    const handleToggle = isOpen => {
        setShowDropdown(isOpen);
    };

    const playPause = () => {
        if (isPlaying) {
            sound.pause();
        } else {
            setIsLoading(true);
            sound.play();
        }
    };

    const onSeek = event => {
        const newTime = parseFloat(event.target.value);
        sound.seek(newTime);
    };

    const onVolumeChange = event => {
        const newVolume = parseFloat(event.target.value);
        Howler.volume(newVolume);
        setVolume(newVolume);
    };

    const onPlaybackRateChange = event => {
        const newRate = parseFloat(event.target.value);
        setPlaybackRate(newRate);
        setTimeout(() => setShowDropdown(true), 0);
    };

    const formatTime = time => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.round(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    return (
        <div className="audio-player-outer">
            <audio src={src} preload="metadata" />

            <div className="audio-ply-cust">
                <button onClick={playPause}>
                    {isPlaying ? (
                        <i class="fa fa-pause"></i>
                    ) : isLoading ? (
                        <div className="button-loader d-flex justify-content-center align-items-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <i class="fa fa-play"></i>
                    )}
                </button>

                <input type="range" min="0" max={duration} value={currentTime} onChange={onSeek} />

                <span>
                    {formatTime(currentTime)} / {formatTime(audioDuration)}
                </span>
            </div>

            <div className="volume-up">
                <label>
                    <i class="fas fa-volume-up"></i>
                </label>
                <input type="range" min="0" max="1" step="0.01" value={volume} onChange={onVolumeChange} style={{ cursor: "pointer" }} />
            </div>
            <div className="drp-dwn-more dp-drop">
                <Dropdown show={showDropdown} onToggle={handleToggle}>
                    <Dropdown.Toggle id="dropdown-basic" className="export-btn">
                        <div className="more-icon">
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu id="dropdown-menu">
                        <div className="dropdown-item">
                            <label>Playback Rate:</label>
                            <select value={playbackRate} onChange={onPlaybackRateChange} style={{ cursor: "pointer" }}>
                                <option value="0.5">0.5x</option>
                                <option value="1">1x</option>
                                <option value="1.5">1.5x</option>
                                <option value="2">2x</option>
                            </select>
                        </div>
                        <Dropdown.Item as="div">
                            <a href={src} download>
                                Download
                            </a>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
};

export default CustomAudioPlayer;
