import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import tradeIcon from "../../../assets/img/Trade.svg";
import systemIcon from "../../../assets/img/systm.svg";
import subSystemIcon from "../../../assets/img/sub-systm.svg";
import docIcon from "../../../assets/img/doc.svg";
import buildingIcon from "../../../assets/img/buildings.svg";
import { getAddressFromCoordinates, renderFileSize, toTitleCase, resetCursor, getFileExtension } from "../../../config/utils";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";

import imgProcess from "../../../assets/img/img-process.svg";
import heartLine from "../../../assets/img/heart-line.svg";
import heartFill from "../../../assets/img/heart-fill.svg";
import Draggable from "react-draggable";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import actions from "../actions";
import { useSelector } from "react-redux";
import Iframe from "react-iframe";
import { Dropdown } from "react-bootstrap";
import noDataForAnalysis from "../../../assets/img/no-data-tr.svg";
import Loader from "../../common/components/Loader";
import LoadingOverlay from "react-loading-overlay";
import Actions from "../actions";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import Portal from "../../common/components/Portal";
import { downloadPresignedUrlFile, getPresignedUrl } from "../../common/actions";

function ImageViewModal({
    selectedImg,
    onCancel,
    hasEdit,
    handleEdit,
    handleFavClick,
    viewRecommendations,
    isAssignView,
    handleNext,
    handlePrev,
    imageList,
    updateImage,
    handleInputCaptionData,
    imageReducer,
    viewAssets,
    getImageAnalysisData,
    editFunction,
    ImageParams,
    isSurveyAssetImage,
    updateImageFromSurveyAsset
}) {
    const [formattedAddress, setFormattedAddress] = useState("");
    const [copied, setCopied] = useState(false);
    const [caption, setCaption] = useState({
        caption: selectedImg?.caption,
        image_ids: [selectedImg?.id],
        tags: selectedImg?.labels?.map(item => item.name),
        removed_tags: [],
        captionchange: true
    });
    const { patchImageAnalysisData } = useSelector(state => state.imageReducer);
    const [currentTab, setCurrentTab] = useState("main");
    const [toggleInput, setToggleInput] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [imageExporting, setImageExporting] = useState(false);
    const [imageAnalyzeLoader, setImageAnalyzeLoader] = useState(false);
    const [imageAnalyzeType, setImageAnalyzeType] = useState("");
    const [imageAnalyzeTab, setImageAnalyzeTab] = useState("text");
    const ref = useRef(null);
    const [editedText, setEditedText] = useState();
    const [originalText, setOriginalText] = useState();
    const [editedObject, setEditedObject] = useState();
    const [originalObjects, setOriginalObjects] = useState();
    const [editableItem, setEditableItem] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dispatch = useDispatch();
    const [filteredIndices, setFilteredIndices] = useState([]);
    const [savedData, setSavedData] = useState({ object: [], text: [] });
    const [copySuccessIndex, setCopySuccessIndex] = useState(false);
    const inputRef = useRef(null);
    const jsonStrings = typeof selectedImg?.image_labels === "string" ? selectedImg?.image_labels?.replace(/'/g, '"') : selectedImg?.image_labels;
    let parsedData = [];
    try {
        parsedData = jsonStrings
            ? typeof jsonStrings === "string"
                ? JSON.parse(jsonStrings)
                : jsonStrings
            : imageReducer?.getImageAnalysisData?.image_text?.[selectedImg?.id]?.labels || [];
    } catch {
        parsedData = [];
    }
    const filteredText = parsedData?.filter(text => text !== "");
    const jsonString = typeof selectedImg?.image_text === "string" ? selectedImg?.image_text.replace(/'/g, '"') : selectedImg?.image_text;
    let parsedArray = [];
    try {
        parsedArray = jsonString
            ? typeof jsonString === "string"
                ? JSON.parse(jsonString)
                : jsonString
            : imageReducer?.getImageAnalysisData?.image_text?.[selectedImg?.id]?.text || [];
    } catch {
        parsedArray = [];
    }
    const filteredTexts = parsedArray?.filter(text => text !== "");

    const handleClickOutsides = event => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            handleSaveChanges(imageAnalyzeTab, "save");
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsides);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsides);
        };
    }, [inputRef, editedObject, editedText, imageAnalyzeTab]);

    useEffect(() => {
        dispatch(actions?.ClearPatchImageAnalysisData());
        setEditedObject(filteredText);
        if (searchTerm !== "" && imageAnalyzeTab === "labels") {
            const results = filteredText?.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase()));
            setOriginalObjects(results);
        } else {
            setOriginalObjects(filteredText);
        }

        setEditedText(filteredTexts);
        if (searchTerm !== "" && imageAnalyzeTab === "text") {
            const results = filteredTexts?.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase()));
            setOriginalText(results);
        } else {
            setOriginalText(filteredTexts);
        }
    }, [selectedImg?.image_text, selectedImg?.image_labels, imageReducer?.getImageAnalysisData]);

    useEffect(() => {
        dispatch(actions?.ClearPatchImageAnalysisData());
        setSavedData({ object: filteredText, text: filteredTexts });
    }, []);
    useEffect(() => {
        !isSurveyAssetImage && ReactTooltip.rebuild();
        ReactTooltip.hide();
    }, [editableItem]);
    useEffect(() => {
        if (searchTerm !== "") {
            if (imageAnalyzeTab === "labels") {
                const results = editedObject?.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase()));
                setOriginalObjects(results);
            } else if (imageAnalyzeTab === "text") {
                const results = editedText?.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase()));
                setOriginalText(results);
            }
        }
    }, [searchTerm, imageAnalyzeTab]);

    useEffect(() => {
        if (imageAnalyzeTab === "labels") {
            setFilteredIndices(
                savedData?.object
                    ?.map((item, index) => (item.toLowerCase().includes(searchTerm.toLowerCase()) ? index : -1))
                    .filter(index => index !== -1)
            );
        } else if (imageAnalyzeTab === "text") {
            setFilteredIndices(
                savedData?.text
                    ?.map((item, index) => (item.toLowerCase().includes(searchTerm.toLowerCase()) ? index : -1))
                    .filter(index => index !== -1)
            );
        }
        if (searchTerm === "") {
            setOriginalObjects(editedObject);
            setOriginalText(editedText);
        }
    }, [editedText, editedObject, searchTerm, imageAnalyzeTab]);

    useEffect(() => {
        const editParams = {
            image_labels: null,
            image_text: null
        };
        if (imageAnalyzeType) {
            if (imageAnalyzeType === "text") {
                editParams.image_text = imageReducer?.getImageAnalysisData?.image_text?.[selectedImg?.id]?.text;
                editParams.image_labels = selectedImg?.image_labels;
                editFunction(editParams, [selectedImg]);
                setSavedData({ object: filteredText, text: filteredTexts });
            }
            if (imageAnalyzeType === "labels") {
                editParams.image_labels = imageReducer?.getImageAnalysisData?.image_text?.[selectedImg?.id]?.labels;
                editParams.image_text = selectedImg?.image_text;
                editFunction(editParams, [selectedImg]);
                setSavedData({ object: filteredText, text: filteredTexts });
            }
            if (imageAnalyzeType === "both") {
                editParams.image_labels = imageReducer?.getImageAnalysisData?.image_text?.[selectedImg?.id]?.labels;
                editParams.image_text = imageReducer?.getImageAnalysisData?.image_text?.[selectedImg?.id]?.text;
                editFunction(editParams, [selectedImg]);
                setSavedData({ object: filteredText, text: filteredTexts });
            }
        }
    }, [imageReducer]);
    useEffect(() => {
        const { latitude = "", longitude = "" } = selectedImg?.meta_data ?? selectedImg ?? {};
        if (latitude && longitude) {
            setAddress(latitude, longitude);
        }
        !isSurveyAssetImage && ReactTooltip.rebuild();
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [toggleInput]);

    useEffect(() => {
        alertMessage?.length && showAlertMessages();
    }, [alertMessage]);
    useEffect(() => {
        if (imageAnalyzeType) {
            analyzeImageData();
        }
    }, [imageAnalyzeType]);

    useEffect(() => {
        if (Object.keys(patchImageAnalysisData).length > 0 && !patchImageAnalysisData?.success) {
            setAlertMessage("Image analysis edit failed.");
            editFunction({ image_labels: savedData?.object, image_text: savedData?.text }, [selectedImg]);
        } else if (Object.keys(patchImageAnalysisData).length > 0 && patchImageAnalysisData) {
            setAlertMessage("Image analyze data updated");
            setSavedData({ object: filteredText, text: filteredTexts });
            dispatch(Actions?.ClearPatchImageAnalysisData());
        }
    }, [patchImageAnalysisData?.success]);

    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            setToggleInput(false);
        }
    };

    const setAddress = async (latitude, longitude) => {
        let address = await getAddressFromCoordinates(latitude, longitude);
        setFormattedAddress(address);
    };

    const showAlert = () => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show";
            x.innerText = "Copied to Clipboard";
            setTimeout(function () {
                x.className = x.className.replace("show", "");
            }, 2500);
        }
    };

    const showAlertMessages = () => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show-long-alert";
            x.innerText = alertMessage;
            setTimeout(function () {
                x.className = x.className.replace("show-long-alert", "");
            }, 6000);
        }
    };

    const handleClick = () => {
        setToggleInput(!toggleInput);
    };

    const handleInputCaption = async () => {
        await handleInputCaptionData(caption);
        handleClick();
        updateImageFromSurveyAsset ? updateImageFromSurveyAsset(caption) : updateImage(caption);
        ReactTooltip.rebuild();
    };

    const moveCaretAtEnd = e => {
        var temp_value = e.target.value;
        e.target.value = "";
        e.target.value = temp_value;
    };

    const downloadImage = async selectedImg => {
        setImageExporting(true);
        const publicUrl = selectedImg?.is_edited ? selectedImg?.s3_eimage_key : selectedImg?.s3_image_key;
        await downloadPresignedUrlFile(publicUrl, "attachment");
        setImageExporting(false);
    };

    const analyzeImageData = async () => {
        setImageAnalyzeLoader(true);
        await getImageAnalysisData({
            image_ids: [selectedImg?.id],
            text: imageAnalyzeType === "both" || imageAnalyzeType === "text" ? true : false,
            labels: imageAnalyzeType === "both" || imageAnalyzeType === "labels" ? true : false,
            type: isSurveyAssetImage ? "survey_image_upload" : undefined
        });
        setImageAnalyzeType("");
        setImageAnalyzeLoader(false);
    };
    const handleEditChange = (e, index, key) => {
        const { value } = e.target;
        if (key === "text") {
            const updatedTexts = [...editedText];
            updatedTexts[filteredIndices[index]] = value;
            setEditedText(updatedTexts);
            const updatedText = [...originalText];
            updatedText[index] = value;
            setOriginalText(updatedText);
        } else {
            const updatedTexts = [...editedObject];
            updatedTexts[filteredIndices[index]] = value;
            setEditedObject(updatedTexts);
            const updatedText = [...originalObjects];
            updatedText[index] = value;
            setOriginalObjects(updatedText);
        }
    };
    const handleSaveChanges = (operationType, dataType) => {
        const dataToSend = {
            [selectedImg?.id]: {}
        };
        if (dataType === "save") {
            if (operationType === "text") {
                const filteredTextData = editedText.filter(text => text !== "");
                const filteredTextOriginal = originalText.filter(text => text !== "");
                setOriginalText(filteredTextOriginal);
                dataToSend[selectedImg?.id].text = filteredTextData;
            } else if (operationType === "labels") {
                const filteredLabels = editedObject.filter(label => Object.keys(label).length !== 0);
                const filteredTextOriginal = originalObjects.filter(text => text !== "");
                setOriginalObjects(filteredTextOriginal);
                dataToSend[selectedImg?.id].labels = filteredLabels;
            }
        } else if (dataType === "delete") {
            if (operationType === "labels") {
                editedObject.splice(filteredIndices[showDeleteConfirmModal], 1);
                dataToSend[selectedImg?.id].labels = editedObject;
            } else if (operationType === "text") {
                editedText.splice(filteredIndices[showDeleteConfirmModal], 1);
                dataToSend[selectedImg?.id].text = editedText;
            }
        }
        let paramData = { ...dataToSend };
        if (isSurveyAssetImage) paramData = { ...paramData, type: "survey_image_upload" };
        dispatch(Actions?.patchImageAnalysisData({ ...paramData }, ImageParams));
        // editFunction({ image_labels: editedObject, image_text: editedText }, [selectedImg]);
        setEditableItem(false);
    };
    const renderMenuSwitchConfirmationModal = () => {
        if (!confirmationModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        type="cancel"
                        heading={"Performing a new analysis will overwrite all existing data"}
                        message={"Are you sure you want to conduct another analysis?"}
                        onNo={() => setConfirmationModal(false)}
                        onYes={() => {
                            if (confirmationModal === "text") {
                                setImageAnalyzeTab("text");
                                setImageAnalyzeType("text");
                            } else if (confirmationModal === "labels") {
                                setImageAnalyzeTab("labels");
                                setImageAnalyzeType("labels");
                            } else {
                                setImageAnalyzeTab("text");
                                setImageAnalyzeType("both");
                            }
                            setConfirmationModal(false);
                        }}
                    />
                }
                onCancel={() => setConfirmationModal(false)}
            />
        );
    };

    const handleInputChange = event => {
        const value = event.target.value;
        if (value.trim() === "") {
            setSearchTerm(value);
        } else {
            setSearchTerm(value);
        }
    };
    const renderConfirmationModal = () => {
        if (showDeleteConfirmModal === false) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to delete this text?"}
                        message={"This action cannot be reverted, are you sure that you need to delete this item?"}
                        onNo={() => setShowDeleteConfirmModal(false)}
                        onYes={() => {
                            handleSaveChanges(imageAnalyzeTab, "delete");
                            setShowDeleteConfirmModal(false);
                        }}
                    />
                }
                onCancel={() => setShowDeleteConfirmModal(false)}
            />
        );
    };

    return (
        <div id="modalId" className="modal modal-region modal-img-magamnt modal-editable-view" ref={ref} style={{ display: "block", cursor: "move" }}>
            <Draggable cancel=".not-dragabble">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div>
                            <button
                                className={`arrow-butn-left ${selectedImg?.id === imageList[0]?.id ? "cursor-diabled" : ""}`}
                                disabled={selectedImg?.id === imageList[0]?.id}
                                onClick={() => handlePrev(selectedImg?.id)}
                                data-place="top"
                                data-effect="solid"
                                data-tip="Previous"
                                data-for={`${selectedImg.id}-view`}
                            >
                                <i class="fas fa-chevron-left"></i>
                            </button>
                            <button
                                className={`arrow-butn-right ${selectedImg?.id === imageList[imageList.length - 1]?.id ? "cursor-diabled" : ""}`}
                                disabled={selectedImg?.id === imageList[imageList.length - 1]?.id}
                                onClick={() => handleNext(selectedImg.id)}
                                data-place="top"
                                data-effect="solid"
                                data-tip="Next"
                                data-for={`${selectedImg.id}-view`}
                            >
                                <i class="fas fa-chevron-right"></i>
                            </button>
                        </div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancel}>
                            <span aria-hidden="true">
                                <img src="/img/close.svg" alt="" />
                            </span>
                        </button>
                        <div className="modal-header">
                            <div class="heading position-relative">
                                {toggleInput ? (
                                    <label htmlFor="">
                                        <>
                                            <div>
                                                <textarea
                                                    className="floating-textarea img-txt-area not-draggable"
                                                    type="text"
                                                    // maxLength={this.state.captionMaxLength}
                                                    // ref={inputRef}
                                                    value={caption.caption}
                                                    onChange={e => {
                                                        resetCursor(e);
                                                        let updatedValue = {};
                                                        updatedValue = { caption: toTitleCase(e.target.value) };
                                                        setCaption(caption => ({ ...caption, ...updatedValue }));
                                                    }}
                                                    onBlur={() => setToggleInput(false)}
                                                    onKeyPress={event => {
                                                        if (event.key === "Enter") {
                                                            handleInputCaption();
                                                        }
                                                    }}
                                                    autoFocus
                                                    onFocus={moveCaretAtEnd}
                                                    placeholder="Caption"
                                                    onDoubleClick={handleClick}
                                                    data-place="bottom"
                                                    data-effect="solid"
                                                    data-for={`${selectedImg.id}-view`}
                                                    data-tip={
                                                        "<b>Please Click Enter key to Update</b><br/>.........................Or........................<br/><b>Double Click  to Go Back</b> <br/>"
                                                    }
                                                />
                                            </div>
                                        </>
                                    </label>
                                ) : (
                                    <h5
                                        data-place="top"
                                        data-effect="solid"
                                        data-tip={
                                            selectedImg?.caption
                                                ? `<b>Double Click Here to Edit Caption</b> <br/> ${selectedImg?.caption}`
                                                : "Double Click Here to Add Caption"
                                        }
                                        data-for={`${selectedImg.id}-view`}
                                        style={{ cursor: "pointer" }}
                                        className="not-dragabble"
                                        onDoubleClick={handleClick}
                                    >
                                        {selectedImg?.caption || "-"}
                                    </h5>
                                )}
                                <div class="image-right d-flex">
                                    {!isSurveyAssetImage && (
                                        <div class="mr-2">
                                            <span class="icon-url icon-heart">
                                                <img
                                                    src={selectedImg?.favourite ? heartFill : heartLine}
                                                    data-for={`${selectedImg.id}-view`}
                                                    data-tip={selectedImg?.favourite ? "Remove from Favorites" : "Add to Favorites"}
                                                    onClick={() => handleFavClick(selectedImg.id, !selectedImg.favourite)}
                                                    alt=""
                                                />
                                            </span>
                                        </div>
                                    )}
                                    {hasEdit && getFileExtension(selectedImg.s3_image_key) !== "html" && (
                                        <div class="mr-2">
                                            <i
                                                class="fa fa-edit"
                                                onClick={() => handleEdit(selectedImg)}
                                                data-for={`${selectedImg.id}-view`}
                                                data-tip="Edit Image"
                                            ></i>
                                        </div>
                                    )}
                                    {/* <div class="mr-2">
                                        <CopyToClipboard
                                            // text={selectedImg?.s3_image_key}
                                            text={selectedImg?.is_edited ? `${selectedImg?.s3_eimage_key}` : `${selectedImg?.s3_image_key}`}
                                            onCopy={() => {
                                                setCopied(true);
                                                setTimeout(() => {
                                                    setCopied(false);
                                                }, 3500);
                                            }}
                                        >
                                            {copied ? (
                                                <i
                                                    class="fa fa-check"
                                                    style={{ color: "green" }}
                                                    data-for={`${selectedImg.id}-view`}
                                                    data-tip={"Copied to Clipboard"}
                                                ></i>
                                            ) : (
                                                <i
                                                    class="fa fa-link"
                                                    onClick={() => showAlert()}
                                                    data-for={`${selectedImg.id}-view`}
                                                    data-tip={"Click to Copy Image URL"}
                                                ></i>
                                            )}
                                        </CopyToClipboard>
                                    </div> */}
                                    <div class="mr-2">
                                        {imageExporting ? (
                                            <div className="edit-icn-bx icon-btn-sec d-inline-block">
                                                <div className="spinner-border" role="status"></div>
                                            </div>
                                        ) : (
                                            <i
                                                class="fa fa-download"
                                                onClick={() => downloadImage(selectedImg)}
                                                data-for={`${selectedImg.id}-view`}
                                                data-tip="Download Image"
                                            ></i>
                                        )}
                                    </div>
                                    {!isAssignView && selectedImg?.recommendations?.length ? (
                                        <div
                                            class="icon mr-2 cursor-hand"
                                            onClick={() => viewRecommendations(selectedImg)}
                                            data-for={`${selectedImg.id}-view`}
                                            data-tip="View Assigned Recommendations"
                                        >
                                            <img src={docIcon} height={18} width={18} alt="" />
                                        </div>
                                    ) : null}
                                    {!isAssignView && selectedImg?.assets?.length ? (
                                        <div
                                            class="icon mr-2 cursor-hand"
                                            onClick={() => viewAssets(selectedImg)}
                                            data-for={`${selectedImg.id}-view`}
                                            data-tip="View Assigned Assets"
                                        >
                                            <img src={docIcon} height={18} width={18} alt="" />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="modal-body region-otr">
                            {selectedImg?.s3_image_key ? (
                                <>
                                    {getFileExtension(selectedImg.s3_image_key) === "html" ? (
                                        <div className="image-preview not-dragabble">
                                            <Iframe
                                                url={selectedImg?.s3_image_key}
                                                width="100%"
                                                height="100%"
                                                display="initial"
                                                position="relative"
                                                className="pdf-view"
                                                overflow="hidden"
                                            />
                                        </div>
                                    ) : (
                                        <TransformWrapper defaultScale={1}>
                                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                <div className="image-preview not-dragabble">
                                                    <div className="md-grp-btn">
                                                        <button onClick={zoomIn}>
                                                            <img src="/img/zoom-in.svg" alt="" />
                                                        </button>
                                                        <button onClick={zoomOut}>
                                                            <img src="/img/zoom-out.svg" alt="" />
                                                        </button>
                                                    </div>
                                                    <TransformComponent>
                                                        <LazyLoadImage
                                                            effect="blur"
                                                            // src={`${selectedImg?.s3_image_key}?${moment(selectedImg?.updated_at).format()}`}
                                                            src={selectedImg?.is_edited ? selectedImg?.s3_eimage_key : selectedImg?.s3_image_key}
                                                        />
                                                    </TransformComponent>
                                                </div>
                                            )}
                                        </TransformWrapper>
                                    )}
                                </>
                            ) : (
                                <div className="image-preview">
                                    <div class="img-process">
                                        <img src={imgProcess} alt="" />
                                        <h3>Processing...</h3>
                                    </div>
                                </div>
                            )}
                            <div className="cnt-img-se outer-right-tabpane not-dragabble">
                                <ul className="nav-ul-tab">
                                    <li
                                        className={currentTab === "main" ? "active cursor-hand" : "cursor-hand"}
                                        onClick={() => setCurrentTab("main")}
                                    >
                                        Main Details
                                    </li>
                                    <li
                                        className={currentTab === "meta" ? "active cursor-hand" : "cursor-hand"}
                                        onClick={() => setCurrentTab("meta")}
                                    >
                                        Meta Data
                                    </li>
                                    {!isSurveyAssetImage && (
                                        <li
                                            className={currentTab === "labels" ? "active cursor-hand" : "cursor-hand"}
                                            onClick={() => setCurrentTab("labels")}
                                        >
                                            Labels
                                        </li>
                                    )}
                                    <li
                                        className={currentTab === "image_text" ? "active cursor-hand" : "cursor-hand"}
                                        onClick={() => setCurrentTab("image_text")}
                                    >
                                        AI Analyze
                                    </li>
                                </ul>
                                {currentTab === "main" ? (
                                    <>
                                        <div className="img-itms">
                                            <div className="icons">
                                                <img src={buildingIcon} alt="" />
                                            </div>

                                            <div className="text-area">
                                                <h4>Client</h4>
                                                <h3>{selectedImg?.client?.name || "-"}</h3>
                                            </div>
                                        </div>
                                        <div className="img-itms">
                                            <div className="icons">
                                                <img src={buildingIcon} alt="" />
                                            </div>
                                            <div className="text-area">
                                                <h4>Project</h4>
                                                <h3>{selectedImg?.project?.name || "-"}</h3>
                                            </div>
                                        </div>
                                        <div className="img-itms">
                                            <div className="icons">
                                                <img src={buildingIcon} alt="" />
                                            </div>
                                            <div className="text-area">
                                                <h4>Region</h4>
                                                <h3>{selectedImg?.region?.name || "-"}</h3>
                                            </div>
                                        </div>
                                        <div className="img-itms">
                                            <div className="icons">
                                                <img src={buildingIcon} alt="" />
                                            </div>
                                            <div className="text-area">
                                                <h4>Site</h4>
                                                <h3>{selectedImg?.site?.name || "-"}</h3>
                                            </div>
                                        </div>
                                        <div className="img-itms">
                                            <div className="icons">
                                                <img src={buildingIcon} alt="" />
                                            </div>
                                            <div className="text-area">
                                                <h4>Building</h4>
                                                <h3>
                                                    {`${selectedImg?.building?.name || "-"} ${
                                                        selectedImg?.building?.description ? `(${selectedImg?.building?.description})` : ""
                                                    }` || ""}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="img-itms">
                                            <div className="icons">
                                                <img src={tradeIcon} alt="" />
                                            </div>
                                            <div className="text-area">
                                                <h4>Trade</h4>
                                                <h3>{selectedImg?.trade?.name || "-"}</h3>
                                            </div>
                                        </div>
                                        <div className="img-itms">
                                            <div className="icons">
                                                <img src={systemIcon} alt="" />
                                            </div>
                                            <div className="text-area">
                                                <h4>System</h4>
                                                <h3>{selectedImg?.system?.name || "-"}</h3>
                                            </div>
                                        </div>
                                        <div className="img-itms">
                                            <div className="icons">
                                                <img src={subSystemIcon} alt="" />
                                            </div>
                                            <div className="text-area">
                                                <h4>Sub-System</h4>
                                                <h3>{selectedImg?.sub_system?.name || "-"}</h3>
                                            </div>
                                        </div>
                                    </>
                                ) : currentTab === "meta" ? (
                                    <div className="img-itms meta not-dragabble">
                                        <div className="foot-area">
                                            <div class="list">
                                                <span class="label-nme">File Name </span>
                                                <span>: </span>
                                                <span class="dtl-lbl">{`${selectedImg?.asset_uid ? `{${selectedImg?.asset_uid}}` : ""}${
                                                    selectedImg?.name
                                                }`}</span>
                                            </div>
                                            <div className="list">
                                                <span class="label-nme">Uploaded By </span>
                                                <span>: </span>
                                                <span class="dtl-lbl">{selectedImg?.user?.name}</span>
                                            </div>
                                            {(selectedImg?.meta_data?.date_taken ?? selectedImg?.date_taken) && (
                                                <div className="list">
                                                    <span class="label-nme">Date Taken </span>
                                                    <span>: </span>{" "}
                                                    <span class="dtl-lbl">
                                                        {moment(selectedImg?.meta_data?.date_taken ?? selectedImg?.date_taken).format(
                                                            "dddd, MMMM Do YYYY, h:mm a"
                                                        ) || ""}
                                                    </span>
                                                </div>
                                            )}
                                            <div className="list">
                                                <span class="label-nme">Date Uploaded </span>
                                                <span>: </span>
                                                <span class="dtl-lbl">
                                                    {selectedImg?.created_at ?? selectedImg?.created_date
                                                        ? moment(selectedImg?.created_at ?? selectedImg?.created_date).format(
                                                              "dddd, MMMM Do YYYY, h:mm a"
                                                          ) || "-"
                                                        : "-"}
                                                </span>
                                            </div>
                                            <div className="list">
                                                <span class="label-nme">Date Modified</span>
                                                <span>: </span>
                                                <span class="dtl-lbl">
                                                    {selectedImg?.updated_at ?? selectedImg?.modified_date
                                                        ? moment(selectedImg?.updated_at ?? selectedImg?.modified_date).format(
                                                              "dddd, MMMM Do YYYY, h:mm a"
                                                          ) || "-"
                                                        : "-"}
                                                </span>
                                            </div>
                                            <div className="list">
                                                <span class="label-nme">Image Modified</span>
                                                <span>: </span>
                                                <span class="dtl-lbl">{selectedImg?.is_edited === true ? "Yes" : "No"}</span>
                                            </div>

                                            {formattedAddress && (
                                                <div className="list">
                                                    <span class="label-nme">Location </span>
                                                    <span>: </span>
                                                    <span class="dtl-lbl">{formattedAddress}</span>
                                                </div>
                                            )}
                                            {(selectedImg?.meta_data?.file_size || selectedImg?.file_size) && (
                                                <div className="list">
                                                    <span class="label-nme">Size </span>
                                                    <span>: </span>
                                                    <span class="dtl-lbl">
                                                        {renderFileSize(selectedImg?.meta_data?.file_size ?? selectedImg?.file_size) || ""}
                                                    </span>
                                                </div>
                                            )}
                                            <div class="list">
                                                <span class="label-nme">Is Asset Image</span>
                                                <span>: </span>
                                                <span class="dtl-lbl">{selectedImg?.is_asset_image ? "Yes" : "No"}</span>
                                            </div>
                                            <div class="list">
                                                <span class="label-nme">Resolution</span>
                                                <span>: </span>
                                                <span class="dtl-lbl">
                                                    {selectedImg?.width && selectedImg?.height
                                                        ? `${selectedImg?.width} x ${selectedImg?.height}`
                                                        : "-"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ) : currentTab === "image_text" ? (
                                    <LoadingOverlay active={imageAnalyzeLoader} spinner={<Loader />} fadeSpeed={10}>
                                        <div className="img-itms meta not-dragabble">
                                            <div className="img-txt-label-outer">
                                                <ul className="list-sec-buton">
                                                    <div class="d-flex btn-tx-obj">
                                                        <li
                                                            className={`list-btn ${imageAnalyzeTab === "text" ? "list-btn-active" : ""}`}
                                                            onClick={() => {
                                                                setImageAnalyzeTab("text");
                                                                setSearchTerm("");
                                                            }}
                                                        >
                                                            {/* <img src={textImg} /> */}
                                                            <svg
                                                                id="text-38"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="15.391"
                                                                height="15.391"
                                                                viewBox="0 0 15.391 15.391"
                                                            >
                                                                <path
                                                                    id="Path_218"
                                                                    data-name="Path 218"
                                                                    d="M12.826,15.391H2.565A2.573,2.573,0,0,1,0,12.826V2.565A2.573,2.573,0,0,1,2.565,0H12.826a2.573,2.573,0,0,1,2.565,2.565V12.826A2.573,2.573,0,0,1,12.826,15.391ZM2.565,1.283A1.286,1.286,0,0,0,1.283,2.565V12.826a1.286,1.286,0,0,0,1.283,1.283H12.826a1.286,1.286,0,0,0,1.283-1.283V2.565a1.286,1.286,0,0,0-1.283-1.283Z"
                                                                    fill="#7489a1"
                                                                ></path>
                                                                <path
                                                                    id="Path_219"
                                                                    data-name="Path 219"
                                                                    d="M263.054,257.283h-6.413a.641.641,0,1,1,0-1.283h6.413a.641.641,0,1,1,0,1.283Z"
                                                                    transform="translate(-252.152 -252.153)"
                                                                    fill="#7489a1"
                                                                ></path>
                                                                <path
                                                                    id="Path_220"
                                                                    data-name="Path 220"
                                                                    d="M256.641,257.924a.606.606,0,0,1-.641-.641v-.641a.641.641,0,0,1,1.283,0v.641A.606.606,0,0,1,256.641,257.924Z"
                                                                    transform="translate(-252.152 -252.153)"
                                                                    fill="#7489a1"
                                                                ></path>
                                                                <path
                                                                    id="Path_221"
                                                                    data-name="Path 221"
                                                                    d="M683.308,257.924a.606.606,0,0,1-.641-.641v-.641a.641.641,0,1,1,1.283,0v.641A.606.606,0,0,1,683.308,257.924Z"
                                                                    transform="translate(-672.406 -252.153)"
                                                                    fill="#7489a1"
                                                                ></path>
                                                                <path
                                                                    id="Path_222"
                                                                    data-name="Path 222"
                                                                    d="M469.974,263.7a.606.606,0,0,1-.641-.641v-6.413a.641.641,0,0,1,1.283,0v6.413A.606.606,0,0,1,469.974,263.7Z"
                                                                    transform="translate(-462.279 -252.153)"
                                                                    fill="#7489a1"
                                                                ></path>
                                                                <path
                                                                    id="Path_223"
                                                                    data-name="Path 223"
                                                                    d="M428.591,683.949h-1.283a.641.641,0,0,1,0-1.283h1.283a.641.641,0,1,1,0,1.283Z"
                                                                    transform="translate(-420.254 -672.406)"
                                                                    fill="#7489a1"
                                                                ></path>
                                                            </svg>
                                                            Text
                                                        </li>
                                                        <li
                                                            className={`list-btn ${imageAnalyzeTab === "labels" ? "list-btn-active" : ""}`}
                                                            onClick={() => {
                                                                setImageAnalyzeTab("labels");
                                                                setSearchTerm("");
                                                            }}
                                                        >
                                                            {/* <img src={objectImg} /> */}
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17.196"
                                                                height="17.196"
                                                                viewBox="0 0 17.196 17.196"
                                                            >
                                                                <path
                                                                    id="object-group-svgrepo-com"
                                                                    d="M18.336,16.014V5.181A1.664,1.664,0,0,0,19.2,3.72,1.725,1.725,0,0,0,17.476,2a1.664,1.664,0,0,0-1.462.86H5.181A1.664,1.664,0,0,0,3.72,2,1.725,1.725,0,0,0,2,3.72a1.664,1.664,0,0,0,.86,1.462V16.014A1.664,1.664,0,0,0,2,17.476,1.725,1.725,0,0,0,3.72,19.2a1.664,1.664,0,0,0,1.462-.86H16.014a1.664,1.664,0,0,0,1.462.86,1.725,1.725,0,0,0,1.72-1.72A1.664,1.664,0,0,0,18.336,16.014Zm-1.72,0a2.009,2.009,0,0,0-.6.6H5.181a2.009,2.009,0,0,0-.6-.6V5.181a2.009,2.009,0,0,0,.6-.6H16.014a2.009,2.009,0,0,0,.6.6Zm-4.3-8v-.86a.812.812,0,0,0-.86-.86h-4.3a.812.812,0,0,0-.86.86v4.3a.812.812,0,0,0,.86.86h.86V9.738a1.725,1.725,0,0,1,1.72-1.72Zm1.72.86h-4.3a.812.812,0,0,0-.86.86v4.3a.812.812,0,0,0,.86.86h4.3a.812.812,0,0,0,.86-.86v-4.3A.812.812,0,0,0,14.037,8.878Z"
                                                                    transform="translate(-2 -2)"
                                                                    fill="#7489a1"
                                                                ></path>
                                                            </svg>
                                                            Object
                                                        </li>
                                                    </div>
                                                    <li class="list-btn anal-btn-outer">
                                                        <div class="analise-butn">
                                                            <Dropdown>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    <span className="">Analyze</span>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            if (editedText?.length > 0) {
                                                                                setConfirmationModal("text");
                                                                            } else {
                                                                                setImageAnalyzeTab("text");
                                                                                setImageAnalyzeType("text");
                                                                            }
                                                                            setEditableItem(false);
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            id="text-38"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="15.391"
                                                                            height="15.391"
                                                                            viewBox="0 0 15.391 15.391"
                                                                        >
                                                                            <path
                                                                                id="Path_218"
                                                                                data-name="Path 218"
                                                                                d="M12.826,15.391H2.565A2.573,2.573,0,0,1,0,12.826V2.565A2.573,2.573,0,0,1,2.565,0H12.826a2.573,2.573,0,0,1,2.565,2.565V12.826A2.573,2.573,0,0,1,12.826,15.391ZM2.565,1.283A1.286,1.286,0,0,0,1.283,2.565V12.826a1.286,1.286,0,0,0,1.283,1.283H12.826a1.286,1.286,0,0,0,1.283-1.283V2.565a1.286,1.286,0,0,0-1.283-1.283Z"
                                                                                fill="#7489a1"
                                                                            />
                                                                            <path
                                                                                id="Path_219"
                                                                                data-name="Path 219"
                                                                                d="M263.054,257.283h-6.413a.641.641,0,1,1,0-1.283h6.413a.641.641,0,1,1,0,1.283Z"
                                                                                transform="translate(-252.152 -252.153)"
                                                                                fill="#7489a1"
                                                                            />
                                                                            <path
                                                                                id="Path_220"
                                                                                data-name="Path 220"
                                                                                d="M256.641,257.924a.606.606,0,0,1-.641-.641v-.641a.641.641,0,0,1,1.283,0v.641A.606.606,0,0,1,256.641,257.924Z"
                                                                                transform="translate(-252.152 -252.153)"
                                                                                fill="#7489a1"
                                                                            />
                                                                            <path
                                                                                id="Path_221"
                                                                                data-name="Path 221"
                                                                                d="M683.308,257.924a.606.606,0,0,1-.641-.641v-.641a.641.641,0,1,1,1.283,0v.641A.606.606,0,0,1,683.308,257.924Z"
                                                                                transform="translate(-672.406 -252.153)"
                                                                                fill="#7489a1"
                                                                            />
                                                                            <path
                                                                                id="Path_222"
                                                                                data-name="Path 222"
                                                                                d="M469.974,263.7a.606.606,0,0,1-.641-.641v-6.413a.641.641,0,0,1,1.283,0v6.413A.606.606,0,0,1,469.974,263.7Z"
                                                                                transform="translate(-462.279 -252.153)"
                                                                                fill="#7489a1"
                                                                            />
                                                                            <path
                                                                                id="Path_223"
                                                                                data-name="Path 223"
                                                                                d="M428.591,683.949h-1.283a.641.641,0,0,1,0-1.283h1.283a.641.641,0,1,1,0,1.283Z"
                                                                                transform="translate(-420.254 -672.406)"
                                                                                fill="#7489a1"
                                                                            />
                                                                        </svg>
                                                                        Text
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            if (editedObject?.length > 0) {
                                                                                setConfirmationModal("labels");
                                                                            } else {
                                                                                setImageAnalyzeTab("labels");
                                                                                setImageAnalyzeType("labels");
                                                                            }
                                                                            setEditableItem(false);
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="17.196"
                                                                            height="17.196"
                                                                            viewBox="0 0 17.196 17.196"
                                                                        >
                                                                            <path
                                                                                id="object-group-svgrepo-com"
                                                                                d="M18.336,16.014V5.181A1.664,1.664,0,0,0,19.2,3.72,1.725,1.725,0,0,0,17.476,2a1.664,1.664,0,0,0-1.462.86H5.181A1.664,1.664,0,0,0,3.72,2,1.725,1.725,0,0,0,2,3.72a1.664,1.664,0,0,0,.86,1.462V16.014A1.664,1.664,0,0,0,2,17.476,1.725,1.725,0,0,0,3.72,19.2a1.664,1.664,0,0,0,1.462-.86H16.014a1.664,1.664,0,0,0,1.462.86,1.725,1.725,0,0,0,1.72-1.72A1.664,1.664,0,0,0,18.336,16.014Zm-1.72,0a2.009,2.009,0,0,0-.6.6H5.181a2.009,2.009,0,0,0-.6-.6V5.181a2.009,2.009,0,0,0,.6-.6H16.014a2.009,2.009,0,0,0,.6.6Zm-4.3-8v-.86a.812.812,0,0,0-.86-.86h-4.3a.812.812,0,0,0-.86.86v4.3a.812.812,0,0,0,.86.86h.86V9.738a1.725,1.725,0,0,1,1.72-1.72Zm1.72.86h-4.3a.812.812,0,0,0-.86.86v4.3a.812.812,0,0,0,.86.86h4.3a.812.812,0,0,0,.86-.86v-4.3A.812.812,0,0,0,14.037,8.878Z"
                                                                                transform="translate(-2 -2)"
                                                                                fill="#7489a1"
                                                                            />
                                                                        </svg>
                                                                        Object
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            if (editedObject?.length > 0 || editedText?.length > 0) {
                                                                                setConfirmationModal("both");
                                                                            } else {
                                                                                setImageAnalyzeTab("text");
                                                                                setImageAnalyzeType("both");
                                                                            }
                                                                            setEditableItem(false);
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="15.391"
                                                                            height="15.391"
                                                                            viewBox="0 0 15.391 15.391"
                                                                        >
                                                                            <g
                                                                                id="Group_18697"
                                                                                data-name="Group 18697"
                                                                                transform="translate(-600 -572.804)"
                                                                            >
                                                                                <g id="text-38" transform="translate(600 572.804)">
                                                                                    <path
                                                                                        id="Path_218"
                                                                                        data-name="Path 218"
                                                                                        d="M12.826,15.391H2.565A2.573,2.573,0,0,1,0,12.826V2.565A2.573,2.573,0,0,1,2.565,0H12.826a2.573,2.573,0,0,1,2.565,2.565V12.826A2.573,2.573,0,0,1,12.826,15.391ZM2.565,1.283A1.286,1.286,0,0,0,1.283,2.565V12.826a1.286,1.286,0,0,0,1.283,1.283H12.826a1.286,1.286,0,0,0,1.283-1.283V2.565a1.286,1.286,0,0,0-1.283-1.283Z"
                                                                                        fill="#7489a1"
                                                                                    />
                                                                                </g>
                                                                                <path
                                                                                    id="Sort_both_small"
                                                                                    d="M0,4.122,4.122,0,8.245,4.122ZM0,5.153,4.122,9.275,8.245,5.153Z"
                                                                                    transform="translate(612.333 576.378) rotate(90)"
                                                                                    fill="#7489a1"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        Both
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className="search-editable-pop">
                                                    <input
                                                        className="search-editable-pop"
                                                        type="text"
                                                        placeholder="Search..."
                                                        value={searchTerm}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span
                                                        class="close-sr"
                                                        onClick={() => {
                                                            setEditableItem(false);
                                                            setSearchTerm("");
                                                        }}
                                                    >
                                                        <i class="fa fa-times"></i>
                                                    </span>
                                                </div>
                                                <ul
                                                    className={`list-detail ${
                                                        imageAnalyzeTab === "labels"
                                                            ? originalObjects?.length === 0
                                                                ? "no-data"
                                                                : ""
                                                            : originalText?.length === 0
                                                            ? "no-data"
                                                            : ""
                                                    }`}
                                                >
                                                    {imageAnalyzeTab === "text" ? (
                                                        originalText?.length > 0 ? (
                                                            originalText.map((item, i) => (
                                                                <li className={`${editableItem === i ? "input-section-band" : ""}`} key={i}>
                                                                    {editableItem === i ? (
                                                                        <div ref={inputRef} className="analyse-field">
                                                                            <input
                                                                                type="text"
                                                                                value={item}
                                                                                onChange={e => {
                                                                                    handleEditChange(e, i, "text");
                                                                                }}
                                                                                autoFocus
                                                                                onKeyDown={e => {
                                                                                    if (e.key === "Enter") {
                                                                                        handleSaveChanges(imageAnalyzeTab, "save");
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <div className="btn-icons-out">
                                                                                <button
                                                                                    className="btn-save-tick"
                                                                                    onClick={() => handleSaveChanges(imageAnalyzeTab, "save")}
                                                                                >
                                                                                    <i class="fa fa-check" currentitem="false"></i>
                                                                                </button>
                                                                                <button
                                                                                    className="btn-save-close"
                                                                                    onClick={() => {
                                                                                        setEditableItem(false);
                                                                                        setEditedObject(savedData?.object);
                                                                                        setOriginalObjects(
                                                                                            searchTerm !== ""
                                                                                                ? savedData?.object?.filter(item =>
                                                                                                      item
                                                                                                          .toLowerCase()
                                                                                                          .includes(searchTerm.toLowerCase())
                                                                                                  )
                                                                                                : savedData?.object
                                                                                        );
                                                                                        setEditedText(savedData?.text);
                                                                                        setOriginalText(
                                                                                            searchTerm !== ""
                                                                                                ? savedData?.text?.filter(item =>
                                                                                                      item
                                                                                                          .toLowerCase()
                                                                                                          .includes(searchTerm.toLowerCase())
                                                                                                  )
                                                                                                : savedData?.text
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <i class="fa fa-times" currentitem="false"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="list-band">
                                                                            {item}{" "}
                                                                            <div>
                                                                                <div
                                                                                    class="icon mr-2 cursor-hand"
                                                                                    onClick={() => {
                                                                                        setEditableItem(i);
                                                                                        setAlertMessage("");
                                                                                        dispatch(actions?.ClearPatchImageAnalysisData());
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        class="fa fa-pencil-alt"
                                                                                        data-tip="Edit"
                                                                                        data-for="analyse"
                                                                                    ></i>
                                                                                </div>
                                                                                <div
                                                                                    class="icon mr-2 cursor-hand"
                                                                                    onClick={() => {
                                                                                        setShowDeleteConfirmModal(i);
                                                                                        setAlertMessage("");
                                                                                    }}
                                                                                >
                                                                                    <i class="fa fa-trash" data-tip="Remove" data-for="analyse"></i>
                                                                                </div>
                                                                                <CopyToClipboard
                                                                                    text={item}
                                                                                    onCopy={() => {
                                                                                        setCopySuccessIndex(i);
                                                                                        setTimeout(() => {
                                                                                            setCopySuccessIndex(false);
                                                                                        }, 3500);
                                                                                    }}
                                                                                >
                                                                                    <div class="icon cursor-hand">
                                                                                        {copySuccessIndex === i ? (
                                                                                            <i
                                                                                                class="fa fa-check"
                                                                                                style={{ color: "green" }}
                                                                                                data-tip={"Copied to Clipboard"}
                                                                                                data-for="analyse"
                                                                                                data-place="left"
                                                                                            ></i>
                                                                                        ) : (
                                                                                            <i
                                                                                                class="fa fa-copy"
                                                                                                onClick={() => showAlert()}
                                                                                                data-tip={"Click to Copy"}
                                                                                                data-for="analyse"
                                                                                                data-place="left"
                                                                                            ></i>
                                                                                        )}
                                                                                    </div>
                                                                                </CopyToClipboard>
                                                                                <ReactTooltip
                                                                                    clickable={false}
                                                                                    id="analyse"
                                                                                    effect="solid"
                                                                                    place="top"
                                                                                    className="rc-tooltip-custom-class"
                                                                                    backgroundColor="#111314"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <div className="no-data-outer">
                                                                <img src={noDataForAnalysis} alt="No data for analysis" />
                                                                <h3>No data Found</h3>
                                                            </div>
                                                        )
                                                    ) : originalObjects?.length > 0 ? (
                                                        originalObjects?.map((item, i) => (
                                                            <li className={`${editableItem === i ? "input-section-band" : ""}`} key={i}>
                                                                {editableItem === i ? (
                                                                    <div ref={inputRef} className="analyse-field">
                                                                        <input
                                                                            type="text"
                                                                            value={item}
                                                                            onChange={e => handleEditChange(e, i, "object")}
                                                                            autoFocus
                                                                            onKeyDown={e => {
                                                                                if (e.key === "Enter") {
                                                                                    handleSaveChanges(imageAnalyzeTab, "save");
                                                                                }
                                                                            }}
                                                                        />
                                                                        <div className="btn-icons-out">
                                                                            <button
                                                                                className="btn-save-tick"
                                                                                onClick={() => handleSaveChanges(imageAnalyzeTab, "save")}
                                                                            >
                                                                                <i class="fa fa-check" currentitem="false"></i>
                                                                            </button>
                                                                            <button
                                                                                className="btn-save-close"
                                                                                onClick={() => {
                                                                                    setEditableItem(false);
                                                                                    setEditedObject(savedData?.object);
                                                                                    setOriginalObjects(
                                                                                        searchTerm !== ""
                                                                                            ? savedData?.object?.filter(item =>
                                                                                                  item
                                                                                                      .toLowerCase()
                                                                                                      .includes(searchTerm.toLowerCase())
                                                                                              )
                                                                                            : savedData?.object
                                                                                    );
                                                                                    setEditedText(savedData?.text);
                                                                                    setOriginalText(
                                                                                        searchTerm !== ""
                                                                                            ? savedData?.text?.filter(item =>
                                                                                                  item
                                                                                                      .toLowerCase()
                                                                                                      .includes(searchTerm.toLowerCase())
                                                                                              )
                                                                                            : savedData?.text
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i class="fa fa-times" currentitem="false"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="list-band">
                                                                        {item}
                                                                        <div>
                                                                            <div
                                                                                class="icon mr-2 cursor-hand"
                                                                                onClick={() => {
                                                                                    setEditableItem(i);
                                                                                    setAlertMessage("");
                                                                                }}
                                                                            >
                                                                                <i class="fa fa-pencil-alt" data-tip="Edit" data-for="analyse"></i>
                                                                            </div>
                                                                            <div
                                                                                class="icon mr-2 cursor-hand"
                                                                                onClick={() => {
                                                                                    setShowDeleteConfirmModal(i);
                                                                                    setAlertMessage("");
                                                                                }}
                                                                            >
                                                                                <i class="fa fa-trash" data-tip="Remove" data-for="analyse"></i>
                                                                            </div>
                                                                            <CopyToClipboard
                                                                                text={item}
                                                                                onCopy={() => {
                                                                                    setCopySuccessIndex(i);
                                                                                    setTimeout(() => {
                                                                                        setCopySuccessIndex(false);
                                                                                    }, 3500);
                                                                                }}
                                                                            >
                                                                                <div class="icon cursor-hand">
                                                                                    {copySuccessIndex === i ? (
                                                                                        <i
                                                                                            class="fa fa-check"
                                                                                            data-for="analyse"
                                                                                            style={{ color: "green" }}
                                                                                            data-tip={"Copied to Clipboard"}
                                                                                            data-place="left"
                                                                                        ></i>
                                                                                    ) : (
                                                                                        <i
                                                                                            class="fa fa-copy"
                                                                                            data-for="analyse"
                                                                                            onClick={() => showAlert()}
                                                                                            data-tip={"Click to Copy"}
                                                                                            data-place="left"
                                                                                        ></i>
                                                                                    )}
                                                                                </div>
                                                                            </CopyToClipboard>
                                                                            <ReactTooltip
                                                                                clickable={false}
                                                                                id="analyse"
                                                                                place="top"
                                                                                effect="solid"
                                                                                className="rc-tooltip-custom-class"
                                                                                backgroundColor="#007bff"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <div className="no-data-outer">
                                                            <img src={noDataForAnalysis} alt="No data for analysis" />
                                                            <h3>No data Found</h3>
                                                        </div>
                                                    )}
                                                    <ReactTooltip place="top" className="rc-tooltip-custom-class" backgroundColor="#007bff" />
                                                </ul>
                                            </div>
                                        </div>
                                    </LoadingOverlay>
                                ) : (
                                    <ul className="label-notification">
                                        {selectedImg.labels?.map(item => (
                                            <li key={item.name}>{item.name}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <ReactTooltip
                                id={`${selectedImg.id}-view`}
                                effect="solid"
                                place="left"
                                backgroundColor="#1383D9"
                                getContent={dataTip => dataTip?.replace(/(?:\r\n|\r|\n)/g, "<br>")}
                                html={true}
                                multiline={true}
                            />
                        </div>
                    </div>
                </div>
            </Draggable>
            {renderMenuSwitchConfirmationModal()}
            {renderConfirmationModal()}
        </div>
    );
}

const mapStateToProps = state => {
    const { imageReducer } = state;
    return { imageReducer };
};

export default withRouter(connect(mapStateToProps, { ...actions })(ImageViewModal));
