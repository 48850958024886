export const fieldTooltips = {
    name: "A description for this field.",
    type: "Select the field's scope: Global, Client, or Project level.",
    client_id: "Display this field for all recommendations under the chosen client.",
    project_id: "Display this field for all recommendations under the selected project.",
    label: "The visible label or title for this field.",
    field_name: "Unique key used to store this field's data. Use lowercase letters, numbers, and underscores only.",
    group_field: "Choose an existing or new band to categorize this field.",
    field_type: "Select the type of data this field should hold.",
    field_options: "Provide options for users to choose from.",
    mandatory: "Indicate if this field must be filled out when creating a recommendation.",
    prefix: "Add a prefix before the field's value.",
    suffix: "Add a suffix after the field's value.",
    thousand_separator: "Include a comma as a thousands separator in the field's value.",
    read_only: "Disable editing for this field.",
    decimal_scale: "Define the number of decimal places allowed in the field's value (use 0 for integers).",
    allow_negative: "Allow negative values in this field.",
    table_default_visible: "Show this field in the recommendation table by default.",
    regex: "Use a regular expression to specify valid input patterns for this field.",
    rules: "Define additional validation and formatting rules for this field under a recommendation.",
    is_standalone: "Enable a field to take up the full width of the screen.",
    show_suggestions: "Enable autocomplete suggestions as you type.",
    scanner: "To use a scanner for automatic input of data into the field."
};

export const ADD_DYNAMIC_FIELD_REQUEST = "ADD_DYNAMIC_FIELD_REQUEST";
export const ADD_DYNAMIC_FIELD_SUCCESS = "ADD_DYNAMIC_FIELD_SUCCESS";
export const ADD_DYNAMIC_FIELD_FAILURE = "ADD_DYNAMIC_FIELD_FAILURE";

export const ADD_ASSET_DYNAMIC_FIELD_REQUEST = "ADD_ASSET_DYNAMIC_FIELD_REQUEST";
export const ADD_ASSET_DYNAMIC_FIELD_SUCCESS = "ADD_ASSET_DYNAMIC_FIELD_SUCCESS";
export const ADD_ASSET_DYNAMIC_FIELD_FAILURE = "ADD_ASSET_DYNAMIC_FIELD_FAILURE";

export const ADD_BUILDING_DYNAMIC_FIELD_REQUEST = "ADD_BUILDING_DYNAMIC_FIELD_REQUEST";
export const ADD_BUILDING_DYNAMIC_FIELD_SUCCESS = "ADD_BUILDING_DYNAMIC_FIELD_SUCCESS";
export const ADD_BUILDING_DYNAMIC_FIELD_FAILURE = "ADD_BUILDING_DYNAMIC_FIELD_FAILURE";

export const UPDATE_DYNAMIC_FIELD_REQUEST = "UPDATE_DYNAMIC_FIELD_REQUEST";
export const UPDATE_DYNAMIC_FIELD_SUCCESS = "UPDATE_DYNAMIC_FIELD_SUCCESS";
export const UPDATE_DYNAMIC_FIELD_FAILURE = "UPDATE_DYNAMIC_FIELD_FAILURE";

export const UPDATE_ASSET_DYNAMIC_FIELD_REQUEST = "UPDATE_ASSET_DYNAMIC_FIELD_REQUEST";
export const UPDATE_ASSET_DYNAMIC_FIELD_SUCCESS = "UPDATE_ASSET_DYNAMIC_FIELD_SUCCESS";
export const UPDATE_ASSET_DYNAMIC_FIELD_FAILURE = "UPDATE_ASSET_DYNAMIC_FIELD_FAILURE";

export const UPDATE_BUILDING_DYNAMIC_FIELD_REQUEST = "UPDATE_BUILDING_DYNAMIC_FIELD_REQUEST";
export const UPDATE_BUILDING_DYNAMIC_FIELD_SUCCESS = "UPDATE_BUILDING_DYNAMIC_FIELD_SUCCESS";
export const UPDATE_BUILDING_DYNAMIC_FIELD_FAILURE = "UPDATE_BUILDING_DYNAMIC_FIELD_FAILURE";

export const GET_DYNAMIC_FIELD_REQUEST = "GET_DYNAMIC_FIELD_REQUEST";
export const GET_DYNAMIC_FIELD_SUCCESS = "GET_DYNAMIC_FIELD_SUCCESS";
export const GET_DYNAMIC_FIELD_FAILURE = "GET_DYNAMIC_FIELD_FAILURE";

export const GET_ASSET_DYNAMIC_FIELD_REQUEST = "GET_ASSET_DYNAMIC_FIELD_REQUEST";
export const GET_ASSET_DYNAMIC_FIELD_SUCCESS = "GET_ASSET_DYNAMIC_FIELD_SUCCESS";
export const GET_ASSET_DYNAMIC_FIELD_FAILURE = "GET_ASSET_DYNAMIC_FIELD_FAILURE";

export const GET_BUILDING_DYNAMIC_FIELD_REQUEST = "GET_BUILDING_DYNAMIC_FIELD_REQUEST";
export const GET_BUILDING_DYNAMIC_FIELD_SUCCESS = "GET_BUILDING_DYNAMIC_FIELD_SUCCESS";
export const GET_BUILDING_DYNAMIC_FIELD_FAILURE = "GET_BUILDING_DYNAMIC_FIELD_FAILURE";

export const GET_DYNAMIC_FIELD_BY_ID_REQUEST = "GET_DYNAMIC_FIELD_BY_ID_REQUEST";
export const GET_DYNAMIC_FIELD_BY_ID_SUCCESS = "GET_DYNAMIC_FIELD_BY_ID_SUCCESS";
export const GET_DYNAMIC_FIELD_BY_ID_FAILURE = "GET_DYNAMIC_FIELD_BY_ID_FAILURE";

export const GET_ASSET_DYNAMIC_FIELD_BY_ID_REQUEST = "GET_ASSET_DYNAMIC_FIELD_BY_ID_REQUEST";
export const GET_ASSET_DYNAMIC_FIELD_BY_ID_SUCCESS = "GET_ASSET_DYNAMIC_FIELD_BY_ID_SUCCESS";
export const GET_ASSET_DYNAMIC_FIELD_BY_ID_FAILURE = "GET_ASSET_DYNAMIC_FIELD_BY_ID_FAILURE";

export const GET_BUILDING_DYNAMIC_FIELD_BY_ID_REQUEST = "GET_BUILDING_DYNAMIC_FIELD_BY_ID_REQUEST";
export const GET_BUILDING_DYNAMIC_FIELD_BY_ID_SUCCESS = "GET_BUILDING_DYNAMIC_FIELD_BY_ID_SUCCESS";
export const GET_BUILDING_DYNAMIC_FIELD_BY_ID_FAILURE = "GET_BUILDING_DYNAMIC_FIELD_BY_ID_FAILURE";

export const GET_COMMON_FILTER_LIST_FOR_DYNAMIC_FIELD_REQUEST = "GET_COMMON_FILTER_LIST_FOR_DYNAMIC_FIELD_REQUEST";
export const GET_COMMON_FILTER_LIST_FOR_DYNAMIC_FIELD_SUCCESS = "GET_COMMON_FILTER_LIST_FOR_DYNAMIC_FIELD_SUCCESS";
export const GET_COMMON_FILTER_LIST_FOR_DYNAMIC_FIELD_FAILURE = "GET_COMMON_FILTER_LIST_FOR_DYNAMIC_FIELD_FAILURE";

export const GET_DYNAMIC_FIELD_EXPORT_REQUEST = "GET_DYNAMIC_FIELD_EXPORT_REQUEST";
export const GET_DYNAMIC_FIELD_EXPORT_SUCCESS = "GET_DYNAMIC_FIELD_EXPORT_SUCCESS";
export const GET_DYNAMIC_FIELD_EXPORT_FAILURE = "GET_DYNAMIC_FIELD_EXPORT_FAILURE";

export const DELETE_DYNAMIC_FIELD_REQUEST = "DELETE_DYNAMIC_FIELD_REQUEST";
export const DELETE_DYNAMIC_FIELD_SUCCESS = "DELETE_DYNAMIC_FIELD_SUCCESS";
export const DELETE_DYNAMIC_FIELD_FAILURE = "DELETE_DYNAMIC_FIELD_FAILURE";

export const RESTORE_DYNAMIC_FIELD_REQUEST = "RESTORE_DYNAMIC_FIELD_REQUEST";
export const RESTORE_DYNAMIC_FIELD_SUCCESS = "RESTORE_DYNAMIC_FIELD_SUCCESS";
export const RESTORE_DYNAMIC_FIELD_FAILURE = "RESTORE_DYNAMIC_FIELD_FAILURE";

export const EMPTY_VIEW_PAGE_DATA = "EMPTY_VIEW_PAGE_DATA";
export const CLEAR_RESPONSES = "CLEAR_RESPONSES";

export const GET_BANDS_REQUEST = "GET_BANDS_REQUEST";
export const GET_BANDS_SUCCESS = "GET_BANDS_SUCCESS";
export const GET_BANDS_FAILURE = "GET_BANDS_FAILURE";

export const GET_ASSET_BANDS_REQUEST = "GET_ASSET_BANDS_REQUEST";
export const GET_ASSET_BANDS_SUCCESS = "GET_ASSET_BANDS_SUCCESS";
export const GET_ASSET_BANDS_FAILURE = "GET_ASSET_BANDS_FAILURE";

export const GET_BUILDING_BANDS_REQUEST = "GET_BUILDING_BANDS_REQUEST";
export const GET_BUILDING_BANDS_SUCCESS = "GET_BUILDING_BANDS_SUCCESS";
export const GET_BUILDING_BANDS_FAILURE = "GET_BUILDING_BANDS_FAILURE";

export const UPDATE_DYNAMIC_FIELD_ENTITY_PARAMS_SUCCESS = "UPDATE_DYNAMIC_FIELD_ENTITY_PARAMS_SUCCESS";
export const UPDATE_DYNAMIC_FIELD_ENTITY_PARAMS_FAILURE = "UPDATE_DYNAMIC_FIELD_ENTITY_PARAMS_FAILURE";

export const GET_DYNAMIC_FIELD_BANDS_REQUEST = "GET_DYNAMIC_FIELD_BANDS_REQUEST";
export const GET_DYNAMIC_FIELD_BANDS_SUCCESS = "GET_DYNAMIC_FIELD_BANDS_SUCCESS";
export const GET_DYNAMIC_FIELD_BANDS_FAILURE = "GET_DYNAMIC_FIELD_BANDS_FAILURE";

export const ADD_DYNAMIC_FIELD_BANDS_REQUEST = "ADD_DYNAMIC_FIELD_BANDS_REQUEST";
export const ADD_DYNAMIC_FIELD_BANDS_SUCCESS = "ADD_DYNAMIC_FIELD_BANDS_SUCCESS";
export const ADD_DYNAMIC_FIELD_BANDS_FAILURE = "ADD_DYNAMIC_FIELD_BANDS_FAILURE";

export const UPDATE_DYNAMIC_FIELD_BANDS_REQUEST = "UPDATE_DYNAMIC_FIELD_BANDS_REQUEST";
export const UPDATE_DYNAMIC_FIELD_BANDS_SUCCESS = "UPDATE_DYNAMIC_FIELD_BANDS_SUCCESS";
export const UPDATE_DYNAMIC_FIELD_BANDS_FAILURE = "UPDATE_DYNAMIC_FIELD_BANDS_FAILURE";
