/* eslint-disable no-unused-vars */
import axios from "axios";
import { API_ROUTE, FAST_API_URL, ORIGIN_URL, REPORT_URL } from "../config/constants";
import { unSubscribeDevice } from "../config/firebase";
import qs from "qs";
export const fcaGateWay = axios.create({
    baseURL: API_ROUTE,
    timeout: 300000,
    withCredentials: true,
    headers: {
        "Access-Control-Allow-Origin": ORIGIN_URL
        // "Accept-Encoding": "gzip"
    },
    paramsSerializer: {
        serialize: params => {
            return qs.stringify(params, {
                arrayFormat: "brackets",
                skipNulls: true,
                filter: filterFunc
            });
        }
    }
});

function filterFunc(prefix, value) {
    if (
        prefix === "list" ||
        prefix === "order" ||
        prefix === "filters" ||
        prefix === "search_filters" ||
        prefix === "top_n" ||
        prefix === "exclude_filters" ||
        prefix === "jsonb_list" ||
        prefix === "jsonb_filters" ||
        prefix === "jsonb_order" ||
        prefix === "hide_asset_columns"
    ) {
        return JSON.stringify(value);
    }
    return value;
}
//for capital request
export const fcaCRGateWay = axios.create({
    baseURL: API_ROUTE,
    timeout: 300000,
    withCredentials: true,
    headers: {
        "Access-Control-Allow-Origin": ORIGIN_URL
    }
});

fcaGateWay.interceptors.response.use(
    res => {
        return res;
    },
    function (res) {
        if (res.response && res.response.status === 401) {
            handleLogout();
        }
        return res;
    }
);

fcaGateWay.interceptors.request.use(function (config) {
    const token = localStorage.getItem("fca-token");
    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": ORIGIN_URL
    };
    return config;
});

fcaCRGateWay.interceptors.request.use(function (config) {
    // passing different jwt tokens in header for public users & system users
    const isPublicCRUser = window.location.pathname?.split("/")?.[1] === "capital-request";
    const crToken = localStorage.getItem(isPublicCRUser ? "crRequestorToken" : "crToken");

    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${crToken}`,
        "Access-Control-Allow-Origin": ORIGIN_URL
    };
    return config;
});

export const restCountriesGateWay = axios.create({
    baseURL: "https://restcountries.eu",
    timeout: 20000
});

export const fcaReportGateway = axios.create({
    baseURL: REPORT_URL,
    // timeout: 300000,
    headers: {}
});

fcaReportGateway.interceptors.request.use(function (config) {
    const token = localStorage.getItem("fca-token");
    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
    };
    return config;
});

fcaReportGateway.interceptors.response.use(
    res => {
        return res;
    },
    function (error) {
        if (error.config && error.config.url !== "/auth/login/" && error.response && error.response.status === 401) {
            handleLogout();
        }
        return error;
    }
);

export const fcaFastApi = axios.create({
    baseURL: FAST_API_URL,
    // timeout: 300000,
    headers: {},
    paramsSerializer: {
        serialize: params => {
            return qs.stringify(params, {
                arrayFormat: "brackets",
                skipNulls: true,
                filter: filterFunc
            });
        }
    }
});

fcaFastApi.interceptors.request.use(function (config) {
    const token = localStorage.getItem("fca-token");
    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
    };
    return config;
});

fcaFastApi.interceptors.response.use(
    res => {
        return res;
    },
    function (error) {
        if (error.config && error.config.url !== "/api/v1/auth/login" && error.response && error.response.status === 401) {
            handleLogout();
        }
        return error;
    }
);

const handleLogout = () => {
    // unsubscribing fcm
    let userId = localStorage.getItem("userId");
    unSubscribeDevice(userId);
    localStorage.clear();
    sessionStorage.clear();
    handleRememberRoute();
    window.location.href = "/";
};

const handleRememberRoute = () => {
    const baseRoute = window.location.pathname;
    if (
        baseRoute?.split("/")?.[1] === "project-capital-request" ||
        baseRoute?.split("/")?.[1] === "assetmanagement" ||
        baseRoute?.split("/")?.[4] === "qaqc"
    ) {
        localStorage.setItem("prevRoute", baseRoute);
    }
};

export const fcaReportCRGateway = axios.create({
    baseURL: REPORT_URL
});

fcaReportCRGateway.interceptors.request.use(function (config) {
    const crToken = localStorage.getItem("crRequestorToken");

    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${crToken}`
    };
    return config;
});

fcaReportCRGateway.interceptors.response.use(
    res => {
        return res;
    },
    function (res) {
        if (res.response && res.response.status === 401) {
            localStorage.removeItem("crRequestorToken");
            window.location.href = "/";
        }
        return res;
    }
);
