import * as actionType from "./constants";

const initialState = {
    entityParams: {
        entity: null,
        selectedEntity: null,
        selectedRowId: null,
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 0,
            search: "",
            filters: null,
            filterKeys: null,
            list: null,
            order: null
        },
        wildCardFilterParams: {},
        filterParams: {},
        tableConfig: null
    },
    surveyAssetEntityParams: {
        entity: null,
        selectedEntity: null,
        selectedRowId: null,
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 0,
            search: "",
            filters: null,
            filterKeys: null,
            list: null,
            order: null
        },
        wildCardFilterParams: {},
        filterParams: {},
        tableConfig: null
    },
    inaccessibleSpaceEntityParams: {
        entity: null,
        selectedEntity: null,
        selectedRowId: null,
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 0,
            search: "",
            filters: null,
            filterKeys: null,
            list: null,
            order: null
        },
        wildCardFilterParams: {},
        filterParams: {},
        tableConfig: null
    },
    assetVoiceNotesEntityParams: {
        entity: null,
        selectedEntity: null,
        selectedRowId: null,
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 0,
            search: "",
            filters: null,
            filterKeys: null,
            list: null,
            order: null
        },
        wildCardFilterParams: {},
        filterParams: {},
        tableConfig: null
    },
    dropDownList: {},
    dropDownLoading: {},
    addAssetSurveyProjectResponse: null,
    addAssetSurveyProjectResponseLoading: false,
    assetSurveyProjectsList: { loading: false },
    surveyAssetList: { surveyAssetLoading: false },
    deleteAssetSurveyProjectResponse: null,
    getAssetSurveyProjectById: null,
    getAssetSurveyProjectByIdLoading: false,
    getSurveyAssetById: null,
    getSurveyAssetByIdLoading: false,
    updateAssetSurveyProjectResponse: null,
    updateAssetSurveyProjectResponseLoading: false,
    getListForCommonFilterResponse: {},
    getDataLogsResponse: { loading: false },
    restoreDataLogsResponse: { loading: false },
    updateSurveyAssetResponse: null,
    updateSurveyAssetResponseLoading: false,
    getSurveyAssetImagesById: null,
    getSurveyAssetImagesByIdLoading: false,
    getReviewedCount: null,
    getReviewedCountLoading: null,
    transferAssetsResponse: null,
    transferAssetsResponseLoading: null,
    getCompletedCount: null,
    getCompletedCountLoading: null,
    getSurveyAssetNoteById: null,
    getSurveyAssetNoteByIdLoading: false,
    inaccessibleSpaceList: { loading: false },
    getInaccessibleSpaceById: null,
    getInaccessibleSpaceByIdLoading: false,
    getReasonsById: null,
    getReasonsByIdLoading: false,
    addReasonResponse: null,
    addReasonResponseLoading: false,
    updateInaccessibleSpaceSettingsResponse: null,
    updateInaccessibleSpaceSettingsResponseLoading: false,
    updateSurveyAssetFieldsResponse: null,
    updateSurveyAssetFieldsResponseLoading: false,
    updateAssetImageCommentResponse: null,
    updateAssetImageCommentResponseLoading: false,
    getSurveyAssetsExportResponse: null,
    getSurveyAssetsExportResponseLoading: false,
    getAssetSurveyProjectExportResponse: null,
    getAssetSurveyProjectExportResponseLoading: false,
    getSurveyAssetsCommonData: null,
    getSurveyAssetsCommonDataLoading: false,
    updateMultiSurveyAsset: null,
    updateMultiSurveyAssetLoading: false,
    getAllSurveyAssetIdsResponse: null,
    getAllSurveyAssetIdsResponseLoading: false,
    getListForSurveyAssetsCommonFilterResponse: null,
    getSurveyorDropdownResponse: null,
    getSurveyorDropdownResponseLoading: false,
    getAssetVoiceNotesResponse: null,
    getAssetVoiceNotesResponseLoading: false,
    deleteAssetByIdVoiceNotesResponse: null,
    deleteAssetByIdVoiceNotesLoading: false,
    getSurveyRecommendationsImagesById: null,
    getSurveyRecommendationsImagesByIdLoading: false
};

const reducers = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_ASSET_SURVEY_PROJECTS_REQUEST:
            return {
                ...state,
                assetSurveyProjectsList: { loading: true }
            };
        case actionType.GET_ASSET_SURVEY_PROJECTS_SUCCESS:
            return {
                ...state,
                assetSurveyProjectsList: { loading: false, success: true, ...action.response }
            };
        case actionType.GET_ASSET_SURVEY_PROJECTS_FAILURE:
            return {
                ...state,
                assetSurveyProjectsList: { loading: false, success: false, ...action.error }
            };

        case actionType.GET_SURVEY_ASSET_REQUEST:
            return {
                ...state,
                surveyAssetList: { surveyAssetLoading: true }
            };
        case actionType.GET_SURVEY_ASSET_SUCCESS:
            return {
                ...state,
                surveyAssetList: { surveyAssetLoading: false, success: true, ...action.response }
            };
        case actionType.GET_SURVEY_ASSET_FAILURE:
            return {
                ...state,
                surveyAssetList: { surveyAssetLoading: false, success: false, ...action.error }
            };

        case actionType.GET_ASSET_SURVEY_PROJECT_DROPDOWN_REQUEST:
            return {
                ...state,
                dropDownList: {
                    ...state.dropDownList,
                    [action.entity]: []
                },
                dropDownLoading: { ...state.dropDownLoading, [action.entity]: true }
            };
        case actionType.GET_ASSET_SURVEY_PROJECT_DROPDOWN_SUCCESS:
            return {
                ...state,
                dropDownLoading: { ...state.dropDownLoading, [action.entity]: false },
                dropDownList: {
                    ...state.dropDownList,
                    [action.entity]: action.response[action.entity]
                }
            };
        case actionType.GET_ASSET_SURVEY_PROJECT_DROPDOWN_FAILURE:
            return {
                ...state,
                dropDownLoading: { ...state.dropDownLoading, [action.entity]: false },
                dropDownList: {
                    ...state.dropDownList
                }
            };

        case actionType.ADD_ASSET_SURVEY_PROJECT_DATA_REQUEST:
            return {
                ...state,
                addAssetSurveyProjectResponse: null,
                addAssetSurveyProjectResponseLoading: true
            };
        case actionType.ADD_ASSET_SURVEY_PROJECT_DATA_SUCCESS:
            return {
                ...state,
                addAssetSurveyProjectResponse: action.response,
                addAssetSurveyProjectResponseLoading: false
            };
        case actionType.ADD_ASSET_SURVEY_PROJECT_DATA_FAILURE:
            return {
                ...state,
                addAssetSurveyProjectResponse: null,
                addAssetSurveyProjectResponseLoading: false
            };

        case actionType.UPDATE_ASSET_SURVEY_PROJECT_DATA_REQUEST:
            return {
                ...state,
                updateAssetSurveyProjectResponse: null,
                updateAssetSurveyProjectResponseLoading: true
            };
        case actionType.UPDATE_ASSET_SURVEY_PROJECT_DATA_SUCCESS:
            return {
                ...state,
                updateAssetSurveyProjectResponse: action.response,
                updateAssetSurveyProjectResponseLoading: false
            };
        case actionType.UPDATE_ASSET_SURVEY_PROJECT_DATA_FAILURE:
            return {
                ...state,
                updateSurveyAssetResponse: null,
                updateSurveyAssetResponseLoading: false
            };

        case actionType.UPDATE_SURVEY_ASSET_DATA_REQUEST:
            return {
                ...state,
                updateSurveyAssetResponse: null,
                updateSurveyAssetResponseLoading: action.response
            };
        case actionType.UPDATE_SURVEY_ASSET_DATA_SUCCESS:
            return {
                ...state,
                updateSurveyAssetResponse: action.response,
                updateSurveyAssetResponseLoading: false
            };
        case actionType.UPDATE_SURVEY_ASSET_DATA_FAILURE:
            return {
                ...state,
                updateSurveyAssetResponse: action.error,
                updateSurveyAssetResponseLoading: false
            };

        case actionType.UPDATE_ASSET_SURVEY_PROJECTS_ENTITY_PARAMS_SUCCESS:
            return {
                ...state,
                entityParams: { ...action.response }
            };
        case actionType.UPDATE_ASSET_SURVEY_PROJECTS_ENTITY_PARAMS_FAILURE:
            return {
                ...state,
                entityParams: { ...action.error }
            };

        case actionType.DELETE_ASSET_SURVEY_PROJECT_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_ASSET_SURVEY_PROJECT_SUCCESS:
            return {
                ...state,
                deleteAssetSurveyProjectResponse: { success: true, ...action.response }
            };
        case actionType.DELETE_ASSET_SURVEY_PROJECT_FAILURE:
            return {
                ...state,
                deleteAssetSurveyProjectResponse: { success: false, ...action.error }
            };

        case actionType.GET_ASSET_SURVEY_PROJECT_BY_ID_REQUEST:
            return {
                ...state,
                getAssetSurveyProjectByIdLoading: true
            };
        case actionType.GET_ASSET_SURVEY_PROJECT_BY_ID_SUCCESS:
            return {
                ...state,
                getAssetSurveyProjectById: { success: true, data: { ...action.response } },
                getAssetSurveyProjectByIdLoading: false
            };
        case actionType.GET_ASSET_SURVEY_PROJECT_BY_ID_FAILURE:
            return {
                ...state,
                getAssetSurveyProjectById: { success: false, ...action.error },
                getAssetSurveyProjectByIdLoading: false
            };

        case actionType.GET_SURVEY_ASSET_IMAGES_BY_ID_REQUEST:
            return {
                ...state,
                getSurveyAssetImagesByIdLoading: true
            };
        case actionType.GET_SURVEY_ASSET_IMAGES_BY_ID_SUCCESS:
            return {
                ...state,
                getSurveyAssetImagesById: { success: true, data: { ...action.response } },
                getSurveyAssetImagesByIdLoading: false
            };
        case actionType.GET_SURVEY_ASSET_IMAGES_BY_ID_FAILURE:
            return {
                ...state,
                getSurveyAssetImagesById: { success: false, ...action.error },
                getSurveyAssetImagesByIdLoading: false
            };

        case actionType.GET_SURVEY_RECOMMENDATION_IMAGES_BY_ID_REQUEST:
            return {
                ...state,
                getSurveyRecommendationsImagesByIdLoading: true
            };
        case actionType.GET_SURVEY_RECOMMENDATION_IMAGES_BY_ID_SUCCESS:
            return {
                ...state,
                getSurveyRecommendationsImagesById: { success: true, data: { ...action.response } },
                getSurveyRecommendationsImagesByIdLoading: false
            };
        case actionType.GET_SURVEY_RECOMMENDATION_IMAGES_BY_ID_FAILURE:
            return {
                ...state,
                getSurveyRecommendationsImagesById: { success: false, ...action.error },
                getSurveyRecommendationsImagesByIdLoading: false
            };

        case actionType.GET_SURVEY_ASSET_BY_ID_REQUEST:
            return {
                ...state,
                getSurveyAssetByIdLoading: true
            };
        case actionType.GET_SURVEY_ASSET_BY_ID_SUCCESS:
            return {
                ...state,
                getSurveyAssetById: { success: true, data: { ...action.response } },
                getSurveyAssetByIdLoading: false
            };
        case actionType.GET_SURVEY_ASSET_BY_ID_FAILURE:
            return {
                ...state,
                getSurveyAssetById: { success: false, ...action.error },
                getSurveyAssetByIdLoading: false
            };

        case actionType.GET_COMMON_FILTER_LIST_FOR_ASSET_SURVEY_PROJECT_REQUEST:
            return {
                ...state
            };
        case actionType.GET_COMMON_FILTER_LIST_FOR_ASSET_SURVEY_PROJECT_SUCCESS:
            return {
                ...state,
                getListForCommonFilterResponse: { success: true, list: action.response || [] }
            };
        case actionType.GET_COMMON_FILTER_LIST_FOR_ASSET_SURVEY_PROJECT_FAILURE:
            return {
                ...state,
                getListForCommonFilterResponse: { success: false, ...action.error }
            };

        case actionType.GET_COMMON_FILTER_LIST_FOR_SURVEY_ASSETS_REQUEST:
            return {
                ...state
            };
        case actionType.GET_COMMON_FILTER_LIST_FOR_SURVEY_ASSETS_SUCCESS:
            return {
                ...state,
                getListForSurveyAssetsCommonFilterResponse: { success: true, list: action.response || [] }
            };
        case actionType.GET_COMMON_FILTER_LIST_FOR_SURVEY_ASSETS_FAILURE:
            return {
                ...state,
                getListForSurveyAssetsCommonFilterResponse: { success: false, ...action.error }
            };

        case actionType.RESET_REDUCER:
            return {
                ...state,
                getAssetSurveyProjectById: null,
                dropDownList: {},
                getSurveyAssetImagesById: null,
                getSurveyAssetNoteById: null,
                getSurveyRecommendationsImagesById: null,
                getSurveyRecommendationsImagesByIdLoading: false
            };
        case actionType.GET_ALL_DATA_LOG_REQUEST:
            return {
                ...state,
                getDataLogsResponse: { loading: true }
            };
        case actionType.GET_ALL_DATA_LOG_SUCCESS:
            return {
                ...state,
                getDataLogsResponse: { loading: false, success: true, ...action.response }
            };
        case actionType.GET_ALL_DATA_LOG_FAILURE:
            return {
                ...state,
                getDataLogsResponse: { loading: false, success: false, ...action.error }
            };
        case actionType.RESTORE_DATA_LOG_REQUEST:
            return {
                ...state,
                restoreDataLogsResponse: { loading: true }
            };
        case actionType.RESTORE_DATA_LOG_SUCCESS:
            return {
                ...state,
                restoreDataLogsResponse: { loading: false, success: true, ...action.response }
            };
        case actionType.RESTORE_DATA_LOG_FAILURE:
            return {
                ...state,
                restoreDataLogsResponse: { loading: false, success: false, ...action.error }
            };

        case actionType.GET_REVIEWED_ASSETS_COUNT_REQUEST:
            return {
                ...state,
                getReviewedCount: null,
                getReviewedCountLoading: true
            };
        case actionType.GET_REVIEWED_ASSETS_COUNT_SUCCESS:
            return {
                ...state,
                getReviewedCount: { success: true, ...action.response },
                getReviewedCountLoading: false
            };
        case actionType.GET_REVIEWED_ASSETS_COUNT_FAILURE:
            return {
                ...state,
                getReviewedCount: { success: false, ...action.error },
                getReviewedCountLoading: false
            };

        case actionType.TRANSFER_REVIEWED_ASSETS_REQUEST:
            return {
                ...state,
                transferAssetsResponse: null,
                transferAssetsResponseLoading: true
            };
        case actionType.TRANSFER_REVIEWED_ASSETS_SUCCESS:
            return {
                ...state,
                transferAssetsResponse: { success: true, ...action.response },
                transferAssetsResponseLoading: false
            };
        case actionType.TRANSFER_REVIEWED_ASSETS_FAILURE:
            return {
                ...state,
                transferAssetsResponse: { success: false, ...action.error },
                transferAssetsResponseLoading: false
            };
        case actionType.GET_COMPLETED_ASSETS_COUNT_REQUEST:
            return {
                ...state,
                getCompletedCount: null,
                getCompletedCountLoading: true
            };
        case actionType.GET_COMPLETED_ASSETS_COUNT_SUCCESS:
            return {
                ...state,
                getCompletedCount: { success: true, ...action.response },
                getCompletedCountLoading: false
            };
        case actionType.GET_COMPLETED_ASSETS_COUNT_FAILURE:
            return {
                ...state,
                getCompletedCount: { success: false, ...action.error },
                getCompletedCountLoading: false
            };
        case actionType.GET_INACCESSIBLE_SPACE_REQUEST:
            return {
                ...state,
                inaccessibleSpaceList: { loading: true }
            };
        case actionType.GET_INACCESSIBLE_SPACE_SUCCESS:
            return {
                ...state,
                inaccessibleSpaceList: { loading: false, success: true, ...action.response }
            };
        case actionType.GET_INACCESSIBLE_SPACE_FAILURE:
            return {
                ...state,
                inaccessibleSpaceList: { loading: false, success: false, ...action.error }
            };

        case actionType.GET_INACCESSIBLE_SPACE_BY_ID_REQUEST:
            return {
                ...state,
                getInaccessibleSpaceByIdLoading: true
            };
        case actionType.GET_INACCESSIBLE_SPACE_BY_ID_SUCCESS:
            return {
                ...state,
                getInaccessibleSpaceById: { success: true, data: { ...action.response } },
                getInaccessibleSpaceByIdLoading: false
            };
        case actionType.GET_INACCESSIBLE_SPACE_BY_ID_FAILURE:
            return {
                ...state,
                getInaccessibleSpaceById: { success: false, ...action.error },
                getInaccessibleSpaceByIdLoading: false
            };
        case actionType.GET_SURVEY_ASSET_NOTE_BY_ID_REQUEST:
            return {
                ...state,
                getSurveyAssetNoteByIdLoading: true
            };
        case actionType.GET_SURVEY_ASSET_NOTE_BY_ID_SUCCESS:
            return {
                ...state,
                getSurveyAssetNoteById: { success: true, data: { ...action.response } },
                getSurveyAssetNoteByIdLoading: false
            };
        case actionType.GET_SURVEY_ASSET_NOTE_BY_ID_FAILURE:
            return {
                ...state,
                getSurveyAssetNoteById: { success: false, ...action.error },
                getSurveyAssetNoteByIdLoading: false
            };

        case actionType.GET_REASONS_BY_ID_REQUEST:
            return {
                ...state,
                getReasonsByIdLoading: true
            };
        case actionType.GET_REASONS_BY_ID_SUCCESS:
            return {
                ...state,
                getReasonsById: { success: true, data: { ...action.response } },
                getReasonsByIdLoading: false
            };
        case actionType.GET_REASONS_BY_ID_FAILURE:
            return {
                ...state,
                getReasonsById: { success: false, ...action.error },
                getReasonsByIdLoading: false
            };

        case actionType.ADD_REASON_REQUEST:
            return {
                ...state,
                addReasonResponseLoading: true
            };
        case actionType.ADD_REASON_SUCCESS:
            return {
                ...state,
                addReasonResponse: { success: true, data: { ...action.response } },
                addReasonResponseLoading: false
            };
        case actionType.ADD_REASON_FAILURE:
            return {
                ...state,
                addReasonResponse: { success: false, ...action.error },
                addReasonResponseLoading: false
            };

        case actionType.UPDATE_INACCESSIBLE_SPACE_SETTINGS_REQUEST:
            return {
                ...state,
                updateInaccessibleSpaceSettingsResponseLoading: true
            };
        case actionType.UPDATE_INACCESSIBLE_SPACE_SETTINGS_SUCCESS:
            return {
                ...state,
                updateInaccessibleSpaceSettingsResponse: { success: true, data: { ...action.response } },
                updateInaccessibleSpaceSettingsResponseLoading: false
            };
        case actionType.UPDATE_INACCESSIBLE_SPACE_SETTINGS_FAILURE:
            return {
                ...state,
                updateInaccessibleSpaceSettingsResponse: { success: false, ...action.error },
                updateInaccessibleSpaceSettingsResponseLoading: false
            };

        case actionType.UPDATE_SURVEY_ASSET_FIELDS_REQUEST:
            return {
                ...state,
                updateSurveyAssetFieldsResponseLoading: true
            };
        case actionType.UPDATE_SURVEY_ASSET_FIELDS_SUCCESS:
            return {
                ...state,
                updateSurveyAssetFieldsResponse: { success: true, data: { ...action.response } },
                updateSurveyAssetFieldsResponseLoading: false
            };
        case actionType.UPDATE_SURVEY_ASSET_FIELDS_FAILURE:
            return {
                ...state,
                updateSurveyAssetFieldsResponse: { success: false, ...action.error },
                updateSurveyAssetFieldsResponseLoading: false
            };

        case actionType.UPDATE_ASSET_IMAGE_COMMENT_REQUEST:
            return {
                ...state,
                updateAssetImageCommentResponseLoading: true
            };
        case actionType.UPDATE_ASSET_IMAGE_COMMENT_SUCCESS:
            return {
                ...state,
                updateAssetImageCommentResponse: { success: true, data: { ...action.response } },
                updateAssetImageCommentResponseLoading: false
            };
        case actionType.UPDATE_ASSET_IMAGE_COMMENT_FAILURE:
            return {
                ...state,
                updateAssetImageCommentResponse: { success: false, ...action.error },
                updateAssetImageCommentResponseLoading: false
            };

        case actionType.UPDATE_SURVEY_ASSETS_ENTITY_PARAMS_SUCCESS:
            return {
                ...state,
                surveyAssetEntityParams: { ...action.response }
            };
        case actionType.UPDATE_SURVEY_ASSETS_ENTITY_PARAMS_FAILURE:
            return {
                ...state,
                surveyAssetEntityParams: { ...action.error }
            };

        case actionType.UPDATE_INACCESSIBLE_SPACE_ENTITY_PARAMS_SUCCESS:
            return {
                ...state,
                surveyAssetEntityParams: { ...action.response }
            };
        case actionType.UPDATE_INACCESSIBLE_SPACE_ENTITY_PARAMS_FAILURE:
            return {
                ...state,
                surveyAssetEntityParams: { ...action.error }
            };

        case actionType.GET_ASSET_SURVEY_PROJECTS_EXPORT_REQUEST:
            return {
                ...state,
                getAssetSurveyProjectExportResponseLoading: true
            };
        case actionType.GET_ASSET_SURVEY_PROJECTS_EXPORT_SUCCESS:
            return {
                ...state,
                getAssetSurveyProjectExportResponse: { success: true, data: { ...action.response } },
                getAssetSurveyProjectExportResponseLoading: false
            };
        case actionType.GET_ASSET_SURVEY_PROJECTS_EXPORT_FAILURE:
            return {
                ...state,
                getAssetSurveyProjectExportResponse: { success: false, ...action.error },
                getAssetSurveyProjectExportResponseLoading: false
            };

        case actionType.GET_SURVEY_ASSET_EXPORT_REQUEST:
            return {
                ...state,
                getSurveyAssetsExportResponseLoading: true
            };
        case actionType.GET_SURVEY_ASSET_EXPORT_SUCCESS:
            return {
                ...state,
                getSurveyAssetsExportResponse: { success: true, data: { ...action.response } },
                getSurveyAssetsExportResponseLoading: false
            };
        case actionType.GET_SURVEY_ASSET_EXPORT_FAILURE:
            return {
                ...state,
                getSurveyAssetsExportResponse: { success: false, ...action.error },
                getSurveyAssetsExportResponseLoading: false
            };

        case actionType.GET_SURVEY_ASSET_COMMON_DATA_BY_IDS_REQUEST:
            return {
                ...state,
                getSurveyAssetsCommonDataLoading: true
            };
        case actionType.GET_SURVEY_ASSET_COMMON_DATA_BY_IDS_SUCCESS:
            return {
                ...state,
                getSurveyAssetsCommonData: { success: true, data: { ...action.response } },
                getSurveyAssetsCommonDataLoading: false
            };
        case actionType.GET_SURVEY_ASSET_COMMON_DATA_BY_IDS_FAILURE:
            return {
                ...state,
                getSurveyAssetsCommonData: { success: false, ...action.error },
                getSurveyAssetsCommonDataLoading: false
            };

        case actionType.UPDATE_MULTI_SURVEY_ASSET_REQUEST:
            return {
                ...state,
                updateMultiSurveyAssetLoading: true
            };
        case actionType.UPDATE_MULTI_SURVEY_ASSET_SUCCESS:
            return {
                ...state,
                updateMultiSurveyAsset: { success: true, data: { ...action.response } },
                updateMultiSurveyAssetLoading: false
            };
        case actionType.UPDATE_MULTI_SURVEY_ASSET_FAILURE:
            return {
                ...state,
                updateMultiSurveyAsset: { success: false, ...action.error },
                updateMultiSurveyAssetLoading: false
            };

        case actionType.RESET_MULTI_COMMON_DATA_SUCCESS:
            return {
                ...state,
                getSurveyAssetsCommonData: null
            };

        case actionType.RESET_MULTI_COMMON_DATA_FAILURE:
            return {
                ...state,
                getSurveyAssetsCommonData: null
            };

        case actionType.GET_ALL_SURVEY_ASSET_IDS_REQUEST:
            return {
                ...state,
                getAllSurveyAssetIdsResponseLoading: true
            };
        case actionType.GET_ALL_SURVEY_ASSET_IDS_SUCCESS:
            return {
                ...state,
                getAllSurveyAssetIdsResponse: { success: true, data: { ...action.response } },
                getAllSurveyAssetIdsResponseLoading: false
            };
        case actionType.GET_ALL_SURVEY_ASSET_IDS_FAILURE:
            return {
                ...state,
                getAllSurveyAssetIdsResponse: { success: false, ...action.error },
                getAllSurveyAssetIdsResponseLoading: false
            };

        case actionType.GET_SURVEYOR_DROPDOWN_REQUEST:
            return {
                ...state,
                getSurveyorDropdownResponseLoading: true
            };
        case actionType.GET_SURVEYOR_DROPDOWN_SUCCESS:
            return {
                ...state,
                getSurveyorDropdownResponse: { success: true, data: { ...action.response } },
                getSurveyorDropdownResponseLoading: false
            };
        case actionType.GET_SURVEYOR_DROPDOWN_FAILURE:
            return {
                ...state,
                getSurveyorDropdownResponse: { success: false, ...action.error },
                getSurveyorDropdownResponseLoading: false
            };

        case actionType.GET_ASSET_VOICE_NOTES_REQUEST:
            return {
                ...state,
                getAssetVoiceNotesResponseLoading: true
            };
        case actionType.GET_ASSET_VOICE_NOTES_SUCCESS:
            return {
                ...state,
                getAssetVoiceNotesResponse: { success: true, data: { ...action.response } },
                getAssetVoiceNotesResponseLoading: false
            };
        case actionType.GET_ASSET_VOICE_NOTES_FAILURE:
            return {
                ...state,
                getAssetVoiceNotesResponse: { success: false, ...action.error },
                getAssetVoiceNotesResponseLoading: false
            };

        case actionType.DELETE_ASSET_BY_ID_VOICE_NOTES_REQUEST:
            return {
                ...state,
                deleteAssetByIdVoiceNotesLoading: true
            };
        case actionType.DELETE_ASSET_BY_ID_VOICE_NOTES_SUCCESS:
            return {
                ...state,
                deleteAssetByIdVoiceNotesResponse: { success: true, data: { ...action.response } },
                deleteAssetByIdVoiceNotesLoading: false
            };
        case actionType.DELETE_ASSET_BY_ID_VOICE_NOTES_FAILURE:
            return {
                ...state,
                deleteAssetByIdVoiceNotesResponse: { success: false, ...action.error },
                deleteAssetByIdVoiceNotesLoading: false
            };

        default:
            return state;
    }
};

export default reducers;
