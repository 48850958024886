import { fcaFastApi, fcaGateWay, fcaReportGateway } from "../../services/authorizationService";
import * as serviceEndpoints from "../../config/serviceEndPoints";

export const getUserDetails = () => fcaGateWay.get(serviceEndpoints.userEndPoints.getUserDetails);
export const loginUserPython = param => fcaReportGateway.post(serviceEndpoints.userEndPoints.loginUserPython, param);
export const logoutUser = param => fcaGateWay.delete(serviceEndpoints.userEndPoints.logoutUser, param);
export const revokeToken = param => fcaReportGateway.post(serviceEndpoints.userEndPoints.revokeToken, param);

export const forgotPassword = param => fcaGateWay.post(serviceEndpoints.userEndPoints.forgotPassword, param);
export const resetPassword = param => fcaGateWay.post(serviceEndpoints.userEndPoints.resetPassword, param);

export const validateToken = token => fcaGateWay.get(`${serviceEndpoints.userEndPoints.verifyToken}`, { params: { token } });

export const resetPasswordProfile = (id, param) =>
    fcaFastApi.patch(`${serviceEndpoints.userEndPoints.resetPasswordProfile}/${id}/update_password`, param);
