export const CAPITAL_REQUEST_STATUS = [
    { id: "submitted", name: "Submitted" },
    { id: "under_review", name: "Under Review" },
    { id: "partially_approved", name: "Partially Approved" },
    { id: "approved", name: "Approved" },
    { id: "not_approved", name: "Not Approved" },
    { id: "deferred_to_next_year", name: "Deferred to next year" }
];

// Get all sites
export const GET_ALL_RECOMMENDATIONS_REQUEST = "GET_ALL_RECOMMENDATIONS_REQUEST";
export const GET_ALL_RECOMMENDATIONS_SUCCESS = "GET_ALL_RECOMMENDATIONS_SUCCESS";
export const GET_ALL_RECOMMENDATIONS_FAILURE = "GET_ALL_RECOMMENDATIONS_FAILURE";

// Add site
export const ADD_RECOMMENDATION_REQUEST = "ADD_RECOMMENDATION_REQUEST";
export const ADD_RECOMMENDATION_SUCCESS = "ADD_RECOMMENDATION_SUCCESS";
export const ADD_RECOMMENDATION_FAILURE = "ADD_RECOMMENDATION_FAILURE";

// PARSE FCA
export const PARSE_FCA_REQUEST = "PARSE_FCA_REQUEST";
export const PARSE_FCA_SUCCESS = "PARSE_FCA_SUCCESS";
export const PARSE_FCA_FAILURE = "PARSE_FCA_FAILURE";

// Update site
export const UPDATE_RECOMMENDATION_REQUEST = "UPDATE_RECOMMENDATION_REQUEST";
export const UPDATE_RECOMMENDATION_SUCCESS = "UPDATE_RECOMMENDATION_SUCCESS";
export const UPDATE_RECOMMENDATION_FAILURE = "UPDATE_RECOMMENDATION_FAILURE";

export const LOCK_RECOMMENDATION_REQUEST = "LOCK_RECOMMENDATION_REQUEST";
export const LOCK_RECOMMENDATION_SUCCESS = "LOCK_RECOMMENDATION_SUCCESS";
export const LOCK_RECOMMENDATION_FAILURE = "LOCK_RECOMMENDATION_FAILURE";

// Delete site
export const DELETE_RECOMMENDATION_REQUEST = "DELETE_RECOMMENDATION_REQUEST";
export const DELETE_RECOMMENDATION_SUCCESS = "DELETE_RECOMMENDATION_SUCCESS";
export const DELETE_RECOMMENDATION_FAILURE = "DELETE_RECOMMENDATION_FAILURE";

// Ger region based on client
export const GET_REGIONS_BASED_ON_CLIENT_REQUEST = "GET_REGIONS_BASED_ON_CLIENT_REQUEST";
export const GET_REGIONS_BASED_ON_CLIENT_SUCCESS = "GET_REGIONS_BASED_ON_CLIENT_SUCCESS";
export const GET_REGIONS_BASED_ON_CLIENT_FAILURE = "GET_REGIONS_BASED_ON_CLIENT_FAILURE";

// Get all consultancy users
export const GET_ALL_CONSULTANCY_USERS_REQUEST = "GET_ALL_CONSULTANCY_USERS_REQUEST";
export const GET_ALL_CONSULTANCY_USERS_SUCCESS = "GET_ALL_CONSULTANCY_USERS_SUCCESS";
export const GET_ALL_CONSULTANCY_USERS_FAILURE = "GET_ALL_CONSULTANCY_USERS_FAILURE";

// Get all Clients
export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const GET_ALL_CLIENTS_FAILURE = "GET_ALL_CLIENTS_FAILURE";

// Get Site by Id
export const GET_RECOMMENDATION_BY_ID_REQUEST = "GET_RECOMMENDATION_BY_ID_REQUEST";
export const GET_RECOMMENDATION_BY_ID_SUCCESS = "GET_RECOMMENDATION_BY_ID_SUCCESS";
export const GET_RECOMMENDATION_BY_ID_FAILURE = "GET_RECOMMENDATION_BY_ID_FAILURE";

// Upload Image
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

// Get all Images
export const GET_ALL_IMAGES_REQUEST = "GET_ALL_IMAGES_REQUEST";
export const GET_ALL_IMAGES_SUCCESS = "GET_ALL_IMAGES_SUCCESS";
export const GET_ALL_IMAGES_FAILURE = "GET_ALL_IMAGES_FAILURE";

// Delete Images
export const DELETE_IMAGES_REQUEST = "DELETE_IMAGES_REQUEST";
export const DELETE_IMAGES_SUCCESS = "DELETE_IMAGES_SUCCESS";
export const DELETE_IMAGES_FAILURE = "DELETE_IMAGES_FAILURE";

// Update Comments Images
export const UPDATE_IMAGE_COMMENT_REQUEST = "UPDATE_IMAGE_COMMENT_REQUEST";
export const UPDATE_IMAGE_COMMENT_SUCCESS = "UPDATE_IMAGE_COMMENT_SUCCESS";
export const UPDATE_IMAGE_COMMENT_FAILURE = "UPDATE_IMAGE_COMMENT_FAILURE";

export const GET_DEPARTMENT_BASED_ON_PROJECT_REQUEST = "GET_DEPARTMENT_BASED_ON_PROJECT_REQUEST";
export const GET_DEPARTMENT_BASED_ON_PROJECT_SUCCESS = "GET_DEPARTMENT_BASED_ON_PROJECT_SUCCESS";
export const GET_DEPARTMENT_BASED_ON_PROJECT_FAILURE = "GET_DEPARTMENT_BASED_ON_PROJECT_FAILURE";

export const GET_SYSTEM_BASED_ON_PROJECT_REQUEST = "GET_SYSTEM_BASED_ON_PROJECT_REQUEST";
export const GET_SYSTEM_BASED_ON_PROJECT_SUCCESS = "GET_SYSTEM_BASED_ON_PROJECT_SUCCESS";
export const GET_SYSTEM_BASED_ON_PROJECT_FAILURE = "GET_SYSTEM_BASED_ON_PROJECT_FAILURE";

export const GET_SUBSYSTEM_BASED_ON_PROJECT_REQUEST = "GET_SUBSYSTEM_BASED_ON_PROJECT_REQUEST";
export const GET_SUBSYSTEM_BASED_ON_PROJECT_SUCCESS = "GET_SUBSYSTEM_BASED_ON_PROJECT_SUCCESS";
export const GET_SUBSYSTEM_BASED_ON_PROJECT_FAILURE = "GET_SUBSYSTEM_BASED_ON_PROJECT_FAILURE";

export const GET_CATEGORY_BASED_ON_PROJECT_REQUEST = "GET_CATEGORY_BASED_ON_PROJECT_REQUEST";
export const GET_CATEGORY_BASED_ON_PROJECT_SUCCESS = "GET_CATEGORY_BASED_ON_PROJECT_SUCCESS";
export const GET_CATEGORY_BASED_ON_PROJECT_FAILURE = "GET_CATEGORY_BASED_ON_PROJECT_FAILURE";

export const GET_CAPITAL_TYPE_BASED_ON_PROJECT_REQUEST = "GET_CAPITAL_TYPE_BASED_ON_PROJECT_REQUEST";
export const GET_CAPITAL_TYPE_BASED_ON_PROJECT_SUCCESS = "GET_CAPITAL_TYPE_BASED_ON_PROJECT_SUCCESS";
export const GET_CAPITAL_TYPE_BASED_ON_PROJECT_FAILURE = "GET_CAPITAL_TYPE_BASED_ON_PROJECT_FAILURE";

export const GET_TRADE_BASED_ON_PROJECT_REQUEST = "GET_TRADE_BASED_ON_PROJECT_REQUEST";
export const GET_TRADE_BASED_ON_PROJECT_SUCCESS = "GET_TRADE_BASED_ON_PROJECT_SUCCESS";
export const GET_TRADE_BASED_ON_PROJECT_FAILURE = "GET_TRADE_BASED_ON_PROJECT_FAILURE";

export const GET_FLOOR_BASED_ON_PROJECT_REQUEST = "GET_FLOOR_BASED_ON_PROJECT_REQUEST";
export const GET_FLOOR_BASED_ON_PROJECT_SUCCESS = "GET_FLOOR_BASED_ON_PROJECT_SUCCESS";
export const GET_FLOOR_BASED_ON_PROJECT_FAILURE = "GET_FLOOR_BASED_ON_PROJECT_FAILURE";

export const GET_FLOOR_BASED_ON_BUILDING_REQUEST = "GET_FLOOR_BASED_ON_BUILDING_REQUEST";
export const GET_FLOOR_BASED_ON_BUILDING_SUCCESS = "GET_FLOOR_BASED_ON_BUILDING_SUCCESS";
export const GET_FLOOR_BASED_ON_BUILDING_FAILURE = "GET_FLOOR_BASED_ON_BUILDING_FAILURE";

export const GET_ADDITION_BASED_ON_BUILDING_REQUEST = "GET_ADDITION_BASED_ON_BUILDING_REQUEST";
export const GET_ADDITION_BASED_ON_BUILDING_SUCCESS = "GET_ADDITION_BASED_ON_BUILDING_SUCCESS";
export const GET_ADDITION_BASED_ON_BUILDING_FAILURE = "GET_ADDITION_BASED_ON_BUILDING_FAILURE";

// Update Recommendation Entity Params
export const UPDATE_RECOMMENDATION_ENTITY_PARAMS_SUCCESS = "UPDATE_RECOMMENDATION_ENTITY_PARAMS_SUCCESS";
export const UPDATE_RECOMMENDATION_ENTITY_PARAMS_FAILURE = "UPDATE_RECOMMENDATION_ENTITY_PARAMS_FAILURE";

export const GET_LIST_FOR_COMMON_FILTER_REQUEST = "GET_LIST_FOR_COMMON_FILTER_REQUEST";
export const GET_LIST_FOR_COMMON_FILTER_SUCCESS = "GET_LIST_FOR_COMMON_FILTER_SUCCESS";
export const GET_LIST_FOR_COMMON_FILTER_FAILURE = "GET_LIST_FOR_COMMON_FILTER_FAILURE";

export const GET_COST_PER_YEAR_BY_PROJECT_REQUEST = "GET_COST_PER_YEAR_BY_PROJECT_REQUEST";
export const GET_COST_PER_YEAR_BY_PROJECT_SUCCESS = "GET_COST_PER_YEAR_BY_PROJECT_SUCCESS";
export const GET_COST_PER_YEAR_BY_PROJECT_FAILURE = "GET_COST_PER_YEAR_BY_PROJECT_FAILURE";

export const GET_FUNDING_SOURCE_BASED_ON_PROJECT_REQUEST = "GET_FUNDING_SOURCE_BASED_ON_PROJECT_REQUEST";
export const GET_FUNDING_SOURCE_BASED_ON_PROJECT_SUCCESS = "GET_FUNDING_SOURCE_BASED_ON_PROJECT_SUCCESS";
export const GET_FUNDING_SOURCE_BASED_ON_PROJECT_FAILURE = "GET_FUNDING_SOURCE_BASED_ON_PROJECT_FAILURE";

export const GET_RESPONSIBILITY_BASED_ON_PROJECT_REQUEST = "GET_RESPONSIBILITY_BASED_ON_PROJECT_REQUEST";
export const GET_RESPONSIBILITY_BASED_ON_PROJECT_SUCCESS = "GET_RESPONSIBILITY_BASED_ON_PROJECT_SUCCESS";
export const GET_RESPONSIBILITY_BASED_ON_PROJECT_FAILURE = "GET_RESPONSIBILITY_BASED_ON_PROJECT_FAILURE";

export const UPDATE_MAINTENANCE_YEARS_CUT_PASTE_REQUEST = "UPDATE_MAINTENANCE_YEARS_CUT_PASTE_REQUEST";
export const UPDATE_MAINTENANCE_YEARS_CUT_PASTE_SUCCESS = "UPDATE_RECOMMENDATION_SUCCESS";
export const UPDATE_MAINTENANCE_YEARS_CUT_PASTE_FAILURE = "UPDATE_MAINTENANCE_YEARS_CUT_PASTE_FAILURE";

export const RECOVER_RECOMMENDATION_REQUEST = "RECOVER_RECOMMENDATION_REQUEST";
export const RECOVER_RECOMMENDATION_SUCCESS = "RECOVER_RECOMMENDATION_SUCCESS";
export const RECOVER_RECOMMENDATION_FAILURE = "RECOVER_RECOMMENDATION_FAILURE";

export const GET_ASSET_CONDITION_BASED_ON_PROJECT_REQUEST = "GET_ASSET_CONDITION_BASED_ON_PROJECT_REQUEST";
export const GET_ASSET_CONDITION_BASED_ON_PROJECT_SUCCESS = "GET_ASSET_CONDITION_BASED_ON_PROJECT_SUCCESS";
export const GET_ASSET_CONDITION_BASED_ON_PROJECT_FAILURE = "GET_ASSET_CONDITION_BASED_ON_PROJECT_FAILURE";

export const GET_ALL_RECOMMENDATION_LOG_REQUEST = "GET_ALL_RECOMMENDATION_LOG_REQUEST";
export const GET_ALL_RECOMMENDATION_LOG_SUCCESS = "GET_ALL_RECOMMENDATION_LOG_SUCCESS";
export const GET_ALL_RECOMMENDATION_LOG_FAILURE = "GET_ALL_RECOMMENDATION_LOG_FAILURE";

export const RESTORE_RECOMMENDATION_LOG_REQUEST = "RESTORE_RECOMMENDATION_LOG_REQUEST";
export const RESTORE_RECOMMENDATION_LOG_SUCCESS = "RESTORE_RECOMMENDATION_LOG_SUCCESS";
export const RESTORE_RECOMMENDATION_LOG_FAILURE = "RESTORE_RECOMMENDATION_LOG_FAILURE";

export const DELETE_RECOMMENDATION_LOG_REQUEST = "DELETE_RECOMMENDATION_LOG_REQUEST";
export const DELETE_RECOMMENDATION_LOG_SUCCESS = "DELETE_RECOMMENDATION_LOG_SUCCESS";
export const DELETE_RECOMMENDATION_LOG_FAILURE = "DELETE_RECOMMENDATION_LOG_FAILURE";

export const GET_RECOMMENDATION_EXPORT_REQUEST = "GET_RECOMMENDATION_EXPORT_REQUEST";
export const GET_RECOMMENDATION_EXPORT_SUCCESS = "GET_RECOMMENDATION_EXPORT_SUCCESS";
export const GET_RECOMMENDATION_EXPORT_FAILURE = "GET_RECOMMENDATION_EXPORT_FAILURE";

export const EXPORT_ALL_TRADES_REQUEST = "EXPORT_ALL_TRADES_REQUEST";
export const EXPORT_ALL_TRADES_SUCCESS = "EXPORT_ALL_TRADES_SUCCESS";
export const EXPORT_ALL_TRADES_FAILURE = "EXPORT_ALL_TRADES_FAILURE";

// Get all initiative
export const GET_INTITIATIVE_DROPDOWN_REQUEST = "GET_INTITIATIVE_DROPDOWN_REQUEST";
export const GET_INTITIATIVE_DROPDOWN_SUCCESS = "GET_INTITIATIVE_DROPDOWN_SUCCESS";
export const GET_INTITIATIVE_DROPDOWN_FAILURE = "GET_INTITIATIVE_DROPDOWN_FAILURE";

export const GET_REPORT_NOTE_TEMPLATES_REQUEST = "GET_REPORT_NOTE_TEMPLATES_REQUEST";
export const GET_REPORT_NOTE_TEMPLATES_SUCCESS = "GET_REPORT_NOTE_TEMPLATES_SUCCESS";
export const GET_REPORT_NOTE_TEMPLATES_FAILURE = "GET_REPORT_NOTE_TEMPLATES_FAILURE";

export const DOWNLOAD_PDF_REPORT_REQUEST = "DOWNLOAD_PDF_REPORT_REQUEST";
export const DOWNLOAD_PDF_REPORT_SUCCESS = "DOWNLOAD_PDF_REPORT_SUCCESS";
export const DOWNLOAD_PDF_REPORT_FAILURE = "DOWNLOAD_PDF_REPORT_FAILURE";

export const ADD_USER_ACTIVITY_LOG_REQUEST = "ADD_USER_ACTIVITY_LOG_REQUEST";
export const ADD_USER_ACTIVITY_LOG_SUCCESS = "ADD_USER_ACTIVITY_LOG_SUCCESS";
export const ADD_USER_ACTIVITY_LOG_FAILURE = "ADD_USER_ACTIVITY_LOG_FAILURE";

export const ASSIGN_IMAGES_TO_RECOM_REQUEST = "ASSIGN_IMAGES_TO_RECOM_REQUEST";
export const ASSIGN_IMAGES_TO_RECOM_SUCCESS = "ASSIGN_IMAGES_TO_RECOM_SUCCESS";
export const ASSIGN_IMAGES_TO_RECOM_FAILURE = "ASSIGN_IMAGES_TO_RECOM_FAILURE";

export const UNASSIGN_IMAGE_REQUEST = "UNASSIGN_IMAGE_REQUEST";
export const UNASSIGN_IMAGE_SUCCESS = "UNASSIGN_IMAGE_SUCCESS";
export const UNASSIGN_IMAGE_FAILURE = "UNASSIGN_IMAGE_FAILURE";

//update Budget priority response
export const UPDATE_BUDGET_PRIORITY_REQUEST = "UPDATE_BUDGET_PRIORITY_REQUEST";
export const UPDATE_BUDGET_PRIORITY_SUCCESS = "UPDATE_BUDGET_PRIORITY_SUCCESS";
export const UPDATE_BUDGET_PRIORITY_FAILURE = "UPDATE_BUDGET_PRIORITY_FAILURE";
//update FMP
export const UPDATE_FMP_REQUEST = "UPDATE_FMP_REQUEST";
export const UPDATE_FMP_SUCCESS = "UPDATE_FMP_SUCCESS";
export const UPDATE_FMP_FAILURE = "UPDATE_FMP_FAILURE";

export const UPDATE_RECOMMENDATION_SCROLL_POSITION_SUCCESS = "UPDATE_RECOMMENDATION_SCROLL_POSITION_SUCCESS";
export const UPDATE_RECOMMENDATION_SCROLL_POSITION_FAILURE = "UPDATE_RECOMMENDATION_SCROLL_POSITION_FAILURE";

export const GET_RECOMMENDATION_TEMPLATES_REQUEST = "GET_RECOMMENDATION_TEMPLATES_REQUEST";
export const GET_RECOMMENDATION_TEMPLATES_SUCCESS = "GET_RECOMMENDATION_TEMPLATES_SUCCESS";
export const GET_RECOMMENDATION_TEMPLATES_FAILURE = "GET_RECOMMENDATION_TEMPLATES_FAILURE";

export const GET_USER_DEFAULT_TRADE_REQUEST = "GET_USER_DEFAULT_TRADE_REQUEST";
export const GET_USER_DEFAULT_TRADE_SUCCESS = "GET_USER_DEFAULT_TRADE_SUCCESS";
export const GET_USER_DEFAULT_TRADE_FAILURE = "GET_USER_DEFAULT_TRADE_FAILURE";
// Update multiple recom
export const UPDATE_MULTIPLE_RECOMMENDATIONS_REQUEST = "UPDATE_MULTIPLE_RECOMMENDATIONS_REQUEST";
export const UPDATE_MULTIPLE_RECOMMENDATIONS_SUCCESS = "UPDATE_MULTIPLE_RECOMMENDATIONS_SUCCESS";
export const UPDATE_MULTIPLE_RECOMMENDATIONS_FAILURE = "UPDATE_MULTIPLE_RECOMMENDATIONS_FAILURE";

export const GET_RECOMMENDATION_COMMON_DATA_BY_IDS_REQUEST = "GET_RECOMMENDATION_COMMON_DATA_BY_IDS_REQUEST";
export const GET_RECOMMENDATION_COMMON_DATA_BY_IDS_SUCCESS = "GET_RECOMMENDATION_COMMON_DATA_BY_IDS_SUCCESS";
export const GET_RECOMMENDATION_COMMON_DATA_BY_IDS_FAILURE = "GET_RECOMMENDATION_COMMON_DATA_BY_IDS_FAILURE";

export const GET_WHOLE_RECOMMENDATION_IDS_REQUEST = "GET_WHOLE_RECOMMENDATION_IDS_REQUEST";
export const GET_WHOLE_RECOMMENDATION_IDS_SUCCESS = "GET_WHOLE_RECOMMENDATION_IDS_SUCCESS";
export const GET_WHOLE_RECOMMENDATION_IDS_FAILURE = "GET_WHOLE_RECOMMENDATION_IDS_FAILURE";

export const GET_PRIORITY_ELEMENT_DROPDOWN_DATA_REQUEST = "GET_PRIORITY_ELEMENT_DROPDOWN_DATA_REQUEST";
export const GET_PRIORITY_ELEMENT_DROPDOWN_DATA_SUCCESS = "GET_PRIORITY_ELEMENT_DROPDOWN_DATA_SUCCESS";
export const GET_PRIORITY_ELEMENT_DROPDOWN_DATA_FAILURE = "GET_PRIORITY_ELEMENT_DROPDOWN_DATA_FAILURE";
//updateIr

//update IR
export const UPDATE_IR_REQUEST = "UPDATE_IR_REQUEST";
export const UPDATE_IR_SUCCESS = "UPDATE_IR_SUCCESS";
export const UPDATE_IR_FAILURE = "UPDATE_IR_FAILURE";

export const UPDATE_RL_REQUEST = "UPDATE_RL_REQUEST";
export const UPDATE_RL_SUCCESS = "UPDATE_RL_SUCCESS";
export const UPDATE_RL_FAILURE = "UPDATE_RL_FAILURE";

export const EXPORT_REPORT_PDF_REQUEST = "EXPORT_REPORT_PDF_REQUEST";
export const EXPORT_REPORT_PDF_SUCCESS = "EXPORT_REPORT_PDF_SUCCESS";
export const EXPORT_REPORT_PDF_FAILURE = "EXPORT_REPORT_PDF_FAILURE";

export const GET_CRITICALITY_DROPDOWN_DATA_REQUEST = "GET_CRITICALITY_DROPDOWN_DATA_REQUEST";
export const GET_CRITICALITY_DROPDOWN_DATA_SUCCESS = "GET_CRITICALITY_DROPDOWN_DATA_SUCCESS";
export const GET_CRITICALITY_DROPDOWN_DATA_FAILURE = "GET_CRITICALITY_DROPDOWN_DATA_FAILURE";

export const GET_CAPITAL_TYPE_DROPDOWN_DATA_REQUEST = "GET_CAPITAL_TYPE_DROPDOWN_DATA_REQUEST";
export const GET_CAPITAL_TYPE_DROPDOWN_DATA_SUCCESS = "GET_CAPITAL_TYPE_DROPDOWN_DATA_SUCCESS";
export const GET_CAPITAL_TYPE_DROPDOWN_DATA_FAILURE = "GET_CAPITAL_TYPE_DROPDOWN_DATA_FAILURE";

export const GET_IMPORT_VIEW_TABLE_REQUEST = "GET_IMPORT_VIEW_TABLE_REQUEST";
export const GET_IMPORT_VIEW_TABLE_SUCCESS = "GET_IMPORT_VIEW_TABLE_SUCCESS";
export const GET_IMPORT_VIEW_TABLE_FAILURE = "GET_IMPORT_VIEW_TABLE_FAILURE";

export const GET_EXPORT_WORD_DATA_REQUEST = "GET_EXPORT_WORD_DATA_REQUEST";
export const GET_EXPORT_WORD_DATA_SUCCESS = "GET_EXPORT_WORD_DATA_SUCCESS";
export const GET_EXPORT_WORD_DATA_FAILURE = "GET_EXPORT_WORD_DATA_FAILURE";

export const UPDATE_NOTE_REQUEST = "UPDATE_NOTE_REQUEST";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAILURE = "UPDATE_NOTE_FAILURE";

export const EXPORT_EXCEL_HISTORY_REQUEST = "EXPORT_EXCEL_HISTORY_REQUEST";
export const EXPORT_EXCEL_HISTORY_FAILURE = "EXPORT_EXCEL_HISTORY_FAILURE";
export const EXPORT_EXCEL_HISTORY_SUCCESS = "EXPORT_EXCEL_HISTORY_SUCCESS";

export const GET_EXPORT_EXCEL_DATA_REQUEST = "GET_EXPORT_EXCEL_DATA_REQUEST";
export const GET_EXPORT_EXCEL_DATA_SUCCESS = "GET_EXPORT_EXCEL_DATA_SUCCESS";
export const GET_EXPORT_EXCEL_DATA_FAILURE = "GET_EXPORT_EXCEL_DATA_FAILURE";

// export settings modal
export const GET_EXPORT_ITEMS_REQUEST = "GET_EXPORT_ITEMS_REQUEST";
export const GET_EXPORT_ITEMS_SUCCESS = "GET_EXPORT_ITEMS_SUCCESS";
export const GET_EXPORT_ITEMS_FAILURE = "GET_EXPORT_ITEMS_FAILURE";

export const GET_EXPORT_PROPERTY_DROPDOWN_REQUEST = "GET_EXPORT_PROPERTY_DROPDOWN_REQUEST";
export const GET_EXPORT_PROPERTY_DROPDOWN_SUCCESS = "GET_EXPORT_PROPERTY_DROPDOWN_SUCCESS";
export const GET_EXPORT_PROPERTY_DROPDOWN_FAILURE = "GET_EXPORT_PROPERTY_DROPDOWN_FAILURE";

export const ADD_EXPORT_ITEMS_REQUEST = "ADD_EXPORT_ITEMS_REQUEST";
export const ADD_EXPORT_ITEMS_SUCCESS = "ADD_EXPORT_ITEMS_SUCCESS";
export const ADD_EXPORT_ITEMS_FAILURE = "ADD_EXPORT_ITEMS_FAILURE";

export const GET_DYNAMIC_FIELDS_REQUEST = "GET_DYNAMIC_FIELDS_REQUEST";
export const GET_DYNAMIC_FIELDS_SUCCESS = "GET_DYNAMIC_FIELDS_SUCCESS";
export const GET_DYNAMIC_FIELDS_FAILURE = "GET_DYNAMIC_FIELDS_FAILURE";

export const CREATE_QAQC_ISSUE_REQUEST = "CREATE_QAQC_ISSUE_REQUEST";
export const CREATE_QAQC_ISSUE_SUCCESS = "CREATE_QAQC_ISSUE_SUCCESS";
export const CREATE_QAQC_ISSUE_FAILURE = "CREATE_QAQC_ISSUE_FAILURE";

export const GET_ALL_RECOMMENDATION_REVIEWS_REQUEST = "GET_ALL_RECOMMENDATION_REVIEWS_REQUEST";
export const GET_ALL_RECOMMENDATION_REVIEWS_SUCCESS = "GET_ALL_RECOMMENDATION_REVIEWS_SUCCESS";
export const GET_ALL_RECOMMENDATION_REVIEWS_FAILURE = "GET_ALL_RECOMMENDATION_REVIEWS_FAILURE";

export const UPDATE_RECOMMENDATION_REVIEW_REQUEST = "UPDATE_RECOMMENDATION_REVIEW_REQUEST";
export const UPDATE_RECOMMENDATION_REVIEW_SUCCESS = "UPDATE_RECOMMENDATION_REVIEW_SUCCESS";
export const UPDATE_RECOMMENDATION_REVIEW_FAILURE = "UPDATE_RECOMMENDATION_REVIEW_FAILURE";

export const UPDATE_REVIEW_STATUS_REVIEWER_REQUEST = "UPDATE_REVIEW_STATUS_REVIEWER_REQUEST";
export const UPDATE_REVIEW_STATUS_REVIEWER_SUCCESS = "UPDATE_REVIEW_STATUS_REVIEWER_SUCCESS";
export const UPDATE_REVIEW_STATUS_REVIEWER_FAILURE = "UPDATE_REVIEW_STATUS_REVIEWER_FAILURE";

export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_FAILURE = "DELETE_REVIEW_FAILURE";

export const SEND_ALL_NOTIFICATION_REQUEST = "SEND_ALL_NOTIFICATION_REQUEST";
export const SEND_ALL_NOTIFICATION_SUCCESS = "SEND_ALL_NOTIFICATION_SUCCESS";
export const SEND_ALL_NOTIFICATION_FAILURE = "SEND_ALL_NOTIFICATION_FAILURE";

export const SEND_REVIEW_NOTIFICATION_REQUEST = "SEND_REVIEW_NOTIFICATION_REQUEST";
export const SEND_REVIEW_NOTIFICATION_SUCCESS = "SEND_REVIEW_NOTIFICATION_SUCCESS";
export const SEND_REVIEW_NOTIFICATION_FAILURE = "SEND_REVIEW_NOTIFICATION_FAILURE";

export const QAQC_PASSED_UPDATE_REQUEST = "QAQC_PASSED_UPDATE_REQUEST";
export const QAQC_PASSED_UPDATE_SUCCESS = "QAQC_PASSED_UPDATE_SUCCESS";
export const QAQC_PASSED_UPDATE_FAILURE = "QAQC_PASSED_UPDATE_FAILURE";

export const GET_RECOMMENDATION_REVIEW_BY_ID_REQUEST = "GET_RECOMMENDATION_REVIEW_BY_ID_REQUEST";
export const GET_RECOMMENDATION_REVIEW_BY_ID_SUCCESS = "GET_RECOMMENDATION_REVIEW_BY_ID_SUCCESS";
export const GET_RECOMMENDATION_REVIEW_BY_ID_FAILURE = "GET_RECOMMENDATION_REVIEW_BY_ID_FAILURE";

export const ADD_REVIEW_COMMENT_REQUEST = "ADD_REVIEW_COMMENT_REQUEST";
export const ADD_REVIEW_COMMENT_SUCCESS = "ADD_REVIEW_COMMENT_SUCCESS";
export const ADD_REVIEW_COMMENT_FAILURE = "ADD_REVIEW_COMMENT_FAILURE";

export const CREATE_ASSET_RECOMMENDATION_REQUEST = "CREATE_ASSET_RECOMMENDATION_REQUEST";
export const CREATE_ASSET_RECOMMENDATION_SUCCESS = "CREATE_ASSET_RECOMMENDATION_SUCCESS";
export const CREATE_ASSET_RECOMMENDATION_FAILURE = "CREATE_ASSET_RECOMMENDATION_FAILURE";

export const RECOMMENDATION_BANDS = [{ key: 1, label: "Dynamic Fields" }];

export const SHIFT_CSP_YEAR_REQUEST = "SHIFT_CSP_YEAR_REQUEST";
export const SHIFT_CSP_YEAR_SUCCESS = "SHIFT_CSP_YEAR_SUCCESS";
export const SHIFT_CSP_YEAR_FAILURE = "SHIFT_CSP_YEAR_FAILURE";

export const GET_SOURCE_YEARS_REQUEST = "GET_SOURCE_YEARS_REQUEST";
export const GET_SOURCE_YEARS_SUCCESS = "GET_SOURCE_YEARS_SUCCESS";
export const GET_SOURCE_YEARS_FAILURE = "GET_SOURCE_YEARS_FAILURE";

export const CLEAR_ENTITY_PARAMS = "CLEAR_ENTITY_PARAMS";

export const NORMAL_RECOM_GROUPING_REQUEST = "NORMAL_RECOM_GROUPING_REQUEST";
export const NORMAL_RECOM_GROUPING_SUCCESS = "NORMAL_RECOM_GROUPING_SUCCESS";
export const NORMAL_RECOM_GROUPING_FAILURE = "NORMAL_RECOM_GROUPING_FAILURE";

export const GET_ALL_MOBILE_RECOMMENDATIONS_REQUEST = "GET_ALL_MOBILE_RECOMMENDATIONS_REQUEST";
export const GET_ALL_MOBILE_RECOMMENDATIONS_SUCCESS = "GET_ALL_MOBILE_RECOMMENDATIONS_SUCCESS";
export const GET_ALL_MOBILE_RECOMMENDATIONS_FAILURE = "GET_ALL_MOBILE_RECOMMENDATIONS_FAILURE";
