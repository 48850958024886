import * as actionType from "./constants";

const initialState = {
    entityParams: {
        entity: null,
        selectedEntity: null,
        selectedRowId: null,
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 0,
            search: "",
            filters: null,
            filterKeys: null,
            list: null,
            order: null
        },
        wildCardFilterParams: {},
        filterParams: {},
        tableConfig: null
    },
    dropDownList: {},
    dropDownLoading: {},
    addSurveyTemplateResponse: null,
    addSurveyTemplateResponseLoading: false,
    surveyTemplateList: { loading: true },
    deleteSurveyTemplateResponse: null,
    getSurveyTemplateById: null,
    getSurveyTemplateByIdLoading: false,
    updateSurveyTemplateResponse: null,
    updateSurveyTemplateResponseLoading: false,
    getListForCommonFilterResponse: {},
    getDefaultSurveyTemplate: null,
    getDefaultSurveyTemplateLoading: false,
    getSurveyTemplateExport: null,
    getSurveyTemplateExportLoading: false
};

const reducers = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_SURVEY_TEMPLATE_REQUEST:
            return {
                ...state,
                surveyTemplateList: { loading: true }
            };
        case actionType.GET_SURVEY_TEMPLATE_SUCCESS:
            return {
                ...state,
                surveyTemplateList: { loading: false, success: true, ...action.response }
            };
        case actionType.GET_SURVEY_TEMPLATE_FAILURE:
            return {
                ...state,
                surveyTemplateList: { loading: false, success: false, ...action.error }
            };

        case actionType.ADD_SURVEY_TEMPLATE_DATA_REQUEST:
            return {
                ...state,
                addSurveyTemplateResponse: null,
                addSurveyTemplateResponseLoading: true
            };
        case actionType.ADD_SURVEY_TEMPLATE_DATA_SUCCESS:
            return {
                ...state,
                addSurveyTemplateResponse: action.response,
                addSurveyTemplateResponseLoading: false
            };
        case actionType.ADD_SURVEY_TEMPLATE_DATA_FAILURE:
            return {
                ...state,
                addSurveyTemplateResponse: null,
                addSurveyTemplateResponseLoading: false
            };

        case actionType.UPDATE_SURVEY_TEMPLATE_DATA_REQUEST:
            return {
                ...state,
                updateSurveyTemplateResponse: null,
                updateSurveyTemplateResponseLoading: true
            };
        case actionType.UPDATE_SURVEY_TEMPLATE_DATA_SUCCESS:
            return {
                ...state,
                updateSurveyTemplateResponse: action.response,
                updateSurveyTemplateResponseLoading: false
            };
        case actionType.UPDATE_SURVEY_TEMPLATE_DATA_FAILURE:
            return {
                ...state,
                updateSurveyTemplateResponse: null,
                updateSurveyTemplateResponseLoading: false
            };

        case actionType.UPDATE_SURVEY_TEMPLATE_ENTITY_PARAMS_SUCCESS:
            return {
                ...state,
                entityParams: { ...action.response }
            };
        case actionType.UPDATE_SURVEY_TEMPLATE_ENTITY_PARAMS_FAILURE:
            return {
                ...state,
                entityParams: { ...action.error }
            };

        case actionType.DELETE_SURVEY_TEMPLATE_REQUEST:
            return {
                ...state
            };
        case actionType.DELETE_SURVEY_TEMPLATE_SUCCESS:
            return {
                ...state,
                deleteSurveyTemplateResponse: { success: true, ...action.response }
            };
        case actionType.DELETE_SURVEY_TEMPLATE_FAILURE:
            return {
                ...state,
                deleteSurveyTemplateResponse: { success: false, ...action.error }
            };

        case actionType.GET_SURVEY_TEMPLATE_BY_ID_REQUEST:
            return {
                ...state,
                getSurveyTemplateByIdLoading: true
            };
        case actionType.GET_SURVEY_TEMPLATE_BY_ID_SUCCESS:
            return {
                ...state,
                getSurveyTemplateById: { success: true, data: { ...action.response } },
                getSurveyTemplateByIdLoading: false
            };
        case actionType.GET_SURVEY_TEMPLATE_BY_ID_FAILURE:
            return {
                ...state,
                getSurveyTemplateById: { success: false, ...action.error },
                getSurveyTemplateByIdLoading: false
            };

        case actionType.GET_DEFAULT_SURVEY_TEMPLATE_REQUEST:
            return {
                ...state,
                getDefaultSurveyTemplateLoading: true
            };
        case actionType.GET_DEFAULT_SURVEY_TEMPLATE_SUCCESS:
            return {
                ...state,
                getDefaultSurveyTemplate: { success: true, ...action.response },
                getDefaultSurveyTemplateLoading: false
            };
        case actionType.GET_DEFAULT_SURVEY_TEMPLATE_FAILURE:
            return {
                ...state,
                getDefaultSurveyTemplate: { success: false, ...action.error },
                getDefaultSurveyTemplateLoading: false
            };

        case actionType.RESET_TEMPLATE_REDUCER:
            return {
                ...state,
                getSurveyTemplateById: null,
                dropDownList: {}
            };

        case actionType.GET_SURVEY_TEMPLATE_EXPORT_REQUEST:
            return {
                ...state,
                getSurveyTemplateExportLoading: true
            };
        case actionType.GET_SURVEY_TEMPLATE_EXPORT_SUCCESS:
            return {
                ...state,
                getSurveyTemplateExport: { success: true, ...action.response },
                getSurveyTemplateExportLoading: false
            };
        case actionType.GET_SURVEY_TEMPLATE_EXPORT_FAILURE:
            return {
                ...state,
                getSurveyTemplateExport: { success: false, ...action.error },
                getSurveyTemplateExportLoading: false
            };

        default:
            return state;
    }
};

export default reducers;
