import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { DYNAMIC_FIELD_TYPES } from "../../../common/constants";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { editorConfiguration } from "../../../../utils/ckEditorUtils";
import Portal from "../../../common/components/Portal";
import { RichEditorPopup } from "../../../common/components/formFields/RichEditorPopup";
import SelectBox from "../../../images/components/SelectBox";

export const DynamicBand = ({
    bandData,
    bandShown,
    handleBandClick,
    handleChangeData,
    showErrorBorder,
    multiselectClass = null,
    isFieldLocked,
    isRecommendation = false,
    isAsset = false,
    isBuilding = false
}) => {
    const { recommendation_custom_fields = {} } = useSelector(state => state.recommendationsReducer?.dynamicFields) || {};
    const { asset_custom_field = {} } = useSelector(state => state.assetReducer?.assetDynamicFields) || {};
    const { building_custom_field = {} } = useSelector(state => state.buildingReducer?.buildingDynamicFields) || {};

    let bands = {};
    if (isAsset) bands = asset_custom_field;
    else if (isBuilding) bands = building_custom_field;
    else bands = recommendation_custom_fields;

    const [showRichTextPopup, setShowRichTextPopup] = useState({ value: "", label: "", key: "", onSaveData: () => {}, show: false });
    const getBandId = band => {
        return band.split(" ").join("_");
    };

    const toggleShowRichTextPopup = value => setShowRichTextPopup(value);

    const renderRichTextPopup = () => {
        const { label, key, onSaveData, show, value } = showRichTextPopup;
        if (!show) return null;
        return (
            <Portal
                body={
                    <RichEditorPopup
                        value={value}
                        label={label}
                        key={key}
                        onCancel={() => toggleShowRichTextPopup({ ...showRichTextPopup, show: false })}
                        saveData={onSaveData}
                    />
                }
                onCancel={() => toggleShowRichTextPopup({ ...showRichTextPopup, show: false })}
            />
        );
    };

    const sliceFields = fields => (isBuilding ? fields : fields.slice(3));

    return (
        <>
            {renderRichTextPopup()}
            {Object.entries(bands)?.map(([band, fields]) => (
                <div className={`card ${isBuilding ? "band-building-sep" : ""}`} key={band}>
                    <div className="card-header" id={`heading-${getBandId(band)}`}>
                        <div className="otr-recom-div">
                            <button
                                className="btn btn-link"
                                data-toggle="collapse"
                                data-target={`#${getBandId(band)}`}
                                aria-expanded="false"
                                onClick={e => {
                                    e.preventDefault();
                                    handleBandClick(getBandId(band), false);
                                }}
                            >
                                {band}
                            </button>
                            {!isBuilding &&
                                fields
                                    .slice(0, 3)
                                    .map(elem => (
                                        <Band
                                            bandShown={bandShown?.[getBandId(band)]}
                                            bandId={getBandId(band)}
                                            fieldType={elem.field_type}
                                            onChange={(key, value) => handleChangeData({ dynamic_fields: { ...bandData, [key]: value } })}
                                            value={bandData?.[elem?.field_name] || ""}
                                            fieldKey={elem.field_name}
                                            label={elem.label}
                                            handleBandClick={() => handleBandClick(getBandId(band), true)}
                                            fieldItem={elem}
                                            showErrorBorder={showErrorBorder}
                                            multiselectClass={multiselectClass}
                                            isFieldLocked={isFieldLocked}
                                            showRichTextPopup={showRichTextPopup}
                                            toggleShowRichTextPopup={toggleShowRichTextPopup}
                                            isRecommendation={isRecommendation}
                                        />
                                    ))}
                        </div>
                    </div>

                    <div
                        id={getBandId(band)}
                        className={bandShown?.[getBandId(band)] || isBuilding ? "collapse show" : "collapse"}
                        aria-labelledby={`heading-${getBandId(band)}`}
                    >
                        <div className="card-body">
                            <div className={`outer-rcm mt-1 ${isBuilding ? "d-flex" : ""}`}>
                                {sliceFields(fields).map(elem => (
                                    <Band
                                        fieldType={elem.field_type}
                                        onChange={(key, value) => handleChangeData({ dynamic_fields: { ...bandData, [key]: value } })}
                                        value={bandData?.[elem?.field_name] || ""}
                                        fieldKey={elem.field_name}
                                        label={elem.label}
                                        handleBandClick={() => handleBandClick(getBandId(band), true)}
                                        fieldItem={elem}
                                        showErrorBorder={showErrorBorder}
                                        multiselectClass={multiselectClass}
                                        isFieldLocked={isFieldLocked}
                                        isBuilding={isBuilding}
                                        toggleShowRichTextPopup={toggleShowRichTextPopup}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

const Band = ({
    fieldType = "text",
    label,
    fieldKey,
    value,
    onChange,
    bandShown,
    bandId,
    handleBandClick,
    fieldItem,
    showErrorBorder,
    multiselectClass,
    isFieldLocked = undefined,
    showRichTextPopup = false,
    isRecommendation = false,
    toggleShowRichTextPopup,
    isBuilding = false
}) => {
    const {
        mandatory = false,
        prefix = "",
        suffix = "",
        thousand_separator = false,
        read_only = false,
        decimal_scale = 0,
        allow_negative = true
    } = fieldItem?.rules || {};
    const isError = showErrorBorder && mandatory && (!value || (value && !value?.toString()?.trim()?.length));
    const isLocked = isFieldLocked && isFieldLocked(fieldKey);

    return (
        <div className={`txt-rcm ${fieldItem.className ? fieldItem.className : ""} ${isBuilding ? "col-md-4 pl-0 " : ""}`} key={fieldKey}>
            <div className={`content-inp-card ${isBuilding && fieldType === DYNAMIC_FIELD_TYPES.SELECT ? "col-md-12 p-0" : ""}`}>
                <div
                    className={`form-group ${fieldType === DYNAMIC_FIELD_TYPES.MEMO ? "ckeditor-band recom-ck-band " : ""}`}
                    data-target={bandShown ? `#${bandId}` : "false"}
                    aria-expanded={bandShown ? true : "false"}
                    onClick={() => handleBandClick()}
                    data-tip={isLocked ? "This field is locked and cannot be edited" : ""}
                    data-for="recommendation_details"
                >
                    <label>
                        {`${label} ${mandatory ? "*" : ""}`}
                        {fieldType === DYNAMIC_FIELD_TYPES.MEMO && (
                            <button
                                class="butn-expansion"
                                data-for="input_field"
                                data-tip="Expand Field"
                                onClick={e => {
                                    e.preventDefault();
                                    toggleShowRichTextPopup({
                                        value: value,
                                        label: label,
                                        key: fieldKey,
                                        onSaveData: value => {
                                            onChange(fieldKey, value);
                                            toggleShowRichTextPopup({ ...showRichTextPopup, value, show: false });
                                        },
                                        show: true
                                    });
                                }}
                            >
                                <img src="/img/expand1.svg" alt="" />
                            </button>
                        )}
                    </label>
                    {fieldType === DYNAMIC_FIELD_TYPES.TEXT ? (
                        <input
                            type="text"
                            autoComplete={"off"}
                            className={`custom-input form-control ${isError ? "error-border" : ""} ${
                                multiselectClass ? multiselectClass(fieldKey, null, "dynamic_field") : ""
                            } ${isLocked ? "grayed-out" : ""}`}
                            placeholder={label}
                            value={value}
                            disabled={read_only || isLocked}
                            name={fieldKey}
                            onChange={e => onChange(e.target.name, e.target.value)}
                        />
                    ) : fieldType === DYNAMIC_FIELD_TYPES.MEMO ? (
                        <div
                            className={`form-control ${read_only ? "cursor-diabled" : ""} ${isError ? "error-border" : ""} ${
                                multiselectClass ? multiselectClass(fieldKey, null, "dynamic_field") : ""
                            } ${isLocked ? "grayed-out" : ""}`}
                        >
                            <CKEditor
                                editor={Editor}
                                config={{ ...editorConfiguration, toolbar: [] }}
                                id="report-note-template"
                                data={value ?? ""}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    onChange(fieldKey, data);
                                }}
                                onReady={editor => {
                                    if (editor) {
                                        editor.setData(value || "");
                                    }
                                }}
                            />
                        </div>
                    ) : fieldType === DYNAMIC_FIELD_TYPES.SELECT ? (
                        <div
                            className={`${multiselectClass ? multiselectClass(fieldKey, null, "dynamic_field") : ""} ${
                                isLocked ? "grayed-out" : ""
                            } sel-recom-react`}
                        >
                            <SelectBox
                                className={`${isError ? "error-border" : ""}`}
                                value={value}
                                disabled={read_only || isLocked}
                                handleChange={value => onChange(fieldKey, value)}
                                optionsList={fieldItem?.field_options ?? []}
                                isClearable={!isRecommendation}
                                showErrorBorder={isError}
                                hasEmptySelect
                                isRecommendation
                                isDynamicField
                            />
                        </div>
                    ) : fieldType === DYNAMIC_FIELD_TYPES.BOOLEAN ? (
                        <div
                            className={`${multiselectClass ? multiselectClass(fieldKey, null, "dynamic_field") : ""} ${
                                isLocked ? "grayed-out" : ""
                            } sel-recom-react`}
                        >
                            <SelectBox
                                className={`${isError ? "error-border" : ""}`}
                                value={value}
                                disabled={read_only || isLocked}
                                handleChange={value => onChange(fieldKey, value)}
                                optionsList={["Yes", "No"].map(e => ({ label: e, value: e }))}
                                isClearable={!isRecommendation}
                                showErrorBorder={isError}
                                hasEmptySelect
                                isRecommendation
                                isDynamicField
                            />
                        </div>
                    ) : fieldType === DYNAMIC_FIELD_TYPES.NUMBER ? (
                        <NumberFormat
                            autoComplete={"off"}
                            className={`form-control ${read_only ? "cursor-diabled" : ""} ${isError ? "error-border" : ""} ${
                                multiselectClass ? multiselectClass(fieldKey, null, "dynamic_field") : ""
                            } ${isLocked ? "grayed-out" : ""}`}
                            placeholder={label}
                            thousandSeparator={thousand_separator}
                            prefix={prefix}
                            suffix={suffix}
                            decimalScale={decimal_scale}
                            value={value || ""}
                            disabled={read_only || isLocked}
                            displayType={"input"}
                            allowNegative={allow_negative}
                            onValueChange={values => {
                                const { floatValue } = values;
                                onChange(fieldKey, floatValue);
                            }}
                        />
                    ) : fieldType === DYNAMIC_FIELD_TYPES.CURRENCY ? (
                        <NumberFormat
                            autoComplete={"off"}
                            className={`form-control ${read_only ? "cursor-diabled" : ""} ${isError ? "error-border" : ""} ${
                                multiselectClass ? multiselectClass(fieldKey, null, "dynamic_field") : ""
                            } ${isLocked ? "grayed-out" : ""}`}
                            placeholder={label}
                            thousandSeparator={true}
                            prefix={"$ "}
                            decimalScale={decimal_scale}
                            allowNegative={false}
                            suffix={suffix}
                            value={value || ""}
                            disabled={read_only || isLocked}
                            displayType={"input"}
                            onValueChange={values => {
                                const { floatValue } = values;
                                onChange(fieldKey, floatValue);
                            }}
                        />
                    ) : fieldType === DYNAMIC_FIELD_TYPES.DATE ? (
                        <ReactDatePicker
                            autoComplete={"nope"}
                            placeholderText={label}
                            className={`${isError ? "error-border" : ""} form-control custom-wid ${
                                multiselectClass ? multiselectClass(fieldKey, null, "dynamic_field") : ""
                            }  ${isLocked ? "grayed-out" : ""}`}
                            selected={value ? new Date(value) : ""}
                            disabled={read_only || isLocked}
                            onChange={date => {
                                const formatted = date ? moment(date).format("YYYY-MM-DD") : "";
                                onChange(fieldKey, formatted);
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};
