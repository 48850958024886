import moment from "moment";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { DYNAMIC_FIELD_TYPES } from "../../../common/constants";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { editorConfiguration } from "../../../../utils/ckEditorUtils";
import Portal from "../../../common/components/Portal";
import { RichEditorPopup } from "../../../common/components/formFields/RichEditorPopup";

export const Field = ({ elem, data, isDynamic }) => {
    const value = data[elem?.key];
    return (
        <div class="codeOtr">
            <h4>{elem.label}</h4>
            <h3>
                {elem.type === "number" ? (
                    <NumberFormat value={value || 0} thousandSeparator={true} displayType={"text"} prefix={elem.isCost ? "$ " : ""} />
                ) : (
                    <>{value || "-"}</>
                )}
            </h3>
        </div>
    );
};

export const DynamicField = ({ elem, data, flat_value = "" }) => {
    const [showRichTextPopup, setShowRichTextPopup] = useState({ label: "", key: "", show: false });
    const value = flat_value || data?.[elem?.field_name]?.value;
    const { prefix = "", suffix = "", thousand_separator = false, decimal_scale = 0 } = elem?.rules;

    const renderRichTextPopup = () => {
        const { label, key, show } = showRichTextPopup;
        if (!show) return null;
        return (
            <Portal
                body={
                    <RichEditorPopup
                        value={value}
                        label={label}
                        key={key}
                        onCancel={() => setShowRichTextPopup({ ...showRichTextPopup, show: false })}
                        isReadOnly
                    />
                }
                onCancel={() => setShowRichTextPopup({ ...showRichTextPopup, show: false })}
            />
        );
    };

    return (
        <div class={`codeOtr ${elem.field_type === DYNAMIC_FIELD_TYPES.MEMO ? "ck-editor-view" : ""}`}>
            {renderRichTextPopup()}
            <h4 className={elem.field_type === DYNAMIC_FIELD_TYPES.MEMO ? "ckeditor-band" : ""}>
                {elem.label}
                {elem.field_type === DYNAMIC_FIELD_TYPES.MEMO && (
                    <button
                        class="butn-expansion"
                        data-for="input_field"
                        data-tip="Expand Field"
                        onClick={() => setShowRichTextPopup({ ...showRichTextPopup, show: true, label: elem.label, key: elem.label })}
                    >
                        <img src="/img/expand1.svg" alt="" />
                    </button>
                )}
            </h4>
            <h3>
                {elem.field_type === DYNAMIC_FIELD_TYPES.NUMBER ? (
                    <NumberFormat
                        value={value || 0}
                        decimalScale={decimal_scale}
                        thousandSeparator={thousand_separator}
                        displayType={"text"}
                        prefix={prefix}
                        suffix={suffix}
                    />
                ) : elem.field_type === DYNAMIC_FIELD_TYPES.MEMO ? (
                    <CKEditor
                        editor={Editor}
                        config={{ ...editorConfiguration, toolbar: [] }}
                        id="report-note-template"
                        data={value ?? ""}
                        onReady={editor => {
                            editor?.enableReadOnlyMode("my-feature-id");
                        }}
                        // isReadOnly={true}
                    />
                ) : elem.field_type === DYNAMIC_FIELD_TYPES.CURRENCY ? (
                    <NumberFormat value={value || 0} decimalScale={decimal_scale} thousandSeparator={true} displayType={"text"} prefix={"$ "} />
                ) : elem.field_type === DYNAMIC_FIELD_TYPES.DATE ? (
                    <>{value ? moment(value).format("MM-DD-YYYY") : ""}</>
                ) : (
                    <>{value || "-"}</>
                )}
            </h3>
        </div>
    );
};

export const Band = ({ bandName = "", data = {}, setToolTip, fields = [], bandId = "", handleBandClick, isDynamic, isBandOpen = false }) => {
    return (
        <div class="card">
            <div class="card-header" id={`heading-${bandId}`}>
                <div className="otr-recom-div">
                    <button
                        class="btn btn-link"
                        data-toggle="collapse"
                        onClick={handleBandClick}
                        data-target={`#${bandId}`}
                        aria-expanded={isBandOpen ? "true" : "false"}
                        // aria-controls="collapseOne"
                    >
                        {bandName}
                    </button>
                    {fields.slice(0, 3).map(elem => (
                        <div class="col-md-3 basic-box">
                            {isDynamic ? <DynamicField elem={elem} data={data} /> : <Field elem={elem} data={data} />}
                        </div>
                    ))}
                </div>
            </div>

            <div id={bandId} class={isBandOpen ? "collapse show" : "collapse"} aria-labelledby={`heading-${bandId}`}>
                <div class="card-body">
                    <div class="outer-rcm mt-1  basic-dtl-otr p-0">
                        {fields.slice(3).map(elem => (
                            <div className="col-md-3 basic-box">
                                {isDynamic ? <DynamicField elem={elem} data={data} /> : <Field elem={elem} data={data} />}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
