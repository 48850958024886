import DocIcon from "../../assets/img/document.svg";
import ImageIcon from "../../assets/img/image.svg";

export const MASTER_FILTER_KEYS = {
    project: [
        { label: "Projects", key: "projects", paramKey: "project_ids" },
        { label: "Region", key: "regions", paramKey: "region_ids" },
        { label: "Site", key: "sites", paramKey: "site_ids" },
        { label: "B.Types", key: "building_types", paramKey: "building_types" },
        { label: "Buildings", key: "buildings", paramKey: "building_ids" },
        { label: "FMP", key: "fmp", paramKey: "fmp" },
        { label: "IR", key: "infrastructure_requests", paramKey: "infrastructure_requests" },
        { label: "Additions", key: "additions", paramKey: "addition_ids" },
        { label: "FCI", key: "fci", paramKey: "color_scale" },
        { label: "Responsibility", key: "responsibility", paramKey: "responsibility_ids" },
        { label: "Trade (For Trade, System & Sub-System)", key: "trades", paramKey: "trade_ids" },
        { label: "System (For System & Sub-System)", key: "system", paramKey: "system_ids" },
        { label: "Sub-System (For Sub-System)", key: "subsystem", paramKey: "subsystem_ids" },
        { label: "Primary Uses", key: "building_use", paramKey: "primary_use" },
        { label: "Secondary Uses", key: "secondary_use", paramKey: "secondary_use" },
        { label: "Sectors", key: "sectors", paramKey: "sectors" },
        { label: "Internal Groups", key: "internal_groups", paramKey: "internal_groups" },
        { label: "Divisions", key: "division", paramKey: "division" }
    ],
    energy_mng: [
        { label: "Region", key: "energy_mng_regions", paramKey: "region_ids" },
        { label: "Site", key: "energy_mng_sites", paramKey: "site_ids" },
        { label: "B.Types", key: "energy_mng_building_types", paramKey: "building_types" },
        { label: "Buildings", key: "energy_mng_buildings", paramKey: "building_ids" },
        { label: "Years", key: "energy_mng_years", paramKey: "years" }
    ],
    assets: [
        { label: "Region", key: "asset_regions", paramKey: "region_ids" },
        { label: "Site", key: "asset_sites", paramKey: "site_ids" },
        { label: "Buildings", key: "asset_buildings", paramKey: "building_ids" }
    ],
    smart_report_list_filter: [
        { label: "Clients", key: "clients", paramKey: "client_ids" },
        { label: "Projects", key: "projects", paramKey: "project_ids" }
    ],
    smart_report_properties_list_filter: [{ label: "Clients", key: "clients", paramKey: "client_ids" }],
    documents: [{ label: "Clients", key: "clients", paramKey: "client_ids" }]
};

export const MASTER_FILTER_ROWS = {
    project: [1, 2, 3, 4],
    energy_mng: [1],
    assets: [1]
};

export const CHART_ITEMS = {
    project: {
        regions: {
            hasConfig: true,
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                remove_tbl_section_break: false
            }
        },
        sites: {
            hasConfig: true,
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                remove_tbl_section_break: false
            }
        },
        buildings: {
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true
            },
            hasConfig: true,
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                remove_tbl_section_break: false
            }
        },
        additions: {
            hasConfig: true,
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                remove_tbl_section_break: false
            }
        },
        sorted_recom: {
            hasConfig: true,
            isVisible: true,
            defaultValue: {
                band1: {
                    mfilter: {},
                    type: ["building"]
                }
            }
        },
        criticality: {
            hasConfig: true,
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                remove_tbl_section_break: false
            }
        },
        categories: {
            hasConfig: true,
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                remove_tbl_section_break: false
            }
        },
        funding_sources: {
            hasConfig: true,
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                remove_tbl_section_break: false
            }
        },
        priorities: {
            hasConfig: true,
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                remove_tbl_section_break: false
            }
        },
        trades: {
            hasConfig: true,
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                remove_tbl_section_break: false
            }
        },
        system: {
            hasConfig: true,
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true,
                normal_recom: true
            },
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                normal_recom: { band1: { mfilter: {}, remove_section_break: false }, name: "Recommendations", config_filter: {} },
                combination: false,
                remove_tbl_section_break: false
            }
        },
        subsystem: {
            hasConfig: true,
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                remove_tbl_section_break: false
            }
        },
        responsibilities: {
            hasConfig: true,
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                remove_tbl_section_break: false
            }
        },
        capital_type: {
            hasConfig: true,
            config: {
                table_view: true,
                summary_view: true,
                detailed_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: {
                    chart_type: ["pie_3d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                detailed_view: {
                    chart_type: ["stacked_column_2d"],
                    remove_section_break: false,
                    config: {
                        legend: true,
                        header: true,
                        total: true
                    }
                },
                remove_tbl_section_break: false
            }
        },
        EFCI: {
            hasConfig: true,
            config: {
                detailed_view: true
            },
            isVisible: true,
            defaultValue: {
                detailed_view: {
                    chart_type: ["line_column_2d"]
                }
            }
        },
        energy_band: {
            hasConfig: false,
            isVisible: true,
            defaultValue: ""
        },
        water_band: {
            hasConfig: false,
            isVisible: true,
            defaultValue: ""
        },
        asset_band: {
            hasConfig: true,
            isVisible: true,
            defaultValue: { table_view: "" }
        },
        bbi_report1: {
            hasConfig: true,
            isVisible: true,
            defaultValue: { band1: { mfilter: {}, name: "BBI" } }
        },
        bsi_report1: {
            hasConfig: false,
            isVisible: true,
            defaultValue: { band1: { mfilter: {}, name: "BSI" } }
        },
        filter_report1: {
            hasConfig: false,
            isVisible: true,
            name: "Master Filters",
            band1: {
                mfilter: {}
            }
        }
    },
    assets: {
        asset_band: {
            hasConfig: true,
            isVisible: true,
            defaultValue: { table_view: "" }
        },
        end_servicelife_year_condition: {
            hasConfig: false,
            // config: {
            //     table_view: true,
            //     summary_view: true,
            //     detailed_view: true
            // },
            isVisible: true,
            defaultValue: { table_view: "", detailed_view: { chart_type: ["stacked_column_2d", "stacked_column_3d"] } }
        },
        asset_age_by_condition: {
            hasConfig: false,
            isVisible: true,
            defaultValue: { table_view: "", detailed_view: { chart_type: ["stacked_column_2d", "stacked_column_3d"] } }
        },
        asset_capital_spending_plan: {
            hasConfig: false,
            isVisible: true,
            defaultValue: {
                table_view: "",
                summary_view: { chart_type: ["pie_2d", "pie_3d"] },
                detailed_view: { chart_type: ["stacked_column_2d", "stacked_column_3d"] }
            }
        },
        asset_system_facility: {
            hasConfig: false,
            isVisible: true,
            defaultValue: { table_view: "", detailed_view: { chart_type: ["stacked_column_2d", "stacked_column_3d"] } }
        }
    },
    energy_mng: {
        em_eui_building_breakdown: {
            hasConfig: true,
            config: {
                // summary_view: true,
                detailed_view: true
                // table_view: true
            },
            isVisible: true,
            defaultValue: { detailed_view: { chart_type: ["stacked_column_2d"] } }
        },
        bld_brkdown_energy_usage: {
            hasConfig: true,
            config: {
                summary_view: true
                // detailed_view: true,
                // table_view: true
            },
            isVisible: true,
            defaultValue: { summary_view: { chart_type: ["pie_2d"] } }
        },
        em_eui_site_brkdown_energy_use: {
            hasConfig: true,
            config: {
                // summary_view: true,
                detailed_view: true
                // table_view: true
            },
            isVisible: true,
            defaultValue: { detailed_view: { chart_type: ["stacked_column_2d"] } }
        },
        em_eui_energy_usage: {
            hasConfig: true,
            config: {
                summary_view: true
                // detailed_view: true,
                // table_view: true
            },
            isVisible: true,
            defaultValue: { summary_view: { chart_type: ["pie_2d"] } }
        },
        bld_brkdown_energy_cost: { hasConfig: true, isVisible: true, defaultValue: { summary_view: { chart_type: ["pie_2d"] } } },
        em_eci_site_cost: { hasConfig: true, isVisible: true, defaultValue: { detailed_view: { chart_type: ["stacked_column_2d"] } } },
        em_eci_energy_cost: { hasConfig: true, isVisible: true, defaultValue: { summary_view: { chart_type: ["pie_2d", "pie_3d"] } } },
        energy_band: {
            hasConfig: false,
            isVisible: true,
            defaultValue: ""
        },
        water_band: {
            hasConfig: false,
            isVisible: true,
            defaultValue: ""
        }
    }
};

export const CHART_DATA_VIEWS = ["summary_view", "detailed_view", "table_view", "normal_recom"];
export const SMART_CHART_TAB_VIEWS = [
    { key: "reporttemplates", label: "Report Templates", url: "/smartcharts/reporttemplates" },
    { key: "reports", label: "Reports", url: "/smartcharts/reports" },
    { key: "corereports", label: "Core Reports", url: "/smartcharts/corereports" },
    { key: "documents", label: "User Documents", url: "/smartcharts/documents", isButtonTab: true, icon: DocIcon, class: "btn-doc mr-2" },
    { key: "images", label: "Images", url: "/smartcharts/images", isButtonTab: true, icon: ImageIcon, class: "btn-img-btn" }
];
export const SMART_CHART_REPORT_VIEW = [
    { key: "reporttemplates", label: "Report Templates", url: "/smartcharts/reporttemplates" },
    { key: "corereports", label: "Core Reports", url: "/smartcharts/corereports" }
];

export const SMART_CHART_FAVORITE_TAB_VIEWS = [
    { key: "reporttemplates", label: "Report Templates", url: "/smartcharts/reporttemplates" },
    { key: "reports", label: "Reports", url: "/smartcharts/reports" },
    { key: "corereports", label: "Core Reports", url: "/smartcharts/corereports" }
];

export const DOC_AND_IMAGE_BANDS = [
    { key: "documents", label: "User Documents", dragDropKey: "USERDOCS" }
    // { key: "images", label: "Images", dragDropKey: "USERIMAGES" }
];

export const INDV_BUILDING_EXPORT_ENTITIES = [
    "buildings",
    "categories",
    "capital_type",
    "criticality",
    "priorities",
    "additions",
    "funding_sources",
    "trades",
    "system",
    "EFCI",
    "sorted_recom"
];

export const CA_RECOMMENDATION_ELEMENTS = [
    {
        key: "trade",
        label: "Trade",
        paramKey: "trade_ids",
        hasFilter: true
    },
    {
        key: "system",
        label: "System",
        paramKey: "system_ids",
        breakdowns: [{ label: "Trade", key: "trade" }],
        hasFilter: true
    },
    {
        key: "subsystem",
        label: "Sub-System",
        paramKey: "subsystem_ids",
        breakdowns: [
            { label: "Trade", key: "trade" },
            { label: "System", key: "system" }
        ],
        hasFilter: true
    },
    {
        key: "category",
        label: "Category",
        paramKey: "category_ids",
        hasFilter: true
    },
    {
        key: "fundingsrc",
        label: "Funding Source",
        paramKey: "fundingsrc_ids",
        hasFilter: true
    },
    {
        key: "criticality",
        label: "Criticality",
        paramKey: "criticality_ids",
        hasFilter: true
    },
    {
        key: "capital",
        label: "Capital Type",
        paramKey: "capital_ids",
        hasFilter: true
    },
    {
        key: "term",
        label: "Term",
        paramKey: "term_ids",
        hasFilter: true
    },
    {
        key: "recommendations",
        label: "Recommendation",
        paramKey: "",
        columnOnly: true,
        haasFilter: false,
        hasColumnSelect: true
    },
    {
        key: "asset",
        label: "Asset",
        paramKey: "",
        columnOnly: true,
        hasFilter: false,
        hasColumnSelect: true
    },
    {
        key: "fciefci",
        label: "FCI & EFCI",
        paramKey: null,
        hasFilter: false
    },
    {
        key: "fci_benchmarking",
        label: "FCI Benchmarking",
        paramKey: null,
        hasFilter: false
    }
];

export const CA_GEO_HIERARCHY_ELEMENTS = [
    {
        key: "addition",
        label: "Addition",
        breakdowns: [
            { label: "Region", key: "region" },
            { label: "Site", key: "site" },
            { label: "Building", key: "building" }
        ]
    },
    {
        key: "building",
        label: "Building",
        breakdowns: [
            { label: "Region", key: "region" },
            { label: "Site", key: "site" }
        ]
    },
    {
        key: "site",
        label: "Site",
        breakdowns: [{ label: "Region", key: "region" }]
    },
    { key: "region", label: "Region" },
    { key: "project", label: "Project" }
];

export const IMAGE_BAND_CONFIG_TYPES = [
    { key: 1, label: "Single Image Band", icon: "/img/squre-img.png" },
    { key: 2, label: "Single Image Band", icon: "/img/rect-img.png" },
    { key: 3, label: "Double Image Band", icon: "/img/doub-img.png" }
];

export const DOCUMENT_SORT_KEYS = {
    documents: [
        { label: "Created At", key: "created_at" },
        { label: "Name", key: "name" },
        { label: "File Name", key: "file_name" }
    ],
    images: [
        { label: "Created At", key: "created_at" },
        { label: "Caption", key: "caption" },
        { label: "File Name", key: "file_name" }
    ],
    reports: [
        { label: "Exported At", key: "created_date" },
        { label: "Exported By", key: "exported_by" },
        { label: "Name", key: "name" },
        { label: "Favorite", key: "favourite" }
    ],
    reporttemplates: [
        { label: "Created At", key: "created_date" },
        { label: "Modified At", key: "modified_date" },
        { label: "Locked", key: "is_locked" },
        { label: "Name", key: "name" },
        { label: "Created By", key: "created_by" }
    ],
    corereports: [
        { label: "Created At", key: "created_date" },
        { label: "Modified At", key: "modified_date" },
        { label: "Name", key: "name" },
        { label: "Created By", key: "created_by" }
    ],
    queries: [
        { label: "Created At", key: "created_date" },
        { label: "Name", key: "name" },
        { label: "Created By", key: "created_by" }
    ]
};

export const ADVANCED_FILTER_KEYS = {
    documents: {
        label: "Document Type",
        options: [
            { label: "All", key: "ALL" },
            { label: "Global User Documents", key: "GLB" },
            { label: "Client User Documents", key: "CLIENT" }
        ]
    },
    reports: {
        label: "Favorites",
        options: [
            { label: "All", key: "" },
            { label: "Favorites", key: "yes" },
            { label: "Non Favorites", key: "no" }
        ]
    },
    reporttemplates: {
        label: "Lock Status",
        options: [
            { label: "All", key: "" },
            { label: "Locked", key: true },
            { label: "Unlocked", key: false }
        ]
    }
};
export const DASHBOARD_CA_RECOMMENDATION_ELEMENTS = [
    {
        key: "trade",
        label: "trades",
        paramKey: "trade_ids"
    },
    {
        key: "region",
        label: "regions",
        paramKey: "region_ids"
    },
    {
        key: "category",
        label: "categories",
        paramKey: "category_ids",
        hasFilter: true
    },
    {
        key: "fundingsrc",
        label: "funding_sources",
        paramKey: "fundingsrc_ids",
        hasFilter: true
    },
    {
        key: "criticality",
        label: "criticality",
        paramKey: "criticality_ids",
        hasFilter: true
    },
    {
        key: "capital",
        label: "capital_type",
        paramKey: "capital_ids",
        hasFilter: true
    },
    {
        key: "term",
        label: "priorities",
        paramKey: "term_ids",
        hasFilter: true
    },
    {
        key: "responsibilities",
        label: "responsibilities",
        paramKey: "",
        columnOnly: true,
        haasFilter: false,
        hasColumnSelect: true
    },
    {
        key: "project",
        label: "projects"
    },
    {
        key: "site",
        label: "sites"
    },
    {
        key: "building",
        label: "buildings"
    }
];
export const DASHBOARD_CATEGORYCHART = [
    {
        key: "trade",
        label: "trades"
    },
    {
        key: "regions",
        label: "regions"
    },
    {
        key: "categories",
        label: "categories"
    },
    {
        key: "funding_sources",
        label: "funding_sources"
    },
    {
        key: "criticality",
        label: "criticality"
    },
    {
        key: "capital_type",
        label: "capital_type"
    },
    {
        key: "term",
        label: "priorities"
    },
    {
        key: "responsibilities",
        label: "responsibilities"
    },
    {
        key: "projects",
        label: "projects"
    },
    {
        key: "sites",
        label: "sites"
    },
    {
        key: "buildings",
        label: "buildings"
    }
];

export const GET_MASTER_FILTER_IN_SMART_CHART_REQUEST = "GET_MASTER_FILTER_IN_SMART_CHART_REQUEST";
export const GET_MASTER_FILTER_IN_SMART_CHART_SUCCESS = "GET_MASTER_FILTER_IN_SMART_CHART_SUCCESS";
export const GET_MASTER_FILTER_IN_SMART_CHART_FAILURE = "GET_MASTER_FILTER_IN_SMART_CHART_FAILURE";

export const EXPORT_SMART_CHART_DATA_REQUEST = "EXPORT_SMART_CHART_DATA_REQUEST";
export const EXPORT_SMART_CHART_DATA_SUCCESS = "EXPORT_SMART_CHART_DATA_SUCCESS";
export const EXPORT_SMART_CHART_DATA_FAILURE = "EXPORT_SMART_CHART_DATA_FAILURE";

export const SAVE_SMART_CHART_DATA_REQUEST = "SAVE_SMART_CHART_DATA_REQUEST";
export const SAVE_SMART_CHART_DATA_SUCCESS = "SAVE_SMART_CHART_DATA_SUCCESS";
export const SAVE_SMART_CHART_DATA_FAILURE = "SAVE_SMART_CHART_DATA_FAILURE";

export const GET_EXPORTED_SMART_CHART_LIST_REQUEST = "GET_EXPORTED_SMART_CHART_LIST_REQUEST";
export const GET_EXPORTED_SMART_CHART_LIST_SUCCESS = "GET_EXPORTED_SMART_CHART_LIST_SUCCESS";
export const GET_EXPORTED_SMART_CHART_LIST_FAILURE = "GET_EXPORTED_SMART_CHART_LIST_FAILURE";

export const DELETE_SMART_CHART_REPORT_REQUEST = "DELETE_SMART_CHART_REPORT_REQUEST";
export const DELETE_SMART_CHART_REPORT_SUCCESS = "DELETE_SMART_CHART_REPORT_SUCCESS";
export const DELETE_SMART_CHART_REPORT_FAILURE = "DELETE_SMART_CHART_REPORT_FAILURE";

export const UPLOAD_DOC_FOR_SMART_REPORT_REQUEST = "UPLOAD_DOC_FOR_SMART_REPORT_REQUEST";
export const UPLOAD_DOC_FOR_SMART_REPORT_SUCCESS = "UPLOAD_DOC_FOR_SMART_REPORT_SUCCESS";
export const UPLOAD_DOC_FOR_SMART_REPORT_FAILURE = "UPLOAD_DOC_FOR_SMART_REPORT_FAILURE";

export const GET_UPLOADED_DOC_LIST_REQUEST = "GET_UPLOADED_DOC_LIST_REQUEST";
export const GET_UPLOADED_DOC_LIST_SUCCESS = "GET_UPLOADED_DOC_LIST_SUCCESS";
export const GET_UPLOADED_DOC_LIST_FAILURE = "GET_UPLOADED_DOC_LIST_FAILURE";

export const UPDATE_SMART_REPORT_DATA_REQUEST = "UPDATE_SMART_REPORT_DATA_REQUEST";
export const UPDATE_SMART_REPORT_DATA_SUCCESS = "UPDATE_SMART_REPORT_DATA_SUCCESS";
export const UPDATE_SMART_REPORT_DATA_FAILURE = "UPDATE_SMART_REPORT_DATA_FAILURE";

export const GET_TEMPLATE_PROPERTIES_LIST_REQUEST = "GET_TEMPLATE_PROPERTIES_LIST_REQUEST";
export const GET_TEMPLATE_PROPERTIES_LIST_SUCCESS = "GET_TEMPLATE_PROPERTIES_LIST_SUCCESS";
export const GET_TEMPLATE_PROPERTIES_LIST_FAILURE = "GET_TEMPLATE_PROPERTIES_LIST_FAILURE";

export const GET_TEMPLATE_LIST_REQUEST = "GET_TEMPLATE_LIST_REQUEST";
export const GET_TEMPLATE_LIST_SUCCESS = "GET_TEMPLATE_LIST_SUCCESS";
export const GET_TEMPLATE_LIST_FAILURE = "GET_TEMPLATE_LIST_FAILURE";

export const DELETE_USER_DOC_REQUEST = "DELETE_USER_DOC_REQUEST";
export const DELETE_USER_DOC_SUCCESS = "DELETE_USER_DOC_SUCCESS";
export const DELETE_USER_DOC_FAILURE = "DELETE_USER_DOC_FAILURE";

export const GET_CLIENTS_LIST_FOR_SMART_CHART_REQUEST = "GET_CLIENTS_LIST_FOR_SMART_CHART_REQUEST";
export const GET_CLIENTS_LIST_FOR_SMART_CHART_SUCCESS = "GET_CLIENTS_LIST_FOR_SMART_CHART_SUCCESS";
export const GET_CLIENTS_LIST_FOR_SMART_CHART_FAILURE = "GET_CLIENTS_LIST_FOR_SMART_CHART_FAILURE";

export const UPDATE_DOC_ORDER_REQUEST = "UPDATE_DOC_ORDER_REQUEST";
export const UPDATE_DOC_ORDER_SUCCESS = "UPDATE_DOC_ORDER_SUCCESS";
export const UPDATE_DOC_ORDER_FAILURE = "UPDATE_DOC_ORDER_FAILURE";

export const UPDATE_USER_DOC_DATA_REQUEST = "UPDATE_USER_DOC_DATA_REQUEST";
export const UPDATE_USER_DOC_DATA_SUCCESS = "UPDATE_USER_DOC_DATA_SUCCESS";
export const UPDATE_USER_DOC_DATA_FAILURE = "UPDATE_USER_DOC_DATA_FAILURE";

export const UPDATE_SMART_CHART_PROPERTY_REQUEST = "UPDATE_SMART_CHART_PROPERTY_REQUEST";
export const UPDATE_SMART_CHART_PROPERTY_SUCCESS = "UPDATE_SMART_CHART_PROPERTY_SUCCESS";
export const UPDATE_SMART_CHART_PROPERTY_FAILURE = "UPDATE_SMART_CHART_PROPERTY_FAILURE";

export const GET_SMART_CHART_PROPERTIES_LIST_REQUEST = "GET_SMART_CHART_PROPERTIES_LIST_REQUEST";
export const GET_SMART_CHART_PROPERTIES_LIST_SUCCESS = "GET_SMART_CHART_PROPERTIES_LIST_SUCCESS";
export const GET_SMART_CHART_PROPERTIES_LIST_FAILURE = "GET_SMART_CHART_PROPERTIES_LIST_FAILURE";

export const GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_REQUEST = "GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_REQUEST";
export const GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_SUCCESS = "GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_SUCCESS";
export const GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_FAILURE = "GET_SMART_CHART_PROPERTIES_SOURCETEMPLETE_FAILURE";

export const GET_SMART_CHART_PROPERTY_BY_ID_REQUEST = "GET_SMART_CHART_PROPERTY_BY_ID_REQUEST";
export const GET_SMART_CHART_PROPERTY_BY_ID_SUCCESS = "GET_SMART_CHART_PROPERTY_BY_ID_SUCCESS";
export const GET_SMART_CHART_PROPERTY_BY_ID_FAILURE = "GET_SMART_CHART_PROPERTY_BY_ID_FAILURE";

export const UPDATE_SMART_REPORT_ENTITY_PARAMS_SUCCESS = "UPDATE_SMART_REPORT_ENTITY_PARAMS_SUCCESS";
export const UPDATE_SMART_REPORT_ENTITY_PARAMS_FAILURE = "UPDATE_SMART_REPORT_ENTITY_PARAMS_FAILURE";

export const GET_UPLOADED_IMAGE_LIST_REQUEST = "GET_UPLOADED_IMAGE_LIST_REQUEST";
export const GET_UPLOADED_IMAGE_LIST_SUCCESS = "GET_UPLOADED_IMAGE_LIST_SUCCESS";
export const GET_UPLOADED_IMAGE_LIST_FAILURE = "GET_UPLOADED_IMAGE_LIST_FAILURE";

export const SAVE_AND_EXPORT_SMART_CHART_DATA_REQUEST = "SAVE_AND_EXPORT_SMART_CHART_DATA_REQUEST";
export const SAVE_AND_EXPORT_SMART_CHART_DATA_SUCCESS = "SAVE_AND_EXPORT_SMART_CHART_DATA_SUCCESS";
export const SAVE_AND_EXPORT_SMART_CHART_DATA_FAILURE = "SAVE_AND_EXPORT_SMART_CHART_DATA_FAILURE";

export const DELETE_SMART_CHART_REPORT_TEMPLATE_REQUEST = "DELETE_SMART_CHART_REPORT_TEMPLATE_REQUEST";
export const DELETE_SMART_CHART_REPORT_TEMPLATE_SUCCESS = "DELETE_SMART_CHART_REPORT_TEMPLATE_SUCCESS";
export const DELETE_SMART_CHART_REPORT_TEMPLATE_FAILURE = "DELETE_SMART_CHART_REPORT_TEMPLATE_FAILURE";

export const GET_REPORTS_BY_TEMPLATE_LIST_REQUEST = "GET_REPORTS_BY_TEMPLATE_LIST_REQUEST";
export const GET_REPORTS_BY_TEMPLATE_LIST_SUCCESS = "GET_REPORTS_BY_TEMPLATE_LIST_SUCCESS";
export const GET_REPORTS_BY_TEMPLATE_LIST_FAILURE = "GET_REPORTS_BY_TEMPLATE_LIST_FAILURE";

export const ASSIGN_IMAGES_TO_SMART_CHARTS_REQUEST = "ASSIGN_IMAGES_TO_SMART_CHARTS_REQUEST";
export const ASSIGN_IMAGES_TO_SMART_CHARTS_SUCCESS = "ASSIGN_IMAGES_TO_SMART_CHARTS_SUCCESS";
export const ASSIGN_IMAGES_TO_SMART_CHARTS_FAILURE = "ASSIGN_IMAGES_TO_SMART_CHARTS_FAILURE";

export const LOCK_SMART_CHART_TEMPLATE_REQUEST = "LOCK_SMART_CHART_TEMPLATE_REQUEST";
export const LOCK_SMART_CHART_TEMPLATE_SUCCESS = "LOCK_SMART_CHART_TEMPLATE_SUCCESS";
export const LOCK_SMART_CHART_TEMPLATE_FAILURE = "LOCK_SMART_CHART_TEMPLATE_FAILURE";

export const GET_CA_BAND_FILTER_DROPDOWN_DATA_REQUEST = "GET_CA_BAND_FILTER_DROPDOWN_DATA_REQUEST";
export const GET_CA_BAND_FILTER_DROPDOWN_DATA_SUCCESS = "GET_CA_BAND_FILTER_DROPDOWN_DATA_SUCCESS";
export const GET_CA_BAND_FILTER_DROPDOWN_DATA_FAILURE = "GET_CA_BAND_FILTER_DROPDOWN_DATA_FAILURE";

export const ADD_SMART_CHART_TO_FAVORITES_REQUEST = "ADD_SMART_CHART_TO_FAVORITES_REQUEST";
export const ADD_SMART_CHART_TO_FAVORITES_SUCCESS = "ADD_SMART_CHART_TO_FAVORITES_SUCCESS";
export const ADD_SMART_CHART_TO_FAVORITES_FAILURE = "ADD_SMART_CHART_TO_FAVORITES_FAILURE";

export const UPDATE_ENTITY_FILTER_PARAMS_SMART_CHARTS = "UPDATE_ENTITY_FILTER_PARAMS_SMART_CHARTS";

export const CLEAR_SMART_CHART_REDUCER_DATA = "CLEAR_SMART_CHART_REDUCER_DATA";

export const GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_REQUEST = "GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_REQUEST";
export const GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_SUCCESS = "GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_SUCCESS";
export const GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_FAILURE = "GET_CA_BAND_TRADE_FILTER_DROPDOWN_DATA_FAILURE";

export const UPDATE_SMART_REPORT_TEMPLATE_ENTITY_PARAMS_SUCCESS = "UPDATE_SMART_REPORT_TEMPLATE_ENTITY_PARAMS_SUCCESS";
export const UPDATE_SMART_REPORT_TEMPLATE_ENTITY_PARAMS_FAILURE = "UPDATE_SMART_REPORT_TEMPLATE_ENTITY_PARAMS_FAILURE";

export const GET_USERS_BY_CLIENT_REQUEST = "GET_USERS_BY_CLIENT_REQUEST";
export const GET_USERS_BY_CLIENT_SUCCESS = "GET_USERS_BY_CLIENT_SUCCESS";
export const GET_USERS_BY_CLIENT_FAILURE = "GET_USERS_BY_CLIENT_FAILURE";

export const ASSIGN_FAVORITE_TO_USERS_REQUEST = "ASSIGN_FAVORITE_TO_USERS_REQUEST";
export const ASSIGN_FAVORITE_TO_USERS_SUCCESS = "ASSIGN_FAVORITE_TO_USERS_SUCCESS";
export const ASSIGN_FAVORITE_TO_USERS_FAILURE = "ASSIGN_FAVORITE_TO_USERS_FAILURE";

export const CLEAR_USER_BY_CLIENT_DATA = "CLEAR_USER_BY_CLIENT_DATA";

export const GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_REQUEST = "GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_REQUEST";
export const GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_SUCCESS = "GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_SUCCESS";
export const GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_FAILURE = "GET_EXPORT_PROPERTY_LIST_NORMAL_RECOM_FAILURE";

export const GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_REQUEST = "GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_REQUEST";
export const GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_SUCCESS = "GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_SUCCESS";
export const GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_FAILURE = "GET_EXPORT_TEMPLATE_LIST_NORMAL_RECOM_FAILURE";

export const GET_EXPORT_SETTINGS_NORMAL_RECOM_REQUEST = "GET_EXPORT_SETTINGS_NORMAL_RECOM_REQUEST";
export const GET_EXPORT_SETTINGS_NORMAL_RECOM_SUCCESS = "GET_EXPORT_SETTINGS_NORMAL_RECOM_SUCCESS";
export const GET_EXPORT_SETTINGS_NORMAL_RECOM_FAILURE = "GET_EXPORT_SETTINGS_NORMAL_RECOM_FAILURE";

export const ADD_EXPORT_SETTINGS_NORMAL_RECOM_REQUEST = "ADD_EXPORT_SETTINGS_NORMAL_RECOM_REQUEST";
export const ADD_EXPORT_SETTINGS_NORMAL_RECOM_SUCCESS = "ADD_EXPORT_SETTINGS_NORMAL_RECOM_SUCCESS";
export const ADD_EXPORT_SETTINGS_NORMAL_RECOM_FAILURE = "ADD_EXPORT_SETTINGS_NORMAL_RECOM_FAILURE";

export const CLEAR_PROPERTY_BY_ID_DATA = "CLEAR_PROPERTY_BY_ID_DATA";

export const GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_REQUEST = "GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_REQUEST";
export const GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_SUCCESS = "GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_SUCCESS";
export const GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_FAILURE = "GET_CA_BAND_SYSTEM_FILTER_DROPDOWN_DATA_FAILURE";

export const CREATE_SKELETON_FROM_TEMPLATE_REQUEST = "CREATE_SKELETON_FROM_TEMPLATE_REQUEST";
export const CREATE_SKELETON_FROM_TEMPLATE_SUCCESS = "CREATE_SKELETON_FROM_TEMPLATE_SUCCESS";
export const CREATE_SKELETON_FROM_TEMPLATE_FAILURE = "CREATE_SKELETON_FROM_TEMPLATE_FAILURE";

export const GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_REQUEST = "GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_REQUEST";
export const GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_SUCCESS = "GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_SUCCESS";
export const GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_FAILURE = "GET_EXPORT_SETTINGS_IDS_FOR_NORMAL_RECOM_FAILURE";
export const CLEAR_SETTINGS_IDS_FOR_NORMAL_RECOM = "CLEAR_SETTINGS_IDS_FOR_NORMAL_RECOM";

export const CREATE_REPORT_FROM_CORE_REPORT_REQUEST = "CREATE_REPORT_FROM_CORE_REPORT_REQUEST";
export const CREATE_REPORT_FROM_CORE_REPORT_SUCCESS = "CREATE_REPORT_FROM_CORE_REPORT_SUCCESS";
export const CREATE_REPORT_FROM_CORE_REPORT_FAILURE = "CREATE_REPORT_FROM_CORE_REPORT_FAILURE";

export const UPDATE_CORE_REPORT_DATA_REQUEST = "UPDATE_CORE_REPORT_DATA_REQUEST";
export const UPDATE_CORE_REPORT_DATA_SUCCESS = "UPDATE_CORE_REPORT_DATA_SUCCESS";
export const UPDATE_CORE_REPORT_DATA_FAILURE = "UPDATE_CORE_REPORT_DATA_FAILURE";

export const GET_CA_COLUMNS_REQUEST = "GET_CA_COLUMNS_REQUEST";
export const GET_CA_COLUMNS_SUCCESS = "GET_CA_COLUMNS_SUCCESS";
export const GET_CA_COLUMNS_FAILURE = "GET_CA_COLUMNS_FAILURE";

export const GET_EXPORT_SETTINGS_ASSET_REQUEST = "GET_EXPORT_SETTINGS_ASSET_REQUEST";
export const GET_EXPORT_SETTINGS_ASSET_SUCCESS = "GET_EXPORT_SETTINGS_ASSET_SUCCESS";
export const GET_EXPORT_SETTINGS_ASSET_FAILURE = "GET_EXPORT_SETTINGS_ASSET_FAILURE";

export const GET_EXPORT_PROPERTY_LIST_ASSET_REQUEST = "GET_EXPORT_PROPERTY_LIST_ASSET_REQUEST";
export const GET_EXPORT_PROPERTY_LIST_ASSET_SUCCESS = "GET_EXPORT_PROPERTY_LIST_ASSET_SUCCESS";
export const GET_EXPORT_PROPERTY_LIST_ASSET_FAILURE = "GET_EXPORT_PROPERTY_LIST_ASSET_FAILURE";

export const GET_EXPORT_TEMPLATE_LIST_ASSET_REQUEST = "GET_EXPORT_TEMPLATE_LIST_ASSET_REQUEST";
export const GET_EXPORT_TEMPLATE_LIST_ASSET_SUCCESS = "GET_EXPORT_TEMPLATE_LIST_ASSET_SUCCESS";
export const GET_EXPORT_TEMPLATE_LIST_ASSET_FAILURE = "GET_EXPORT_TEMPLATE_LIST_ASSET_FAILURE";

export const ADD_EXPORT_SETTINGS_ASSET_REQUEST = "ADD_EXPORT_SETTINGS_ASSET_REQUEST";
export const ADD_EXPORT_SETTINGS_ASSET_SUCCESS = "ADD_EXPORT_SETTINGS_ASSET_SUCCESS";
export const ADD_EXPORT_SETTINGS_ASSET_FAILURE = "ADD_EXPORT_SETTINGS_ASSET_FAILURE";

export const DEFAULT_PROPERTY_VALUE = {
    project: {
        band1: {
            mfilter: {},
            type: {
                regions: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    remove_tbl_section_break: false
                },
                sites: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    remove_tbl_section_break: false
                },
                buildings: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    remove_tbl_section_break: false
                },
                additions: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    remove_tbl_section_break: false
                },
                sorted_recom: {
                    band1: {
                        mfilter: {},
                        type: ["building"]
                    }
                },
                filter_report1: {
                    hasConfig: false,
                    isVisible: true,
                    name: "Master Filters",
                    band1: {
                        mfilter: {}
                    }
                },
                criticality: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    remove_tbl_section_break: false
                },
                categories: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    remove_tbl_section_break: false
                },
                funding_sources: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    remove_tbl_section_break: false
                },
                priorities: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    remove_tbl_section_break: false
                },
                trades: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    remove_tbl_section_break: false
                },
                system: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    normal_recom: { band1: { mfilter: {}, remove_section_break: false, name: "Recommendations", config_filter: {} } },
                    remove_tbl_section_break: false,
                    combination: false
                },
                subsystem: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    remove_tbl_section_break: false
                },
                responsibilities: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    remove_tbl_section_break: false
                },
                capital_type: {
                    table_view: "",
                    summary_view: {
                        chart_type: ["pie_3d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    detailed_view: {
                        chart_type: ["stacked_column_2d"],
                        remove_section_break: false,
                        config: {
                            legend: true,
                            header: true,
                            total: true
                        }
                    },
                    remove_tbl_section_break: false
                },
                EFCI: {
                    detailed_view: {
                        chart_type: ["line_column_2d"]
                    }
                },
                energy_band: "",
                water_band: "",
                asset_band: {
                    table_view: ""
                },
                bbi_report1: {
                    band1: { mfilter: {}, name: "BBI" }
                },
                bsi_report1: {
                    band1: { mfilter: {}, name: "BSI" }
                }
            }
        }
    },
    assets: {
        band1: {
            mfilter: {},
            type: {
                asset_band: {
                    table_view: ""
                },
                end_servicelife_year_condition: {
                    table_view: "",
                    detailed_view: { chart_type: ["stacked_column_2d", "stacked_column_3d"] }
                },
                asset_age_by_condition: {
                    table_view: "",
                    detailed_view: { chart_type: ["stacked_column_2d", "stacked_column_3d"] }
                },
                asset_capital_spending_plan: {
                    table_view: "",
                    summary_view: { chart_type: ["pie_2d", "pie_3d"] },
                    detailed_view: { chart_type: ["stacked_column_2d", "stacked_column_3d"] }
                },
                asset_system_facility: {
                    table_view: "",
                    detailed_view: { chart_type: ["stacked_column_2d", "stacked_column_3d"] }
                }
            }
        }
    },
    energy_mng: {
        band1: {
            mfilter: {},
            type: {
                em_eui_building_breakdown: { detailed_view: { chart_type: ["stacked_column_2d"] } },
                bld_brkdown_energy_usage: { summary_view: { chart_type: ["pie_2d"] } },
                em_eui_site_brkdown_energy_use: { detailed_view: { chart_type: ["stacked_column_2d"] } },
                em_eui_energy_usage: { summary_view: { chart_type: ["pie_2d"] } },
                bld_brkdown_energy_cost: { summary_view: { chart_type: ["pie_2d"] } },
                em_eci_site_cost: { detailed_view: { chart_type: ["stacked_column_2d"] } },
                em_eci_energy_cost: { summary_view: { chart_type: ["pie_2d", "pie_3d"] } },
                energy_band: "",
                water_band: ""
            }
        }
    }
};

export const DEFAULT_PROPERTY_VALUE_FOR_EXCEL = {
    project: {
        band1: {
            mfilter: {},
            type: {
                regions: {
                    table_view: ""
                },
                sites: { table_view: "" },
                buildings: {
                    table_view: ""
                },
                additions: {
                    table_view: ""
                },
                sorted_recom: {
                    band1: {
                        mfilter: {},
                        type: ["building"]
                    }
                },
                filter_report1: {
                    hasConfig: false,
                    isVisible: true,
                    name: "Master Filters",
                    band1: {
                        mfilter: {}
                    }
                },
                criticality: {
                    table_view: ""
                },
                categories: {
                    table_view: ""
                },
                funding_sources: {
                    table_view: ""
                },
                priorities: {
                    table_view: ""
                },
                trades: {
                    table_view: ""
                },
                // system: {
                //     table_view: ""
                // },
                // subsystem: {
                //     table_view: ""
                // },
                responsibilities: {
                    table_view: ""
                },
                capital_type: {
                    table_view: ""
                },
                // EFCI: {
                //     detailed_view: {
                //         chart_type: ["line_column_2d"]
                //     }
                // },
                energy_band: "",
                water_band: "",
                asset_band: {
                    table_view: ""
                }
            }
        }
    },
    assets: {
        band1: {
            mfilter: {},
            type: {
                asset_band: {
                    table_view: ""
                },
                end_servicelife_year_condition: {
                    table_view: "",
                    detailed_view: { chart_type: ["stacked_column_2d", "stacked_column_3d"] }
                },
                asset_age_by_condition: {
                    table_view: "",
                    detailed_view: { chart_type: ["stacked_column_2d", "stacked_column_3d"] }
                },
                asset_capital_spending_plan: {
                    table_view: "",
                    summary_view: { chart_type: ["pie_2d", "pie_3d"] },
                    detailed_view: { chart_type: ["stacked_column_2d", "stacked_column_3d"] }
                },
                asset_system_facility: {
                    table_view: "",
                    detailed_view: { chart_type: ["stacked_column_2d", "stacked_column_3d"] }
                }
            }
        }
    },
    energy_mng: {
        band1: {
            mfilter: {},
            type: {
                em_eui_building_breakdown: { detailed_view: { chart_type: ["stacked_column_2d"] } },
                bld_brkdown_energy_usage: { summary_view: { chart_type: ["pie_2d"] } },
                em_eui_site_brkdown_energy_use: { detailed_view: { chart_type: ["stacked_column_2d"] } },
                em_eui_energy_usage: { summary_view: { chart_type: ["pie_2d"] } },
                bld_brkdown_energy_cost: { summary_view: { chart_type: ["pie_2d"] } },
                em_eci_site_cost: { detailed_view: { chart_type: ["stacked_column_2d"] } },
                em_eci_energy_cost: { summary_view: { chart_type: ["pie_2d", "pie_3d"] } },
                energy_band: "",
                water_band: ""
            }
        }
    }
};

export const CHART_ITEMS_FOR_EXCEL = {
    project: {
        regions: {
            hasConfig: true,
            config: {
                table_view: true
            },
            isVisible: true,
            defaultValue: { table_view: "" }
        },
        sites: {
            hasConfig: true,
            config: {
                table_view: true
            },
            isVisible: true,
            defaultValue: { table_view: "" }
        },
        buildings: {
            config: {
                table_view: true
            },
            hasConfig: true,
            isVisible: true,
            defaultValue: { table_view: "" }
        },
        additions: {
            hasConfig: true,
            config: {
                table_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: ""
            }
        },
        sorted_recom: {
            hasConfig: true,
            isVisible: true,
            defaultValue: {
                band1: {
                    mfilter: {},
                    type: ["building"]
                }
            }
        },

        criticality: {
            hasConfig: true,
            config: {
                table_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: ""
            }
        },
        categories: {
            hasConfig: true,
            config: {
                table_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: ""
            }
        },
        funding_sources: {
            hasConfig: true,
            config: {
                table_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: ""
            }
        },
        priorities: {
            hasConfig: true,
            config: {
                table_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: ""
            }
        },
        trades: {
            hasConfig: true,
            config: {
                table_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: ""
            }
        },
        system: {
            hasConfig: true,
            config: {
                table_view: true,
                normal_recom: true
            },
            isVisible: true,
            defaultValue: {
                table_view: ""
            }
        },
        subsystem: {
            hasConfig: true,
            config: {
                table_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: ""
            }
        },
        responsibilities: {
            hasConfig: true,
            config: {
                table_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: ""
            }
        },
        capital_type: {
            hasConfig: true,
            config: {
                table_view: true
            },
            isVisible: true,
            defaultValue: {
                table_view: ""
            }
        },
        energy_band: {
            hasConfig: false,
            isVisible: true,
            defaultValue: ""
        },
        water_band: {
            hasConfig: false,
            isVisible: true,
            defaultValue: ""
        },
        asset_band: {
            hasConfig: true,
            isVisible: true,
            defaultValue: { table_view: "" }
        },
        filter_report1: {
            hasConfig: false,
            isVisible: true,
            name: "Master Filters",
            band1: {
                mfilter: {}
            }
        }
    },
    assets: {
        asset_band: {
            hasConfig: false,
            isVisible: true,
            defaultValue: { table_view: "" }
        },
        end_servicelife_year_condition: {
            hasConfig: false,
            isVisible: true,
            defaultValue: { table_view: "" }
        },
        asset_age_by_condition: {
            hasConfig: false,
            isVisible: true,
            defaultValue: { table_view: "" }
        },
        asset_capital_spending_plan: {
            hasConfig: false,
            isVisible: true,
            defaultValue: {
                table_view: ""
            }
        },
        asset_system_facility: {
            hasConfig: false,
            isVisible: true,
            defaultValue: { table_view: "" }
        }
    },
    energy_mng: {
        // em_eui_building_breakdown: {
        //     hasConfig: true,
        //     config: {
        //         detailed_view: true
        //     },
        //     isVisible: true,
        //     defaultValue: { detailed_view: { chart_type: ["stacked_column_2d"] } }
        // },
        // bld_brkdown_energy_usage: {
        //     hasConfig: true,
        //     config: {
        //         summary_view: true
        //     },
        //     isVisible: true,
        //     defaultValue: { summary_view: { chart_type: ["pie_2d"] } }
        // },
        // em_eui_site_brkdown_energy_use: {
        //     hasConfig: true,
        //     config: {
        //         detailed_view: true
        //     },
        //     isVisible: true,
        //     defaultValue: { detailed_view: { chart_type: ["stacked_column_2d"] } }
        // },
        // em_eui_energy_usage: {
        //     hasConfig: true,
        //     config: {
        //         summary_view: true
        //     },
        //     isVisible: true,
        //     defaultValue: { summary_view: { chart_type: ["pie_2d"] } }
        // },
        // bld_brkdown_energy_cost: { hasConfig: true, isVisible: true, defaultValue: { summary_view: { chart_type: ["pie_2d"] } } },
        // em_eci_site_cost: { hasConfig: true, isVisible: true, defaultValue: { detailed_view: { chart_type: ["stacked_column_2d"] } } },
        // em_eci_energy_cost: { hasConfig: true, isVisible: true, defaultValue: { summary_view: { chart_type: ["pie_2d", "pie_3d"] } } },
        energy_band: {
            hasConfig: false,
            isVisible: true,
            defaultValue: ""
        },
        water_band: {
            hasConfig: false,
            isVisible: true,
            defaultValue: ""
        }
    }
};

export const EXPORTING_CHART_SECTIONS = [
    { key: "header", label: "Header" },
    { key: "legend", label: "Legend" },
    { key: "total", label: "Total" }
];

export const RECOM_FIRST_LEVEL_GROUPING = [
    {
        value: "region",
        name: "Region"
    },
    {
        value: "site",
        name: "Site"
    },
    {
        value: "building",
        name: "Building"
    },
    {
        value: "responsibility",
        name: "Responsibility"
    },
    {
        value: "category",
        name: "Category"
    },
    {
        value: "addition",
        name: "Addition"
    },
    {
        value: "project",
        name: "Project"
    },
    {
        value: "trade",
        name: "Trade"
    },
    {
        value: "system",
        name: "System"
    },
    {
        value: "subsystem",
        name: "Subsystem"
    },
    {
        value: "criticality",
        name: "Criticality"
    },
    {
        value: "fundingsource",
        name: "Funding source"
    },
    {
        value: "department",
        name: "Department"
    },
    {
        value: "floor",
        name: "Floor"
    },
    {
        value: "condition",
        name: "Condition"
    },
    {
        value: "capitaltype",
        name: "Capitaltype"
    },
    {
        value: "code",
        name: "Code"
    },
    {
        value: "status",
        name: "Status"
    }
];
export const RECOM_SECOND_LEVEL_GROUPING = [
    {
        value: "site",
        name: "Site"
    },
    {
        value: "building",
        name: "Building"
    }
];
