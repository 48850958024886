export const imageResolutions = [500, 1000, 1500, 2000, 2500, 3000, 3500];

export const internetSignalStrength = [20, 50, 100, 200, 300, 400];

export const aspectRatio = [
    { id: "4_3", name: "4:3" },
    { id: "16_9", name: "16:9" }
];

export const ADD_ASSET_SURVEY_SETTINGS_DATA_REQUEST = "ADD_ASSET_SURVEY_SETTINGS_DATA_REQUEST";
export const ADD_ASSET_SURVEY_SETTINGS_DATA_SUCCESS = "ADD_ASSET_SURVEY_SETTINGS_DATA_SUCCESS";
export const ADD_ASSET_SURVEY_SETTINGS_DATA_FAILURE = "ADD_ASSET_SURVEY_SETTINGS_DATA_FAILURE";

export const GET_ASSET_SURVEY_SETTINGS_REQUEST = "GET_ASSET_SURVEY_SETTINGS_REQUEST";
export const GET_ASSET_SURVEY_SETTINGS_SUCCESS = "GET_ASSET_SURVEY_SETTINGS_SUCCESS";
export const GET_ASSET_SURVEY_SETTINGS_FAILURE = "GET_ASSET_SURVEY_SETTINGS_FAILURE";
