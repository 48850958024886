import React, { useState } from "react";
import Draggable from "react-draggable";
import ReactTooltip from "react-tooltip";

export const ExportModal = ({ onCancel, onSelection, requiredFields, chartData }) => {
    const [selectedExportType, setSelectedExportType] = useState("");
    const [selectedFileType, setSelectedFileType] = useState("");
    const [selectedFileExtension, setSelectedFileExtension] = useState("word");

    const lastValueOfProject = requiredFields?.project[requiredFields.project.length - 1];
    const lastFilterValueInProject = chartData?.properties_text?.project?.band1?.mfilter?.[lastValueOfProject];
    return (
        <Draggable handle=".draggable" cancel=".not-draggable">
            <div className="modal modal-region add-image-modal img-pop-small core-rpt-popup" id="modalId" tabIndex={-1} style={{ display: "block" }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className={`modal-body region-otr draggable`}>
                            <div className="close-btn-area">
                                <button type="button" className="close not-draggable" onClick={onCancel}>
                                    <span aria-hidden="true">
                                        <img src="/img/close.svg" alt="" />
                                    </span>
                                </button>
                            </div>
                            <div className="content-area mt-3 not-draggable ">
                                {lastFilterValueInProject?.length > 1 && (
                                    <div className="radio-area">
                                        <div className="heading export-form-inp">
                                            <h3>
                                                Please Select Calculation Type{" "}
                                                <i
                                                    class="fa fa-info"
                                                    data-tip="Single Data Set = Current selection will be summed up into one Data Set.<br>This will result in a single file export.<br><br>
                                                Multiple Data Set = Current selection will display a separate calculation for each selection.<br>
                                                In the next menu option, user can select whether to export these separate calculations as a single file, or generate a separate file for each selection."
                                                    data-for="export-type"
                                                    data-html={true}
                                                ></i>
                                            </h3>
                                        </div>
                                        <div className="radio-sec">
                                            <div className="button-group">
                                                <div className="button">
                                                    <label className="container cursor-hand">
                                                        <input
                                                            checked={selectedExportType === "SingleDataSet"}
                                                            onChange={() => setSelectedExportType("SingleDataSet")}
                                                            type="radio"
                                                        />
                                                        <span className="checkmark" />
                                                        Single Data Set
                                                    </label>
                                                </div>
                                                <div className="button">
                                                    <label className="container cursor-hand">
                                                        <input
                                                            checked={selectedExportType === "MultipleDataSet"}
                                                            onChange={() => setSelectedExportType("MultipleDataSet")}
                                                            type="radio"
                                                        />
                                                        <span className="checkmark" />
                                                        Multiple Data set
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {selectedExportType === "MultipleDataSet" && (
                                    <div className="radio-area">
                                        <div className="heading export-form-inp">
                                            <h3>
                                                Please Select Export Type{" "}
                                                <i
                                                    class="fa fa-info"
                                                    data-for="export-type"
                                                    data-tip="Single File = Current selection will be exported as one file.<br>
                                                All Data Sets will be grouped into a single file and exported.<br>
                                                Example: 10 Data Sets will be exported as 1 file.<br><br>
                                                Separate Files = Current selection will be exported as multiple files.<br>
                                                Each Data Set will export as a separate file.<br>
                                                Example: 10 Data Sets will export as 10 files."
                                                ></i>
                                            </h3>
                                        </div>
                                        <ReactTooltip
                                            className="rc-tooltip-custom-class tool-format-bx"
                                            id="export-type"
                                            backgroundColor="#007bff"
                                            place="top"
                                            effect="solid"
                                            html={true}
                                            getContent={dataTip => dataTip?.replace(/(?:\r\n|\r|\n)/g, "<br>")}
                                        />
                                        <div className="radio-sec">
                                            <div className="button-group">
                                                <div className="button">
                                                    <label className="container cursor-hand">
                                                        <input
                                                            type="radio"
                                                            checked={selectedFileType === "Single(Aggregated File)"}
                                                            onChange={() => setSelectedFileType("Single(Aggregated File)")}
                                                            defaultChecked=""
                                                        />
                                                        <span className="checkmark" />
                                                        Single File (Aggregated File)
                                                    </label>
                                                </div>
                                                <div className="button">
                                                    <label className="container cursor-hand">
                                                        <input
                                                            type="radio"
                                                            checked={selectedFileType === "SeparateFile"}
                                                            onChange={() => setSelectedFileType("SeparateFile")}
                                                        />
                                                        <span className="checkmark" />
                                                        Separate Files
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="radio-area">
                                    <div className="heading export-form-inp">
                                        <h3>
                                            Please Select File Format
                                            <i
                                                class="fa fa-info"
                                                data-tip="Do you want to export the report as PDF or as Word"
                                                data-for="export-type"
                                            ></i>
                                        </h3>
                                    </div>
                                    <div className="radio-sec">
                                        <div className="button-group">
                                            <div className="button">
                                                <label className="container cursor-hand">
                                                    <input
                                                        checked={selectedFileExtension === "word"}
                                                        onChange={() => setSelectedFileExtension("word")}
                                                        type="radio"
                                                    />
                                                    <span className="checkmark" />
                                                    Word
                                                </label>
                                            </div>
                                            <div className="button">
                                                <label className="container cursor-hand">
                                                    <input
                                                        checked={selectedFileExtension === "is_pdf"}
                                                        onChange={() => setSelectedFileExtension("is_pdf")}
                                                        type="radio"
                                                    />
                                                    <span className="checkmark" />
                                                    PDF
                                                </label>
                                            </div>
                                            <div className="button">
                                                <label className="container cursor-hand">
                                                    <input
                                                        checked={selectedFileExtension === "is_excel"}
                                                        onChange={() => setSelectedFileExtension("is_excel")}
                                                        type="radio"
                                                    />
                                                    <span className="checkmark" />
                                                    Excel
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="down-area btn-area mt-2 d-flex">
                                    <button className="btn btn-download outdated mb-1 mr-4" onClick={onCancel}>
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-download mb-3"
                                        onClick={() => onSelection(selectedExportType, selectedFileType, selectedFileExtension)}
                                        disabled={
                                            lastFilterValueInProject?.length > 1 &&
                                            ((selectedExportType === "SingleDataSet" && !selectedExportType) ||
                                                (selectedExportType === "MultipleDataSet" && !selectedFileType) ||
                                                !selectedExportType)
                                        }
                                    >
                                        Export
                                    </button>
                                </div>
                                <ReactTooltip
                                    className="rc-tooltip-custom-class tool-format-bx"
                                    id="export-type"
                                    backgroundColor="#007bff"
                                    place="top"
                                    effect="solid"
                                    html={true}
                                    getContent={dataTip => {
                                        dataTip?.replace(/(?:\r\n|\r|\n)/g, "<br>");
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};
