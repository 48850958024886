import React from "react";
import { useState, useEffect, useRef } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import _, { uniqBy } from "lodash";
import { DragDropContext } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import ReactTooltip from "react-tooltip";

import ChartMain from "./ChartMain";
import ChartItemConfigModal from "./ChartItemConfigModal";
import Portal from "../../common/components/Portal";
import {
    CHART_ITEMS,
    DEFAULT_PROPERTY_VALUE,
    DEFAULT_PROPERTY_VALUE_FOR_EXCEL,
    CHART_ITEMS_FOR_EXCEL,
    CA_RECOMMENDATION_ELEMENTS,
    CA_GEO_HIERARCHY_ELEMENTS,
    CHART_DATA_VIEWS
} from "../constants";
import { shiftObjectProps, hasOnePagerConfig, hasNormalRecomConfig } from "../utils";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import DocumentsMain from "./DoucmentsMain";
import actions from "../actions";
import { reorderArray, findPrevPathFromBreadCrump, popBreadCrumpData, addToBreadCrumpData, checkPermission } from "../../../config/utils";
import exclmIcon from "../../../assets/img/recom-icon.svg";
import SaveAsConfirmationModal from "../../project/components/MergeOrReplaceModalSelection";
import EntityDataEditModal from "./SmartChartDataEditForm";
import ImageBandConfigModal from "./ImageBandConfigModal";
import ImageOrDocListForBandModal from "./ImageListForBandModal";
import AddCAbandModal from "./AddCAbandModal";
import MultipleRecommendationModal from "./MultipleRecommendationModal";
import Action from "../../recommendations/actions";
import ExportPropertiesModal from "./ExportPropertiesModal";
import chartPropertyActions from "../../chartProperties/actions";
import { RichEditorPopup } from "../../common/components/formFields/RichEditorPopup";
import ViewExportModal from "../../recommendations/components/ExportSettingsModal";
import BbiConfigModal from "./BbiConfigModal";

const CreateSmartChart = ({
    getSmartChartMasterFilterDropDown,
    projectsDropdownData,
    exportSmartChartData,
    exportSmartChartDataResponse,
    masterFilterList,
    saveSmartChartData,
    saveSmartChartResponse = {},
    refreshTableData,
    deleteUserDocs,
    updateUserDocData,
    updateSmartChartProperty,
    viewReports,
    getCAbandFilterDropDownData,
    getCAbandTradeFilterDropDownData,
    mFilters = {},
    clearReducerData,
    getCAbandSystemFilterDropDownData,
    ...props
}) => {
    const dispatch = useDispatch();
    const [projectId, setProjectId] = useState("");
    const [chartData, setChartData] = useState({
        name: "",
        notes: "",
        properties_text: {
            project: {
                band1: {
                    mfilter: {},
                    type: {}
                }
            },
            assets: {
                band1: {
                    mfilter: {},
                    type: {}
                }
            },
            energy_mng: {
                band1: {
                    mfilter: {},
                    type: {}
                }
            }
        }
    });
    const [copyChartData, setCopyChartData] = useState({});
    const [showErrorBorder, setShowErrorBorder] = useState(false);
    const [chartsOrder, setChartsOrder] = useState({
        project: Object.keys(CHART_ITEMS.project),
        energy_mng: Object.keys(CHART_ITEMS.energy_mng),
        assets: Object.keys(CHART_ITEMS.assets)
    });
    const [showChartItemConfigModal, setShowChartItemConfigModal] = useState(false);
    const [showBbiConfigModal, setShowBbiConfigModal] = useState(false);
    const [selectedChartConfigData, setSelectedChartConfigData] = useState({});
    const [userDocs, setUserDocs] = useState([]);
    const [selectedExportProperty, setSelectedExportProperty] = useState("");
    const [selectedExportTemplate, setSelectedExportTemplate] = useState("");
    const [selectedClient, setSelectedClient] = useState("");
    const [chartConfigOrder, setChartConfigOrder] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState("");
    const [enableExport, setEnableExport] = useState(false);
    const [savedChartData, setSavedChartData] = useState(null);
    const initialData = useRef(null);
    const [selectedProperty, setSelectedProperty] = useState(props.location?.state?.property ? { ...props.location.state.property } : null);
    const [userImages, setUserImages] = useState([]);
    const [propertyName, setPropertyName] = useState("");
    const [isSaveAndExport, setIsSaveAndExport] = useState(false);
    const [showSaveAsConfirmationModal, setShowSaveAsConfirmationModal] = useState(false);
    const [showNewTemplateEditModal, setShowNewTemplateEditModal] = useState(false);
    const [showImageBandConfigModal, setShowImageBandConfigModal] = useState(false);
    const [showImageListForBandModal, setShowImageListForBandModal] = useState(false);
    const [isImageListView, setIsImageListView] = useState(false);
    const [showImageBandDeleteConfirmationModal, setShowImageBandDeleteConfirmationModal] = useState(false);
    const [imageBandData, setImageBandData] = useState({});
    const [toggleImageBandEditModal, setToggleImageBandEditModal] = useState(false);
    const [entityForListModal, setEntityForListModal] = useState("");
    const [selectedBand, setSelectedBand] = useState("");
    const [selectedTileType, setSelectedTileType] = useState("");
    const [showAddCAband, setShowAddCAband] = useState(false);
    const [showMultiRecommendationModal, setShowMultiRecommendationModal] = useState(false);
    const [configEntity, setConfigEntity] = useState(null);
    const [recommendationBandType, setRecommendationBandType] = useState("");
    const [showExcelExportConfirmModal, setShowExcelExportConfirmModal] = useState(false);
    const [isExcelExport, setIsExcelExport] = useState(false);
    const [confirmationPopupType, setConfirmationPopupType] = useState("");
    const [selectedExptProperty, setSelectedExptProperty] = useState("");
    const [requiredFields, setRequiredFields] = useState({
        project: [],
        energy_mng: [],
        assets: []
    });
    const { filterFields, getDashboard } = useSelector(state => state?.dashboardReducer);
    const [showExportProperties, setShowExportProperties] = useState(false);
    const changeDefaultProjectRef = useRef(true);
    const isEdit = props.match.params.viewType && props.match.params.viewType === "edit" ? true : false;
    const { search: searchParams } = useLocation();
    let isSkeletonView = searchParams.includes("is_skeleton");
    const [showRichTextPopup, setShowRichTextPopup] = useState({ label: "", key: "", show: false, value: "" });
    const [yearField, setYearFields] = useState([]);
    const [isInitialChartStateUpdated, setIsInitialChartStateUpdated] = useState(false);
    const [showAssetExportSetting, setShowAssetExportSetting] = useState(false);
    const [showCorereportUpdateConfirmationModal, setShowCorereportUpdateConfirmationModal] = useState(false);

    useEffect(() => {
        if (selectedProperty) {
            setPropertyName(selectedProperty.name);
            let updatedPropertyData = getUpdatedPropertyValueForEdit(selectedProperty.properties_text);
            if (
                updatedPropertyData?.project?.band1?.type?.hasOwnProperty("energy_band") ||
                updatedPropertyData?.energy_mng?.band1?.type?.hasOwnProperty("energy_band")
            ) {
                updatedPropertyData = removeExtrakeysFromEnergyBand(updatedPropertyData);
            }
            let isExcelData = updatedPropertyData?.project?.band1?.export_type === "excel" ? true : false;
            setChartData(prevChartData => {
                return {
                    ...prevChartData,
                    name: !isSkeletonView ? selectedProperty.name : "",
                    notes: !isSkeletonView ? selectedProperty.notes : "",
                    properties_text: updatedPropertyData
                };
            });
            let updatedChartsOrderData = setChartsDataForEdit(isExcelData);
            setChartsOrder(updatedChartsOrderData);
            initialData.current = {
                ...chartData,
                name: selectedProperty.name,
                notes: selectedProperty.notes,
                properties_text: updatedPropertyData
            };
            if (isExcelData) {
                setIsExcelExport(true);
            }
            if (isSkeletonView) {
                setRequiredFieldsForSkeleton(updatedPropertyData);
            }
            const result = {
                project: {
                    band1: {
                        mfilter: {
                            ...(DEFAULT_PROPERTY_VALUE?.project?.band1?.mfilter || {}),
                            ...(updatedPropertyData?.project?.band1?.mfilter || {})
                        },

                        type: { ...(DEFAULT_PROPERTY_VALUE?.project?.band1?.type || {}), ...(updatedPropertyData?.project?.band1?.type || {}) }
                    }
                },
                assets: {
                    band1: {
                        mfilter: {
                            ...(DEFAULT_PROPERTY_VALUE?.assets?.band1?.mfilter || {}),
                            ...(updatedPropertyData?.assets?.band1?.mfilter || {})
                        },
                        type: { ...(DEFAULT_PROPERTY_VALUE?.assets?.band1?.type || {}), ...(updatedPropertyData?.assets?.band1?.type || {}) }
                    }
                },
                energy_mng: {
                    band1: {
                        mfilter: {
                            ...(DEFAULT_PROPERTY_VALUE?.energy_mng?.band1?.mfilter || {}),
                            ...(updatedPropertyData?.energy_mng?.band1?.mfilter || {})
                        },
                        type: {
                            ...(DEFAULT_PROPERTY_VALUE?.energy_mng?.band1?.type || {}),
                            ...(updatedPropertyData?.energy_mng?.band1?.type || {})
                        }
                    }
                }
            };
            setCopyChartData({
                name: "",
                notes: "",
                properties_text: {
                    ...result
                }
            });
        } else {
            initialData.current = { ...chartData };
            setCopyChartData({
                name: "",
                notes: "",
                properties_text: {
                    ...DEFAULT_PROPERTY_VALUE
                }
            });
        }
        props.getClientDropDownData();

        return () => {
            clearReducerData();
            props.getExportSettingsForAllSmartChartRecomTiles(null, "clear");
        };
    }, []);
    // useEffect(() => {
    //     if (props.smartChartReducer.uploadedDocListResponse?.data) {
    //         setUserDocs([...props.smartChartReducer.uploadedDocListResponse.data]);
    //     }
    // }, [props.smartChartReducer.uploadedDocListResponse]);

    // useEffect(() => {
    //     if (props.smartChartReducer.uploadedImageListResponse?.data) {
    //         setUserImages([...props.smartChartReducer.uploadedImageListResponse.data]);
    //     }
    // }, [props.smartChartReducer.uploadedImageListResponse]);

    useEffect(() => {
        let clientList = props?.smartChartReducer?.getClientDropDownDataResponse?.data || [];
        if (clientList.length) {
            let defaultClient = null;
            if (selectedProperty && !isSkeletonView) {
                defaultClient = { id: selectedProperty.client_id };
            } else {
                defaultClient = mFilters?.client_ids?.[0] ? { id: mFilters?.client_ids?.[0] } : clientList.find(client => client.default == true);
            }
            if (defaultClient) {
                setSelectedClient(defaultClient.id);
                props.getTemplatePropertiesList({ client_id: defaultClient.id });
                props.getTemplateList({ client_id: defaultClient.id });
                getSmartChartMasterFilterDropDown("projects", "project", {
                    client_id: defaultClient?.id || null,
                    user_id: localStorage.getItem("userId") || ""
                });
                if (selectedClient === "" && !isEdit) {
                    const entities = ["project", "assets"];
                    entities.forEach(entity => {
                        setSmartChartData("mfilter", {
                            mfilterKey: "client_ids",
                            bandName: "band1",
                            filterValues: [defaultClient.id],
                            entity: entity,
                            filterKey: "clients",
                            dropDownData: [...defaultClient.id] || [],
                            filterLabel: "Client",
                            initialSetup: true
                        });
                    });
                }
            }
        }
    }, [props.smartChartReducer.getClientDropDownDataResponse]);

    useEffect(() => {
        if (isInitialChartStateUpdated) {
            initialData.current = { ...chartData };
            setIsInitialChartStateUpdated(false);
        }
    }, [isInitialChartStateUpdated]);

    useEffect(() => {
        const timer2 = setTimeout(() => {
            const { start, end } = getDashboard?.years || {};
            if (start && end) {
                let yearFilters = [];
                const yearsArray = Array.from({ length: end - start + 1 }, (_, index) => start + index);
                yearFilters = yearsArray.map(year => ({
                    label: year,
                    key: year,
                    paramKey: `maintenance_years.${year}`,
                    isWildCardFilter: true,
                    fieldType: "number"
                }));
                setYearFields(yearFilters);
            }
        }, 100);

        return () => {
            clearTimeout(timer2);
        };
    }, [getDashboard]);

    useEffect(() => {
        let propertyList = props?.smartChartReducer?.getTemplatePropertiesListResponse?.data || [];
        if (propertyList.length) {
            let defaultProperty = null;
            if (isEdit) {
                defaultProperty = { id: selectedProperty?.template_property_id };
            } else {
                defaultProperty =
                    propertyList.find(property => property.sm_default === true) || propertyList.find(property => property.default === true);
            }
            if (selectedExptProperty) {
                setSelectedExportProperty(selectedExptProperty);
                setSelectedExptProperty("");
            } else if (defaultProperty) {
                setSelectedExportProperty(defaultProperty.id);
            }
        }
        let templateList = props?.smartChartReducer?.getTemplateListResponse?.data || [];
        if (templateList.length) {
            let defaultTemplate = null;
            if (isEdit) {
                defaultTemplate = { id: selectedProperty?.template_id };
            } else {
                defaultTemplate =
                    templateList.find(property => property.sm_default === true) || templateList.find(property => property.default === true);
            }
            if (defaultTemplate) {
                setSelectedExportTemplate(defaultTemplate.id);
            }
        }
    }, [props.smartChartReducer.getTemplatePropertiesListResponse, props.smartChartReducer.getTemplateListResponse, selectedProperty]);

    useEffect(() => {
        if (props.smartChartReducer.deleteUserDocResponse?.success && selectedDoc) {
            showAlert("User Document deleted successfully");
            setSelectedDoc("");
        }
    }, [props.smartChartReducer.deleteUserDocResponse]);

    useEffect(() => {
        if (savedChartData) {
            if (_.isEqual(chartData, savedChartData) && !enableExport) {
                setEnableExport(true);
            }
            if (enableExport && !_.isEqual(chartData, savedChartData)) {
                setEnableExport(false);
            }
        }
    }, [chartData, savedChartData]);

    useEffect(() => {
        let projectList = props?.smartChartReducer?.masterFilterList?.project?.projects || [];
        if (
            (isSkeletonView ||
                !selectedProperty ||
                // (selectedProperty?.client_id === selectedClient && isEdit) ||
                selectedProperty?.client_id !== selectedClient) &&
            projectList.length &&
            changeDefaultProjectRef.current
        ) {
            changeDefaultProjectRef.current = false;
            let selectedProjectIds = [];
            if (projectList.length) {
                selectedProjectIds = [projectList[0]?.id];
                if (isSkeletonView) {
                    let normalRecomTiles = hasNormalRecomConfig(chartData.properties_text, "ids") || [];
                    if (normalRecomTiles.length) {
                        props.getExportSettingsForAllSmartChartRecomTiles({
                            project_id: projectList[0]?.id,
                            client_id: selectedClient,
                            skel_nrmlrm_settings_id: normalRecomTiles
                            // number_of_bands: normalRecomTiles
                        });
                    }
                }
                setSmartChartData("mfilter", {
                    mfilterKey: "project_ids",
                    bandName: "band1",
                    filterValues: selectedProjectIds,
                    entity: "project",
                    filterKey: "projects",
                    dropDownData: [...projectList] || [],
                    filterLabel: "Projects",
                    initialSetup: true
                });
            }
            setSmartChartData("mfilter", {
                mfilterKey: "project_ids",
                bandName: "band1",
                filterValues: selectedProjectIds,
                entity: "project",
                filterKey: "projects",
                dropDownData: [...projectList] || [],
                filterLabel: "Projects",
                initialSetup: true
            });
            setSmartChartData("mfilter", {
                mfilterKey: "project_ids",
                bandName: "band1",
                filterValues: selectedProjectIds,
                entity: "energy_mng",
                filterKey: "energy_mng_projects",
                dropDownData: [...projectList] || [],
                filterLabel: "Projects",
                initialSetup: true
            });
        }
    }, [props.smartChartReducer.masterFilterList]);

    useEffect(() => {
        if (props.smartChartReducer.allExportSettingsData?.data?.settings_ids?.length) {
            let settingsIdData = props.smartChartReducer.allExportSettingsData?.data?.settings_ids || [];
            const {
                project: {
                    band1: { type: projectTypes = {} }
                }
            } = chartData.properties_text;
            let currentIndex = 0;
            let updatedProjectTileData = Object.entries({ ...projectTypes }).reduce((resultData, [currentDataKey, currentDataValue]) => {
                if (currentDataKey.includes("normal_recom")) {
                    let currentSettingsId = settingsIdData?.[currentIndex];
                    currentIndex += 1;
                    return {
                        ...resultData,
                        [currentDataKey]: {
                            band1: {
                                ...currentDataValue?.band1,
                                settings_id: currentSettingsId
                            }
                        }
                    };
                }
                return { ...resultData, [currentDataKey]: currentDataValue };
            }, {});
            setChartData(prevState => {
                return {
                    ...prevState,
                    properties_text: {
                        ...prevState.properties_text,
                        project: {
                            ...prevState.properties_text.project,
                            band1: {
                                ...prevState.properties_text.project.band1,
                                type: {
                                    ...updatedProjectTileData
                                }
                            }
                        }
                    }
                };
            });
        }
    }, [props.smartChartReducer.allExportSettingsData]);

    const getUpdatedPropertyValueForEdit = propertyData => {
        let tempPropertyData = JSON.parse(propertyData);
        if (tempPropertyData?.project?.band1?.type?.subsystem) {
            if (tempPropertyData?.project?.band1?.type?.subsystem?.trade_ids?.length) {
                tempPropertyData.project.band1.mfilter = {
                    ...tempPropertyData.project.band1.mfilter,
                    trade_ids: [...tempPropertyData.project.band1.type.subsystem.trade_ids]
                };
            }
            if (tempPropertyData?.project?.band1?.type?.subsystem?.system_ids?.length) {
                tempPropertyData.project.band1.mfilter = {
                    ...tempPropertyData.project.band1.mfilter,
                    system_ids: [...tempPropertyData.project.band1.type.subsystem.system_ids]
                };
            }
            if (tempPropertyData?.project?.band1?.type?.subsystem?.subsystem_ids?.length) {
                tempPropertyData.project.band1.mfilter = {
                    ...tempPropertyData.project.band1.mfilter,
                    subsystem_ids: [...tempPropertyData.project.band1.type.subsystem.subsystem_ids]
                };
            }
            return tempPropertyData;
        }
        if (tempPropertyData?.project?.band1?.type?.system?.trade_ids?.length) {
            tempPropertyData.project.band1.mfilter = {
                ...tempPropertyData.project.band1.mfilter,
                trade_ids: [...tempPropertyData.project.band1.type.system.trade_ids]
            };
        }
        return tempPropertyData;
    };

    const setChartsDataForEdit = (isExcelExport = false) => {
        let newChartsOrderData = {};
        let currentPropertyData = JSON.parse(selectedProperty.properties_text) || {};
        let currentTileItems = isExcelExport ? CHART_ITEMS_FOR_EXCEL : CHART_ITEMS;
        let extra_energy_band_keys = ["em_eci_site_cost", "em_eci_energy_cost", "bld_brkdown_energy_cost"];
        newChartsOrderData = Object.keys(currentPropertyData).reduce((resultData, currentProp, index) => {
            let defaultKeys = Object.keys(currentTileItems[currentProp]);
            let selectedKeys = Object.keys(currentPropertyData[currentProp].band1.type).filter(key => !extra_energy_band_keys.includes(key));
            let updatedDefaultKeys = defaultKeys.filter(dKey => !selectedKeys.includes(dKey));
            return { ...resultData, [currentProp]: [...selectedKeys, ...updatedDefaultKeys] };
        }, {});
        return newChartsOrderData;
    };
    function checkIncludesNames(key) {
        const allElements = [...CA_RECOMMENDATION_ELEMENTS, ...CA_GEO_HIERARCHY_ELEMENTS];
        const [firstPart, secondPart] = key?.split("_")?.slice(0, 2);
        const thirdPart = key?.split("_")[2];
        const firstPartExists = allElements?.find(innerElement => innerElement?.key === firstPart);
        const secondPartExists = allElements?.find(innerElement => innerElement?.key === secondPart);
        const thirdPartIsUnderscore = thirdPart !== "";
        const includesNames = firstPartExists && secondPartExists && thirdPartIsUnderscore;
        if (includesNames) {
            return firstPartExists || secondPartExists;
        }
        return includesNames;
    }

    const setSmartChartData = (action, params = {}) => {
        const {
            bandName,
            chartKey,
            chartConfigData = {},
            mfilterKey = "",
            filterValues = [],
            defaultValue = {},
            orderedKeys = {},
            isSelectAll,
            docId,
            entity,
            filterKey,
            configOrder = [],
            dropDownData = [],
            filterLabel,
            mediaKey,
            additionalKeysForConfig = {},
            imageBandConfig = {},
            documentTileData = {},
            addedDocKeys = [],
            customTileKey,
            customTileData = {},
            isEditing = false,
            prevTileKey = "",
            isFilterValue = false,
            isYearFilter = false,
            paramKey = "",
            removeWildcardFilter = false,
            removeYearFilter = false,
            initialSetup = false
        } = params;
        switch (action) {
            case "mfilter":
                if (entity === "project" && mfilterKey === "years") {
                    setChartData(prevState => {
                        return {
                            ...prevState,
                            properties_text: {
                                ...prevState.properties_text,
                                [entity]: {
                                    ...prevState.properties_text[entity],
                                    [bandName]: {
                                        ...prevState.properties_text[entity][bandName],
                                        mfilter:
                                            isYearFilter && paramKey === "start_year"
                                                ? {
                                                      ...prevState.properties_text[entity][bandName].mfilter,
                                                      start_year: paramKey === "start_year" && filterValues.start
                                                  }
                                                : isYearFilter && paramKey === "end_year"
                                                ? {
                                                      ...prevState.properties_text[entity][bandName].mfilter,
                                                      end_year: paramKey === "end_year" && filterValues.end
                                                  }
                                                : {
                                                      ...removeMfilterEmptyKeys({
                                                          ...prevState.properties_text[entity][bandName].mfilter,
                                                          start: filterValues.start,
                                                          end: filterValues.end
                                                      })
                                                  },
                                        filter_label: prevState.properties_text[entity][bandName]?.filter_label
                                            ? {
                                                  ...removeMfilterEmptyKeys({
                                                      ...prevState.properties_text[entity][bandName].filter_label,
                                                      ...(paramKey === "start_year" && {
                                                          ["Start Year"]: filterValues.start ? [filterValues.start] : []
                                                      }),
                                                      ...(paramKey === "end_year" && { ["End Year"]: filterValues.end ? [filterValues.end] : [] })
                                                  })
                                              }
                                            : {
                                                  ...removeMfilterEmptyKeys({
                                                      ...(paramKey === "start_year" && {
                                                          ["Start Year"]: filterValues.start ? [filterValues.start] : []
                                                      }),
                                                      ...(paramKey === "end_year" && { ["End Year"]: filterValues.end ? [filterValues.end] : [] })
                                                  })
                                              }
                                    }
                                }
                            }
                        };
                    });
                } else if (mfilterKey === "energy_mng_custom_years") {
                    setChartData(prevState => {
                        return {
                            ...prevState,
                            properties_text: {
                                ...prevState.properties_text,
                                [entity]: {
                                    ...prevState.properties_text[entity],
                                    [bandName]: {
                                        ...prevState.properties_text[entity][bandName],
                                        mfilter: {
                                            ...removeMfilterEmptyKeys({
                                                ...prevState.properties_text[entity][bandName].mfilter,
                                                ...filterValues
                                            })
                                        }
                                        // filter_label: prevState.properties_text[entity][bandName]?.filter_label
                                        //     ? {
                                        //           ...removeMfilterEmptyKeys({
                                        //               ...prevState.properties_text[entity][bandName].filter_label,
                                        //               ["start_year"]: filterValues.start ? [filterValues.start] : [],
                                        //               ["end_year"]: filterValues.end ? [filterValues.end] : []
                                        //           })
                                        //       }
                                        //     : {
                                        //           ...removeMfilterEmptyKeys({
                                        //               ["start_Year"]: filterValues.start ? [filterValues.start] : [],
                                        //               ["end_Year"]: filterValues.end ? [filterValues.end] : []
                                        //           })
                                        //       }
                                    }
                                }
                            }
                        };
                    });
                } else {
                    if (
                        entity === "project" &&
                        mfilterKey === "building_ids" &&
                        Object.keys(chartData.properties_text.project?.band1?.type).join()?.includes("bbi_report") &&
                        !filterValues.length
                    ) {
                        removeBBITiles();
                    }
                    if (
                        entity === "project" &&
                        mfilterKey === "site_ids" &&
                        Object.keys(chartData.properties_text.project?.band1?.type).join()?.includes("bsi_report") &&
                        !filterValues.length
                    ) {
                        removeBSITiles();
                    }
                    if (isFilterValue) {
                        setChartData(prevState => {
                            return {
                                ...prevState,
                                properties_text: {
                                    ...prevState.properties_text,
                                    [entity]: {
                                        ...prevState.properties_text[entity],
                                        [bandName]: {
                                            ...prevState.properties_text[entity][bandName],
                                            mfilter: Object.keys(prevState.properties_text[entity][bandName].mfilter).reduce((filteredData, key) => {
                                                if (key === "client_ids" || key === "project_ids") {
                                                    filteredData[key] = prevState.properties_text[entity][bandName].mfilter[key];
                                                }
                                                return filteredData;
                                            }, {}),
                                            type: prevState.properties_text[entity][bandName]?.type
                                                ? Object.keys(prevState.properties_text[entity][bandName].type).reduce((acc, key) => {
                                                      if (key?.startsWith("multi_recommendation")) {
                                                          return {
                                                              ...acc,
                                                              [key]: {
                                                                  ...prevState.properties_text[entity][bandName].type[key],
                                                                  recommendations: [],
                                                                  number_of_recom: 0,
                                                                  config_filter: {},
                                                                  config_filter_names: {},
                                                                  mfilter: Object.keys(prevState.properties_text[entity][bandName].mfilter).reduce(
                                                                      (filteredData, keyValue) => {
                                                                          if (keyValue === "client_ids" || keyValue === "project_ids") {
                                                                              filteredData[keyValue] =
                                                                                  prevState.properties_text[entity][bandName].mfilter[keyValue];
                                                                          }
                                                                          return filteredData;
                                                                      },
                                                                      {}
                                                                  )
                                                              }
                                                          };
                                                      } else if (["normal_recom", "recom_onepager", "asset-onepager"].some(e => key?.startsWith(e))) {
                                                          let newConfigFilter = {
                                                              number_of_recom: 0,
                                                              recommendations: [],
                                                              config_filter: {},
                                                              config_filter_names: {},
                                                              mfilter: Object.keys(prevState.properties_text[entity][bandName].mfilter).reduce(
                                                                  (filteredData, keyValue) => {
                                                                      if (keyValue === "client_ids" || keyValue === "project_ids") {
                                                                          filteredData[keyValue] =
                                                                              prevState.properties_text[entity][bandName].mfilter[keyValue];
                                                                      }
                                                                      return filteredData;
                                                                  },
                                                                  {}
                                                              )
                                                          };
                                                          if (key?.startsWith("asset-onepager")) {
                                                              const { asset_ids, ...rest } = prevState.properties_text[entity][bandName].mfilter;

                                                              newConfigFilter = { ...newConfigFilter, mfilter: { ...rest } };
                                                          }
                                                          return {
                                                              ...acc,
                                                              [key]: {
                                                                  ...prevState.properties_text[entity][bandName]?.type[key],
                                                                  band1: {
                                                                      ...(prevState.properties_text[entity][bandName]?.type[key]?.band1 || {}),
                                                                      ...newConfigFilter,
                                                                      mfilter: Object.keys(
                                                                          prevState.properties_text[entity][bandName].mfilter
                                                                      ).reduce((filteredData, keyValue) => {
                                                                          if (keyValue === "client_ids" || keyValue === "project_ids") {
                                                                              filteredData[keyValue] =
                                                                                  prevState.properties_text[entity][bandName].mfilter[keyValue];
                                                                          }
                                                                          return filteredData;
                                                                      }, {})
                                                                      //   mfilter: {
                                                                      //       recommendations: []
                                                                      //   }
                                                                  }
                                                              }
                                                          };
                                                      } else if (
                                                          key === "system" &&
                                                          prevState.properties_text[entity][bandName]?.type[key]?.normal_recom
                                                      ) {
                                                          return {
                                                              ...acc,
                                                              [key]: {
                                                                  ...prevState.properties_text[entity][bandName]?.type[key],
                                                                  normal_recom: {
                                                                      ...prevState.properties_text[entity][bandName]?.type[key]?.normal_recom,
                                                                      band1: {
                                                                          ...prevState.properties_text[entity][bandName]?.type[key]?.normal_recom
                                                                              ?.band1,
                                                                          mfilter: Object.keys(
                                                                              prevState.properties_text[entity][bandName].mfilter
                                                                          ).reduce((filteredData, keyValue) => {
                                                                              if (keyValue === "client_ids" || keyValue === "project_ids") {
                                                                                  filteredData[keyValue] =
                                                                                      prevState.properties_text[entity][bandName].mfilter[keyValue];
                                                                              }
                                                                              return filteredData;
                                                                          }, {})
                                                                      }
                                                                  }
                                                              }
                                                          };
                                                      }
                                                      return { ...acc, [key]: prevState.properties_text[entity][bandName]?.type[key] };
                                                  }, {})
                                                : {},
                                            filter_label: { Projects: prevState.properties_text[entity]?.[bandName]?.filter_label?.["Projects"] }
                                        }
                                    }
                                }
                            };
                        });
                    }
                    if (removeWildcardFilter || removeYearFilter) {
                        setChartData(prevState => {
                            return {
                                ...prevState,
                                properties_text: {
                                    ...prevState.properties_text,
                                    [entity]: {
                                        ...prevState.properties_text[entity],
                                        [bandName]: {
                                            ...prevState.properties_text[entity][bandName],
                                            mfilter:
                                                prevState.properties_text[entity][bandName].mfilter &&
                                                Object.keys(prevState.properties_text[entity][bandName].mfilter)?.reduce((acc, key) => {
                                                    const value = prevState.properties_text[entity][bandName]?.mfilter[key];
                                                    if (removeWildcardFilter && removeYearFilter) {
                                                        if (
                                                            key !== "filters" &&
                                                            key !== "start_year" &&
                                                            key !== "end_year" &&
                                                            key !== "update_fci" &&
                                                            !(Array.isArray(value) && value?.length === 0) &&
                                                            key !== ""
                                                        ) {
                                                            acc[key] = value;
                                                        }
                                                    } else if (removeWildcardFilter) {
                                                        if (key !== "filters" && !(Array.isArray(value) && value?.length === 0) && key !== "") {
                                                            acc[key] = value;
                                                        }
                                                    } else if (removeYearFilter) {
                                                        if (key !== "start_year" || key !== "end_year" || key !== "update_fci") {
                                                            acc[key] = value;
                                                        }
                                                    }
                                                    return acc;
                                                }, {}),
                                            type: { ...prevState.properties_text[entity][bandName]?.type },
                                            filter_label: {
                                                ...removeMfilterEmptyKeys({
                                                    ...Object.fromEntries(
                                                        Object.entries(prevState?.properties_text?.[entity]?.[bandName]?.filter_label || {})
                                                            .filter(([key, value]) => {
                                                                const wildCardLabels = [...(filterFields || []), ...(yearField || [])]
                                                                    .filter(e => e?.isWildCardFilter === true)
                                                                    .map(e => String(e?.label));
                                                                if (removeWildcardFilter && removeYearFilter) {
                                                                    return (
                                                                        !wildCardLabels.includes(String(key)) &&
                                                                        !(key === "Start Year" || key === "End Year")
                                                                    );
                                                                } else if (removeWildcardFilter) {
                                                                    const wildCardLabels = [...(filterFields || []), ...(yearField || [])]
                                                                        .filter(e => e?.isWildCardFilter === true)
                                                                        .map(e => String(e?.label));
                                                                    return !wildCardLabels?.includes(String(key));
                                                                } else if (removeYearFilter) {
                                                                    return !(key === "Start Year" || key === "End Year");
                                                                }
                                                            })
                                                            ?.map(([key, value]) => [key, Array.isArray(value) ? value : [value]])
                                                    )
                                                })
                                            }
                                        }
                                    }
                                }
                            };
                        });
                    }
                    let filterNames = [];
                    let topName = [];
                    if (filterLabel === "filters" || filterLabel === "top_n") {
                        uniqBy(filterFields, "key").forEach(fi => {
                            if (filterLabel === "filters") {
                                Object.entries(filterValues).forEach(([wKey, wValue]) => {
                                    if (fi.paramKey === wKey) {
                                        filterNames.push({
                                            name: fi.label,
                                            value:
                                                fi.paramKey === "buildings.redline_completed_date"
                                                    ? [`${wValue?.filters?.[0]} ${wValue?.key?.from}-${wValue?.key?.to}`]
                                                    : [`${wValue?.key || ""} [${wValue?.filters?.join(",")}]`]
                                        });
                                    }
                                });
                            } else if (filterLabel === "top_n") {
                                Object.entries(filterValues).forEach(([wKey, wValue]) => {
                                    if (fi.paramKey === wKey) {
                                        topName.push({
                                            name: fi.label,
                                            value: [`${wValue || ""} [Top N]`]
                                        });
                                    }
                                });
                            }
                            return filterNames;
                        });
                    }
                    setChartData(prevState => {
                        return {
                            ...prevState,
                            properties_text: {
                                ...prevState.properties_text,
                                [entity]: {
                                    ...prevState.properties_text[entity],
                                    [bandName]: {
                                        ...prevState.properties_text[entity][bandName],
                                        mfilter: {
                                            ...(filterKey === "filters" || filterKey === "top_n"
                                                ? {
                                                      ...prevState.properties_text[entity][bandName].mfilter,
                                                      [mfilterKey]: filterValues?.length > 0 ? [...filterValues] : filterValues
                                                  }
                                                : removeMfilterEmptyKeys({
                                                      ...prevState.properties_text[entity][bandName].mfilter,
                                                      [mfilterKey]: filterValues?.length > 0 ? [...filterValues] : filterValues
                                                  }))
                                        },
                                        type: prevState.properties_text[entity][bandName]?.type
                                            ? Object.keys(prevState.properties_text[entity][bandName].type).reduce((acc, key) => {
                                                  if (key?.startsWith("multi_recommendation")) {
                                                      return {
                                                          ...acc,
                                                          [key]: {
                                                              ...prevState.properties_text[entity][bandName].type[key],
                                                              recommendations: [],
                                                              number_of_recom: 0,
                                                              config_filter: {},
                                                              config_filter_names: {}
                                                          }
                                                      };
                                                  } else if (["normal_recom", "recom_onepager", "asset-onepager"].some(e => key?.startsWith(e))) {
                                                      let newConfigFilter = {
                                                          number_of_recom: 0,
                                                          recommendations: [],
                                                          config_filter: {},
                                                          config_filter_names: {}
                                                      };
                                                      if (key?.startsWith("asset-onepager")) {
                                                          const { asset_ids, ...rest } = prevState.properties_text[entity][bandName].mfilter;

                                                          newConfigFilter = { ...newConfigFilter, mfilter: { ...rest } };
                                                      }
                                                      return {
                                                          ...acc,
                                                          [key]: {
                                                              ...prevState.properties_text[entity][bandName]?.type[key],
                                                              band1: {
                                                                  ...(prevState.properties_text[entity][bandName]?.type[key]?.band1 || {}),
                                                                  ...newConfigFilter,
                                                                  mfilter: {
                                                                      ...(prevState.properties_text[entity][bandName]?.type[key]?.band1?.mfilter ||
                                                                          {}),
                                                                      recommendations: []
                                                                  }
                                                              }
                                                          }
                                                      };
                                                  } else if (
                                                      key === "system" &&
                                                      prevState.properties_text[entity][bandName]?.type[key]?.normal_recom
                                                  ) {
                                                      return {
                                                          ...acc,
                                                          [key]: {
                                                              ...prevState.properties_text[entity][bandName]?.type[key],
                                                              normal_recom: {
                                                                  ...prevState.properties_text[entity][bandName]?.type[key]?.normal_recom,
                                                                  band1: {
                                                                      ...prevState.properties_text[entity][bandName]?.type[key]?.normal_recom?.band1,
                                                                      mfilter: {
                                                                          ...(filterKey === "filters"
                                                                              ? {
                                                                                    ...prevState.properties_text[entity][bandName].mfilter,
                                                                                    [mfilterKey]:
                                                                                        filterValues?.length > 0 ? [...filterValues] : filterValues
                                                                                }
                                                                              : removeMfilterEmptyKeys({
                                                                                    ...prevState.properties_text[entity][bandName]?.mfilter,
                                                                                    [mfilterKey]:
                                                                                        filterValues?.length > 0 ? [...filterValues] : filterValues
                                                                                }))
                                                                      }
                                                                  }
                                                              }
                                                          }
                                                      };
                                                  }
                                                  if (checkIncludesNames(key)) {
                                                      const s = checkIncludesNames(key);
                                                      const updatedType = { ...prevState.properties_text[entity][bandName]?.type[key] };
                                                      if (
                                                          s?.paramKey === "trade_ids" ||
                                                          s?.paramKey === "system_ids" ||
                                                          s?.paramKey === "subsystem_ids"
                                                      ) {
                                                          updatedType.trade_ids = [];
                                                          updatedType.config_filter_names = {
                                                              ...updatedType?.config_filter_names,
                                                              CA_BandFilter: {
                                                                  ...updatedType?.config_filter_names?.CA_BandFilter,
                                                                  Trade: []
                                                              }
                                                          };
                                                          if (s?.paramKey === "system_ids" || s?.paramKey === "subsystem_ids") {
                                                              updatedType.system_ids = [];
                                                              updatedType.config_filter_names = {
                                                                  ...updatedType?.config_filter_names,
                                                                  CA_BandFilter: {
                                                                      ...updatedType?.config_filter_names?.CA_BandFilter,
                                                                      System: []
                                                                  }
                                                              };
                                                              if (s?.paramKey === "subsystem_ids") {
                                                                  updatedType.subsystem_ids = [];
                                                              }
                                                              updatedType.config_filter_names = {
                                                                  ...updatedType?.config_filter_names,
                                                                  CA_BandFilter: {
                                                                      ...updatedType?.config_filter_names?.CA_BandFilter,
                                                                      "Sub-System": []
                                                                  }
                                                              };
                                                          }
                                                      } else {
                                                          updatedType[s?.paramKey] = [];
                                                          updatedType.config_filter_names = {
                                                              ...updatedType?.config_filter_names,
                                                              CA_BandFilter: {
                                                                  ...updatedType?.config_filter_names?.CA_BandFilter,
                                                                  [s?.label]: []
                                                              }
                                                          };
                                                      }
                                                      return {
                                                          ...acc,
                                                          [key]: updatedType
                                                      };
                                                  }
                                                  return { ...acc, [key]: prevState.properties_text[entity][bandName]?.type[key] };
                                              }, {})
                                            : {},
                                        filter_label: prevState.properties_text[entity][bandName]?.filter_label
                                            ? {
                                                  ...(filterLabel === "filters"
                                                      ? {
                                                            ...removeMfilterEmptyKeys({
                                                                ...Object.fromEntries(
                                                                    Object.entries(
                                                                        prevState?.properties_text?.[entity]?.[bandName]?.filter_label || {}
                                                                    )
                                                                        .filter(([key, value]) => {
                                                                            const wildCardLabels = [...(filterFields || []), ...(yearField || [])]
                                                                                .filter(e => e.isWildCardFilter === true)
                                                                                .map(e => String(e.label));
                                                                            return !wildCardLabels.includes(String(key));
                                                                        })
                                                                        .map(([key, value]) => [key, Array.isArray(value) ? value : [value]])
                                                                ),
                                                                ...filterNames?.reduce((acc, e) => {
                                                                    acc[e.name] = e.value;
                                                                    return acc;
                                                                }, {})
                                                            })
                                                        }
                                                      : filterLabel === "top_n"
                                                      ? {
                                                            ...prevState.properties_text[entity][bandName].filter_label,
                                                            ...topName?.reduce((acc, e) => {
                                                                acc[e.name] = e.value;
                                                                return acc;
                                                            }, {})
                                                        }
                                                      : {
                                                            ...removeMfilterEmptyKeys({
                                                                ...(prevState?.properties_text?.[entity]?.[bandName]?.filter_label || {}),
                                                                ...(Array.isArray(dropDownData) && {
                                                                    [filterLabel]: getSelectedFilterNameFormIds(dropDownData, filterValues)
                                                                })
                                                            })
                                                        })
                                              }
                                            : {
                                                  ...removeMfilterEmptyKeys({
                                                      [filterLabel]: [...getSelectedFilterNameFormIds(dropDownData, filterValues)]
                                                  })
                                              }
                                    }
                                }
                            }
                        };
                    });
                }

                break;
            case "customize_chart":
                if (params?.is_remove) {
                    setChartData(prevState => {
                        return {
                            ...prevState,
                            properties_text: {
                                ...prevState.properties_text,
                                [entity]: {
                                    ...prevState.properties_text[entity],
                                    [bandName]: {
                                        ...prevState.properties_text[entity][bandName],
                                        type: {
                                            ..._.omit(prevState.properties_text[entity][bandName].type, [chartKey])
                                        }
                                    }
                                }
                            }
                        };
                    });
                } else {
                    let prevChartConfig = copyChartData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey] || null;
                    setChartData(prevState => {
                        return {
                            ...prevState,
                            properties_text: {
                                ...prevState.properties_text,
                                [entity]: {
                                    ...prevState.properties_text[entity],
                                    [bandName]: {
                                        ...prevState.properties_text[entity][bandName],
                                        type: {
                                            ...shiftObjectProps(
                                                {
                                                    ...prevState.properties_text[entity][bandName].type,
                                                    [chartKey]:
                                                        chartKey === "system"
                                                            ? {
                                                                  ...(prevChartConfig ? prevChartConfig : defaultValue),
                                                                  normal_recom: {
                                                                      band1: {
                                                                          ...prevChartConfig?.normal_recom?.band1,
                                                                          mfilter: {
                                                                              ...(prevChartConfig
                                                                                  ? prevChartConfig?.normal_recom?.band1?.mfilter
                                                                                  : defaultValue?.normal_recom?.band1?.mfilter),
                                                                              ...chartData?.properties_text?.project?.band1?.mfilter
                                                                          }
                                                                      }
                                                                  }
                                                              }
                                                            : prevChartConfig
                                                            ? prevChartConfig
                                                            : defaultValue
                                                },
                                                chartsOrder[entity]
                                            )
                                        }
                                    }
                                }
                            }
                        };
                    });
                }
                break;
            case "config_chart":
                let newChartData = {};
                if (chartKey === "sorted_recom") {
                    newChartData = {
                        ...chartData,
                        properties_text: {
                            ...chartData.properties_text,
                            [entity]: {
                                ...chartData.properties_text[entity],
                                [bandName]: {
                                    ...chartData.properties_text[entity][bandName],
                                    type: {
                                        ...chartData.properties_text[entity][bandName].type,
                                        [chartKey]: {
                                            band1: {
                                                mfilter: {},
                                                ...additionalKeysForConfig
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    };
                    setCopyChartData(prevState => {
                        return {
                            ...prevState,
                            properties_text: {
                                ...prevState.properties_text,
                                [entity]: {
                                    ...prevState.properties_text[entity],
                                    [bandName]: {
                                        ...prevState.properties_text[entity][bandName],
                                        type: {
                                            ...prevState.properties_text[entity][bandName].type,
                                            [chartKey]: {
                                                band1: {
                                                    mfilter: {},
                                                    ...additionalKeysForConfig
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        };
                    });
                } else if (
                    chartKey.includes("multi_recommendation") ||
                    chartKey.includes("normal_recom") ||
                    chartKey.includes("recom_onepager") ||
                    chartKey.includes("asset-onepager") ||
                    chartKey.includes("memo_field") ||
                    chartKey.includes("asset_band")
                ) {
                    newChartData = {
                        ...chartData,
                        properties_text: {
                            ...chartData.properties_text,
                            [entity]: {
                                ...chartData.properties_text[entity],
                                [bandName]: {
                                    ...chartData.properties_text[entity][bandName],
                                    type: {
                                        ...chartData.properties_text[entity][bandName].type,
                                        [chartKey]: {
                                            ...chartConfigData
                                        }
                                    }
                                }
                            }
                        }
                    };
                    setCopyChartData(prevState => {
                        return {
                            ...prevState,
                            properties_text: {
                                ...prevState.properties_text,
                                [entity]: {
                                    ...prevState.properties_text[entity],
                                    [bandName]: {
                                        ...prevState.properties_text[entity][bandName],
                                        type: {
                                            ...prevState.properties_text[entity][bandName].type,
                                            [chartKey]: {
                                                ...chartConfigData
                                            }
                                        }
                                    }
                                }
                            }
                        };
                    });
                } else {
                    newChartData = {
                        ...chartData,
                        properties_text: {
                            ...chartData.properties_text,
                            [entity]: {
                                ...chartData.properties_text[entity],
                                [bandName]: {
                                    ...chartData.properties_text[entity][bandName],
                                    type: {
                                        ...chartData.properties_text[entity][bandName].type,
                                        [chartKey]: { ...shiftObjectProps({ ...chartConfigData }, [...configOrder]), ...additionalKeysForConfig }
                                    }
                                }
                            }
                        }
                    };
                    setCopyChartData(prevState => {
                        return {
                            ...prevState,
                            properties_text: {
                                ...prevState.properties_text,
                                [entity]: {
                                    ...prevState.properties_text[entity],
                                    [bandName]: {
                                        ...prevState.properties_text[entity][bandName],
                                        type: {
                                            ...prevState.properties_text[entity][bandName].type,
                                            [chartKey]: { ...shiftObjectProps({ ...chartConfigData }, [...configOrder]), ...additionalKeysForConfig }
                                        }
                                    }
                                }
                            }
                        };
                    });
                    setChartConfigOrder(prevChartConfigOrder => {
                        return {
                            ...prevChartConfigOrder,
                            [chartKey]: [...configOrder]
                        };
                    });
                }
                setChartData({ ...newChartData });
                break;
            case "dnd":
                setChartsOrder(prevOrder => {
                    return {
                        ...prevOrder,
                        [entity]: orderedKeys
                    };
                });
                setChartData(prevState => {
                    return {
                        ...prevState,
                        properties_text: {
                            ...prevState.properties_text,
                            [entity]: {
                                ...prevState.properties_text[entity],
                                [bandName]: {
                                    ...prevState.properties_text[entity][bandName],
                                    type: {
                                        ...shiftObjectProps(
                                            {
                                                ...prevState.properties_text[entity][bandName].type
                                            },
                                            orderedKeys
                                        )
                                    }
                                }
                            }
                        }
                    };
                });
                break;
            case "selectAll":
                if (!isSelectAll) {
                    setChartData(prevState => {
                        return {
                            ...prevState,
                            properties_text: {
                                ...prevState.properties_text,
                                [entity]: {
                                    ...prevState.properties_text[entity],
                                    [bandName]: {
                                        ...prevState.properties_text[entity][bandName],
                                        type: {}
                                    }
                                }
                            }
                        };
                    });
                } else {
                    let prevChartTypes = { ...copyChartData.properties_text[entity].band1.type };
                    setChartData(prevState => {
                        return {
                            ...prevState,
                            properties_text: {
                                ...prevState.properties_text,
                                [entity]: {
                                    ...prevState.properties_text[entity],
                                    [bandName]: {
                                        ...prevState.properties_text[entity][bandName],
                                        type: {
                                            ...shiftObjectProps(
                                                {
                                                    ...prevChartTypes
                                                },
                                                chartsOrder[entity]
                                            )
                                        }
                                    }
                                }
                            }
                        };
                    });
                }
                break;
            case "docdnd":
                let mediaData = mediaKey === "user_doc" ? [...userDocs] : [...userImages];
                let selectedDoc = mediaData.find(doc => doc.id === docId);
                setCopyChartData(prevState => {
                    return {
                        ...prevState,
                        properties_text: {
                            ...prevState.properties_text,
                            [entity]: {
                                ...prevState.properties_text[entity],
                                [bandName]: {
                                    ...prevState.properties_text[entity][bandName],
                                    type: {
                                        ...prevState.properties_text[entity][bandName].type,
                                        [mediaKey + docId]: {
                                            id: selectedDoc?.id,
                                            name: selectedDoc?.name || selectedDoc?.gallery_image?.caption || ""
                                        }
                                    }
                                }
                            }
                        }
                    };
                });
                setChartsOrder(prevOrder => {
                    return {
                        ...prevOrder,
                        [entity]: orderedKeys
                    };
                });
                setChartData(prevState => {
                    return {
                        ...prevState,
                        properties_text: {
                            ...prevState.properties_text,
                            [entity]: {
                                ...prevState.properties_text[entity],
                                [bandName]: {
                                    ...prevState.properties_text[entity][bandName],
                                    type: {
                                        ...shiftObjectProps(
                                            {
                                                ...prevState.properties_text[entity][bandName].type,
                                                [mediaKey + docId]: {
                                                    id: selectedDoc?.id,
                                                    name: selectedDoc?.name || selectedDoc?.gallery_image?.caption || ""
                                                }
                                            },
                                            orderedKeys
                                        )
                                    }
                                }
                            }
                        }
                    };
                });
                break;
            case "custom_tile":
                setCopyChartData(prevState => {
                    return {
                        ...prevState,
                        properties_text: {
                            ...prevState.properties_text,
                            [entity]: {
                                ...prevState.properties_text[entity],
                                [bandName]: {
                                    ...prevState.properties_text[entity][bandName],
                                    type: {
                                        ...prevState.properties_text[entity][bandName].type,
                                        [customTileKey]: { ...customTileData }
                                    }
                                }
                            }
                        }
                    };
                });
                if (!isEditing) {
                    setChartsOrder(prevOrder => {
                        return {
                            ...prevOrder,
                            [entity]: [customTileKey, ...prevOrder[entity]]
                        };
                    });
                    setChartData(prevState => {
                        return {
                            ...prevState,
                            properties_text: {
                                ...prevState.properties_text,
                                [entity]: {
                                    ...prevState.properties_text[entity],
                                    [bandName]: {
                                        ...prevState.properties_text[entity][bandName],
                                        type: {
                                            [customTileKey]: { ...customTileData },
                                            ...prevState.properties_text[entity][bandName].type
                                        }
                                    }
                                }
                            }
                        };
                    });
                } else {
                    setChartData(prevState => {
                        return {
                            ...prevState,
                            properties_text: {
                                ...prevState.properties_text,
                                [entity]: {
                                    ...prevState.properties_text[entity],
                                    [bandName]: {
                                        ...prevState.properties_text[entity][bandName],
                                        type: {
                                            ...prevState.properties_text[entity][bandName].type,
                                            [customTileKey]: { ...customTileData }
                                        }
                                    }
                                }
                            }
                        };
                    });
                }
                break;
            case "config_image_band":
                setCopyChartData(prevState => {
                    return {
                        ...prevState,
                        properties_text: {
                            ...prevState.properties_text,
                            [entity]: {
                                ...prevState.properties_text[entity],
                                [bandName]: {
                                    ...prevState.properties_text[entity][bandName],
                                    type: {
                                        ...prevState.properties_text[entity][bandName].type,
                                        [chartKey]: { ...prevState.properties_text[entity][bandName].type[chartKey], ...imageBandConfig }
                                    }
                                }
                            }
                        }
                    };
                });
                setChartData(prevChartData => {
                    return {
                        ...prevChartData,
                        properties_text: {
                            ...prevChartData.properties_text,
                            [entity]: {
                                ...prevChartData.properties_text[entity],
                                [bandName]: {
                                    ...prevChartData.properties_text[entity][bandName],
                                    type: {
                                        ...prevChartData.properties_text[entity][bandName].type,
                                        [chartKey]: { ...prevChartData.properties_text[entity][bandName].type[chartKey], ...imageBandConfig }
                                    }
                                }
                            }
                        }
                    };
                });
                break;
            case "delete_image_band":
                setChartData(prevState => {
                    return {
                        ...prevState,
                        properties_text: {
                            ...prevState.properties_text,
                            [entity]: {
                                ...prevState.properties_text[entity],
                                [bandName]: {
                                    ...prevState.properties_text[entity][bandName],
                                    type: {
                                        ..._.omit(prevState.properties_text[entity][bandName].type, [chartKey])
                                    }
                                }
                            }
                        }
                    };
                });
                setCopyChartData(prevState => {
                    return {
                        ...prevState,
                        properties_text: {
                            ...prevState.properties_text,
                            [entity]: {
                                ...prevState.properties_text[entity],
                                [bandName]: {
                                    ...prevState.properties_text[entity][bandName],
                                    type: {
                                        ..._.omit(prevState.properties_text[entity][bandName].type, [chartKey])
                                    }
                                }
                            }
                        }
                    };
                });
                setChartsOrder(prevOrder => {
                    let prevKeyOrder = [...prevOrder[entity]];
                    prevKeyOrder = prevKeyOrder.filter(o => o !== chartKey);
                    return {
                        ...prevOrder,
                        [entity]: [...prevKeyOrder]
                    };
                });
                break;
            case "add_document_tile":
                setCopyChartData(prevState => {
                    return {
                        ...prevState,
                        properties_text: {
                            ...prevState.properties_text,
                            [entity]: {
                                ...prevState.properties_text[entity],
                                [bandName]: {
                                    ...prevState.properties_text[entity][bandName],
                                    type: {
                                        ...documentTileData,
                                        ...prevState.properties_text[entity][bandName].type
                                    }
                                }
                            }
                        }
                    };
                });
                setChartsOrder(prevOrder => {
                    return {
                        ...prevOrder,
                        [entity]: [...addedDocKeys, ...prevOrder[entity]]
                    };
                });
                setChartData(prevState => {
                    return {
                        ...prevState,
                        properties_text: {
                            ...prevState.properties_text,
                            [entity]: {
                                ...prevState.properties_text[entity],
                                [bandName]: {
                                    ...prevState.properties_text[entity][bandName],
                                    type: {
                                        ...documentTileData,

                                        ...prevState.properties_text[entity][bandName].type
                                    }
                                }
                            }
                        }
                    };
                });
                break;
            case "replace_ca_band":
                let allKeysofCurrentBand = [...chartsOrder[entity]];
                let oldIndex = allKeysofCurrentBand.indexOf(prevTileKey);
                allKeysofCurrentBand[oldIndex] = customTileKey;
                setCopyChartData(prevState => {
                    return {
                        ...prevState,
                        properties_text: {
                            ...prevState.properties_text,
                            [entity]: {
                                ...prevState.properties_text[entity],
                                [bandName]: {
                                    ...prevState.properties_text[entity][bandName],
                                    type: {
                                        ...prevState.properties_text[entity][bandName].type,
                                        [customTileKey]: { ...customTileData }
                                    }
                                }
                            }
                        }
                    };
                });
                setChartsOrder(prevOrder => {
                    return {
                        ...prevOrder,
                        [entity]: allKeysofCurrentBand
                    };
                });
                setChartData(prevState => {
                    return {
                        ...prevState,
                        properties_text: {
                            ...prevState.properties_text,
                            [entity]: {
                                ...prevState.properties_text[entity],
                                [bandName]: {
                                    ...prevState.properties_text[entity][bandName],
                                    type: {
                                        ...shiftObjectProps(
                                            {
                                                ...prevState.properties_text[entity][bandName].type,
                                                [customTileKey]: { ...customTileData }
                                            },
                                            allKeysofCurrentBand
                                        )
                                    }
                                }
                            }
                        }
                    };
                });
                break;
            default:
        }
        if (initialSetup) {
            setIsInitialChartStateUpdated(true);
        }
    };
    const getSelectedFilterNameFormIds = (dropDownData = [], selectedIds = []) => {
        let filteredNames = [];
        if (selectedIds.length) {
            dropDownData.map(data => {
                if (selectedIds.includes(data.id)) {
                    filteredNames.push(data.name + (data.description ? " (" + data.description + ")" : ""));
                }
            });
        }
        return filteredNames;
    };

    const removeMfilterEmptyKeys = (data, keyList = []) => {
        let shiftedObjectData = {};
        shiftedObjectData = Object.keys(data).reduce((resultData, currentProp) => {
            if (
                (currentProp === "start" ||
                    currentProp === "end" ||
                    currentProp === "start_year" ||
                    currentProp === "end_year" ||
                    currentProp === "update_fci") &&
                data[currentProp] === ""
            ) {
                return resultData;
            } else if (
                currentProp !== "start" &&
                currentProp !== "end" &&
                currentProp !== "start_year" &&
                currentProp !== "end_year" &&
                currentProp !== "update_fci" &&
                !data[currentProp]?.length
            ) {
                return resultData;
            }
            return { ...resultData, [currentProp]: data[currentProp] };
        }, {});
        return shiftedObjectData;
    };

    const rearrangeBands = (bands = {}) => {
        let resultBand = Object.keys(bands).reduce((bandData, currentBand, index) => {
            return { ...bandData, [`band${index + 1}`]: bands[currentBand] };
        }, {});
        return resultBand;
    };

    const exportSmartChart = async () => {
        if (props.smartChartReducer.saveSmartChartDataResponse?.id) {
            await exportSmartChartData({ property_id: props.smartChartReducer.saveSmartChartDataResponse.id });
            showLongAlert("Report generation initiated and will be ready for download shortly. Please click the Refresh button to check for status.");
            props.history.push("/smartcharts/reports");
        }
    };

    const showAlert = msg => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show";
            x.innerText = msg;
            setTimeout(function () {
                x.className = x.className.replace("show", "");
            }, 3000);
        }
    };

    const showLongAlert = msg => {
        var x = document.getElementById("sucess-alert");
        if (x) {
            x.className = "show-long-alert";
            x.innerText = msg;
            setTimeout(function () {
                x.className = x.className.replace("show-long-alert", "");
            }, 6000);
        }
    };
    const setAssetSettingsId = settingsId => {
        let currentBandData = selectedChartConfigData?.selectedChartConfig || {};
        let AssetConfig = {
            ...currentBandData,
            settings_id: settingsId
        };
        setSmartChartData("config_chart", {
            ...selectedChartConfigData,
            chartConfigData: AssetConfig,
            defaultValue: selectedChartConfigData?.selectedChartConfig || null
        });
    };
    const renderExportSettings = () => {
        if (!showAssetExportSetting) return null;
        return (
            <Portal
                body={
                    <ViewExportModal
                        onCancel={() => {
                            setShowAssetExportSetting(false);
                        }}
                        isAssetExportSettingsview={showAssetExportSetting}
                        clientIdForSmartChart={selectedClient}
                        projectIdForSmartChart={chartData?.properties_text?.project?.band1?.mfilter?.project_ids[0]}
                        setNormalRecomData={settingsId => setAssetSettingsId(settingsId)}
                        smartChartTileConfig={
                            chartData.properties_text?.[selectedChartConfigData?.entity]?.band1?.type?.[selectedChartConfigData?.chartKey]
                        }
                    />
                }
                onCancel={() => setShowAssetExportSetting(false)}
            />
        );
    };
    const renderChartItemConfigModal = () => {
        if (!showChartItemConfigModal) return null;
        return (
            <Portal
                body={
                    <ChartItemConfigModal
                        setSmartChartData={setSmartChartData}
                        chartData={chartData}
                        configData={selectedChartConfigData}
                        onCancel={() => setShowChartItemConfigModal(false)}
                        defaultData={copyChartData.properties_text}
                        configOrder={chartConfigOrder}
                        isExcelExport={isExcelExport}
                        isSkeletonView={isSkeletonView}
                        updatePageBreakConfig={updatePageBreakConfig}
                        selectedClient={selectedClient}
                    />
                }
                onCancel={() => setShowChartItemConfigModal(false)}
            />
        );
    };
    const onBbiConfigSave = (ids, simpleNarrativeText) => {
        const { entity = "", chartKey = "", bandName = "" } = selectedChartConfigData;
        if (chartKey === "BBI") {
            setChartData(prevChartData => {
                return {
                    ...prevChartData,
                    properties_text: {
                        ...prevChartData.properties_text,
                        [entity]: {
                            ...prevChartData.properties_text[entity],
                            [bandName]: {
                                ...prevChartData.properties_text[entity][bandName],
                                type: {
                                    ...prevChartData.properties_text[entity][bandName].type,
                                    ["bbi_report1"]: {
                                        band1: {
                                            ...prevChartData.properties_text[entity][bandName].type["bbi_report1"]?.band1,
                                            mfilter: {
                                                ...prevChartData.properties_text[entity][bandName].type["bbi_report1"]?.band1?.mfilter
                                            },
                                            executive_summary_ids: ids,
                                            simple_text: simpleNarrativeText
                                        }
                                    }
                                }
                            }
                        }
                    }
                };
            });
            setShowBbiConfigModal(false);
        }
    };
    const renderBbiConfigModal = () => {
        if (!showBbiConfigModal) return null;
        const { entity = "", bandName = "" } = selectedChartConfigData;
        let selectedTileData = chartData?.properties_text?.[entity]?.[bandName]?.type?.["bbi_report1"]
            ? { ...chartData?.properties_text?.[entity]?.[bandName]?.type?.["bbi_report1"] }
            : {};
        return (
            <Portal
                body={
                    <BbiConfigModal
                        trade_ids={selectedTileData?.band1?.executive_summary_ids}
                        simpleNarrativeTextValue={selectedTileData?.band1?.simple_text}
                        onCancel={() => setShowBbiConfigModal(false)}
                        heading={"BBI"}
                        onSave={(ids, simpleNarrativeText) => onBbiConfigSave(ids, simpleNarrativeText)}
                        getListParams={chartData.properties_text?.project?.band1?.mfilter}
                        btnName="Save"
                    />
                }
                onCancel={() => setShowBbiConfigModal(false)}
            />
        );
    };

    const openChartItemConfigModal = params => {
        const { entity, chartKey, bandName, isImageBand = false, recomType = null, isMemo = false } = params;
        let selectedChartConfig = chartData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey]
            ? { ...chartData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey] }
            : {};
        dispatch(Action.clearEntityParams());
        setSelectedChartConfigData({ ...params, selectedChartConfig });
        if (isImageBand) {
            setShowImageBandConfigModal(true);
        } else if (isMemo) {
            setShowRichTextPopup({ label: "Text Band", value: selectedChartConfig?.text || "", show: true, key: "Text Band" });
        } else if (recomType) {
            setRecommendationBandType(recomType);
            setShowMultiRecommendationModal(true);
            setConfigEntity(entity);
        } else if (chartKey === "BBI") {
            setShowBbiConfigModal(true);
        } else if (chartKey === "asset_band") {
            setShowAssetExportSetting(true);
        } else {
            setShowChartItemConfigModal(true);
        }
    };
    const saveSmartChartConfigData = async (isExport = false, isSaveAs = false, templateData = null, corereportData) => {
        if (validate()) {
            let tempSaveChartData = { ...chartData };

            if (chartData?.properties_text?.project?.band1?.mfilter && chartData.properties_text.project.band1.type?.sorted_recom) {
                tempSaveChartData.properties_text.project.band1.type.sorted_recom.band1.mfilter = {
                    ...chartData.properties_text.project.band1.mfilter
                };
            }
            if (chartData.properties_text.project.band1.type?.system) {
                tempSaveChartData.properties_text.project.band1.type.system = {
                    trade_ids: chartData?.properties_text?.project?.band1?.mfilter?.trade_ids?.length
                        ? [...chartData.properties_text.project.band1.mfilter.trade_ids]
                        : [],
                    system_ids: chartData?.properties_text?.project?.band1?.mfilter?.system_ids?.length
                        ? [...chartData.properties_text.project.band1.mfilter.system_ids]
                        : [],
                    ...chartData.properties_text.project.band1.type.system
                };

                if (
                    chartData.properties_text.project.band1.type?.system?.summary_view &&
                    chartData.properties_text.project.band1.type?.system?.nslice
                ) {
                    tempSaveChartData.properties_text.project.band1.type.system = {
                        ...tempSaveChartData.properties_text.project.band1.type.system,
                        summary_view: {
                            ...tempSaveChartData.properties_text.project.band1.type.system?.summary_view,
                            nslice: tempSaveChartData.properties_text.project.band1.type.system.nslice
                        }
                    };
                } else if (
                    chartData.properties_text.project.band1.type?.system?.summary_view &&
                    !chartData.properties_text.project.band1.type?.system?.nslice
                ) {
                    const { nslice, ...restSummaryView } = tempSaveChartData.properties_text.project.band1.type.system.summary_view;
                    tempSaveChartData.properties_text.project.band1.type.system = {
                        ...tempSaveChartData.properties_text.project.band1.type.system,
                        summary_view: {
                            ...restSummaryView
                        }
                    };
                }
                if (
                    chartData.properties_text.project.band1.type?.system?.detailed_view &&
                    chartData.properties_text.project.band1.type?.system?.nslice
                ) {
                    tempSaveChartData.properties_text.project.band1.type.system = {
                        ...tempSaveChartData.properties_text.project.band1.type.system,
                        detailed_view: {
                            ...tempSaveChartData.properties_text.project.band1.type.system?.detailed_view,
                            nslice: tempSaveChartData.properties_text.project.band1.type.system.nslice
                        }
                    };
                } else if (
                    chartData.properties_text.project.band1.type?.system?.detailed_view &&
                    !chartData.properties_text.project.band1.type?.system?.nslice
                ) {
                    const { nslice, ...restSummaryView } = tempSaveChartData.properties_text.project.band1.type.system.detailed_view;
                    tempSaveChartData.properties_text.project.band1.type.system = {
                        ...tempSaveChartData.properties_text.project.band1.type.system,
                        detailed_view: {
                            ...restSummaryView
                        }
                    };
                }
                if (
                    chartData.properties_text.project.band1.type?.system?.normal_recom &&
                    chartData.properties_text.project.band1.type?.system?.nslice
                ) {
                    tempSaveChartData.properties_text.project.band1.type.system = {
                        ...tempSaveChartData.properties_text.project.band1.type.system,
                        normal_recom: {
                            band1: {
                                ...tempSaveChartData.properties_text.project.band1.type.system?.normal_recom?.band1,
                                recom_nslice: tempSaveChartData.properties_text.project.band1.type.system.nslice,
                                config_filter: { ...tempSaveChartData.properties_text.project.band1.type.system?.normal_recom?.config_filter }
                                // config_filter: {
                                //     ...tempSaveChartData.properties_text.project.band1.type.system?.normal_recom?.config_filter,
                                //     wildcard_filters: {
                                //         project_total: {
                                //             filters: ["top_n"],
                                //             key: tempSaveChartData.properties_text.project.band1.type.system.nslice,
                                //             is_static_field: true
                                //         }
                                //     }
                                // }
                            }
                        }
                    };
                } else if (
                    chartData.properties_text.project.band1.type?.system?.normal_recom &&
                    !chartData.properties_text.project.band1.type?.system?.nslice
                ) {
                    tempSaveChartData.properties_text.project.band1.type.system = {
                        ...tempSaveChartData.properties_text.project.band1.type.system,
                        normal_recom: {
                            band1: {
                                ...tempSaveChartData.properties_text.project.band1.type.system?.normal_recom?.band1,
                                config_filter: {}
                            }
                        }
                    };
                }
            }
            if (chartData.properties_text.project.band1.type?.subsystem) {
                tempSaveChartData.properties_text.project.band1.type.subsystem = {
                    ...chartData.properties_text.project.band1.type.subsystem,
                    trade_ids: chartData?.properties_text?.project?.band1?.mfilter?.trade_ids?.length
                        ? [...chartData.properties_text.project.band1.mfilter.trade_ids]
                        : [],
                    system_ids: chartData?.properties_text?.project?.band1?.mfilter?.system_ids?.length
                        ? [...chartData.properties_text.project.band1.mfilter.system_ids]
                        : [],
                    subsystem_ids: chartData?.properties_text?.project?.band1?.mfilter?.subsystem_ids?.length
                        ? [...chartData.properties_text.project.band1.mfilter.subsystem_ids]
                        : []
                };
            }
            if (chartData.properties_text.project.band1.type?.trades) {
                tempSaveChartData.properties_text.project.band1.type.trades = {
                    trade_ids: chartData?.properties_text?.project?.band1?.mfilter?.trade_ids?.length
                        ? [...chartData.properties_text.project.band1.mfilter.trade_ids]
                        : [],
                    ...chartData.properties_text.project.band1.type.trades
                };
            }
            if (chartData.properties_text.project.band1.type?.trades) {
                tempSaveChartData.properties_text.project.band1.type.trades = {
                    trade_ids: chartData?.properties_text?.project?.band1?.mfilter?.trade_ids?.length
                        ? [...chartData.properties_text.project.band1.mfilter.trade_ids]
                        : [],
                    ...chartData.properties_text.project.band1.type.trades
                };
            }
            if (chartData.properties_text.project.band1?.type?.asset_band) {
                tempSaveChartData.properties_text.project.band1.type.asset_band = {
                    ...chartData.properties_text.project.band1?.type?.asset_band,
                    mfilter: {
                        ...chartData.properties_text.project?.band1?.mfilter,
                        ...chartData.properties_text.project?.band1?.type.asset_band?.mfilter
                    }
                };
            }
            if (chartData.properties_text.assets.band1?.type?.asset_band) {
                tempSaveChartData.properties_text.assets.band1.type.asset_band = {
                    ...chartData.properties_text.assets.band1?.type?.asset_band,
                    mfilter: {
                        ...chartData.properties_text.assets?.band1?.mfilter,
                        ...chartData.properties_text.assets?.band1?.type.asset_band?.mfilter
                    }
                };
            }
            if (
                chartData.properties_text.project.band1?.filter_label &&
                chartData.properties_text.project.band1?.filter_label?.Projects &&
                chartData.properties_text.project.band1?.filter_label["FCA Projects"]
            ) {
                const { ["FCA Projects"]: _, ...updatedFilterLabel } = chartData.properties_text.project.band1?.filter_label;
                tempSaveChartData.properties_text.project.band1 = {
                    ...chartData?.properties_text?.project?.band1,
                    filter_label: { ...updatedFilterLabel }
                };
            }
            let updatedProjectDataWithDocs = {};
            let updatedEnergyMngDataWithDocs = {};
            let updatedAssetMngDataWithDocs = {};
            let tempProjectTypes = { ...tempSaveChartData.properties_text?.project?.band1?.type };
            updatedProjectDataWithDocs = updateDataWithUserDocs(tempProjectTypes, "project");
            let tempEnergyMngTypes = { ...tempSaveChartData.properties_text?.energy_mng?.band1?.type };
            updatedEnergyMngDataWithDocs = updateDataWithUserDocs(tempEnergyMngTypes, "energy_mng");
            let tempAssetMngTypes = { ...tempSaveChartData.properties_text?.assets?.band1?.type };
            updatedAssetMngDataWithDocs = updateDataWithUserDocs(tempAssetMngTypes, "assets");
            let updatedDataWithCustomKeys = setCustomDataForSave(updatedProjectDataWithDocs);
            tempSaveChartData.properties_text.project.band1.type = {
                ...updatedDataWithCustomKeys
            };
            tempSaveChartData.properties_text.energy_mng.band1.type = {
                ...updatedEnergyMngDataWithDocs
            };

            tempSaveChartData.properties_text.assets.band1.type = {
                ...updatedAssetMngDataWithDocs
            };
            // if (chartData?.properties_text?.project?.band1?.mfilter?.trade_ids) {
            //     tempSaveChartData.properties_text.project.band1.mfilter = {
            //         ..._.omit({ ...chartData.properties_text.project.band1.mfilter }, "trade_ids")
            //     };
            // }
            // if (chartData?.properties_text?.project?.band1?.mfilter?.system_ids) {
            //     tempSaveChartData.properties_text.project.band1.mfilter = {
            //         ..._.omit({ ...chartData.properties_text.project.band1.mfilter }, "system_ids")
            //     };
            // }
            // if (chartData?.properties_text?.project?.band1?.mfilter?.subsystem_ids) {
            //     tempSaveChartData.properties_text.project.band1.mfilter = {
            //         ..._.omit({ ...chartData.properties_text.project.band1.mfilter }, "subsystem_ids")
            //     };
            // }
            tempSaveChartData.properties_text.project.band1.mfilter = {
                ...tempSaveChartData.properties_text.project.band1.mfilter,
                client_ids: [selectedClient]
            };
            tempSaveChartData.properties_text.energy_mng.band1.mfilter = {
                ...tempSaveChartData.properties_text.energy_mng.band1.mfilter,
                client_ids: [selectedClient]
            };
            tempSaveChartData.properties_text.assets.band1.mfilter = {
                ...tempSaveChartData.properties_text.assets.band1.mfilter,
                client_ids: [selectedClient]
            };

            if (isExcelExport) {
                tempSaveChartData.properties_text.project.band1.export_type = "excel";
            } else if (tempSaveChartData.properties_text.project.band1.export_type) {
                delete tempSaveChartData.properties_text.project.band1.export_type;
            }
            let saveChartData = {
                ...chartData,
                properties_text: JSON.stringify(tempSaveChartData.properties_text)
            };
            if (selectedExportProperty) {
                saveChartData.template_property_id = selectedExportProperty;
            }
            if (selectedExportTemplate) {
                saveChartData.template_id = selectedExportTemplate;
            }
            saveChartData.client_id = selectedClient;

            let propertyId = "";
            if (props.match.params.viewType && props.match.params.viewType === "edit") {
                propertyId = selectedProperty.id;
            } else {
                propertyId = saveSmartChartResponse.id;
            }
            if (isExport) {
                setIsSaveAndExport(true);
            }
            if (isEdit && !isSaveAs && !isSkeletonView) {
                await updateSmartChartProperty(propertyId, {
                    ...saveChartData,
                    modified_by: localStorage.getItem("userId"),
                    ...(corereportData && { ...corereportData })
                });
            } else {
                if (templateData) {
                    saveChartData = { ...saveChartData, name: templateData.name, notes: templateData.notes };
                }
                if (isExport) {
                    saveChartData = { ...saveChartData, is_export: true };
                    if (templateData.is_pdf) {
                        saveChartData = { ...saveChartData, is_pdf: true };
                    } else if (templateData.is_ppt) {
                        saveChartData = { ...saveChartData, is_ppt: true };
                    } else if (templateData.is_excel) {
                        saveChartData = { ...saveChartData, is_excel: true };
                    }
                    saveChartData.orientation = templateData.orientation;
                }
                if (isSkeletonView) {
                    saveChartData = { ...saveChartData, property_type: "SKEL_PROP", skelton_property_id: selectedProperty?.id };
                }
                await saveSmartChartData({ ...saveChartData, user: localStorage.getItem("userId") });
            }
            setIsSaveAndExport(false);
            setSelectedProperty(prevProperty => {
                return {
                    ...prevProperty,
                    ...saveChartData
                };
            });

            setSavedChartData({ ...chartData });
            if (isExport) {
                showLongAlert(
                    "Report generation initiated and will be ready for download shortly. Please click the Refresh button to check for status."
                );
                popBreadCrumpData();
                popBreadCrumpData();
                addToBreadCrumpData({
                    key: "reports",
                    name: "Report",
                    path: `/smartcharts/reports`
                });
                props.history.push("/smartcharts/reports");
            } else {
                showLongAlert(
                    isEdit && !isSaveAs
                        ? "Smart chart report template updated successfully"
                        : "Smart chart report template saved successfully, You can export the report now."
                );
                if (isSaveAs || isSkeletonView) {
                    popBreadCrumpData();
                    popBreadCrumpData();
                    addToBreadCrumpData({
                        key: "reporttemplates",
                        name: "Report Templates",
                        path: `/smartcharts/reporttemplates`
                    });
                    props.history.push("/smartcharts/reporttemplates");
                } else {
                    let prevUrl = isSkeletonView ? "/smartcharts/reporttemplates" : findPrevPathFromBreadCrump() || "/smartcharts/reporttemplates";
                    popBreadCrumpData();
                    props.history.push(prevUrl);
                }
            }
        }
    };

    const updateDataWithUserDocs = (entityTypeData, entity) => {
        let defaultEntityKeys = Object.keys(CHART_ITEMS[entity]);
        let updatedData = Object.keys(entityTypeData).reduce((resultData, currentProp, index) => {
            if (currentProp.includes("user_doc")) {
                return {
                    ...resultData,
                    ["user_doc" + (index + 1)]: {
                        id: entityTypeData[currentProp]?.id,
                        name: entityTypeData[currentProp]?.name,
                        remove_section_break: entityTypeData[currentProp]?.remove_section_break
                    }
                };
            }
            if (currentProp.includes("image_doc")) {
                return {
                    ...resultData,
                    ["image_doc" + (index + 1)]: { id: entityTypeData[currentProp]?.id, name: entityTypeData[currentProp]?.name }
                };
            }
            if (currentProp.includes("list_images")) {
                return {
                    ...resultData,
                    ["list_images" + (index + 1)]: { ...entityTypeData[currentProp] }
                };
            }
            if (currentProp.includes("multi_recommendation")) {
                const { number_of_recom = [], ...restRecomBandData } = entityTypeData[currentProp] ?? {};
                return {
                    ...resultData,
                    ["multi_recommendation" + (index + 1)]: {
                        ...restRecomBandData,
                        mfilter: {
                            ...entityTypeData?.[currentProp]?.mfilter,
                            ...chartData.properties_text.project.band1.mfilter
                        },
                        key_name:
                            entityTypeData[currentProp]?.key_name &&
                            typeof entityTypeData[currentProp].key_name === "object" &&
                            entityTypeData[currentProp]?.key_name !== null
                                ? {
                                      ...entityTypeData[currentProp]?.key_name,
                                      ...getKeyNamesForMultiRecom(),
                                      custom_heading: entityTypeData[currentProp]?.name || "Recommendation Summary"
                                  }
                                : { ...getKeyNamesForMultiRecom(), custom_heading: entityTypeData[currentProp]?.name || "Recommendation Summary" }
                    }
                };
            }
            if (currentProp.includes("normal_recom")) {
                let currentData = entityTypeData[currentProp] || { band1: { mfilter: {} } };
                const { number_of_recom = [], ...restRecomBandData } = currentData?.band1 ?? {};
                return {
                    ...resultData,
                    ["normal_recom" + (index + 1)]: {
                        band1: {
                            ...restRecomBandData,
                            mfilter: {
                                ...currentData?.band1?.mfilter,
                                ...chartData.properties_text.project.band1.mfilter
                            }
                        }
                    }
                };
            }
            if (currentProp.includes("recom_onepager")) {
                let currentData = entityTypeData[currentProp] || { band1: { mfilter: {} } };

                const { number_of_recom = [], ...restRecomBandData } = currentData?.band1 ?? {};
                return {
                    ...resultData,
                    ["recom_onepager" + (index + 1)]: {
                        band1: {
                            ...restRecomBandData,
                            mfilter: {
                                ...currentData?.band1?.mfilter,
                                ...chartData.properties_text.project.band1.mfilter
                            }
                        }
                    }
                };
            }
            if (currentProp.includes("asset-onepager")) {
                let currentData = entityTypeData[currentProp] || { band1: { mfilter: {} } };
                const { number_of_recom = [], ...restRecomBandData } = currentData?.band1 ?? {};
                return {
                    ...resultData,
                    ["asset-onepager" + (index + 1)]: {
                        band1: {
                            ...restRecomBandData,
                            mfilter: {
                                ...currentData?.band1?.mfilter,
                                ...chartData.properties_text[entity].band1.mfilter,
                                client_ids: [selectedClient]
                            }
                        }
                    }
                };
            }
            if (currentProp.includes("bbi_report")) {
                let currentData = entityTypeData[currentProp] || { band1: { mfilter: {}, name: "BBI" } };
                const { ...restRecomBandData } = currentData?.band1;
                return {
                    ...resultData,
                    ["bbi_report" + 1]: {
                        band1: {
                            ...restRecomBandData,
                            mfilter: {
                                ...currentData?.band1?.mfilter,
                                ...chartData.properties_text.project.band1.mfilter
                            }
                        }
                    }
                };
            }
            if (currentProp.includes("bsi_report")) {
                let currentData = entityTypeData[currentProp] || { band1: { mfilter: {}, name: "BSI" } };
                const { ...restRecomBandData } = currentData?.band1;
                return {
                    ...resultData,
                    ["bsi_report" + 1]: {
                        band1: {
                            ...restRecomBandData,
                            mfilter: {
                                ...currentData?.band1?.mfilter,
                                ...chartData.properties_text.project.band1.mfilter
                            }
                        }
                    }
                };
            }
            if (currentProp.includes("memo_field")) {
                let currentData = entityTypeData[currentProp] || { name: "Memo_field" };
                const { ...restRecomBandData } = currentData;
                return {
                    ...resultData,
                    ["memo_field_" + (index + 1)]: {
                        ...restRecomBandData,
                        key_name:
                            entityTypeData[currentProp]?.key_name &&
                            typeof entityTypeData[currentProp].key_name === "object" &&
                            entityTypeData[currentProp]?.key_name !== null
                                ? {
                                      ...entityTypeData[currentProp]?.key_name,
                                      ...getKeyNamesForMultiRecom(),
                                      custom_heading: entityTypeData[currentProp]?.name || "Text Band"
                                  }
                                : { ...getKeyNamesForMultiRecom(), custom_heading: entityTypeData[currentProp]?.name || "Text Band" }
                    }
                };
            }
            if (currentProp.includes("filter_report1")) {
                let currentData = entityTypeData[currentProp] || { band1: { mfilter: {}, name: "Master Filters" } };
                const { ...restRecomBandData } = currentData?.band1;
                return {
                    ...resultData,
                    ["filter_report1"]: {
                        ...entityTypeData[currentProp],
                        band1: {
                            ...restRecomBandData,
                            mfilter: {
                                ...currentData?.band1?.mfilter,
                                ...chartData.properties_text.project.band1.mfilter
                            }
                        }
                    }
                };
            }
            if (currentProp.includes("asset_band") && (entity === "project" || entity === "assets")) {
                let currentData = entityTypeData[currentProp] || { mfilter: {} };
                return {
                    ...resultData,
                    [currentProp]: {
                        ...entityTypeData[currentProp],
                        mfilter: {
                            ...currentData?.mfilter,
                            ...chartData.properties_text?.[entity]?.band1?.mfilter
                        }
                    }
                };
            }
            // if (!defaultEntityKeys.includes(currentProp)) {
            //     let currentData = chartData?.properties_text?.[entity] || { band1: { mfilter: {} } };
            //     console.log({
            //         system_ids: [...entityTypeData[currentProp]?.system_ids, ...currentData?.band1?.mfilter?.system_ids],
            //         trade_ids: [...entityTypeData[currentProp]?.trade_ids, ...currentData?.band1?.mfilter?.trade_ids]
            //     });
            //     return {
            //         ...resultData,
            //         [currentProp?.split("_")?.[0] + "_" + currentProp?.split("_")?.[1] + "_" + (index + 1)]: {
            //             ...entityTypeData[currentProp],
            //             trade_ids:
            //                 (currentProp.includes("system") || currentProp.includes("trade")) && entityTypeData[currentProp]?.trade_ids
            //                     ? [...entityTypeData[currentProp]?.trade_ids]
            //                     : chartData?.properties_text?.[entity]?.band1?.mfilter?.trade_ids?.length
            //                     ? [...chartData.properties_text[entity].band1.mfilter.trade_ids]
            //                     : [],

            //             ...(currentProp.includes("subsystem")
            //                 ? {
            //                       subsystem_ids: [...entityTypeData[currentProp]?.sub_system_ids, ...currentData?.band1?.mfilter?.sub_system_ids],
            //                       system_ids: [...entityTypeData[currentProp]?.system_ids, ...currentData?.band1?.mfilter?.system_ids],
            //                       trade_ids: [...entityTypeData[currentProp]?.trade_ids, ...currentData?.band1?.mfilter?.trade_ids]
            //                   }
            //                 : currentProp.includes("system")
            //                 ? {
            //                       system_ids: [...entityTypeData[currentProp]?.system_ids, ...currentData?.band1?.mfilter?.system_ids],
            //                       trade_ids: [...entityTypeData[currentProp]?.trade_ids, ...currentData?.band1?.mfilter?.trade_ids]
            //                   }
            //                 : currentProp.includes("category")
            //                 ? { category_ids: currentData?.band1?.mfilter?.category_ids || [...entityTypeData[currentProp]?.category_ids] }
            //                 : currentProp.includes("fundingsrc")
            //                 ? { fundingsrc_ids: currentData?.band1?.mfilter?.fundingsrc_ids || [...entityTypeData[currentProp]?.fundingsrc_ids] }
            //                 : currentProp.includes("criticality")
            //                 ? { criticality_ids: currentData?.band1?.mfilter?.criticality_ids || [...entityTypeData[currentProp]?.criticality_ids] }
            //                 : currentProp.includes("capital")
            //                 ? { capital_ids: currentData?.band1?.mfilter?.capital_type_ids || [...entityTypeData[currentProp]?.capital_type_ids] }
            //                 : currentProp.includes("term")
            //                 ? { term_ids: currentData?.band1?.mfilter?.priority_ids || [...entityTypeData[currentProp]?.priority_ids] }
            //                 : null)
            //         }
            //     };
            // }
            if (!defaultEntityKeys.includes(currentProp)) {
                let currentData = chartData?.properties_text?.[entity] || { band1: { mfilter: {} } };
                let mfilter = currentData?.band1?.mfilter || {};

                let resultIds = {};
                const mergeIds = (key, filterKey) => {
                    let entityIds = entityTypeData[currentProp]?.[key] || [];
                    let mfilterIds = mfilter[filterKey] || [];
                    resultIds[key] = _.uniq([...entityIds, ...mfilterIds]);
                };
                if (currentProp.includes("subsystem")) {
                    mergeIds("subsystem_ids", "sub_system_ids");
                    mergeIds("system_ids", "system_ids");
                    mergeIds("trade_ids", "trade_ids");
                } else if (currentProp.includes("system")) {
                    mergeIds("system_ids", "system_ids");
                    mergeIds("trade_ids", "trade_ids");
                } else if (currentProp.includes("category")) {
                    mergeIds("category_ids", "category_ids");
                } else if (currentProp.includes("fundingsrc")) {
                    mergeIds("fundingsrc_ids", "fundingsrc_ids");
                } else if (currentProp.includes("criticality")) {
                    mergeIds("criticality_ids", "criticality_ids");
                } else if (currentProp.includes("capital")) {
                    mergeIds("capital_type_ids", "capital_type_ids");
                } else if (currentProp.includes("term")) {
                    mergeIds("priority_ids", "priority_ids");
                }
                let newKey = `${currentProp.split("_")[0]}_${currentProp.split("_")[1]}_${index + 1}`;
                if (currentProp?.includes("fci_benchmarking")) {
                    newKey = `${currentProp?.split("_")[0]}_${currentProp?.split("_")[1]}_${currentProp?.split("_")[2]}`;
                }
                return {
                    ...resultData,
                    [newKey]: {
                        ...entityTypeData[currentProp]
                        // ...resultIds
                    }
                };
            }

            return { ...resultData, [currentProp]: entityTypeData[currentProp] };
        }, {});
        return updatedData;
    };

    const setCustomDataForSave = (data = {}) => {
        let customKeys = ["energy_band"];
        let newObjectData = {};
        newObjectData = Object.keys(data).reduce((resultData, currentProp, index) => {
            if (customKeys.includes(currentProp)) {
                return { ...resultData, ...getDataForCustomDataSave(currentProp, data[currentProp]) };
            }
            return { ...resultData, [currentProp]: data[currentProp] };
        }, {});
        return newObjectData;
    };

    const getDataForCustomDataSave = key => {
        switch (key) {
            case "energy_band":
                return {
                    energy_band: "",
                    em_eci_site_cost: { detailed_view: { chart_type: ["stacked_column_2d"] } },
                    em_eci_energy_cost: { summary_view: { chart_type: ["pie_2d"] } },
                    bld_brkdown_energy_cost: { summary_view: { chart_type: ["pie_2d"] } }
                };
            default:
                return {};
        }
    };

    const validate = () => {
        setShowErrorBorder(false);
        if (!selectedClient?.trim()?.length) {
            setShowErrorBorder(true);
            return false;
        }
        if (!selectedExportProperty?.trim()?.length) {
            setShowErrorBorder(true);
            return false;
        }
        if (!selectedExportTemplate?.trim()?.length) {
            setShowErrorBorder(true);
            return false;
        }
        if (!chartData.name && !chartData.name.trim().length) {
            setShowErrorBorder(true);
            return false;
        }
        if (chartData.name.trim().length > 100) {
            setShowErrorBorder(true);
            return false;
        }
        if (
            chartData.properties_text.project.band1.type &&
            Object.keys(chartData.properties_text.project.band1.type).length &&
            (!chartData.properties_text.project.band1.mfilter?.project_ids || !chartData.properties_text.project.band1.mfilter?.project_ids?.length)
        ) {
            showAlert("Please select a project");
            return false;
        }
        if (
            (Object.keys(chartData.properties_text.energy_mng.band1.type).includes("water_band") ||
                Object.keys(chartData.properties_text.energy_mng.band1.type).includes("energy_band")) &&
            (!chartData.properties_text.energy_mng.band1.mfilter?.project_ids ||
                !chartData.properties_text.energy_mng.band1.mfilter?.project_ids?.length)
        ) {
            showLongAlert("Please select a project in Energy Reporting");
            return false;
        }
        if (
            !Object.keys(chartData.properties_text.energy_mng.band1.type).length &&
            !Object.keys(chartData.properties_text.assets.band1.type).length &&
            !Object.keys(chartData.properties_text.project.band1.type).length
        ) {
            showLongAlert("Please select at least one chart");
            return false;
        }
        if (isSkeletonView && !checkRequiredFilterSelected()) {
            showLongAlert("Please select the required filters");
            return false;
        }
        return true;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        let droppableId = result.destination?.droppableId || "";
        let droppedEntity = droppableId ? droppableId.split("-")[1] : "";
        let draggedEntity = result.draggableId?.split("-")[1] || "";
        // if (result.source?.droppableId.includes("USERDOCS")) {
        //     let currentDocId = result.draggableId?.split("doc")?.[1] || "";
        //     const keyList = reorderArray(["user_doc" + currentDocId, ...chartsOrder[droppedEntity]], 0, result.destination.index);
        //     setSmartChartData("docdnd", {
        //         bandName: "band1",
        //         orderedKeys: keyList,
        //         docId: currentDocId,
        //         entity: droppedEntity,
        //         mediaKey: "user_doc"
        //     });
        // }
        // if (result.source?.droppableId.includes("USERIMAGES")) {
        //     let currentImageId = result.draggableId?.split("doc")?.[1] || "";
        //     const keyList = reorderArray(["image_doc" + currentImageId, ...chartsOrder[droppedEntity]], 0, result.destination.index);
        //     setSmartChartData("docdnd", {
        //         bandName: "band1",
        //         orderedKeys: keyList,
        //         docId: currentImageId,
        //         entity: droppedEntity,
        //         mediaKey: "image_doc"
        //     });
        // } else {
        if (draggedEntity !== droppedEntity) {
            return;
        }
        const keyList = reorderArray(chartsOrder[droppedEntity], result.source.index, result.destination.index);
        setSmartChartData("dnd", {
            bandName: "band1",
            orderedKeys: keyList,
            entity: droppedEntity
        });
        // }
    };

    const handleSelectClient = async value => {
        setSelectedClient(value);
        setSelectedExportProperty("");
        setSelectedExptProperty("");
        setSelectedExportTemplate("");
        if (value) {
            changeDefaultProjectRef.current = true;
            props.getTemplatePropertiesList({ client_id: value });
            props.getTemplateList({ client_id: value });
            getSmartChartMasterFilterDropDown("projects", "project", {
                client_id: value,
                user_id: localStorage.getItem("userId") || ""
            });
        }
        setChartData(prevState => {
            return {
                ...prevState,
                properties_text: {
                    ...prevState.properties_text,
                    project: {
                        ...prevState.properties_text.project,
                        band1: {
                            ...prevState.properties_text.project.band1,
                            mfilter: { client_ids: [value] },
                            filter_label: {},
                            type: prevState.properties_text.project.band1?.type
                                ? Object.keys(prevState.properties_text.project.band1.type).reduce((acc, key) => {
                                      if (key?.startsWith("multi_recommendation")) {
                                          const { project_id } = prevState.properties_text.project.band1.type[key];
                                          return {
                                              ...acc,
                                              [key]: {
                                                  ...prevState.properties_text.project.band1.type[key],
                                                  recommendations: [],
                                                  number_of_recom: 0,
                                                  config_filter: {},
                                                  config_filter_names: {}
                                              }
                                          };
                                      } else if (["normal_recom", "recom_onepager"].some(e => key?.startsWith(e))) {
                                          const { project_id } = prevState.properties_text.project.band1.type[key];
                                          let newConfigFilter = {
                                              number_of_recom: 0,
                                              recommendations: [],
                                              config_filter: {},
                                              config_filter_names: {}
                                          };
                                          return {
                                              ...acc,
                                              [key]: {
                                                  ...prevState.properties_text.project.band1.type[key],
                                                  band1: {
                                                      ...(prevState.properties_text?.project?.band1?.type[key]?.band1 || {}),
                                                      ...newConfigFilter,
                                                      mfilter: {
                                                          //   ...(prevState.properties_text?.project?.band1?.type[key]?.band1.mfilter || {}),
                                                          client_ids: [value],
                                                          recommendations: []
                                                      },
                                                      filter_label: {}
                                                  }
                                              }
                                          };
                                      }
                                      if (key === "system" && prevState.properties_text?.project?.band1?.type[key]?.normal_recom) {
                                          return {
                                              ...acc,
                                              [key]: {
                                                  ...prevState.properties_text?.project?.band1?.type[key],
                                                  normal_recom: {
                                                      ...prevState.properties_text?.project?.band1?.type[key]?.normal_recom,
                                                      band1: {
                                                          ...prevState.properties_text?.project?.band1?.type[key]?.normal_recom?.band1,
                                                          mfilter: {
                                                              client_ids: [value]
                                                          },
                                                          filter_label: {}
                                                      }
                                                  }
                                              }
                                          };
                                      }
                                      return { ...acc, [key]: prevState.properties_text.project.band1?.type[key] };
                                  }, {})
                                : {}
                        }
                    },
                    assets: {
                        ...prevState.properties_text.assets,
                        band1: {
                            ...prevState.properties_text.assets.band1,
                            mfilter: { client_ids: [value] },
                            type: prevState.properties_text.assets.band1?.type
                                ? Object.keys(prevState.properties_text.assets.band1.type).reduce((acc, key) => {
                                      if (key?.startsWith("multi_recommendation")) {
                                          return {
                                              ...acc,
                                              [key]: {
                                                  ...prevState.properties_text.assets.band1.type[key],
                                                  recommendations: [],
                                                  number_of_recom: 0,
                                                  config_filter: {},
                                                  config_filter_names: {}
                                              }
                                          };
                                      } else if (["normal_recom", "recom_onepager", "asset-onepager"].some(e => key?.startsWith(e))) {
                                          let newConfigFilter = {
                                              recommendations: [],
                                              config_filter: {},
                                              config_filter_names: {}
                                          };
                                          if (key?.startsWith("asset-onepager")) {
                                              const { asset_ids, ...rest } = prevState.properties_text.assets.band1.mfilter;

                                              newConfigFilter = { ...newConfigFilter, mfilter: { ...rest } };
                                          }
                                          return {
                                              ...acc,
                                              [key]: {
                                                  ...prevState.properties_text.assets.band1.mfilter[key],
                                                  ...newConfigFilter
                                              }
                                          };
                                      }
                                      return { ...acc, [key]: prevState.properties_text.assets.band1?.type[key] };
                                  }, {})
                                : {}
                        }
                    },
                    energy_mng: {
                        ...prevState.properties_text.energy_mng,
                        band1: {
                            ...prevState.properties_text.energy_mng.band1,
                            mfilter: {}
                        }
                    }
                }
            };
        });
    };

    const handleCancelClick = () => {
        if (_.isEqual(chartData, initialData.current)) {
            let prevUrl = findPrevPathFromBreadCrump() || "/smartcharts/reporttemplates";
            popBreadCrumpData();
            props.history.push(prevUrl);
        } else {
            setShowConfirmModal(true);
        }
    };

    const renderConfirmationModal = () => {
        if (!showConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to clear and lose all changes?"}
                        type="cancel"
                        message={"This action cannot be reverted, are you sure that you need to cancel?"}
                        onNo={() => setShowConfirmModal(false)}
                        onYes={() => {
                            let prevUrl = findPrevPathFromBreadCrump() || "/smartcharts/reporttemplates";
                            popBreadCrumpData();
                            props.history.push(prevUrl);
                        }}
                    />
                }
                onCancel={() => setShowConfirmModal(false)}
            />
        );
    };
    const renderCorereportConfirmationModal = () => {
        if (!showCorereportUpdateConfirmationModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"We detected a filter change."}
                        type="cancel"
                        message={" How do you want to update your connected core reports?"}
                        onNo={() => setShowCorereportUpdateConfirmationModal(false)}
                        onYes={(clientFilterList, globalFilterList) => {
                            setChartData(prevChartData => ({
                                ...prevChartData,
                                client_core_report_req_filters: clientFilterList?.project,
                                global_core_report_req_filters: globalFilterList?.project
                            }));
                            saveSmartChartConfigData(false, false, null, {
                                client_core_report_req_filters: clientFilterList?.project,
                                global_core_report_req_filters: globalFilterList?.project
                            });
                        }}
                        chartData={chartData?.properties_text}
                        globalCoreReport={selectedProperty?.global_connected_core_reports_count}
                        clientCoreReport={selectedProperty?.client_connected_core_reports_count}
                        isCoreReportFilterChangeModal={true}
                    />
                }
                onCancel={() => setShowConfirmModal(false)}
            />
        );
    };

    const checkForSaveAsNewTemplate = () => {
        let isFilterChanged = false;
        if (!_.isEqual(chartData.properties_text.project.band1.mfilter, initialData.current?.properties_text?.project?.band1?.mfilter)) {
            isFilterChanged = true;
        }
        if (!_.isEqual(chartData.properties_text.assets.band1.mfilter, initialData.current?.properties_text?.assets?.band1?.mfilter)) {
            isFilterChanged = true;
        }
        if (!_.isEqual(chartData.properties_text.energy_mng.band1.mfilter, initialData.current?.properties_text?.energy_mng?.band1?.mfilter)) {
            isFilterChanged = true;
        }
        return isFilterChanged;
    };

    const updateSmartChartConfigData = () => {
        let coreReportCount = selectedProperty?.global_connected_core_reports_count + selectedProperty?.client_connected_core_reports_count || 0;
        if (validate()) {
            if (selectedProperty?.is_mapped || coreReportCount > 0) {
                setShowSaveAsConfirmationModal(true);
            } else {
                saveSmartChartConfigData();
            }
        }
    };

    const renderSaveAsConfirmationModal = () => {
        if (!showSaveAsConfirmationModal) return null;
        let exportCount = selectedProperty?.smart_export_count || 0;
        let coreReportCount = selectedProperty?.global_connected_core_reports_count + selectedProperty?.client_connected_core_reports_count || 0;

        return (
            <Portal
                body={
                    <SaveAsConfirmationModal
                        onCancel={() => setShowSaveAsConfirmationModal(false)}
                        message={"Do you want to update this Template ?"}
                        subMessage1={
                            <>
                                This template is already connected to{" "}
                                {exportCount > 0 && (
                                    <>
                                        <span className="badge-red-circled">{exportCount}</span>
                                        {` report${exportCount > 1 ? "s" : ""}`}
                                    </>
                                )}
                                {coreReportCount > 0 && exportCount > 0 && " & "}
                                {coreReportCount > 0 && (
                                    <>
                                        <span className="badge-red-circled">{coreReportCount}</span>
                                        {` Core Report${coreReportCount > 1 ? "s" : ""}`}
                                    </>
                                )}
                                . Are you sure you want to update the template, or would you like to save it as a new template instead?
                            </>
                        }
                        buttonNo={{ label: "Save As New Template", value: "save_as", note: "" }}
                        buttonYes={{ label: "Update", value: "update", note: "" }}
                        hasCancelButton={true}
                        onSelection={selectedKey => (selectedKey === "save_as" ? toggleNewTemplateEditModal() : updateTemplateConfirm())}
                        viewReports={isReportView => viewReports(selectedProperty?.id, null, isReportView)}
                        isSmartChart={selectedProperty?.smart_export_count > 0 ? true : false}
                        smartChartBtnText={
                            <>
                                View
                                {`Connected Report${selectedProperty.smart_export_count > 1 || coreReportCount > 1 ? "s" : ""}`}
                            </>
                        }
                        isReportView={true}
                    />
                }
                onCancel={() => setShowSaveAsConfirmationModal(false)}
            />
        );
    };

    const handleSaveAsNewTemplate = data => {
        sessionStorage.removeItem("selectedProperty");
        setShowSaveAsConfirmationModal(false);
        setShowNewTemplateEditModal(false);
        saveSmartChartConfigData(false, true, data);
    };

    const updateTemplateConfirm = () => {
        let coreReportCount = selectedProperty?.global_connected_core_reports_count + selectedProperty?.client_connected_core_reports_count || 0;
        setShowSaveAsConfirmationModal(false);
        if (checkForSaveAsNewTemplate() && coreReportCount > 0) {
            setShowCorereportUpdateConfirmationModal(true);
        } else {
            saveSmartChartConfigData();
        }
    };

    const toggleNewTemplateEditModal = () => {
        setShowSaveAsConfirmationModal(false);
        setShowNewTemplateEditModal(true);
    };

    const renderNewTemplateEditModal = () => {
        if (!showNewTemplateEditModal) return null;
        let newTemplateData = { name: chartData.name, notes: chartData.notes };
        return (
            <Portal
                body={
                    <EntityDataEditModal
                        onCancel={() => setShowNewTemplateEditModal(false)}
                        heading={isEdit ? "Save As New Template" : "Save & Export"}
                        selectedData={newTemplateData}
                        updateData={isEdit ? handleSaveAsNewTemplate : saveAndExportConfirm}
                        buttonText={"Save"}
                        hasExportType={!isEdit}
                        smartchartConfigData={chartData?.properties_text}
                        isExcelExport={isExcelExport}
                    />
                }
                onCancel={() => setShowNewTemplateEditModal(false)}
            />
        );
    };

    const saveAndExportReportData = () => {
        if (validate()) {
            setShowNewTemplateEditModal(true);
        }
    };

    const saveAndExportConfirm = data => {
        setShowNewTemplateEditModal(false);
        saveSmartChartConfigData(true, false, data);
    };

    const renderImageBandConfigModal = () => {
        if (!showImageBandConfigModal) return null;
        return (
            <Portal
                body={
                    <ImageBandConfigModal
                        onCancel={() => setShowImageBandConfigModal(false)}
                        configData={selectedChartConfigData}
                        setSmartChartData={setSmartChartData}
                    />
                }
                onCancel={() => setShowImageBandConfigModal(false)}
            />
        );
    };

    const toggleImageListForBandModal = (params, isView = false) => {
        const { entity, chartKey, bandName } = params;
        let selectedChartConfig = chartData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey]
            ? { ...chartData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey] }
            : {};
        setEntityForListModal("Image");
        setSelectedChartConfigData({ ...params, selectedChartConfig });
        setIsImageListView(isView);
        setShowImageListForBandModal(true);
    };

    const renderImageListForBandModal = () => {
        if (!showImageListForBandModal) return null;
        return (
            <Portal
                body={
                    <ImageOrDocListForBandModal
                        onCancel={() => setShowImageListForBandModal(false)}
                        setSmartChartData={setSmartChartData}
                        selectedClient={selectedClient}
                        configData={selectedChartConfigData}
                        isView={isImageListView}
                        entity={entityForListModal}
                        selectedBand={selectedBand}
                    />
                }
                onCancel={() => setShowImageListForBandModal(false)}
            />
        );
    };

    const toggleImageBandDeleteConfirmationModal = bandData => {
        const { type = "" } = bandData;
        setSelectedTileType(type);
        setImageBandData(bandData);
        setShowImageBandDeleteConfirmationModal(true);
    };

    const renderDeleteConfirmationModal = () => {
        if (!showImageBandDeleteConfirmationModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={`Do you want to delete this ${selectedTileType}?`}
                        message={"This action cannot be reverted, are you sure that you need to delete this item?"}
                        onNo={() => setShowImageBandDeleteConfirmationModal(false)}
                        onYes={deleteImageBandConfirm}
                    />
                }
                onCancel={() => setShowImageBandDeleteConfirmationModal(false)}
            />
        );
    };

    const deleteImageBandConfirm = () => {
        setSmartChartData("delete_image_band", {
            ...imageBandData
        });
        setShowImageBandDeleteConfirmationModal(false);
    };

    const editImageBand = (band, tileType) => {
        setSelectedChartConfigData(band);
        if (tileType === "CA") {
            toggleAddCAband(band?.entity, true);
        } else {
            setToggleImageBandEditModal(true);
        }
    };

    const renderImageBandEditModal = () => {
        if (!toggleImageBandEditModal) return null;
        let bandData = { name: selectedChartConfigData?.name || selectedChartConfigData?.band1?.name || "Image Band" };
        let isRecom = selectedChartConfigData?.chartKey?.includes("multi_recommendation");
        let isMemo =
            selectedChartConfigData?.chartKey?.includes("memo_field") ||
            selectedChartConfigData?.chartKey?.includes("normal_recom") ||
            selectedChartConfigData?.selectedTile?.isFilterReport;
        const { entity = "", chartKey = "", bandName = "" } = selectedChartConfigData;
        let selectedTileData = chartData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey]
            ? { ...chartData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey] }
            : {};
        return (
            <Portal
                body={
                    <EntityDataEditModal
                        onCancel={() => setToggleImageBandEditModal(false)}
                        heading={
                            selectedChartConfigData?.chartKey?.includes("multi_recommendation")
                                ? "Edit Recommendation Summary Band Name"
                                : selectedChartConfigData?.chartKey?.includes("normal_recom")
                                ? "Edit Recommendation Table Band Name"
                                : selectedChartConfigData?.chartKey?.includes("recom_onepager") ||
                                  selectedChartConfigData?.chartKey?.includes("asset-onepager")
                                ? "Edit One Pager Band Name"
                                : selectedChartConfigData?.chartKey?.includes("memo_field")
                                ? "Edit Memo Band Name"
                                : selectedChartConfigData?.chartKey?.includes("bsi_report1")
                                ? "Edit BSI"
                                : "Edit Image Band Name"
                        }
                        selectedData={bandData}
                        updateData={
                            selectedChartConfigData?.chartKey?.includes("multi_recommendation") ||
                            selectedChartConfigData?.chartKey?.includes("memo_field") ||
                            isMemo
                                ? updateMultiRecomBand
                                : selectedChartConfigData?.chartKey?.includes("normal_recom") ||
                                  selectedChartConfigData?.chartKey?.includes("recom_onepager") ||
                                  selectedChartConfigData?.chartKey?.includes("asset-onepager") ||
                                  selectedChartConfigData?.chartKey?.includes("memo_field") ||
                                  selectedChartConfigData?.chartKey?.includes("bsi_report1")
                                ? updateRecomBand
                                : updateImageBand
                        }
                        buttonText={"Update"}
                        hasNotes={false}
                        isRecom={isRecom}
                        isMemo={isMemo}
                        isNarrativeText={selectedChartConfigData?.chartKey?.includes("recom_onepager") || false}
                        selectedTileData={selectedTileData}
                        isBsi={selectedChartConfigData?.chartKey?.includes("bsi_report1")}
                        isNormalRecom={
                            selectedChartConfigData?.chartKey?.includes("normal_recom") || selectedChartConfigData?.selectedTile?.isFilterReport
                        }
                    />
                }
                onCancel={() => setToggleImageBandEditModal(false)}
            />
        );
    };

    const updateImageBand = params => {
        setSmartChartData("config_image_band", {
            ...selectedChartConfigData,
            imageBandConfig: { name: params?.name }
        });
    };

    const addDocumentTile = band => {
        setSelectedBand(band);
        setIsImageListView(false);
        setEntityForListModal("Document");
        setShowImageListForBandModal(true);
    };

    const toggleAddCAband = (band, isEdit = false) => {
        if (!isEdit) {
            setSelectedChartConfigData(null);
        }

        setSelectedBand(band);
        setShowAddCAband(true);
    };

    const renderAddCAbandModal = () => {
        if (!showAddCAband) return null;
        let currentMFilter = chartData.properties_text?.[selectedBand]?.band1?.mfilter || {};
        let currentTileData = chartData.properties_text?.[selectedBand]?.band1?.type || {};
        return (
            <Portal
                body={
                    <AddCAbandModal
                        onCancel={() => setShowAddCAband(false)}
                        entity={selectedBand}
                        setSmartChartData={setSmartChartData}
                        getCAbandFilterDropDownData={getCAbandFilterDropDownData}
                        getCAbandDropdownData={props?.smartChartReducer?.getCAbandDropdownData?.data || []}
                        getCAbandTradeDropdownData={props?.smartChartReducer?.getCAbandTradeDropdownData?.data || []}
                        entityMfilters={currentMFilter}
                        selectedConfig={selectedChartConfigData}
                        currentData={currentTileData}
                        getCAbandTradeFilterDropDownData={getCAbandTradeFilterDropDownData}
                        getCAbandSystemFilterDropDownData={getCAbandSystemFilterDropDownData}
                        getCAbandSystemDropdownData={props?.smartChartReducer?.getCAbandSystemDropdownData?.data || []}
                        isExcelExport={isExcelExport}
                        selectedClient={selectedClient}
                        selectedProject={chartData.properties_text?.project?.band1?.mfilter?.project_ids?.[0]}
                        mfilter={chartData.properties_text?.project?.band1?.mfilter}
                    />
                }
                onCancel={() => setShowAddCAband(false)}
            />
        );
    };

    const renderMultiRecommendationModal = () => {
        if (!showMultiRecommendationModal) return null;
        let selectedProject = chartData.properties_text?.project?.band1?.mfilter?.project_ids?.[0];
        let selectedRecomIds =
            configEntity === "assets"
                ? chartData.properties_text?.assets?.band1?.type?.[selectedChartConfigData?.chartKey]?.asset_ids ||
                  chartData.properties_text?.assets?.band1?.type?.[selectedChartConfigData?.chartKey]?.band1?.mfilter?.asset_ids ||
                  []
                : chartData.properties_text?.project?.band1?.type?.[selectedChartConfigData?.chartKey]?.recommendations ||
                  chartData.properties_text?.project?.band1?.type?.[selectedChartConfigData?.chartKey]?.band1?.mfilter?.recommendations ||
                  [];
        const configParams = chartData.properties_text?.assets?.band1?.type?.[selectedChartConfigData?.chartKey]?.band1?.config_filter;

        let smartChartFilters =
            configEntity === "assets" ? chartData.properties_text?.assets?.band1?.mfilter : chartData.properties_text?.project?.band1?.mfilter || {};
        if (smartChartFilters?.building_types?.length) {
            smartChartFilters = {
                ...smartChartFilters,
                building_types: chartData.properties_text?.project?.band1?.filter_label["B.Types"]?.length
                    ? [...chartData.properties_text.project.band1.filter_label["B.Types"]]
                    : []
            };
        }
        let recomTileConfigData = chartData.properties_text?.project?.band1?.type?.[selectedChartConfigData?.chartKey] || {};
        return (
            <Portal
                body={
                    <MultipleRecommendationModal
                        onCancel={() => setShowMultiRecommendationModal(false)}
                        entity={configEntity}
                        setSmartChartData={setSmartChartData}
                        getCAbandFilterDropDownData={getCAbandFilterDropDownData}
                        getCAbandDropdownData={props?.smartChartReducer?.getCAbandDropdownData?.data || []}
                        configData={selectedChartConfigData}
                        selectedProject={selectedProject}
                        selectedRecomIds={selectedRecomIds}
                        recomType={recommendationBandType}
                        clientId={selectedClient}
                        smartChartFilters={smartChartFilters}
                        smartChartTileConfig={recomTileConfigData}
                        getExportSettingsForSmartChartRecom={props.getExportSettingsForSmartChartRecom}
                        configParams={configParams}
                    />
                }
                onCancel={() => setShowMultiRecommendationModal(false)}
            />
        );
    };
    const renderMemoEditor = () => {
        const { label, key, show, value } = showRichTextPopup;
        const smartChartTileValue = chartData.properties_text?.project?.band1?.type?.[selectedChartConfigData?.chartKey];
        if (!show) return null;
        return (
            <Portal
                body={
                    <RichEditorPopup
                        value={value}
                        label={label}
                        key={key}
                        isSmartchart={true}
                        saveData={data => {
                            const config = {
                                ...smartChartTileValue,
                                text: data
                            };
                            setSmartChartData("config_chart", {
                                ...selectedChartConfigData,
                                chartConfigData: config,
                                defaultValue: smartChartTileValue
                            });
                        }}
                        onCancel={() => setShowRichTextPopup({})}
                    />
                }
                onCancel={() => setShowRichTextPopup(null)}
            />
        );
    };

    const updateRecomBand = params => {
        const { entity, chartKey, bandName } = selectedChartConfigData;
        let selectedTileData = chartData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey]
            ? { ...chartData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey] }
            : {};
        setSmartChartData("config_image_band", {
            ...selectedChartConfigData,
            imageBandConfig: { band1: { ...selectedTileData.band1, ...params } }
        });
    };

    const updateMultiRecomBand = params => {
        setSmartChartData("config_image_band", {
            ...selectedChartConfigData,
            imageBandConfig: params
        });
    };

    const getKeyNamesForMultiRecom = () => {
        let tempKeys = chartData?.properties_text?.project?.band1?.filter_label
            ? { ...chartData?.properties_text?.project?.band1?.filter_label }
            : {};
        let removeKey = Object.keys(tempKeys).find(keyValue => keyValue.toLowerCase().includes("project")) || null;
        if (removeKey) {
            delete tempKeys[removeKey];
        }
        return tempKeys;
    };

    const addOnepagerTile = () => {
        setSmartChartData("custom_tile", {
            bandName: "band1",
            entity: "project",
            customTileKey: "recom_onepager" + uuid(),
            customTileData: {
                band1: {
                    mfilter: { recommendations: [] },
                    name: "Recommendation One Pager"
                }
            }
        });
    };

    const addOnepagerAssetTile = entity => {
        setSmartChartData("custom_tile", {
            bandName: "band1",
            entity,
            customTileKey: "asset-onepager" + uuid(),
            customTileData: {
                band1: {
                    mfilter: {},
                    name: "Asset One Pager"
                }
            }
        });
    };

    const renderExcelExportConfirmationModal = () => {
        if (!showExcelExportConfirmModal) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to switch the excel export and lose all current changes ?"}
                        type="cancel"
                        message={"This action cannot be reverted, are you sure that you need to switch ?"}
                        onNo={() => {
                            setShowExcelExportConfirmModal(false);
                            setConfirmationPopupType("");
                        }}
                        onYes={() => {
                            if (confirmationPopupType === "excel") {
                                toggleExcelExportView();
                            } else {
                                setSmartChartData("custom_tile", {
                                    bandName: "band1",
                                    entity: "project",
                                    customTileKey: "recom_onepager" + uuid(),
                                    customTileData: {
                                        band1: {
                                            mfilter: {},
                                            settings_id: "",
                                            name: "Recommendation One Pager",
                                            remove_section_break: false
                                        }
                                    }
                                });
                            }
                            setConfirmationPopupType("");
                            setShowExcelExportConfirmModal(false);
                        }}
                    />
                }
                onCancel={() => setShowExcelExportConfirmModal(false)}
            />
        );
    };

    const toggleToExcelExportCheck = () => {
        const {
            project: {
                band1: { type: projectTypes = {} }
            },
            assets: {
                band1: { type: assetTypes = {} }
            },
            energy_mng: {
                band1: { type: energyTypes = {} }
            }
        } = chartData.properties_text;
        if (Object.keys({ ...projectTypes, ...assetTypes, ...energyTypes }).length) {
            setConfirmationPopupType("excel");
            setShowExcelExportConfirmModal(true);
        } else {
            toggleExcelExportView();
        }
    };

    const toggleExcelExportView = () => {
        setCopyChartData(prevValue => {
            return {
                ...prevValue,
                properties_text: isExcelExport
                    ? {
                          ...DEFAULT_PROPERTY_VALUE
                      }
                    : { ...DEFAULT_PROPERTY_VALUE_FOR_EXCEL }
            };
        });
        setChartData(prevValue => {
            return {
                ...prevValue,
                properties_text: {
                    project: {
                        band1: {
                            ...prevValue.properties_text.project.band1,
                            type: {}
                        }
                    },
                    assets: {
                        band1: {
                            ...prevValue.properties_text.assets.band1,
                            type: {}
                        }
                    },
                    energy_mng: {
                        band1: {
                            ...prevValue.properties_text.energy_mng.band1,
                            type: {}
                        }
                    }
                }
            };
        });
        setChartsOrder(
            isExcelExport
                ? {
                      project: Object.keys(CHART_ITEMS.project),
                      energy_mng: Object.keys(CHART_ITEMS.energy_mng),
                      assets: Object.keys(CHART_ITEMS.assets)
                  }
                : {
                      project: Object.keys(CHART_ITEMS_FOR_EXCEL.project),
                      energy_mng: Object.keys(CHART_ITEMS_FOR_EXCEL.energy_mng),
                      assets: Object.keys(CHART_ITEMS_FOR_EXCEL.assets)
                  }
        );
        setIsExcelExport(prevValue => !prevValue);
    };

    const checkTileSelectionDisabled = () => {
        const {
            project: {
                band1: { type: projectTypes = {} }
            },
            assets: {
                band1: { type: assetTypes = {} }
            },
            energy_mng: {
                band1: { type: energyTypes = {} }
            }
        } = chartData.properties_text;
        let isDisabled = false;
        if (isExcelExport && Object.keys({ ...projectTypes, ...assetTypes, ...energyTypes }).length) {
            isDisabled = true;
        }
        return isDisabled;
    };

    const setRequiredFieldsForSkeleton = (skelData = {}) => {
        setRequiredFields({
            project: [...Object.keys(skelData?.project?.band1?.mfilter || {})],
            energy_mng: [...Object.keys(skelData?.energy_mng?.band1?.mfilter || {})],
            assets: [...Object.keys(skelData?.assets?.band1?.mfilter || {})]
        });
    };

    const checkRequiredFilterSelected = () => {
        return requiredFields?.project
            ?.filter(key => key !== "client_ids")
            ?.every(filterKey => chartData.properties_text?.project?.band1?.mfilter?.[filterKey]?.length);
    };

    const removeExtrakeysFromEnergyBand = tempPropertyData => {
        if (tempPropertyData?.project?.band1?.type?.hasOwnProperty("energy_band")) {
            delete tempPropertyData?.project?.band1?.type["em_eci_site_cost"];
            delete tempPropertyData?.project?.band1?.type["em_eci_energy_cost"];
            delete tempPropertyData?.project?.band1?.type["bld_brkdown_energy_cost"];
        }
        if (tempPropertyData?.energy_mng?.band1?.type?.hasOwnProperty("energy_band")) {
            delete tempPropertyData?.energy_mng?.band1?.type["em_eci_site_cost"];
            delete tempPropertyData?.energy_mng?.band1?.type["em_eci_energy_cost"];
            delete tempPropertyData?.energy_mng?.band1?.type["bld_brkdown_energy_cost"];
        }
        return tempPropertyData;
    };

    const updatePageBreakConfig = params => {
        const { entity, chartKey, bandName } = params;
        let selectedTileData = chartData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey]
            ? { ...chartData?.properties_text?.[entity]?.[bandName]?.type?.[chartKey] }
            : {};
        setSmartChartData("config_image_band", {
            ...params,
            imageBandConfig: params?.selectedTile?.isRecommendation
                ? { band1: { ...selectedTileData.band1, remove_section_break: params?.remove_section_break } }
                : { ...selectedTileData, remove_section_break: params?.remove_section_break }
        });
    };

    const removeBBITiles = () => {
        const { bbi_report1, ...restTypeData } = chartData.properties_text.project.band1?.type;
        setChartData(prevState => {
            return {
                ...prevState,
                properties_text: {
                    ...prevState.properties_text,
                    project: {
                        ...prevState.properties_text?.project,
                        band1: {
                            ...prevState.properties_text?.project?.band1,
                            type: {
                                ...restTypeData
                            }
                        }
                    }
                }
            };
        });
    };

    const removeBSITiles = () => {
        const { bsi_report1, ...restTypeData } = chartData.properties_text.project.band1?.type;
        setChartData(prevState => {
            return {
                ...prevState,
                properties_text: {
                    ...prevState.properties_text,
                    project: {
                        ...prevState.properties_text?.project,
                        band1: {
                            ...prevState.properties_text?.project?.band1,
                            type: {
                                ...restTypeData
                            }
                        }
                    }
                }
            };
        });
    };

    const showExportPropertiesModal = () => {
        setShowExportProperties(prevValue => !prevValue);
    };

    const renderExportPropertiesModal = () => {
        if (!showExportProperties) return null;
        return (
            <Portal
                body={
                    <ExportPropertiesModal
                        onCancel={() => setShowExportProperties(false)}
                        selectedExportProperty={selectedExportProperty}
                        handleUpdateExportProperty={handleUpdateExportProperty}
                    />
                }
                onCancel={() => setShowExportProperties(false)}
            />
        );
    };

    const handleUpdateExportProperty = async data => {
        await props.updateProperty(data);
        showAlert("Export Property updated successfully");
        props.getTemplatePropertiesList({ client_id: selectedClient });
        setShowExportProperties(false);
    };
    const getDropdownTooltip = (itemId = "", optionArray = []) => {
        const item = optionArray?.find(option => option.id === itemId);
        let content = item?.name;
        if (optionArray?.some(option => option?.sm_default)) {
            if (item?.sm_default) {
                content += ` (Active)`;
            }
        } else if (item?.default) {
            content += ` (Active)`;
        }
        return content;
    };

    let exportPropertyList = props?.smartChartReducer?.getTemplatePropertiesListResponse?.data || [];
    let exportTemplateList = props?.smartChartReducer?.getTemplateListResponse?.data || [];
    let clientList = props?.smartChartReducer?.getClientDropDownDataResponse?.data || [];
    let isSelectionDisabled = checkTileSelectionDisabled();

    function getViewOrder(typeData) {
        const orderedKeys = Object.keys(typeData);

        return orderedKeys?.filter(key => CHART_DATA_VIEWS?.includes(key));
    }
    function getOrderedKeys(bandData) {
        return bandData?.type ? Object.keys(bandData?.type) : [];
    }

    function compareDataOrders(data1, data2) {
        const bands = ["project", "energy_mng", "assets"];

        const areGeneralKeysSame = band => {
            const keys1 = getOrderedKeys(data1?.[band]?.band1 || {});
            const keys2 = getOrderedKeys(data2?.[band]?.band1 || {});
            return _.isEqual(keys1, keys2);
        };

        const areViewOrdersSame = (band, type) => {
            const typeData1 = data1?.[band]?.band1?.type?.[type] || {};
            const typeData2 = data2?.[band]?.band1?.type?.[type] || {};
            const viewOrder1 = getViewOrder(typeData1);
            const viewOrder2 = getViewOrder(typeData2);
            return _.isEqual(viewOrder1, viewOrder2);
        };

        const areGeneralKeysEqual = bands?.every(areGeneralKeysSame);

        const types = Array.from(new Set([...Object.keys(data1?.project?.band1?.type || {}), ...Object.keys(data2?.project?.band1?.type || {})]));
        const areViewOrdersEqual = bands?.every(band => types.every(type => areViewOrdersSame(band, type)));

        return areGeneralKeysEqual && areViewOrdersEqual;
    }

    return (
        <>
            <div class="dtl-sec col-md-12 dshb layout-sm-outer">
                <ReactTooltip
                    id="report"
                    className="rc-tooltip-custom-class"
                    backgroundColor="#007bff"
                    place="right"
                    effect="solid"
                    delayShow={500}
                />
                <div class="table-top-menu pt-3 pb-3 ">
                    {isEdit ? (
                        <div class="recom-notify-img">
                            <img src={exclmIcon} alt="" />
                        </div>
                    ) : null}
                    <div class="lft-sec">
                        <h4>
                            {isEdit ? (
                                <>
                                    Edit <span>{propertyName}</span> Report Template{" "}
                                </>
                            ) : (
                                "New Report Template"
                            )}
                        </h4>
                    </div>
                    {isEdit && selectedProperty?.smart_export_count ? (
                        <div className="view-reports-sm-charts">
                            <button class="btn btn-primary view-report-btn" onClick={() => viewReports(selectedProperty?.id)}>
                                View <span>{selectedProperty.smart_export_count || 0}</span>{" "}
                                {`Connected Report${selectedProperty.smart_export_count > 1 ? "s" : ""}`}
                            </button>
                        </div>
                    ) : null}
                    <div class="rgt">
                        <div class="switcher-sm-chart">
                            <label class="name-label">Excel Export</label>
                            <label
                                class="switch toggle-switch mr-2"
                                data-tip=""
                                data-effect="solid"
                                data-place="bottom"
                                data-for="table-row1079f3b2-e33b-4273-9679-38566601b9c1"
                                currentitem="false"
                            >
                                <input type="checkbox" checked={isExcelExport} onChange={() => toggleToExcelExportCheck()} />
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <button class="btn btn-save btn-cnl" onClick={() => handleCancelClick()}>
                            Cancel
                        </button>
                        <button
                            class="btn btn-save"
                            disabled={
                                isEdit
                                    ? _.isEqual(chartData, initialData?.current) &&
                                      selectedProperty?.template_id === selectedExportTemplate &&
                                      selectedProperty?.template_property_id === selectedExportProperty &&
                                      compareDataOrders(chartData?.properties_text, initialData?.current?.properties_text)
                                    : false
                            }
                            onClick={() => (isEdit ? updateSmartChartConfigData() : saveSmartChartConfigData())}
                        >
                            {isEdit ? "Update" : "Save"}
                        </button>
                        {!isEdit ? (
                            <button class="btn btn-save btn-exp" onClick={() => saveAndExportReportData()}>
                                Save & Export
                                {isSaveAndExport ? <span className="spinner-border spinner-border-sm pl-2 ml-2" role="status"></span> : ""}
                            </button>
                        ) : (
                            <button class="btn btn-save btn-exp" onClick={() => toggleNewTemplateEditModal()}>
                                Save As New Template
                            </button>
                        )}
                    </div>
                </div>
                <div class="tab-dtl region-mng chart-drage-area ">
                    <div class="tab-active location-sec smart-detail-scroll">
                        <div className="row m-0">
                            <div className="col-md-12">
                                <div className="chart-hed-main">
                                    <div className="form-row rpt-templ">
                                        <div className="form-group col-4">
                                            <label>Client</label>
                                            <select
                                                className={`${
                                                    showErrorBorder && !selectedClient?.trim()?.length ? "error-border" : ""
                                                } form-control dropdown export-prop-select`}
                                                value={selectedClient}
                                                name="font_id"
                                                onChange={e => handleSelectClient(e.target.value)}
                                                data-tip={getDropdownTooltip(
                                                    selectedClient,
                                                    props?.smartChartReducer?.getClientDropDownDataResponse?.data
                                                )}
                                                data-for="report"
                                            >
                                                <option value="">Select</option>
                                                {clientList.map(p => (
                                                    <option key={p.id} value={p.id}>
                                                        {p.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group col-4">
                                            <label>Export Property</label>
                                            {checkPermission("forms", "client_chart_properties", "edit") && selectedExportProperty && (
                                                <>
                                                    <ReactTooltip
                                                        id={`sm-charts-export-property-button`}
                                                        effect="solid"
                                                        place="bottom"
                                                        backgroundColor="#007bff"
                                                    />
                                                    <button
                                                        className="btn btn-edit btn-edit-exp"
                                                        data-tip={"Edit Export Property"}
                                                        data-for={`sm-charts-export-property-button`}
                                                        data-place="top"
                                                        onClick={e => {
                                                            setSelectedExptProperty(selectedExportProperty);
                                                            showExportPropertiesModal();
                                                        }}
                                                    >
                                                        <img src="/img/Icon-material-mode-edit.svg" />
                                                    </button>
                                                </>
                                            )}
                                            <select
                                                className={`${
                                                    showErrorBorder && !selectedExportProperty?.trim()?.length ? "error-border" : ""
                                                } form-control dropdown export-prop-select`}
                                                value={selectedExportProperty}
                                                name="font_id"
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    setSelectedExportProperty(value);
                                                }}
                                                data-tip={getDropdownTooltip(
                                                    selectedExportProperty,
                                                    props?.smartChartReducer?.getTemplatePropertiesListResponse?.data
                                                )}
                                                data-for="report"
                                            >
                                                <option value="">Select</option>
                                                {selectedClient &&
                                                    exportPropertyList.map(p => (
                                                        <option key={p.id} value={p.id}>
                                                            {p.name}{" "}
                                                            {exportPropertyList.some(temp => temp.sm_default) && p.sm_default
                                                                ? "(Active)"
                                                                : p.default
                                                                ? "(Active)"
                                                                : ""}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        {!isExcelExport && (
                                            <div className="form-group col-4">
                                                <label>Export Template</label>
                                                <select
                                                    className={`${
                                                        showErrorBorder && !selectedExportTemplate?.trim()?.length ? "error-border" : ""
                                                    } form-control dropdown export-prop-select`}
                                                    value={selectedExportTemplate}
                                                    name="font_id"
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        setSelectedExportTemplate(value);
                                                    }}
                                                    data-tip={getDropdownTooltip(
                                                        selectedExportTemplate,
                                                        props?.smartChartReducer?.getTemplateListResponse?.data
                                                    )}
                                                    data-for="report"
                                                >
                                                    <option value="">Select</option>
                                                    {selectedClient &&
                                                        exportTemplateList.map(p => {
                                                            return (
                                                                <option key={p.id} value={p.id}>
                                                                    {p.name}{" "}
                                                                    {exportTemplateList.some(temp => temp.sm_default) && p.sm_default
                                                                        ? "(Active)"
                                                                        : p.default
                                                                        ? "(Active)"
                                                                        : ""}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        )}
                                        <div className="form-group col-4">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                className={`${showErrorBorder && !chartData.name.trim().length ? "error-border" : ""} form-control`}
                                                value={chartData.name}
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    setChartData(prevChartData => {
                                                        return {
                                                            ...prevChartData,
                                                            name: value
                                                        };
                                                    });
                                                }}
                                            />
                                            {showErrorBorder && chartData.name.trim().length > 100 && (
                                                <small className="text-danger pl-2 er-danger">Name should not exceed 100 characters.</small>
                                            )}
                                        </div>
                                        <div className="form-group col-4">
                                            <label>Notes</label>
                                            <textarea
                                                className="form-control textarea area-hgt"
                                                value={chartData.notes}
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    setChartData(prevChartData => {
                                                        return {
                                                            ...prevChartData,
                                                            notes: value
                                                        };
                                                    });
                                                }}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <div className="row m-0">
                                <div className="col-md-12">
                                    <div id="accordion">
                                        {Object.keys(chartData.properties_text).map((entity, index) => (
                                            <ChartMain
                                                getSmartChartMasterFilterDropDown={getSmartChartMasterFilterDropDown}
                                                projectsDropdownData={projectsDropdownData}
                                                projectId={projectId}
                                                setSmartChartData={setSmartChartData}
                                                chartData={chartData?.properties_text}
                                                copyChartData={copyChartData?.properties_text}
                                                masterFilterList={masterFilterList}
                                                openChartItemConfigModal={openChartItemConfigModal}
                                                chartKeys={chartsOrder[entity]}
                                                entity={entity}
                                                selectedClient={selectedClient}
                                                isEdit={isEdit}
                                                toggleImageListForBandModal={toggleImageListForBandModal}
                                                toggleImageBandDeleteConfirmationModal={toggleImageBandDeleteConfirmationModal}
                                                editImageBand={editImageBand}
                                                addDocumentTile={addDocumentTile}
                                                toggleAddCAband={toggleAddCAband}
                                                addOnepagerTile={addOnepagerTile}
                                                addOnepagerAssetTile={addOnepagerAssetTile}
                                                isExcelExport={isExcelExport}
                                                isSelectionDisabled={isSelectionDisabled}
                                                isSkeletonView={isSkeletonView}
                                                requiredFields={requiredFields}
                                                updatePageBreakConfig={updatePageBreakConfig}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </DragDropContext>
                    </div>
                </div>
            </div>
            {renderChartItemConfigModal()}
            {renderBbiConfigModal()}
            {renderConfirmationModal()}
            {renderSaveAsConfirmationModal()}
            {renderNewTemplateEditModal()}
            {renderImageBandConfigModal()}
            {renderImageListForBandModal()}
            {renderDeleteConfirmationModal()}
            {renderImageBandEditModal()}
            {renderAddCAbandModal()}
            {renderMultiRecommendationModal()}
            {renderExcelExportConfirmationModal()}
            {renderExportPropertiesModal()}
            {renderMemoEditor()}
            {renderExportSettings()}
            {renderCorereportConfirmationModal()}
        </>
    );
};
const { updateProperty } = chartPropertyActions;

const mapStateToProps = state => {
    const { smartChartReducer } = state;
    return { smartChartReducer };
};

export default withRouter(connect(mapStateToProps, { ...actions, updateProperty })(CreateSmartChart));
