/* eslint-disable import/no-anonymous-default-export */
import * as actionType from "./constants";

const initialState = {
    getNormalRecomDownloadResponse: { isLoading: false },
    getMobileRecommendationsResponse: { isLoading: false },
    mobileRecommendationEntityParams: {
        entity: null,
        selectedEntity: null,
        selectedRowId: null,
        paginationParams: {
            totalPages: 0,
            perPage: 100,
            currentPage: 0,
            totalCount: 0
        },
        params: {
            limit: 100,
            offset: 0,
            search: "",
            filters: null,
            filterKeys: null,
            list: null,
            order: null
        },
        wildCardFilterParams: {},
        filterParams: {},
        tableConfig: null
    },
    getMobileRecommendationByIdResponse: { loading: false }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_ALL_MOBILE_RECOMMENDATIONS_REQUEST:
            return {
                ...state,
                getMobileRecommendationsResponse: { isLoading: true }
            };
        case actionType.GET_ALL_MOBILE_RECOMMENDATIONS_SUCCESS:
            return {
                ...state,
                getMobileRecommendationsResponse: { isLoading: false, success: true, ...action.response }
            };
        case actionType.GET_ALL_MOBILE_RECOMMENDATIONS_FAILURE:
            return {
                ...state,
                getMobileRecommendationsResponse: { isLoading: false, success: false, ...action.error }
            };

        case actionType.GET_MOBILE_RECOMMENDATION_BY_ID_REQUEST:
            return {
                ...state,
                getMobileRecommendationByIdResponse: { isLoading: true }
            };
        case actionType.GET_MOBILE_RECOMMENDATION_BY_ID_SUCCESS:
            return {
                ...state,
                getMobileRecommendationByIdResponse: { isLoading: false, success: true, ...action.response }
            };
        case actionType.GET_MOBILE_RECOMMENDATION_BY_ID_FAILURE:
            return {
                ...state,
                getMobileRecommendationByIdResponse: { isLoading: false, success: false, ...action.error }
            };

        case actionType.UPDATE_SURVEY_RECOMMENDATION_ENTITY_PARAMS_SUCCESS:
            return {
                ...state,
                entityParams: { ...action.response }
            };
        case actionType.UPDATE_SURVEY_RECOMMENDATION_ENTITY_PARAMS_FAILURE:
            return {
                ...state,
                entityParams: { ...action.error }
            };

        case actionType.CLEAR_ENTITY_PARAMS:
            return {
                ...state,
                mobileRecommendationEntityParams: {
                    entity: null,
                    selectedEntity: null,
                    selectedRowId: null,
                    paginationParams: {
                        totalPages: 0,
                        perPage: 100,
                        currentPage: 0,
                        totalCount: 0
                    },
                    params: {
                        limit: 100,
                        offset: 0,
                        search: "",
                        filters: null,
                        filterKeys: null,
                        list: null,
                        order: null
                    },
                    wildCardFilterParams: {},
                    filterParams: {},
                    tableConfig: null
                }
            };
        default:
            return state;
    }
};
