import React, { memo, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import imgProcess from "../../../assets/img/img-process.svg";
import heartLine from "../../../assets/img/heart-line.svg";
import heartFill from "../../../assets/img/heart-fill.svg";
import { areEqual } from "react-window";
import { getFileExtension, resetCursor, toTitleCase } from "../../../config/utils";
import { useRef } from "react";
import Iframe from "react-iframe";
import { useDispatch, useSelector } from "react-redux";
import actions from "../actions";

export const GridItem = memo(
    ({
        index,
        item,
        updateImage,
        isSmartChartView = false,
        handleEdit,
        handleFavClick,
        handleMultiSelectImage,
        isAssignView,
        hasEdit,
        viewRecommendations,
        viewImageModal,
        showSelectBox,
        handleInputCaptionData,
        viewAssets,
        isTouched,
        isSelected,
        isFavorite
    }) => {
        const dispatch = useDispatch();
        const ref = useRef(null);
        const [toggleInput, setToggleInput] = useState(false);
        const textareaRef = useRef(null);
        const [caption, setCaption] = useState({
            caption: "",
            image_ids: [],
            tags: [],
            removed_tags: [],
            captionchange: true
        });
        const [initialCaption, setInitialCaption] = useState("");
        const [alertMessage, setAlertMessage] = useState("");
        const { imageUpdateResponse } = useSelector(state => state?.imageReducer);
        useEffect(() => {
            document.addEventListener("click", handleClickOutside, true);
            ReactTooltip.rebuild();
            return () => {
                document.removeEventListener("click", handleClickOutside, true);
            };
        }, [toggleInput]);
        useEffect(() => {
            const handleClickOutside = event => {
                if (textareaRef.current && !textareaRef.current.contains(event.target)) {
                    if (initialCaption?.trim() !== caption?.caption?.trim()) {
                        handleInputCaption();
                    }
                    handleClick("");
                }
            };
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [caption]);
        useEffect(() => {
            if (imageUpdateResponse?.message && (initialCaption || caption?.image_ids.length > 0)) {
                setAlertMessage("Image Caption Updated Successfully");
            }
        }, [imageUpdateResponse]);
        useEffect(() => {
            if (alertMessage !== "") {
                var x = document.getElementById("sucess-alert");
                if (x) {
                    x.className = "show";
                    x.innerText = alertMessage;
                    setTimeout(function () {
                        x.className = x.className.replace("show", "");
                    }, 3000);
                }
                setAlertMessage("");
            }
        }, [alertMessage]);

        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setToggleInput(false);
            }
        };

        const handleGetImageId = (id, value) => {
            setInitialCaption(value?.caption);
            setCaption({
                caption: value.caption?.trim(),
                image_ids: [id],
                tags: value?.labels?.map(item => item.name),
                removed_tags: [],
                captionchange: true
            });
            handleClick();
        };
        const handleClick = () => {
            setToggleInput(!toggleInput);
        };
        const handleInputCaption = async () => {
            await handleInputCaptionData(caption);
            handleClick();
            dispatch(actions.updateImage(caption));
            ReactTooltip.rebuild();
        };
        const moveCaretAtEnd = e => {
            var temp_value = e.target.value;
            e.target.value = "";
            e.target.value = temp_value;
        };

        if (!item) return null;
        return (
            <div className={`items ${isTouched ? "active-item" : ""}`} key={`img-grid-items-${item.id}`} ref={ref}>
                <div className="drop-sec">
                    {getFileExtension(item.s3_thumbnail_key) === "html" && (
                        <div
                            className="icon"
                            onClick={e => {
                                ReactTooltip.hide();
                                viewImageModal(item);
                            }}
                        >
                            <i className="fa fa-eye" data-for={"image-grid"} data-tip={"View"}></i>
                        </div>
                    )}
                    <div className="icon-url icon-hert mr-2">
                        <img
                            alt="fav"
                            src={isFavorite ? heartFill : heartLine}
                            data-for={"image-grid"}
                            data-tip={isFavorite ? "Remove from Favorites" : "Add to Favorites"}
                            onClick={() => handleFavClick(item?.id, !isFavorite)}
                        />
                    </div>
                    {item.recommendations?.length ? (
                        <div className="icon-rec" onClick={() => (isAssignView ? null : viewRecommendations(item, index))}>
                            <i
                                className="far fa-file-alt"
                                data-for={"image-grid"}
                                data-tip={isAssignView ? "Assigned to Recommendation" : "View Recommendations"}
                            ></i>
                        </div>
                    ) : null}
                    {item.assets?.length ? (
                        <div className="icon-rec" onClick={() => (isAssignView ? null : viewAssets(item, index))}>
                            <i
                                className="far fa-file-alt"
                                data-for={"image-grid"}
                                data-tip={isAssignView ? "Assigned to Asset" : "View Assigned Assets"}
                            ></i>
                        </div>
                    ) : null}
                    {hasEdit && getFileExtension(item.s3_thumbnail_key) !== "html" && (
                        <div className="icon" onClick={() => handleEdit(item)}>
                            <i className="fa fa-pencil-alt" data-for={"image-grid"} data-tip={"Edit"}></i>
                        </div>
                    )}
                </div>

                <div className="images">
                    {item?.s3_thumbnail_key ? (
                        <>
                            {getFileExtension(item.s3_thumbnail_key) === "html" ? (
                                <>
                                    <Iframe
                                        url={item?.s3_thumbnail_key}
                                        width="100%"
                                        height="100%"
                                        display="initial"
                                        position="relative"
                                        className="pdf-view"
                                        overflow="hidden"
                                    />
                                </>
                            ) : (
                                <img
                                    onClick={() => viewImageModal(item)}
                                    alt={`${item?.name}`}
                                    placeholderSrc={imgProcess}
                                    src={item.is_edited ? item?.s3_ethumbnail_key : item?.s3_thumbnail_key}
                                    className="cursor-hand"
                                />
                            )}
                        </>
                    ) : (
                        <div className="img-process">
                            <img src={imgProcess} alt="processing" />
                            <h3>Processing...</h3>
                        </div>
                    )}
                    {isAssignView && (!item.not_assigned || item.is_locked || !item.s3_thumbnail_key) && !isSmartChartView
                        ? null
                        : showSelectBox && (
                              <label className="container-check">
                                  <input type="checkbox" checked={isSelected} onChange={e => handleMultiSelectImage(item, e.target.checked)} />
                                  <span className="checkmark"></span>
                              </label>
                          )}
                </div>
                <div className="cnt-img-sec">
                    <div className="heading">
                        {toggleInput ? (
                            <div htmlFor="" className="img-title-edit-lbl editor-txt-lb">
                                <textarea
                                    // className="floating-textarea"
                                    type="text"
                                    ref={textareaRef}
                                    // maxLength={this.state.captionMaxLength}
                                    // ref={inputRef}
                                    value={caption.caption}
                                    onChange={e => {
                                        resetCursor(e);
                                        let updatedValue = {};
                                        updatedValue = { caption: toTitleCase(e.target.value) };
                                        setCaption(caption => ({ ...caption, ...updatedValue }));
                                    }}
                                    // onBlur={() => setToggleInput(false)}
                                    onKeyDown={event => {
                                        if (event.key === "Enter" && !event.shiftKey) {
                                            if (caption?.caption?.trim() !== item?.caption?.trim()) {
                                                handleInputCaption();
                                            }
                                            handleClick("");
                                        }
                                        if (event.key === "Escape") {
                                            setCaption(prevCaption => ({
                                                ...prevCaption,
                                                caption: item?.caption
                                            }));
                                            handleClick("");
                                        }
                                    }}
                                    placeholder="Caption"
                                    onDoubleClick={handleClick}
                                    autoFocus
                                    onFocus={moveCaretAtEnd}
                                    data-place="bottom"
                                    data-effect="solid"
                                    // data-for={"image-grid"}
                                    // data-tip={
                                    //     "<b>Please Click Enter key to Update</b><br/>.........................Or........................<br/><b>Double Click  to Go Back</b> <br/>"
                                    // }
                                />
                            </div>
                        ) : (
                            <div className="title-edit-out">
                                <h3
                                    className={`${item?.caption ? "" : "no-caption-txt"}`}
                                    // data-for={"image-grid"}
                                    style={{ cursor: item?.caption ? "auto" : "pointer", textTransform: "none" }}
                                    html={true}
                                    // data-tip={
                                    //     item.caption
                                    //         ? `<b>Double Click Here to Edit Caption</b> <br/> ${item.caption}`
                                    //         : "Double Click Here to Add Caption"
                                    // }
                                    data-for={"image-grid"}
                                    data-tip={item?.caption}
                                    onDoubleClick={() => {
                                        if (item?.caption && item?.s3_thumbnail_key) {
                                            handleGetImageId(item.id, item);
                                        }
                                    }}
                                    onClick={() => {
                                        if (!item?.caption && item?.s3_thumbnail_key) {
                                            handleGetImageId(item.id, item);
                                        }
                                    }}
                                >
                                    {item?.caption || "No Caption"}
                                </h3>
                                {item?.s3_thumbnail_key && (
                                    <i
                                        className="fa fa-pencil-alt"
                                        data-for={"image-grid"}
                                        data-tip={"Edit"}
                                        onClick={() => {
                                            if (item?.s3_thumbnail_key) {
                                                handleGetImageId(item.id, item);
                                            }
                                        }}
                                    ></i>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="cnt-area">
                        <div className="secs">
                            <span className="sec-label"> Building </span>
                            <span
                                className="dtl"
                                data-for={"image-grid"}
                                data-tip={`${item?.building?.name || ""} ${item.building?.description ? `(${item.building?.description})` : ""}`}
                            >
                                {item?.building?.name || "-"}
                            </span>
                        </div>
                        <div className="secs">
                            <span className="sec-label">Trade </span>
                            <span className="dtl" data-for={"image-grid"} data-tip={item?.trade?.name || "-"}>
                                {item?.trade?.name || "-"}
                            </span>
                        </div>
                    </div>
                    <div className="cnt-area sec-row">
                        <div className="secs">
                            <span className="dtl" data-for={"image-grid"} data-tip={item?.name || "-"}>
                                {item?.name || "-"}
                            </span>
                        </div>
                        <div className="secs">
                            <span
                                className="dtl"
                                data-for={"image-grid"}
                                data-tip={item?.meta_data?.date_taken ? moment(item?.meta_data?.date_taken).format("MM/DD/YY h:mm a") : "-"}
                            >
                                {item?.meta_data?.date_taken ? moment(item?.meta_data?.date_taken).format("MM/DD/YY h:mm a") : "-"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    },
    areEqual
);
