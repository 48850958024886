export const GET_ASSET_SURVEY_PROJECT_DROPDOWN_REQUEST = "GET_ASSET_SURVEY_PROJECT_DROPDOWN_REQUEST";
export const GET_ASSET_SURVEY_PROJECT_DROPDOWN_SUCCESS = "GET_ASSET_SURVEY_PROJECT_DROPDOWN_SUCCESS";
export const GET_ASSET_SURVEY_PROJECT_DROPDOWN_FAILURE = "GET_ASSET_SURVEY_PROJECT_DROPDOWN_FAILURE";

export const ADD_ASSET_SURVEY_PROJECT_DATA_REQUEST = "ADD_ASSET_SURVEY_PROJECT_DATA_REQUEST";
export const ADD_ASSET_SURVEY_PROJECT_DATA_SUCCESS = "ADD_ASSET_SURVEY_PROJECT_DATA_SUCCESS";
export const ADD_ASSET_SURVEY_PROJECT_DATA_FAILURE = "ADD_ASSET_SURVEY_PROJECT_DATA_FAILURE";

export const UPDATE_ASSET_SURVEY_PROJECT_DATA_REQUEST = "UPDATE_ASSET_SURVEY_PROJECT_DATA_REQUEST";
export const UPDATE_ASSET_SURVEY_PROJECT_DATA_SUCCESS = "UPDATE_ASSET_SURVEY_PROJECT_DATA_SUCCESS";
export const UPDATE_ASSET_SURVEY_PROJECT_DATA_FAILURE = "UPDATE_ASSET_SURVEY_PROJECT_DATA_FAILURE";

export const GET_ASSET_SURVEY_PROJECTS_SUCCESS = "GET_ASSET_SURVEY_PROJECTS_SUCCESS";
export const GET_ASSET_SURVEY_PROJECTS_REQUEST = "GET_ASSET_SURVEY_PROJECTS_REQUEST";
export const GET_ASSET_SURVEY_PROJECTS_FAILURE = "GET_ASSET_SURVEY_PROJECTS_FAILURE";

export const GET_SURVEY_ASSET_SUCCESS = "GET_SURVEY_ASSET_SUCCESS";
export const GET_SURVEY_ASSET_REQUEST = "GET_SURVEY_ASSET_REQUEST";
export const GET_SURVEY_ASSET_FAILURE = "GET_SURVEY_ASSET_FAILURE";

export const UPDATE_ASSET_SURVEY_PROJECTS_ENTITY_PARAMS_SUCCESS = "UPDATE_ASSET_SURVEY_PROJECTS_ENTITY_PARAMS_SUCCESS";
export const UPDATE_ASSET_SURVEY_PROJECTS_ENTITY_PARAMS_FAILURE = "UPDATE_ASSET_SURVEY_PROJECTS_ENTITY_PARAMS_FAILURE";

export const DELETE_ASSET_SURVEY_PROJECT_REQUEST = "DELETE_ASSET_SURVEY_PROJECT_REQUEST";
export const DELETE_ASSET_SURVEY_PROJECT_SUCCESS = "DELETE_ASSET_SURVEY_PROJECT_SUCCESS";
export const DELETE_ASSET_SURVEY_PROJECT_FAILURE = "DELETE_ASSET_SURVEY_PROJECT_FAILURE";

export const GET_ASSET_SURVEY_PROJECT_BY_ID_REQUEST = "GET_ASSET_SURVEY_PROJECT_BY_ID_REQUEST";
export const GET_ASSET_SURVEY_PROJECT_BY_ID_SUCCESS = "GET_ASSET_SURVEY_PROJECT_BY_ID_SUCCESS";
export const GET_ASSET_SURVEY_PROJECT_BY_ID_FAILURE = "GET_ASSET_SURVEY_PROJECT_BY_ID_FAILURE";

export const GET_COMMON_FILTER_LIST_FOR_ASSET_SURVEY_PROJECT_REQUEST = "GET_COMMON_FILTER_LIST_FOR_ASSET_SURVEY_PROJECT_REQUEST";
export const GET_COMMON_FILTER_LIST_FOR_ASSET_SURVEY_PROJECT_SUCCESS = "GET_COMMON_FILTER_LIST_FOR_ASSET_SURVEY_PROJECT_SUCCESS";
export const GET_COMMON_FILTER_LIST_FOR_ASSET_SURVEY_PROJECT_FAILURE = "GET_COMMON_FILTER_LIST_FOR_ASSET_SURVEY_PROJECT_FAILURE";

export const GET_SURVEY_ASSET_BY_ID_REQUEST = "GET_SURVEY_ASSET_BY_ID_REQUEST";
export const GET_SURVEY_ASSET_BY_ID_SUCCESS = "GET_SURVEY_ASSET_BY_ID_SUCCESS";
export const GET_SURVEY_ASSET_BY_ID_FAILURE = "GET_SURVEY_ASSET_BY_ID_FAILURE";

export const GET_SURVEY_ASSET_IMAGES_BY_ID_REQUEST = "GET_SURVEY_ASSET_IMAGES_BY_ID_REQUEST";
export const GET_SURVEY_ASSET_IMAGES_BY_ID_SUCCESS = "GET_SURVEY_ASSET_IMAGES_BY_ID_SUCCESS";
export const GET_SURVEY_ASSET_IMAGES_BY_ID_FAILURE = "GET_SURVEY_ASSET_IMAGES_BY_ID_FAILURE";

export const UPDATE_SURVEY_ASSET_DATA_REQUEST = "UPDATE_SURVEY_ASSET_DATA_REQUEST";
export const UPDATE_SURVEY_ASSET_DATA_SUCCESS = "UPDATE_SURVEY_ASSET_DATA_SUCCESS";
export const UPDATE_SURVEY_ASSET_DATA_FAILURE = "UPDATE_SURVEY_ASSET_DATA_FAILURE";

export const RESET_REDUCER = "RESET_REDUCER";

export const assetSurveyStatus = {
    // 1: { value: "Pending", class: "pending-st", color: "#e61607", disabled: true },
    2: { value: "Synced", class: "inprogress-st", color: "#FF2A00" },
    3: { value: "In Review", class: "submitted-st", color: "#006EFF" },
    4: { value: "Reviewed", class: "review-st", color: "#8193A9" },
    7: { value: "Rejected", class: "pending-st", color: "#e61607" },
    5: { value: "Completed", class: "completed-st", color: "#04B934", disabled: true }
};
export const surveyStatus = {
    1: { value: "Pending", class: "pending-st", color: "#e61607" },
    2: { value: "In Progress", class: "submitted-st", color: "#006EFF" },
    4: { value: "Completed", class: "completed-st", color: "#04B934" }
};

export const GET_ALL_DATA_LOG_REQUEST = "GET_ALL_DATA_LOG_REQUEST";
export const GET_ALL_DATA_LOG_SUCCESS = "GET_ALL_DATA_LOG_SUCCESS";
export const GET_ALL_DATA_LOG_FAILURE = "GET_ALL_DATA_LOG_FAILURE";

export const RESTORE_DATA_LOG_REQUEST = "RESTORE_DATA_LOG_REQUEST";
export const RESTORE_DATA_LOG_SUCCESS = "RESTORE_DATA_LOG_SUCCESS";
export const RESTORE_DATA_LOG_FAILURE = "RESTORE_DATA_LOG_FAILURE";

export const GET_REVIEWED_ASSETS_COUNT_REQUEST = "GET_REVIEWED_ASSETS_COUNT_REQUEST";
export const GET_REVIEWED_ASSETS_COUNT_SUCCESS = "GET_REVIEWED_ASSETS_COUNT_SUCCESS";
export const GET_REVIEWED_ASSETS_COUNT_FAILURE = "GET_REVIEWED_ASSETS_COUNT_FAILURE";

export const TRANSFER_REVIEWED_ASSETS_REQUEST = "TRANSFER_REVIEWED_ASSETS_REQUEST";
export const TRANSFER_REVIEWED_ASSETS_SUCCESS = "TRANSFER_REVIEWED_ASSETS_SUCCESS";
export const TRANSFER_REVIEWED_ASSETS_FAILURE = "TRANSFER_REVIEWED_ASSETS_FAILURE";

export const GET_COMPLETED_ASSETS_COUNT_REQUEST = "GET_COMPLETED_ASSETS_COUNT_REQUEST";
export const GET_COMPLETED_ASSETS_COUNT_SUCCESS = "GET_COMPLETED_ASSETS_COUNT_SUCCESS";
export const GET_COMPLETED_ASSETS_COUNT_FAILURE = "GET_COMPLETED_ASSETS_COUNT_FAILURE";

export const GET_SURVEY_ASSET_NOTE_BY_ID_REQUEST = "GET_SURVEY_ASSET_NOTE_BY_ID_REQUEST";
export const GET_SURVEY_ASSET_NOTE_BY_ID_SUCCESS = "GET_SURVEY_ASSET_NOTE_BY_ID_SUCCESS";
export const GET_SURVEY_ASSET_NOTE_BY_ID_FAILURE = "GET_SURVEY_ASSET_NOTE_BY_ID_FAILURE";

export const GET_INACCESSIBLE_SPACE_REQUEST = "GET_INACCESSIBLE_SPACE_REQUEST";
export const GET_INACCESSIBLE_SPACE_SUCCESS = "GET_INACCESSIBLE_SPACE_SUCCESS";
export const GET_INACCESSIBLE_SPACE_FAILURE = "GET_INACCESSIBLE_SPACE_FAILURE";

export const GET_INACCESSIBLE_SPACE_BY_ID_REQUEST = "GET_INACCESSIBLE_SPACE_BY_ID_REQUEST";
export const GET_INACCESSIBLE_SPACE_BY_ID_SUCCESS = "GET_INACCESSIBLE_SPACE_BY_ID_SUCCESS";
export const GET_INACCESSIBLE_SPACE_BY_ID_FAILURE = "GET_INACCESSIBLE_SPACE_BY_ID_FAILURE";

export const GET_REASONS_BY_ID_REQUEST = "GET_REASONS_BY_ID_REQUEST";
export const GET_REASONS_BY_ID_SUCCESS = "GET_REASONS_BY_ID_SUCCESS";
export const GET_REASONS_BY_ID_FAILURE = "GET_REASONS_BY_ID_FAILURE";

export const ADD_REASON_REQUEST = "ADD_REASON_REQUEST";
export const ADD_REASON_SUCCESS = "ADD_REASON_SUCCESS";
export const ADD_REASON_FAILURE = "ADD_REASON_FAILURE";

export const UPDATE_INACCESSIBLE_SPACE_SETTINGS_REQUEST = "UPDATE_INACCESSIBLE_SPACE_SETTINGS_REQUEST";
export const UPDATE_INACCESSIBLE_SPACE_SETTINGS_SUCCESS = "UPDATE_INACCESSIBLE_SPACE_SETTINGS_SUCCESS";
export const UPDATE_INACCESSIBLE_SPACE_SETTINGS_FAILURE = "UPDATE_INACCESSIBLE_SPACE_SETTINGS_FAILURE";

export const UPDATE_SURVEY_ASSET_FIELDS_REQUEST = "UPDATE_SURVEY_ASSET_FIELDS_REQUEST";
export const UPDATE_SURVEY_ASSET_FIELDS_SUCCESS = "UPDATE_SURVEY_ASSET_FIELDS_SUCCESS";
export const UPDATE_SURVEY_ASSET_FIELDS_FAILURE = "UPDATE_SURVEY_ASSET_FIELDS_FAILURE";

export const UPDATE_ASSET_IMAGE_COMMENT_REQUEST = "UPDATE_ASSET_IMAGE_COMMENT_REQUEST";
export const UPDATE_ASSET_IMAGE_COMMENT_SUCCESS = "UPDATE_ASSET_IMAGE_COMMENT_SUCCESS";
export const UPDATE_ASSET_IMAGE_COMMENT_FAILURE = "UPDATE_ASSET_IMAGE_COMMENT_FAILURE";

export const UPDATE_SURVEY_ASSETS_ENTITY_PARAMS_SUCCESS = "UPDATE_SURVEY_ASSETS_ENTITY_PARAMS_SUCCESS";
export const UPDATE_SURVEY_ASSETS_ENTITY_PARAMS_FAILURE = "UPDATE_SURVEY_ASSETS_ENTITY_PARAMS_FAILURE";

export const UPDATE_INACCESSIBLE_SPACE_ENTITY_PARAMS_SUCCESS = "UPDATE_INACCESSIBLE_SPACE_ENTITY_PARAMS_SUCCESS";
export const UPDATE_INACCESSIBLE_SPACE_ENTITY_PARAMS_FAILURE = "UPDATE_INACCESSIBLE_SPACE_ENTITY_PARAMS_FAILURE";

export const GET_ASSET_SURVEY_PROJECTS_EXPORT_REQUEST = "GET_ASSET_SURVEY_PROJECTS_EXPORT_REQUEST";
export const GET_ASSET_SURVEY_PROJECTS_EXPORT_SUCCESS = "GET_ASSET_SURVEY_PROJECTS_EXPORT_SUCCESS";
export const GET_ASSET_SURVEY_PROJECTS_EXPORT_FAILURE = "GET_ASSET_SURVEY_PROJECTS_EXPORT_FAILURE";

export const GET_SURVEY_ASSET_EXPORT_REQUEST = "GET_SURVEY_ASSET_EXPORT_REQUEST";
export const GET_SURVEY_ASSET_EXPORT_SUCCESS = "GET_SURVEY_ASSET_EXPORT_SUCCESS";
export const GET_SURVEY_ASSET_EXPORT_FAILURE = "GET_SURVEY_ASSET_EXPORT_FAILURE";

export const GET_SURVEY_ASSET_COMMON_DATA_BY_IDS_REQUEST = "GET_SURVEY_ASSET_COMMON_DATA_BY_IDS_REQUEST";
export const GET_SURVEY_ASSET_COMMON_DATA_BY_IDS_SUCCESS = "GET_SURVEY_ASSET_COMMON_DATA_BY_IDS_SUCCESS";
export const GET_SURVEY_ASSET_COMMON_DATA_BY_IDS_FAILURE = "GET_SURVEY_ASSET_COMMON_DATA_BY_IDS_FAILURE";

export const UPDATE_MULTI_SURVEY_ASSET_REQUEST = "UPDATE_MULTI_SURVEY_ASSET_REQUEST";
export const UPDATE_MULTI_SURVEY_ASSET_SUCCESS = "UPDATE_MULTI_SURVEY_ASSET_SUCCESS";
export const UPDATE_MULTI_SURVEY_ASSET_FAILURE = "UPDATE_MULTI_SURVEY_ASSET_FAILURE";

export const RESET_MULTI_COMMON_DATA_SUCCESS = "RESET_MULTI_COMMON_DATA_SUCCESS";
export const RESET_MULTI_COMMON_DATA_FAILURE = "RESET_MULTI_COMMON_DATA_FAILURE";

export const GET_ALL_SURVEY_ASSET_IDS_REQUEST = "GET_ALL_SURVEY_ASSET_IDS_REQUEST";
export const GET_ALL_SURVEY_ASSET_IDS_SUCCESS = "GET_ALL_SURVEY_ASSET_IDS_SUCCESS";
export const GET_ALL_SURVEY_ASSET_IDS_FAILURE = "GET_ALL_SURVEY_ASSET_IDS_FAILURE";

export const GET_COMMON_FILTER_LIST_FOR_SURVEY_ASSETS_REQUEST = "GET_COMMON_FILTER_LIST_FOR_SURVEY_ASSETS_REQUEST";
export const GET_COMMON_FILTER_LIST_FOR_SURVEY_ASSETS_SUCCESS = "GET_COMMON_FILTER_LIST_FOR_SURVEY_ASSETS_SUCCESS";
export const GET_COMMON_FILTER_LIST_FOR_SURVEY_ASSETS_FAILURE = "GET_COMMON_FILTER_LIST_FOR_SURVEY_ASSETS_FAILURE";

export const GET_SURVEYOR_DROPDOWN_REQUEST = "GET_SURVEYOR_DROPDOWN_REQUEST";
export const GET_SURVEYOR_DROPDOWN_SUCCESS = "GET_SURVEYOR_DROPDOWN_SUCCESS";
export const GET_SURVEYOR_DROPDOWN_FAILURE = "GET_SURVEYOR_DROPDOWN_FAILURE";

export const GET_ASSET_VOICE_NOTES_REQUEST = "GET_ASSET_VOICE_NOTES_REQUEST";
export const GET_ASSET_VOICE_NOTES_SUCCESS = "GET_ASSET_VOICE_NOTES_SUCCESS";
export const GET_ASSET_VOICE_NOTES_FAILURE = "GET_ASSET_VOICE_NOTES_FAILURE";

export const DELETE_ASSET_BY_ID_VOICE_NOTES_REQUEST = "DELETE_ASSET_BY_ID_VOICE_NOTES_REQUEST";
export const DELETE_ASSET_BY_ID_VOICE_NOTES_SUCCESS = "DELETE_ASSET_BY_ID_VOICE_NOTES_SUCCESS";
export const DELETE_ASSET_BY_ID_VOICE_NOTES_FAILURE = "DELETE_ASSET_BY_ID_VOICE_NOTES_FAILURE";

export const GET_SURVEY_RECOMMENDATION_IMAGES_BY_ID_REQUEST = "GET_SURVEY_RECOMMENDATION_IMAGES_BY_ID_REQUEST";
export const GET_SURVEY_RECOMMENDATION_IMAGES_BY_ID_SUCCESS = "GET_SURVEY_RECOMMENDATION_IMAGES_BY_ID_SUCCESS";
export const GET_SURVEY_RECOMMENDATION_IMAGES_BY_ID_FAILURE = "GET_SURVEY_RECOMMENDATION_IMAGES_BY_ID_FAILURE";

export const dropdownKeys = {
    "assets.site_id": "sites",
    "assets.trade_id": "trades",
    "assets.building_id": "buildings",
    "assets.region_id": "regions",
    "assets.system_id": "systems",
    "assets.floor_id": "floors",
    "assets.addition_id": "additions",
    "assets.asset_status_id": "asset_statuses",
    "assets.asset_type_id": "asset_types",
    "assets.client_asset_condition_id": "asset_conditions",
    "assets.main_category_id": "main_categories",
    "assets.manufacturer": "manufacturers"
};

export const autoFields = ["buildings.main_building_type_id", "assets.client_asset_condition__description"];
