import React, { Component } from "react";
import Draggable from "react-draggable";
import { withRouter } from "react-router-dom";
import { MASTER_FILTER_KEYS } from "../../smartCharts/constants";

class ConfirmationModal extends Component {
    state = {
        isDoubleCheckOk: false,
        isDeletePermanent: false,
        isRestoreCheckOk: false,
        isUnassignCheckOk: false,
        filterLists: {},
        globalfilterLists: {}
    };

    componentDidMount = async () => {
        if (this.props?.chartData && (this.props.clientCoreReport > 0 || this.props.globalCoreReport > 0)) {
            const filteredDatas = {};
            const filterValue = {};
            const GeoHierarchyFields = ["Projects", "Region", "Site", "B.Types", "Buildings", "Years", "start", "end"];
            const entityFilters = MASTER_FILTER_KEYS["project"]?.filter(e => GeoHierarchyFields.includes(e.label));

            if (entityFilters && this.props.chartData["project"]?.band1?.mfilter) {
                entityFilters.forEach(filter => {
                    const paramKey = filter.paramKey;
                    if (Object.keys(this.props.chartData["project"].band1.mfilter).includes(paramKey)) {
                        if (paramKey === "project_ids") {
                            filterValue[paramKey] = {
                                required: true,
                                keep_values: true
                            };
                        } else if (Object.keys(this.props.chartData["project"].band1.mfilter).includes(paramKey)) {
                            filterValue[paramKey] = {
                                required: true,
                                keep_values: false
                            };
                        }
                    }
                });
                filteredDatas["project"] = filterValue;
            }
            this.setState(prevState => {
                const newState = {
                    filterLists: prevState.filterLists,
                    globalfilterLists: prevState.globalfilterLists
                };
                if (this.props.clientCoreReport > 0) {
                    newState.filterLists = filteredDatas;
                }
                if (this.props.globalCoreReport > 0) {
                    newState.globalfilterLists = filteredDatas;
                }

                return newState;
            });
        }
        this.nameInput.focus();
        const { isDeleted } = this.props;
        if (isDeleted) {
            await this.setState({ isDeletePermanent: true });
        }
    };

    confirmationDoublecheck = event => {
        this.setState({
            isDoubleCheckOk: false
        });
        if (event.target.value.trim() === "DELETE") {
            this.setState({
                isDoubleCheckOk: true
            });
        }
    };

    confirmRestore = event => {
        this.setState({
            isRestoreCheckOk: false
        });
        if (event.target.value.trim() === "RESTORE") {
            this.setState({
                isRestoreCheckOk: true
            });
        }
    };

    confirmUnAssign = event => {
        this.setState({
            isUnassignCheckOk: false
        });
        if (event.target.value.trim() === "YES") {
            this.setState({
                isUnassignCheckOk: true
            });
        }
    };

    thousands_separators = num => {
        if (!num) return null;
        let numbe = num?.toString();
        let number = numbe?.split(".");
        number[0] = number[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return number.join(".");
    };
    getSelectedFilters = () => {
        const filteredDatas = {};
        const GeoHierarchyFields = ["Projects", "Region", "Site", "B.Types", "Buildings", "Years", "start", "end"];
        const entityFilters = MASTER_FILTER_KEYS["project"]?.filter(e => GeoHierarchyFields.includes(e.label));
        if (entityFilters && this.props?.chartData?.["project"]?.band1?.mfilter) {
            const filteredData = {};
            entityFilters.forEach(filter => {
                const paramKey = filter.paramKey;
                if (Object.keys(this?.props?.chartData?.["project"].band1.mfilter).includes(paramKey)) {
                    if (this.props?.chartData?.["project"]?.band1?.mfilter?.[paramKey]?.length > 0) {
                        filteredData[paramKey] = filter;
                    }
                }
            });
            filteredDatas["project"] = filteredData;
        }
        return filteredDatas;
    };

    handleFilterClick = (entityName, label, keyLabel, checked, type) => {
        const updateState = type === "global" ? "globalfilterLists" : "filterLists";

        this.setState(prevState => ({
            [updateState]: {
                ...prevState[updateState],
                [entityName]: {
                    ...prevState[updateState][entityName],
                    [label]: {
                        ...prevState[updateState][entityName][label],
                        [keyLabel]: checked,
                        ...(type !== "global" && checked && keyLabel === "keep_values" && { required: true })
                    }
                }
            }
        }));
    };

    render() {
        const {
            heading,
            message,
            onNo,
            onYes,
            isUnAssign,
            type = "delete",
            isHard = false,
            onHardDelete,
            isRestore = false,
            isDeleted = false,
            isCutPaste = false,
            isLogChange = false,
            associatedchanges,
            singleOk,
            onlyOk
        } = this.props;
        const { isDeletePermanent } = this.state;
        const integerValues = [
            "cost",
            "project_total",
            "area",
            "priority",
            "priority_element1",
            "priority_element2",
            "priority_element3",
            "priority_element4",
            "priority_element5",
            "priority_element6",
            "priority_element7",
            "priority_element8",
            "crv",
            "replacement_cost"
        ];
        return (
            <React.Fragment>
                <div
                    id="modalId"
                    className={`modal modal-region ${this.props?.isCoreReportFilterChangeModal ? "core-report-dtl-modal" : "deleteModal"}`}
                    style={{ display: "block" }}
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <Draggable cancel=".not-draggable">
                        <div className="modal-dialog draggable" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {singleOk ? (
                                        <button
                                            type="button"
                                            className="close not-draggable"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => onlyOk()}
                                        >
                                            <span aria-hidden="true">
                                                <img src="/img/close.svg" alt="" />
                                            </span>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="close not-draggable"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => onNo()}
                                        >
                                            <span aria-hidden="true">
                                                <img src="/img/close.svg" alt="" />
                                            </span>
                                        </button>
                                    )}
                                </div>
                                <div className="modal-body region-otr p-3 pb-5">
                                    <div className="deleteInner">
                                        {this.props.isNotOk ? (
                                            <i className="fas fa-times-circle" />
                                        ) : !this.props?.isCoreReportFilterChangeModal ? (
                                            <i className="far fa-check-circle" />
                                        ) : (
                                            ""
                                        )}
                                        <h2 className="not-draggable">{heading}</h2>
                                        {isLogChange
                                            ? associatedchanges && associatedchanges.length
                                                ? associatedchanges.map(i => {
                                                      return Object.entries(i.changeset).map((dat, index) => {
                                                          return (
                                                              <p className="not-draggable">
                                                                  The Value of Field <b>{dat[0].replace("_id", "")}</b> will restore to{" "}
                                                                  <b>
                                                                      {integerValues.includes(dat[0]) || typeof dat[1][0] === "number"
                                                                          ? this.thousands_separators(dat[1][0] && dat[1][0].toString()) || "null"
                                                                          : (dat[1][0] && dat[1][0].toString()) || "null"}
                                                                  </b>
                                                              </p>
                                                          );
                                                      });
                                                  })
                                                : null
                                            : null}
                                        {isLogChange ? (
                                            Object.entries(message).map((data, index) => {
                                                return (
                                                    <p className="not-draggable">
                                                        The Value of Field <b>{data[0]}</b> will restore to{" "}
                                                        <b>
                                                            {integerValues.includes(data[0]) || typeof data[1][0] === "number"
                                                                ? this.thousands_separators(data[1][0] && data[1][0].toString()) || "null"
                                                                : (data[1][0] && data[1][0].toString()) || "null"}
                                                        </b>
                                                    </p>
                                                );
                                            })
                                        ) : this.state.isDeletePermanent ? (
                                            <p className="not-draggable">
                                                {"This action cannot be reverted, are you sure that you need to permanently delete this item?"}
                                            </p>
                                        ) : (
                                            <p className="not-draggable">{message}</p>
                                        )}
                                        {isHard ? (
                                            <p className="not-draggable">
                                                <label className="container-check">
                                                    <input
                                                        className="form-check-input ml-2"
                                                        type="checkbox"
                                                        checked={isDeletePermanent || isDeleted ? "check" : ""}
                                                        disabled={isDeleted}
                                                        onClick={() => this.setState({ isDeletePermanent: !this.state.isDeletePermanent })}
                                                    />{" "}
                                                    Delete permanently<span className="checkmark"></span>
                                                </label>
                                            </p>
                                        ) : null}
                                    </div>
                                    {this.props.match.params.tab !== "infoimages" && type === "delete" && !this.props.isSurveyAsset ? (
                                        <div className="deleteInner">
                                            <p className="not-draggable">Type "DELETE" to confirm</p>
                                            <input
                                                className="form-control not-draggable"
                                                type="text"
                                                onChange={e => this.confirmationDoublecheck(e)}
                                            />
                                        </div>
                                    ) : type === "restore" ? (
                                        <div className="deleteInner">
                                            <p className="not-draggable">Type "RESTORE" to confirm</p>
                                            <input className="form-control not-draggable" type="text" onChange={e => this.confirmRestore(e)} />
                                        </div>
                                    ) : type === "unassign" ? (
                                        <div className="deleteInner">
                                            <p className="not-draggable">Type "YES" to confirm</p>
                                            <input className="form-control not-draggable" type="text" onChange={e => this.confirmUnAssign(e)} />
                                        </div>
                                    ) : null}
                                    {this.props?.clientCoreReport > 0 &&
                                        Object.entries(this.getSelectedFilters())?.map(([entityName, filterLabels], index) => (
                                            <div className="category-check-wrap not-draggable">
                                                <h4 style={{ textTransform: "capitalize" }}>Client Core Report</h4>
                                                {Object.entries(filterLabels).map(([key, Values], index) => (
                                                    <div className="d-flex">
                                                        <div className="formInp">
                                                            <div className="col-md-12 checkbox-cover">
                                                                <label className="container-check pl-0">{Values?.label}</label>
                                                            </div>
                                                        </div>
                                                        <div className="formInp">
                                                            <div className="col-md-12 checkbox-cover">
                                                                <span className="dropdown-item">
                                                                    <label className="container-check">
                                                                        Keep values
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={this?.state?.filterLists?.[entityName]?.[key]?.keep_values}
                                                                            disabled={Values?.label === "Projects"}
                                                                            onClick={e => {
                                                                                this.handleFilterClick(
                                                                                    entityName,
                                                                                    key,
                                                                                    "keep_values",
                                                                                    e.target.checked,
                                                                                    "client"
                                                                                );
                                                                            }}
                                                                        />
                                                                        <span
                                                                            className={`checkmark ${
                                                                                Values?.label === "Projects" ? "cursor-notallowed" : ""
                                                                            }`}
                                                                        ></span>
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="formInp">
                                                            <div className="col-md-12 checkbox-cover">
                                                                <span className="dropdown-item">
                                                                    <label className="container-check">
                                                                        Required
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={this.state?.filterLists?.[entityName]?.[key]?.required}
                                                                            disabled={
                                                                                Values?.label === "Projects" ||
                                                                                this.state?.filterLists?.[entityName]?.[key]?.keep_values
                                                                            }
                                                                            onClick={e => {
                                                                                this.handleFilterClick(
                                                                                    entityName,
                                                                                    key,
                                                                                    "required",
                                                                                    e.target.checked,
                                                                                    "client"
                                                                                );
                                                                            }}
                                                                        />
                                                                        <span
                                                                            className={`checkmark ${
                                                                                Values?.label === "Projects" ||
                                                                                this.state?.filterLists?.[entityName]?.[key]?.keep_values
                                                                                    ? "cursor-notallowed"
                                                                                    : ""
                                                                            }`}
                                                                        ></span>
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    {this.props?.globalCoreReport > 0 &&
                                        Object.entries(this.getSelectedFilters())?.map(([entityName, filterLabels], index) => (
                                            <div className="category-check-wrap not-draggable">
                                                <h4 style={{ textTransform: "capitalize" }}>Global Core Report</h4>
                                                {Object.entries(filterLabels).map(([key, Values], index) => (
                                                    <div className="d-flex">
                                                        <div className="formInp">
                                                            <div className="col-md-12 checkbox-cover">
                                                                <label className="container-check pl-0">{Values?.label}</label>
                                                            </div>
                                                        </div>
                                                        <div className="formInp">
                                                            <div className="col-md-12 checkbox-cover">
                                                                <span className="dropdown-item">
                                                                    <label className="container-check">
                                                                        Required
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={this.state?.globalfilterLists?.[entityName]?.[key]?.required}
                                                                            disabled={
                                                                                Values?.label === "Projects" ||
                                                                                this.state?.globalfilterLists?.[entityName]?.[key]?.keep_values
                                                                            }
                                                                            onClick={e => {
                                                                                this.handleFilterClick(
                                                                                    entityName,
                                                                                    key,
                                                                                    "required",
                                                                                    e.target.checked,
                                                                                    "global"
                                                                                );
                                                                            }}
                                                                        />
                                                                        <span
                                                                            className={`checkmark ${
                                                                                Values?.label === "Projects" ||
                                                                                this.state?.globalfilterLists?.[entityName]?.[key]?.keep_values
                                                                                    ? "cursor-notallowed"
                                                                                    : ""
                                                                            }`}
                                                                        ></span>
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    {isRestore ? (
                                        <div className="btnOtr">
                                            <button
                                                type="button"
                                                className="btn btn-secondary btnClr"
                                                onClick={() => onNo()}
                                                ref={input => {
                                                    this.nameInput = input;
                                                }}
                                            >
                                                No
                                            </button>
                                            {this.state.isRestoreCheckOk ? (
                                                <button type="button" className="btn btn-primary btnRgion not-draggable" onClick={() => onYes()}>
                                                    Yes
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btnRgion cursor-diabled not-draggable"
                                                    disabled={true}
                                                >
                                                    Yes
                                                </button>
                                            )}
                                        </div>
                                    ) : isUnAssign ? (
                                        <div className="btnOtr">
                                            <button
                                                type="button"
                                                className="btn btn-secondary btnClr not-draggable"
                                                onClick={() => onNo()}
                                                ref={input => {
                                                    this.nameInput = input;
                                                }}
                                            >
                                                No
                                            </button>
                                            {this.state.isUnassignCheckOk ? (
                                                <button type="button" className="btn btn-primary btnRgion not-draggable" onClick={() => onYes()}>
                                                    Yes
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btnRgion cursor-diabled not-draggable"
                                                    disabled={true}
                                                >
                                                    Yes
                                                </button>
                                            )}
                                        </div>
                                    ) : isCutPaste ? (
                                        <div className="btnOtr">
                                            <button
                                                type="button"
                                                className="btn btn-secondary btnClr not-draggable"
                                                onClick={() => onNo()}
                                                ref={input => {
                                                    this.nameInput = input;
                                                }}
                                            >
                                                No
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary btnRgion not-draggable"
                                                onClick={() => {
                                                    onYes();
                                                }}
                                            >
                                                Yes
                                            </button>
                                        </div>
                                    ) : singleOk ? (
                                        <div className="btnOtr">
                                            <button
                                                type="button"
                                                className="btn btn-primary not-draggable"
                                                onClick={() => onlyOk()}
                                                ref={input => {
                                                    this.nameInput = input;
                                                }}
                                            >
                                                Ok
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="btnOtr">
                                            <button
                                                type="button"
                                                className="btn btn-secondary btnRgion not-draggable mr-2"
                                                onClick={() => onNo()}
                                                ref={input => {
                                                    this.nameInput = input;
                                                }}
                                            >
                                                No
                                            </button>

                                            {this.state.isDoubleCheckOk || this.props.match.params.tab === "infoimages" || type !== "delete" ? (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary  btnClr not-draggable"
                                                    onClick={() => {
                                                        isHard
                                                            ? onYes(isDeletePermanent)
                                                            : this.props?.clientCoreReport > 0 || this.props?.globalCoreReport > 0
                                                            ? onYes(this.state.filterLists, this.state.globalfilterLists)
                                                            : onYes();
                                                    }}
                                                >
                                                    Yes
                                                </button>
                                            ) : (
                                                <button type="button" className="btn btn-primary btnClr cursor-diabled not-draggable" disabled={true}>
                                                    Yes
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ConfirmationModal);
