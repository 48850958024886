export const GET_ALL_MOBILE_RECOMMENDATIONS_REQUEST = "GET_ALL_MOBILE_RECOMMENDATIONS_REQUEST";
export const GET_ALL_MOBILE_RECOMMENDATIONS_SUCCESS = "GET_ALL_MOBILE_RECOMMENDATIONS_SUCCESS";
export const GET_ALL_MOBILE_RECOMMENDATIONS_FAILURE = "GET_ALL_MOBILE_RECOMMENDATIONS_FAILURE";

export const GET_MOBILE_RECOMMENDATION_BY_ID_REQUEST = "GET_MOBILE_RECOMMENDATION_BY_ID_REQUEST";
export const GET_MOBILE_RECOMMENDATION_BY_ID_SUCCESS = "GET_MOBILE_RECOMMENDATION_BY_ID_SUCCESS";
export const GET_MOBILE_RECOMMENDATION_BY_ID_FAILURE = "GET_MOBILE_RECOMMENDATION_BY_ID_FAILURE";

export const UPDATE_SURVEY_RECOMMENDATION_ENTITY_PARAMS_SUCCESS = "UPDATE_SURVEY_RECOMMENDATION_ENTITY_PARAMS_SUCCESS";
export const UPDATE_SURVEY_RECOMMENDATION_ENTITY_PARAMS_FAILURE = "UPDATE_SURVEY_RECOMMENDATION_ENTITY_PARAMS_FAILURE";

export const CLEAR_ENTITY_PARAMS = "CLEAR_ENTITY_PARAMS";
