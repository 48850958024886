import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactTooltip from "react-tooltip";
import { resetCursor, toTitleCase } from "../../../../config/utils";
import Loader from "../Loader";
import GreenTickIcon from "../../../../assets/img/img-green-tick.svg";
import BlueTickIcon from "../../../../assets/img/img-blue-tick.svg";
import imgProcess from "../../../../assets/img/img-process.svg";

import { useSelector } from "react-redux";
function ImageGrid({
    imageData,
    handleSelect,
    handleClickImage,
    hasMore,
    fetchMoreImages,
    hasCheckBox,
    isReportImage,
    checkIfNarrativeImageUsed,
    imagesNotUsedIds,
    hasViewRecom,
    viewRecommendations,
    hasEdit,
    handleEdit,
    updateImageFromInfoImages1,
    handleInputCaptionData,
    hasDelete,
    handleDelete,
    handleMultiSelectImage,
    selectedImages,
    isAsset
}) {
    const ref = useRef(null);
    const textareaRef = useRef(null);
    // const inputRef = useRef(null);
    const [toggleInput, setToggleInput] = useState({});
    const [caption, setCaption] = useState({
        id: "",
        description: ""
    });
    const [initialCaption, setInitialCaption] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const { updateImageCommentResponse } = useSelector(state => state?.recommendationsReducer);
    useEffect(() => {
        // inputRef && inputRef.current && inputRef.current.focus();
        document.addEventListener("click", handleClickOutside, true);
        ReactTooltip.rebuild();
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [toggleInput]);
    useEffect(() => {
        if (updateImageCommentResponse?.message && (initialCaption || caption?.id)) {
            setAlertMessage("Image Caption Updated Successfully");
        }
    }, [updateImageCommentResponse]);
    useEffect(() => {
        const handleClickOutside = event => {
            if (textareaRef.current && !textareaRef.current.contains(event.target)) {
                if (initialCaption?.trim() !== caption?.description?.trim()) {
                    handleInputCaption();
                }
                handleClick("");
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [caption]);
    useEffect(() => {
        if (alertMessage !== "") {
            var x = document.getElementById("sucess-alert");
            if (x) {
                x.className = "show";
                x.innerText = alertMessage;
                setTimeout(function () {
                    x.className = x.className.replace("show", "");
                }, 3000);
            }
            setAlertMessage("");
        }
    }, [alertMessage]);

    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            handleClick("");
        }
    };

    const handleClick = key => {
        setToggleInput(prevState => {
            return { ...prevState, toggleInput: toggleInput === key ? "" : key || "" };
        });
    };
    const handleInputCaption = async () => {
        await handleInputCaptionData(caption);
        handleClick("");
        updateImageFromInfoImages1(caption);
        ReactTooltip.rebuild();
    };
    const handleGetImageId = (id, value) => {
        setInitialCaption(value);
        setCaption({
            id: id,
            description: value?.trim()
        });
        handleClick(id);
    };
    const moveCaretAtEnd = e => {
        var temp_value = e.target.value;
        e.target.value = "";
        e.target.value = temp_value;
    };

    const getImageUrl = image => {
        let imageUrl = "";
        let imageIsPresent = false;
        if (image) {
            const { is_edited, s3_ethumbnail_key, s3_thumbnail_key } = image;
            imageIsPresent = s3_ethumbnail_key || s3_thumbnail_key;
            imageUrl = is_edited ? s3_ethumbnail_key : s3_thumbnail_key;
        }
        return imageIsPresent ? imageUrl : "";
    };
    return (
        <InfiniteScroll
            dataLength={imageData?.length}
            next={fetchMoreImages}
            hasMore={hasMore}
            height={400}
            scrollThreshold={0.6}
            loader={
                <div className="col-12">
                    <Loader />
                </div>
            }
            className="infinite-scroll"
            endMessage={<p style={{ textAlign: "center" }}>{/* <b>No more data</b> */}</p>}
        >
            <div ref={ref}>
                {imageData?.length
                    ? imageData.map((item, idx) => (
                          <div class="items" key={`img-grid-items-${item.id}-${idx}`}>
                              <div class="drop-sec">
                                  {hasViewRecom && (
                                      <div class="icon-rec hover-none" onClick={() => viewRecommendations(item.recommendation_id)}>
                                          <i class="far fa-file-alt" data-for={item.id} data-tip={"View Recommendation"}></i>
                                      </div>
                                  )}
                                  {hasDelete && (
                                      <div class="icon mr-2 hover-none" onClick={() => handleDelete(item.id)}>
                                          <i class="fa fa-trash" data-for={item.id} data-tip={"Remove"}></i>
                                      </div>
                                  )}
                                  {hasEdit && (
                                      <div class="icon hover-none" onClick={() => handleEdit(item)}>
                                          <i class="fa fa-pencil-alt" data-for={item.id} data-tip={"Edit"}></i>
                                      </div>
                                  )}
                              </div>
                              <div class="images">
                                  {getImageUrl(item?.master_image ?? item) ? (
                                      <LazyLoadImage
                                          onClick={() => handleClickImage(item)}
                                          alt={`img-${idx}`}
                                          effect="blur"
                                          src={getImageUrl(item?.master_image ?? item)}
                                          className="cursor-hand"
                                      />
                                  ) : (
                                      <div className="img-process">
                                          <img src={imgProcess} alt="processing" />
                                          <h3>Processing...</h3>
                                      </div>
                                  )}
                                  {!isAsset && (
                                      <label class="container-check">
                                          <input
                                              type="checkbox"
                                              checked={selectedImages?.find(selectedImage => selectedImage?.id === item?.id)}
                                              onChange={e => handleMultiSelectImage(item, e.target.checked)}
                                          />
                                          <span class="checkmark"></span>
                                      </label>
                                  )}
                                  {/* {hasCheckBox && (
                                      <label class="container-check-set-as-default" data-for={item.id} data-tip="Set as Default">
                                          <input
                                              type="checkbox"
                                              checked={item.default_image}
                                              onChange={() => handleSelect(item, !item.default_image)}
                                          />
                                          <span class="checkmark"></span>
                                      </label>
                                  )} */}
                                  {item.hasOwnProperty("printable") && (
                                      <>
                                          {imagesNotUsedIds && !imagesNotUsedIds.includes(item.id) && item.printable ? (
                                              <img
                                                  src="/img/check_green.svg"
                                                  className="no-recom img-ident-icon"
                                                  alt=""
                                                  data-for={item.id}
                                                  data-tip={"Used"}
                                              />
                                          ) : (
                                              <img
                                                  src="/img/check_green red.svg"
                                                  className="no-recom img-ident-icon"
                                                  alt=""
                                                  data-for={item.id}
                                                  data-tip={"Not Used"}
                                              />
                                          )}
                                      </>
                                  )}
                              </div>
                              <div class="cnt-img-sec">
                                  <div class="heading">
                                      {toggleInput.toggleInput === item.id ? (
                                          <>
                                              {/* {caption.id === item.id &&  */}
                                              <div htmlFor="" className="img-title-edit-lbl editor-txt-lb">
                                                  <textarea
                                                      type="text"
                                                      //   className="img-title-textarea"
                                                      ref={textareaRef}
                                                      // maxLength={this.state.captionMaxLength}
                                                      //   ref={inputRef}
                                                      value={caption.description}
                                                      onChange={e => {
                                                          resetCursor(e);
                                                          let updatedValue = {};
                                                          updatedValue = { description: toTitleCase(e.target.value) };
                                                          setCaption(caption => ({ ...caption, ...updatedValue }));
                                                      }}
                                                      //   onBlur={() => setToggleInput("")}
                                                      onKeyDown={event => {
                                                          if (event.key === "Enter" && !event.shiftKey) {
                                                              if (caption?.description?.trim() !== item?.description?.trim()) {
                                                                  handleInputCaption();
                                                              }
                                                              handleClick("");
                                                          }
                                                          if (event.key === "Escape") {
                                                              setCaption(prevCaption => ({
                                                                  ...prevCaption,
                                                                  description: item?.description
                                                              }));
                                                              handleClick("");
                                                          }
                                                      }}
                                                      placeholder="Caption"
                                                      onDoubleClick={() => handleClick("")}
                                                      autoFocus
                                                      onFocus={moveCaretAtEnd}
                                                      data-place="bottom"
                                                      data-effect="solid"
                                                      //   data-for={item.id}
                                                      //   data-tip={"Please Click Enter key to Update<br/>Or<br/>Double Click  to Go Back<br/>"}
                                                  />
                                              </div>
                                              {/* } */}
                                          </>
                                      ) : (
                                          <div className="title-edit-out">
                                              <h3
                                                  className={`${item?.description || item?.caption ? "" : "no-caption-txt"}`}
                                                  onDoubleClick={() => {
                                                      if (item?.description || item?.caption) {
                                                          handleGetImageId(item.id, item?.description ?? item?.caption);
                                                      }
                                                  }}
                                                  onClick={() => {
                                                      if (!item?.description || item?.caption) {
                                                          handleGetImageId(item.id, item?.description ?? item?.caption);
                                                      }
                                                  }}
                                                  style={{ cursor: item?.description || item?.caption ? "auto" : "pointer", textTransform: "none" }}
                                                  html={true}
                                                  data-for={item.id}
                                                  data-tip={item?.description || item?.caption}
                                              >
                                                  {item?.description || item?.caption || "No caption"}
                                              </h3>

                                              <i
                                                  className="fa fa-pencil-alt"
                                                  data-for={item.id}
                                                  data-tip={"Edit"}
                                                  onClick={() => handleGetImageId(item.id, item?.description ?? item?.caption)}
                                              ></i>
                                          </div>
                                      )}
                                  </div>
                                  <div class="cnt-area sec-row" onClick={() => handleClick("")}>
                                      <div class="secs">
                                          <span className="dtl" data-for={item.id} data-tip={item?.master_image?.name || item?.name || "-"}>
                                              {item?.master_image?.name ?? item?.name}
                                          </span>
                                      </div>
                                  </div>
                                  {hasCheckBox && (
                                      <>
                                          {item.default_image ? (
                                              <div className="default-tick-green">
                                                  <img src={GreenTickIcon} alt="green-tick" data-for={item.id} data-tip="Default Image" />
                                              </div>
                                          ) : (
                                              <div className="default-tick-blue hover-none">
                                                  <img
                                                      onClick={() => handleSelect(item, true)}
                                                      src={BlueTickIcon}
                                                      alt="blue-tick"
                                                      data-for={item.id}
                                                      data-tip="Set as Default"
                                                  />
                                              </div>
                                          )}
                                      </>
                                  )}
                              </div>
                              <ReactTooltip id={item.id} effect="solid" backgroundColor="#1383D9" multiline={true} />
                          </div>
                      ))
                    : null}
            </div>
        </InfiniteScroll>
    );
}

export default ImageGrid;
