import React, { useEffect, useState } from "react";
import Portal from "../Portal";
import ImageViewModal from "./ImageViewModal";
import ImageForm from "./ImageForm";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import ConfirmationModal from "../ConfirmationModal";

export const ImageBand = ({ basicDetails, updateImage, deleteImage, hasEdit, getAllImagesResponse, className = "", refreshImageList }) => {
    const [showImageModal, setImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(basicDetails?.image);
    const [imageList, setImageList] = useState([]);
    const [showImageEditModal, setImageEditModal] = useState(false);
    const [showDeleteConfirmation, setDeleteConfirmation] = useState(false);

    useEffect(() => {
        if (getAllImagesResponse?.images?.length) {
            const { images } = getAllImagesResponse;
            const defaultImageItem = images.find(item => item?.default_image === true);
            const index = images.indexOf(defaultImageItem);
            if (index > -1) {
                images.splice(index, 1);
                images.unshift(defaultImageItem);
            }
            setSelectedImage(defaultImageItem || images[0]);
            setImageList(images);
        } else {
            setSelectedImage({});
            setImageList([]);
        }
    }, [getAllImagesResponse]);

    const handleNext = id => {
        let current_index = imageList?.findIndex(item => item?.id === id);
        let selectedImage = imageList[current_index + 1];
        setSelectedImage(selectedImage);
    };
    const handlePrev = id => {
        let current_index = imageList?.findIndex(item => item?.id === id);
        let selectedImage = imageList[current_index - 1];
        setSelectedImage(selectedImage);
    };

    const renderDeleteConfirmationModal = () => {
        if (!showDeleteConfirmation) return null;
        return (
            <Portal
                body={
                    <ConfirmationModal
                        heading={"Do you want to remove this image from this recommendation?"}
                        message={"This action cannot be reverted, are you sure that you need to remove this image?"}
                        onNo={() => setDeleteConfirmation(false)}
                        onYes={() => handleDeleteImage(showDeleteConfirmation)}
                    />
                }
                onCancel={() => setDeleteConfirmation(false)}
            />
        );
    };

    const handleDeleteImage = id => {
        setDeleteConfirmation(false);
        setImageList(imageList.filter(item => item.id === id));
        setImageEditModal(false);
        setImageModal(false);
        deleteImage(id);
    };

    const { is_edited = "", s3_eimage_key, s3_image_key, updated_at = "" } = selectedImage?.master_image || selectedImage || {};

    const imageThumbnailUrl = is_edited ? s3_eimage_key : s3_image_key;
    return (
        <>
            {imageList?.length > 1 && (
                <div>
                    <button
                        className={`arrow-butn-left ${imageList[0]?.id === selectedImage?.id ? "cursor-diabled" : ""}`}
                        disabled={imageList[0]?.id === selectedImage?.id}
                        onClick={() => handlePrev(selectedImage?.id)}
                        data-place="top"
                        data-effect="solid"
                        data-tip="Previous"
                        data-for="image-view-modal"
                    >
                        <i class="fas fa-chevron-left"></i>
                    </button>

                    <button
                        className={`arrow-butn-right ${selectedImage?.id === imageList[imageList.length - 1]?.id ? "cursor-diabled" : ""}`}
                        disabled={selectedImage?.id === imageList[imageList.length - 1]?.id}
                        onClick={() => handleNext(selectedImage?.id)}
                        data-place="top"
                        data-effect="solid"
                        data-tip="Next"
                        data-for="image-view-modal"
                    >
                        <i class="fas fa-chevron-right"></i>
                    </button>
                </div>
            )}
            <div
                className={`details-img-block ${imageThumbnailUrl ? "cursor-hand" : ""} ${className}`}
                onClick={() => (imageThumbnailUrl ? setImageModal(true) : null)}
            >
                {imageThumbnailUrl ? (
                    <>
                        <ReactTooltip id="image-caption" backgroundColor="#007bff" />
                        <img src={`${imageThumbnailUrl}`} alt="" />
                        <div
                            className="sub-cont-img-sec"
                            data-place="bottom"
                            data-effect="solid"
                            data-tip={selectedImage?.description || ""}
                            data-for="image-caption"
                        >
                            <h4>{selectedImage?.description || "-"}</h4>
                        </div>
                    </>
                ) : (
                    <img src="/img/no-image.png" alt="" />
                )}
            </div>
            {showImageModal && imageList?.length ? (
                <Portal
                    body={
                        <ImageViewModal
                            imageList={imageList}
                            image={selectedImage}
                            handleNext={handleNext}
                            handlePrev={handlePrev}
                            onCancel={() => setImageModal(false)}
                            hasEditButton={hasEdit}
                            hasNextPrev={imageList.length > 1}
                            handleEdit={() => setImageEditModal(true)}
                            isRecommendationView={true}
                        />
                    }
                    onCancel={() => setImageModal(false)}
                />
            ) : null}
            {showImageEditModal && (
                <Portal
                    body={
                        <ImageForm
                            imageList={imageList}
                            images={selectedImage}
                            handleNext={handleNext}
                            handlePrev={handlePrev}
                            updateImage={updateImage}
                            handleDelete={id => setDeleteConfirmation(id)}
                            onCancel={() => setImageEditModal(false)}
                            isRecommendationView={true}
                            hasNextPrev={imageList.length > 1}
                            refreshImageList={() => {
                                setImageModal(false);
                                refreshImageList && refreshImageList();
                            }}
                        />
                    }
                    onCancel={() => setImageEditModal(false)}
                />
            )}
            {renderDeleteConfirmationModal()}
        </>
    );
};
