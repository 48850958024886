export const ADD_SURVEY_TEMPLATE_DATA_REQUEST = "ADD_SURVEY_TEMPLATE_DATA_REQUEST";
export const ADD_SURVEY_TEMPLATE_DATA_SUCCESS = "ADD_SURVEY_TEMPLATE_DATA_SUCCESS";
export const ADD_SURVEY_TEMPLATE_DATA_FAILURE = "ADD_SURVEY_TEMPLATE_DATA_FAILURE";

export const UPDATE_SURVEY_TEMPLATE_DATA_REQUEST = "UPDATE_SURVEY_TEMPLATE_DATA_REQUEST";
export const UPDATE_SURVEY_TEMPLATE_DATA_SUCCESS = "UPDATE_SURVEY_TEMPLATE_DATA_SUCCESS";
export const UPDATE_SURVEY_TEMPLATE_DATA_FAILURE = "UPDATE_SURVEY_TEMPLATE_DATA_FAILURE";

export const GET_SURVEY_TEMPLATE_SUCCESS = "GET_SURVEY_TEMPLATE_SUCCESS";
export const GET_SURVEY_TEMPLATE_REQUEST = "GET_SURVEY_TEMPLATE_REQUEST";
export const GET_SURVEY_TEMPLATE_FAILURE = "GET_SURVEY_TEMPLATE_FAILURE";

export const UPDATE_SURVEY_TEMPLATE_ENTITY_PARAMS_SUCCESS = "UPDATE_SURVEY_TEMPLATE_ENTITY_PARAMS_SUCCESS";
export const UPDATE_SURVEY_TEMPLATE_ENTITY_PARAMS_FAILURE = "UPDATE_SURVEY_TEMPLATE_ENTITY_PARAMS_FAILURE";

export const DELETE_SURVEY_TEMPLATE_REQUEST = "DELETE_SURVEY_TEMPLATE_REQUEST";
export const DELETE_SURVEY_TEMPLATE_SUCCESS = "DELETE_SURVEY_TEMPLATE_SUCCESS";
export const DELETE_SURVEY_TEMPLATE_FAILURE = "DELETE_SURVEY_TEMPLATE_FAILURE";

export const GET_SURVEY_TEMPLATE_BY_ID_REQUEST = "GET_SURVEY_TEMPLATE_BY_ID_REQUEST";
export const GET_SURVEY_TEMPLATE_BY_ID_SUCCESS = "GET_SURVEY_TEMPLATE_BY_ID_SUCCESS";
export const GET_SURVEY_TEMPLATE_BY_ID_FAILURE = "GET_SURVEY_TEMPLATE_BY_ID_FAILURE";

export const GET_DEFAULT_SURVEY_TEMPLATE_REQUEST = "GET_DEFAULT_SURVEY_TEMPLATE_REQUEST";
export const GET_DEFAULT_SURVEY_TEMPLATE_SUCCESS = "GET_DEFAULT_SURVEY_TEMPLATE_SUCCESS";
export const GET_DEFAULT_SURVEY_TEMPLATE_FAILURE = "GET_DEFAULT_SURVEY_TEMPLATE_FAILURE";

export const RESET_TEMPLATE_REDUCER = "RESET_TEMPLATE_REDUCER";

export const GET_SURVEY_TEMPLATE_EXPORT_REQUEST = "GET_SURVEY_TEMPLATE_EXPORT_REQUEST";
export const GET_SURVEY_TEMPLATE_EXPORT_SUCCESS = "GET_SURVEY_TEMPLATE_EXPORT_SUCCESS";
export const GET_SURVEY_TEMPLATE_EXPORT_FAILURE = "GET_SURVEY_TEMPLATE_EXPORT_FAILURE";

export const staticFields = [
    "asset_name",
    "asset_note",
    "asset_tag",
    "installed_year",
    "service_life",
    "usefull_life_remaining",
    "crv",
    "manufacturer",
    "model_number",
    "serial_number",
    "capacity",
    "area_served",
    "room_number",
    "room_name",
    "architectural_room_number",
    "additional_room_description",
    "asset_description",
    "asset_type_id",
    "asset_barcode",
    "asset_client_id",
    "asset_cmms_id",
    "warranty_start",
    "warranty_end",
    "install_date",
    "startup_date",
    "upstream_asset_barcode_number",
    "linked_asset_barcode_number",
    "source_panel_barcode_number",
    "source_panel",
    "asset_status_id",
    "notes",
    // "region_id",
    "site_id",
    "building_id",
    "main_building_type_id",
    "floor_id",
    "addition_id",
    "uniformat_level_1_id",
    "uniformat_level_2_id",
    "uniformat_level_3_id",
    "uniformat_level_4_id",
    "uniformat_level_5_id",
    "location",
    "year_manufactured",
    "criticality",
    // "image_id",
    // "code",
    "custom_id",
    // "client_id",
    // "guid",
    "main_category_id",
    "sub_category_1_id",
    "sub_category_2_id",
    "sub_category_3_id",
    "client_asset_condition_id",
    "est_replacement_cost",
    "source_panel_name",
    "skysite_hyperlink",
    "trade_id",
    "sub_system_id",
    "system_id",
    "fca_cost",
    "capacity_unit",
    "quantity",
    "uniformat_level_6_id",
    "rtls_tag",
    // "latitude",
    // "longitude",
    "current_age",
    "age",
    "new_asset",
    "parent_global_id",
    "survey_global_id",
    "survey_id",
    "survey_property_note",
    "capacity_status",
    "installed_year_status",
    "name_plate_status",
    "qa_notes",
    "additional_qa_notes",
    "surveyor",
    "editor",
    "survey_date_created",
    "survey_date_edited",
    "asset_condition_notes",
    "category_description",
    "core_model_number",
    // "id",
    // "created_at",
    // "updated_at",
    "client_asset_condition__description",
    "asset_ownership",
    "csp_unit",
    "refrigerant",
    "energy_use_type",
    "unit_cost",
    "end_of_service_life"
];

export const customSelectFields = [
    "trade_id",
    "sub_system_id",
    "system_id",
    "main_category_id",
    "sub_category_1_id",
    "sub_category_2_id",
    "sub_category_3_id",
    "manufacturer",
    "asset_type_id",
    "region_id",
    "site_id",
    "building_id",
    "floor_id",
    "addition_id",
    "uniformat_level_1_id",
    "uniformat_level_2_id",
    "uniformat_level_3_id",
    "uniformat_level_4_id",
    "uniformat_level_5_id",
    "uniformat_level_6_id",
    "client_asset_condition_id",
    "main_building_type_id",
    "client_asset_condition__description",
    "asset_status_id"
];

export const intFields = [
    "installed_year",
    "service_life",
    "usefull_life_remaining",
    "crv",
    "custom_id",
    "est_replacement_cost",
    "fca_cost",
    "quantity",
    "recommendation_count",
    "end_of_service_life",
    "year_manufactured",
    "current_age",
    "age",
    "unit_cost"
];

export const dateFields = ["warranty_start", "warranty_end", "install_date", "startup_date"];
