import * as actionType from "./constants";

const initialState = {
    addAssetSurveySettingsResponse: null,
    addAssetSurveySettingsResponseLoading: false,
    assetSurveySettingsList: null,
    assetSurveySettingsListLoading: false
};

const reducers = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_ASSET_SURVEY_SETTINGS_REQUEST:
            return {
                ...state,
                assetSurveySettingsListLoading: true
            };
        case actionType.GET_ASSET_SURVEY_SETTINGS_SUCCESS:
            return {
                ...state,
                assetSurveySettingsList: { success: true, ...action.response },
                assetSurveySettingsListLoading: false
            };
        case actionType.GET_ASSET_SURVEY_SETTINGS_FAILURE:
            return {
                ...state,
                assetSurveySettingsList: { success: false, ...action.error },
                assetSurveySettingsListLoading: false
            };

        case actionType.ADD_ASSET_SURVEY_SETTINGS_DATA_REQUEST:
            return {
                ...state,
                addAssetSurveySettingsResponse: null,
                addAssetSurveySettingsResponseLoading: true
            };
        case actionType.ADD_ASSET_SURVEY_SETTINGS_DATA_SUCCESS:
            return {
                ...state,
                addAssetSurveySettingsResponse: action.response,
                addAssetSurveySettingsResponseLoading: false
            };
        case actionType.ADD_ASSET_SURVEY_SETTINGS_DATA_FAILURE:
            return {
                ...state,
                addAssetSurveySettingsResponse: null,
                addAssetSurveySettingsResponseLoading: false
            };

        default:
            return state;
    }
};

export default reducers;
