import { fcaFastApi, fcaGateWay, fcaReportGateway } from "../../services/authorizationService";
import * as serviceEndpoints from "../../config/serviceEndPoints";

export const getListForCommonFilter = (params, listEndPoint) =>
    fcaGateWay.get(`${serviceEndpoints.assetEndPoints.assets}${listEndPoint ? listEndPoint : "/get_list"}`, {
        params
    });

export const getDataList = params => fcaGateWay.get(`${serviceEndpoints.assetEndPoints.assets}`, { params });
export const getChartAssetDataList = params => fcaGateWay.get(`${serviceEndpoints.assetEndPoints.chartAssets}`, { params });

export const addData = params => fcaGateWay.post(`${serviceEndpoints.assetEndPoints.assets}`, params);
export const getDataById = id => fcaGateWay.get(`${serviceEndpoints.assetEndPoints.assets}/${id}`);

export const updateData = (id, params) => fcaGateWay.patch(`${serviceEndpoints.assetEndPoints.assets}/${id}`, params);

export const deleteData = id => fcaGateWay.delete(`${serviceEndpoints.assetEndPoints.assets}/${id}`);

export const exportData = params =>
    fcaGateWay.get(`${serviceEndpoints.assetEndPoints.assets}/export_xl`, {
        method: "GET",
        responseType: "blob",
        params
    });

export const exportCustomExcel = params =>
    fcaGateWay.get(`${serviceEndpoints.assetEndPoints.assets}/custom_export_xl`, {
        method: "GET",
        responseType: "blob",
        params
    });

export const getAllDataLogs = (id, params) => {
    return fcaGateWay.get(`${serviceEndpoints.assetEndPoints.assets}/${id}/logs`, { params });
};

export const restoreDataLog = id => fcaGateWay.patch(`${serviceEndpoints.assetEndPoints.logs}/${id}/restore`);

export const deleteDataLog = id => fcaGateWay.delete(`${serviceEndpoints.assetEndPoints.logs}/${id}`);

export const getDropdownList = (level, params) =>
    level === "regions"
        ? fcaGateWay.get(`${serviceEndpoints.assetEndPoints.dropdown.regionsByClient}/${params.client_id}/regions`)
        : level === "sites"
        ? fcaGateWay.get(`${serviceEndpoints.assetEndPoints.dropdown.regions}/${params.region_id}/sites`)
        : level === "buildings"
        ? // ? fcaGateWay.get(`${serviceEndpoints.assetEndPoints.dropdown.sites}/${params.site_id}/buildings`)
          fcaGateWay.get(`${serviceEndpoints.assetEndPoints.dropdown.sites}/${params.site_id}/buildings_dropdown`)
        : level === "additions"
        ? fcaGateWay.get(`${serviceEndpoints.assetEndPoints.dropdown.buildings}/${params.building_id}/additions`)
        : level === "floors"
        ? fcaGateWay.get(`${serviceEndpoints.assetEndPoints.dropdown.buildings}/${params.building_id}/floors`)
        : level === "manufacturers"
        ? fcaGateWay.get(`${serviceEndpoints.assetEndPoints.dropdown.manufacturers}`, { params })
        : fcaGateWay.get(`${serviceEndpoints.assetEndPoints.dropdown[level]}`, { params });

//Asset Image
export const getAllImages = (id, params) => fcaGateWay.get(`${serviceEndpoints.assetEndPoints.assets}/${id}/images`, { params });
export const uploadImage = (imageData, id) => fcaGateWay.post(`${serviceEndpoints.assetEndPoints.assets}/${id}/upload`, imageData);
export const updateAssetImage = imageData => fcaGateWay.patch(`${serviceEndpoints.assetEndPoints.assets}/${imageData.id}/update_image`, imageData);
export const deleteImages = id => fcaGateWay.delete(`${serviceEndpoints.assetEndPoints.assets}/${id}/remove_image`);
export const exportAssetReportPdf = params =>
    fcaReportGateway.post(`${serviceEndpoints.assetEndPoints.getPdfforReport}`, params, { method: "POST", responseType: "blob" });
export const exportAssetReportWord = params =>
    fcaReportGateway.post(`${serviceEndpoints.assetEndPoints.getWordforReport}`, params, { method: "POST", responseType: "blob" });

export const uploadExcelData = params => fcaGateWay.post(`${serviceEndpoints.assetEndPoints.uploadExcelData}`, params);
export const uploadSpecialExcelData = params => fcaGateWay.post(`${serviceEndpoints.assetEndPoints.uploadSpecialExcelData}`, params);
export const getExportProperties = params => fcaReportGateway.get(`${serviceEndpoints.assetEndPoints.getExportProperties}`, { params });
export const postExportProperties = params => fcaReportGateway.post(`${serviceEndpoints.assetEndPoints.postExportProperties}`, params);

export const getAllAssetIds = params => fcaGateWay.get(`${serviceEndpoints.assetEndPoints.getAllAssetIds}`, { params });

export const getAssetCommonDataByIds = params => fcaGateWay.post(serviceEndpoints.assetEndPoints.getAssetCommonDataByIds, params);

export const updateMultiAssets = params => fcaGateWay.patch(serviceEndpoints.assetEndPoints.updateMultiAssets, params);

export const getAssetDynamicFields = params => fcaGateWay.get(`${serviceEndpoints.assetEndPoints.getAssetDynamicFields}`, { params });

export const saveFavorites = params => fcaReportGateway.post(`${serviceEndpoints.assetEndPoints.saveFavorites}`, params);
export const getFavorites = params => fcaReportGateway.get(`${serviceEndpoints.assetEndPoints.getFavorites}`, { params });
