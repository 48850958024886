import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import actions from "../../assets/actions";
import NumberFormat from "react-number-format";
import { getCurrentAge, getUsefullLifeRemaining } from "../../../config/utils";
import ReactTooltip from "react-tooltip";
import { FormField } from "./Bands/FormField";
import { RecommendationBand } from "./Bands/RecommendationBand";
import { DYNAMIC_FIELD_TYPES } from "../../common/constants";
class AssetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBand: {
                band1: true,
                band2: false,
                band3: false,
                band4: false,
                band5: false
            },
            isLoadingDrop: false
        };
    }
    handleChange = async e => {
        const { name, value } = e.target;
        await this.props.handleChangeAssetForm(e);
        if (name === "uniformat_level_1_id") {
            this.handleLevel1Select();
        } else if (name === "uniformat_level_2_id") {
            this.handleLevel2Select();
        } else if (name === "uniformat_level_3_id") {
            this.handleLevel3Select();
        } else if (name === "uniformat_level_4_id") {
            this.handleLevel4Select();
        } else if (name === "uniformat_level_5_id") {
            this.handleLevel5Select();
        } else if (name === "uniformat_level_6_id") {
            this.handleLevel6Select(value);
        } else if (name === "main_category_id") {
            this.handleMainCategorySelect();
        } else if (name === "sub_category_1_id") {
            this.handleSubCategory1Select();
        } else if (name === "sub_category_2_id") {
            this.handleSubCategory2Select(value);
        } else if (name === "client_asset_condition_id") {
            this.handleAssetConditionSelect(value);
        }
    };

    handleLevel1Select = async () => {
        let data = {
            ...this.props.assetFormData,
            uniformat_level_2_id: "",
            uniformat_level_3_id: "",
            uniformat_level_4_id: "",
            uniformat_level_5_id: "",
            uniformat_level_6_id: ""
        };
        this.props.setAssetFormData(data);
        this.props.getDropdownList("uniformat_level_2s", {
            uniformat_level_1_id: this.props.assetFormData.uniformat_level_1_id
        });
    };

    handleLevel2Select = async () => {
        let data = {
            ...this.props.assetFormData,
            uniformat_level_3_id: "",
            uniformat_level_4_id: "",
            uniformat_level_5_id: "",
            uniformat_level_6_id: ""
        };
        this.props.setAssetFormData(data);
        this.props.getDropdownList("uniformat_level_3s", {
            uniformat_level_1_id: this.props.assetFormData.uniformat_level_1_id,
            uniformat_level_2_id: this.props.assetFormData.uniformat_level_2_id
        });
    };
    handleLevel3Select = async () => {
        let data = {
            ...this.props.assetFormData,
            uniformat_level_4_id: "",
            uniformat_level_5_id: "",
            uniformat_level_6_id: ""
        };
        this.props.setAssetFormData(data);
        this.props.getDropdownList("uniformat_level_4s", {
            uniformat_level_1_id: this.props.assetFormData.uniformat_level_1_id,
            uniformat_level_2_id: this.props.assetFormData.uniformat_level_2_id,
            uniformat_level_3_id: this.props.assetFormData.uniformat_level_3_id
        });
    };
    handleLevel4Select = async () => {
        let data = {
            ...this.props.assetFormData,
            uniformat_level_5_id: "",
            uniformat_level_6_id: ""
        };
        this.props.setAssetFormData(data);
        this.props.getDropdownList("uniformat_level_5s", {
            uniformat_level_1_id: this.props.assetFormData.uniformat_level_1_id,
            uniformat_level_2_id: this.props.assetFormData.uniformat_level_2_id,
            uniformat_level_3_id: this.props.assetFormData.uniformat_level_3_id,
            uniformat_level_4_id: this.props.assetFormData.uniformat_level_4_id
        });
    };
    handleLevel5Select = async () => {
        let data = {
            ...this.props.assetFormData,
            uniformat_level_6_id: ""
        };
        this.props.setAssetFormData(data);
        this.props.getDropdownList("uniformat_level_6s", {
            uniformat_level_1_id: this.props.assetFormData.uniformat_level_1_id,
            uniformat_level_2_id: this.props.assetFormData.uniformat_level_2_id,
            uniformat_level_3_id: this.props.assetFormData.uniformat_level_3_id,
            uniformat_level_4_id: this.props.assetFormData.uniformat_level_4_id,
            uniformat_level_5_id: this.props.assetFormData.uniformat_level_5_id
        });
    };
    handleLevel6Select = async value => {
        const {
            assetReducer: { dropDownList }
        } = this.props;
        const uniformat_level_6_description = dropDownList?.uniformat_level_6s?.find(elem => elem.id === value)?.uniformat_level_6_description || "";
        let data = {
            ...this.props.assetFormData,
            uniformat_level_6_description
        };
        this.props.setAssetFormData(data);
    };

    handleClientSelect = async () => {
        let data = {
            ...this.props.assetFormData,
            asset_type_id: "",
            asset_status_id: "",
            client_asset_condition_id: "",
            region_id: "",
            site_id: "",
            building_id: "",
            addition_id: "",
            floor_id: ""
        };
        this.props.setAssetFormData(data);
        this.props.getDropdownList("regions", {
            client_id: this.props.assetFormData.client_id
        });
        this.props.getDropdownList("asset_statuses", {
            client_id: this.props.assetFormData.client_id
        });
        this.props.getDropdownList("asset_types", {
            client_id: this.props.assetFormData.client_id
        });
        this.props.getDropdownList("asset_conditions", {
            client_id: this.props.assetFormData.client_id
        });
        this.props.getDropdownList("main_categories", {
            client_id: this.props.assetFormData.client_id
        });
    };

    handleMainCategorySelect = async () => {
        let data = {
            ...this.props.assetFormData,
            sub_category_1_id: "",
            sub_category_2_id: "",
            sub_category_3_id: ""
        };
        this.props.setAssetFormData(data);
        this.props.getDropdownList("sub_category_1s", {
            client_id: this.props.assetFormData.client_id,
            main_category_id: this.props.assetFormData.main_category_id
        });
    };

    handleSubCategory1Select = async () => {
        let data = {
            ...this.props.assetFormData,
            sub_category_2_id: "",
            sub_category_3_id: ""
        };
        this.props.setAssetFormData(data);
        this.props.getDropdownList("sub_category_2s", {
            client_id: this.props.assetFormData.client_id,
            main_category_id: this.props.assetFormData.main_category_id,
            sub_category_1_id: this.props.assetFormData.sub_category_1_id
        });
    };

    handleSubCategory2Select = async value => {
        const {
            assetReducer: { dropDownList }
        } = this.props;
        const subcategory2_description = dropDownList?.sub_category_2s?.find(elem => elem.id === value)?.subcategory2_description || "";
        let data = {
            ...this.props.assetFormData,
            sub_category_3_id: "",
            subcategory2_description
        };
        this.props.setAssetFormData(data);
        this.props.getDropdownList("sub_category_3s", {
            client_id: this.props.assetFormData.client_id,
            main_category_id: this.props.assetFormData.main_category_id,
            sub_category_1_id: this.props.assetFormData.sub_category_1_id,
            sub_category_2_id: this.props.assetFormData.sub_category_2_id
        });
    };
    handleAssetConditionSelect = async value => {
        const {
            assetReducer: { dropDownList }
        } = this.props;
        const description = dropDownList?.asset_conditions?.find(elem => elem.id === value)?.description || "";
        let data = {
            ...this.props.assetFormData,
            description
        };
        this.props.setAssetFormData(data);
    };
    handleBandClick = (key, value) => {
        this.setState({
            showBand: {
                ...this.state.showBand,
                [key]: value
            }
        });
    };
    fetchOptions = async inputValue => {
        this.setState({ isLoadingDrop: true, inputValue });
        const response = await this.props.getDropdownList("manufacturers", { search: inputValue });
        this.setState({ isLoadingDrop: false });
    };

    handleInputChange = (newValue, { action }) => {
        if (action === "input-change" || action === "input-click") {
            this.fetchOptions(newValue);
            return newValue;
        }
    };
    render() {
        const { showErrorBorder } = this.props;
        const {
            assetReducer: { dropDownList }
        } = this.props;
        const { showBand } = this.state;
        const {
            assetFormData,
            clientList,
            regionList,
            siteList,
            buildingList,
            additionList,
            floorList,
            tradeList,
            systemList,
            subSystemList,
            miscellaneous,
            isFieldLocked
        } = this.props;
        return (
            <div className="dtl-sec col-md-12 p-0 mt-0 mb-0">
                <ReactTooltip
                    id="asset_details"
                    effect="solid"
                    place="top"
                    backgroundColor="#007bff"
                    className="rc-tooltip-custom-class"
                    html={true}
                />

                <div className="tab-dtl region-mng">
                    <form autoComplete={"nope"}>
                        <div className="tab-active build-dtl add-recommendation">
                            <div className="otr-common-edit custom-col  border-0 p-0 outer-rcm recommendations">
                                <div className="col-md-12 detail-recom add-details-outer height-overfl-recom-edit">
                                    <div className="outer-rcm recommendations">
                                        <div className="cnt-sec">
                                            <div className="row">
                                                <div className="col-md-12 pr-0 asset-edit-port">
                                                    <RecommendationBand
                                                        label="Basic Asset Information"
                                                        bandId="band1"
                                                        handleBandClick={this.handleBandClick}
                                                        isDefaultOpen
                                                        showBand={showBand}
                                                        MainFields={
                                                            <>
                                                                <FormField
                                                                    label="Asset Code"
                                                                    fieldKey="Asset Code"
                                                                    fieldParam="asset_code"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.code}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isFieldLocked={isFieldLocked}
                                                                    fieldItem={{
                                                                        rules: { mandatory: false, read_only: true }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Asset Name"
                                                                    fieldKey="Asset Name"
                                                                    fieldParam="asset_name"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.asset_name}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder && !assetFormData?.asset_name?.trim()?.length}
                                                                    isFieldLocked={isFieldLocked}
                                                                    fieldItem={{
                                                                        rules: { mandatory: true }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Asset Description "
                                                                    fieldKey="asset_description"
                                                                    fieldParam="asset_description"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.asset_description}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    isAsset
                                                                    fieldItem={{
                                                                        rules: { mandatory: false }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                />
                                                            </>
                                                        }
                                                        RestFields={
                                                            <>
                                                                <FormField
                                                                    label="Asset Type"
                                                                    fieldKey="asset_type_id"
                                                                    fieldParam="asset_type_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.asset_type_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "asset_type_id" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: dropDownList?.asset_types
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Asset Status"
                                                                    fieldKey="asset_status_id"
                                                                    fieldParam="asset_status_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.asset_status_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({ target: { value: e, name: "asset_status_id" } })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: dropDownList?.asset_statuses
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Asset Condition"
                                                                    fieldKey="client_asset_condition_id"
                                                                    fieldParam="client_asset_condition_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.client_asset_condition_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e => {
                                                                        this.handleChange({
                                                                            target: { value: e, name: "client_asset_condition_id" }
                                                                        });
                                                                    }}
                                                                    showErrorBorder={
                                                                        showErrorBorder && !assetFormData?.client_asset_condition_id?.trim()?.length
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: dropDownList?.asset_conditions,
                                                                        rules: { mandatory: true }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Asset Condition Description"
                                                                    fieldKey="asset_condition_description"
                                                                    fieldParam="asset_condition_description"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.description}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                    fieldItem={{
                                                                        rules: { mandatory: false, read_only: true }
                                                                    }}
                                                                />
                                                                <FormField
                                                                    label="Asset Ownership"
                                                                    fieldKey="asset_ownership"
                                                                    fieldParam="asset_ownership"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.asset_ownership}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e => {
                                                                        this.handleChange({
                                                                            target: { value: e, name: "asset_ownership" }
                                                                        });
                                                                    }}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Owned", name: "Owned" },
                                                                            { id: "Leased", name: "Leased" },
                                                                            { id: "Other", name: "Other" }
                                                                        ]
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Criticality"
                                                                    fieldKey="criticality"
                                                                    fieldParam="criticality"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.criticality}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                    fieldItem={{
                                                                        rules: { mandatory: false }
                                                                    }}
                                                                />
                                                                <FormField
                                                                    label={`Asset Tag ${miscellaneous?.asset_tag_required ? "*" : ""}`}
                                                                    fieldKey="asset_tag"
                                                                    fieldParam="asset_tag"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.asset_tag}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={
                                                                        showErrorBorder &&
                                                                        miscellaneous?.asset_tag_required &&
                                                                        !assetFormData?.asset_tag?.trim()?.length
                                                                    }
                                                                    fieldItem={{
                                                                        rules: { mandatory: miscellaneous?.asset_tag_required }
                                                                    }}
                                                                    isAsset
                                                                    isFieldLocked={isFieldLocked}
                                                                />
                                                                <FormField
                                                                    label="GUID"
                                                                    fieldKey="guid"
                                                                    fieldParam="guid"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.guid}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                    fieldItem={{
                                                                        rules: { mandatory: false }
                                                                    }}
                                                                />
                                                                <FormField
                                                                    label={`Model Number ${miscellaneous?.model_number_required ? "*" : ""}`}
                                                                    fieldKey="model_number"
                                                                    fieldParam="model_number"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.model_number}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={
                                                                        showErrorBorder &&
                                                                        miscellaneous?.model_number_required &&
                                                                        !assetFormData?.model_number?.trim()?.length
                                                                    }
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                    fieldItem={{
                                                                        rules: { mandatory: false }
                                                                    }}
                                                                />

                                                                <FormField
                                                                    label="Serial Number"
                                                                    fieldKey="serial_number"
                                                                    fieldParam="serial_number"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.serial_number}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={
                                                                        showErrorBorder &&
                                                                        miscellaneous?.serial_number_required &&
                                                                        !assetFormData?.serial_number?.trim()?.length
                                                                    }
                                                                    fieldItem={{
                                                                        rules: { mandatory: false }
                                                                    }}
                                                                    isAsset
                                                                    isFieldLocked={isFieldLocked}
                                                                />
                                                                <FormField
                                                                    label={`Capacity ${miscellaneous?.capacity_required ? "*" : ""}`}
                                                                    fieldKey="capacity"
                                                                    fieldParam="capacity"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.capacity}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={
                                                                        showErrorBorder &&
                                                                        miscellaneous?.capacity_required &&
                                                                        !assetFormData?.capacity?.trim()?.length
                                                                    }
                                                                    isFieldLocked={isFieldLocked}
                                                                    fieldItem={{
                                                                        rules: { mandatory: miscellaneous?.capacity_required }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label={`Capacity Unit ${miscellaneous?.capacity_unit_required ? "*" : ""}`}
                                                                    fieldKey="capacity_unit"
                                                                    fieldParam="capacity_unit"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.capacity_unit}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={
                                                                        showErrorBorder &&
                                                                        miscellaneous?.capacity_unit_required &&
                                                                        !assetFormData?.capacity_unit?.trim()?.length
                                                                    }
                                                                    isFieldLocked={isFieldLocked}
                                                                    fieldItem={{
                                                                        rules: { mandatory: miscellaneous?.capacity_unit_required }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Capacity Status"
                                                                    fieldKey="capacity_status"
                                                                    fieldParam="capacity_status"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.capacity_status}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Asset Barcode"
                                                                    fieldKey="asset_barcode"
                                                                    fieldParam="asset_barcode"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.asset_barcode}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Asset Client ID"
                                                                    fieldKey="asset_client_id"
                                                                    fieldParam="asset_client_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.asset_client_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                    isFieldLocked={isFieldLocked}
                                                                />
                                                                <FormField
                                                                    label="Asset CMMS ID"
                                                                    fieldKey="asset_cmms_id"
                                                                    fieldParam="asset_cmms_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.asset_cmms_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                    isFieldLocked={isFieldLocked}
                                                                />
                                                                <FormField
                                                                    label="Name Plate Status"
                                                                    fieldKey="name_plate_status"
                                                                    fieldParam="name_plate_status"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.name_plate_status}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                    isFieldLocked={isFieldLocked}
                                                                />
                                                                <FormField
                                                                    label="RTLS Tag"
                                                                    fieldKey="rtls_tag"
                                                                    fieldParam="rtls_tag"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.rtls_tag}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                    />
                                                    <RecommendationBand
                                                        label="Geo Hierarchy"
                                                        bandId="band2"
                                                        handleBandClick={this.handleBandClick}
                                                        showBand={showBand}
                                                        MainFields={
                                                            <>
                                                                <FormField
                                                                    label="Client"
                                                                    fieldKey="client_id"
                                                                    fieldParam="client_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.client_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "asset_type_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: clientList || [],
                                                                        rules: { mandatory: true, read_only: true }
                                                                    }}
                                                                    isAsset
                                                                    isFieldLocked={isFieldLocked}
                                                                />
                                                                <FormField
                                                                    label="Region"
                                                                    fieldKey="region_id"
                                                                    fieldParam="region_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.region_id}
                                                                    optionsList={assetFormData.client_id ? regionList : []}
                                                                    isFieldLocked={isFieldLocked}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e => this.handleChange({ target: { value: e, name: "region_id" } })}
                                                                    fieldItem={{
                                                                        field_options: assetFormData.client_id ? regionList : [],
                                                                        rules: {
                                                                            mandatory: true,
                                                                            read_only: true
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Site"
                                                                    fieldKey="site_id"
                                                                    fieldParam="site_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.site_id}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e => this.handleChange({ target: { value: e, name: "site_id" } })}
                                                                    disabled={true}
                                                                    fieldItem={{
                                                                        field_options: assetFormData.region_id ? siteList : [],
                                                                        rules: { mandatory: true, read_only: true }
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                        RestFields={
                                                            <>
                                                                <FormField
                                                                    label="Building"
                                                                    fieldKey="building_id"
                                                                    fieldParam="building_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.building_id}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e => this.handleChange({ target: { value: e, name: "building_id" } })}
                                                                    fieldItem={{
                                                                        field_options: assetFormData.site_id ? buildingList : [],
                                                                        rules: {
                                                                            mandatory: true,
                                                                            read_only: true
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Building Type"
                                                                    fieldKey="building_type"
                                                                    fieldParam="building_type"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.building_type}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            read_only: true
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Addition"
                                                                    fieldKey="addition_id"
                                                                    fieldParam="addition_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.addition_id}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e => this.handleChange({ target: { value: e, name: "addition_id" } })}
                                                                    fieldItem={{
                                                                        field_options: assetFormData.building_id ? additionList : [],
                                                                        rules: { read_only: true }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Floor"
                                                                    fieldKey="floor_id"
                                                                    fieldParam="floor_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.floor_id}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e => this.handleChange({ target: { value: e, name: "floor_id" } })}
                                                                    fieldItem={{
                                                                        field_options: assetFormData.building_id ? floorList : [],
                                                                        rules: { read_only: true }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Room Number"
                                                                    fieldKey="room_number"
                                                                    fieldParam="room_number"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.room_number}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: { read_only: true }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Room Name"
                                                                    fieldKey="room_name"
                                                                    fieldParam="room_name"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.room_name}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Location"
                                                                    fieldKey="location"
                                                                    fieldParam="location"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.location}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Architectural Room Number"
                                                                    fieldKey="architectural_room_number"
                                                                    fieldParam="architectural_room_number"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.architectural_room_number}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Additional Room Description"
                                                                    fieldKey="additional_room_description"
                                                                    fieldParam="additional_room_description"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.additional_room_description}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Longitude"
                                                                    fieldKey="longitude"
                                                                    fieldParam="longitude"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.longitude}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                    isFieldLocked={isFieldLocked}
                                                                />
                                                                <FormField
                                                                    label="Latitude"
                                                                    fieldKey="latitude"
                                                                    fieldParam="latitude"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.latitude}
                                                                    bandId="band2"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                    />

                                                    {/* ----------------------------- */}
                                                    <RecommendationBand
                                                        label="Extended Asset Information"
                                                        bandId="band3"
                                                        handleBandClick={this.handleBandClick}
                                                        showBand={showBand}
                                                        MainFields={
                                                            <>
                                                                <FormField
                                                                    label="Manufacturer"
                                                                    fieldKey="manufacturer"
                                                                    fieldParam="manufacturer"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.manufacturer_id}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={(value, name) =>
                                                                        this.handleChange({
                                                                            target: { value, name: "manufacturer", selectedName: name }
                                                                        })
                                                                    }
                                                                    showErrorBorder={
                                                                        showErrorBorder &&
                                                                        miscellaneous?.manufacturer_required &&
                                                                        !assetFormData?.manufacturer?.trim()?.length
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: dropDownList?.manufacturers || [],
                                                                        rules: {
                                                                            mandatory: miscellaneous?.manufacturer_required
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                    handleInputChange={this.handleInputChange}
                                                                    isManufacturer={true}
                                                                    isLoadingDrop={this.state?.isLoadingDrop}
                                                                    manufacturerCount={this.props.assetReducer?.manufacturerCount?.count}
                                                                    isFieldLocked={isFieldLocked}
                                                                />
                                                                <FormField
                                                                    label="Year Manufactured"
                                                                    placeholder="Year Manufactored"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={parseInt(assetFormData.year_manufactured) || ""}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    format="####"
                                                                    handleBandClick={this.handleBandClick}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            read_only: isFieldLocked("year_manufactured")
                                                                        }
                                                                    }}
                                                                    onChange={values => {
                                                                        const { value } = values;
                                                                        let data = {
                                                                            ...assetFormData,
                                                                            year_manufactured: value
                                                                        };
                                                                        this.props.setAssetFormData(data);
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Installed Year"
                                                                    placeholder="Installed Year"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={parseInt(assetFormData.installed_year) || ""}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    format="####"
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            read_only: isFieldLocked("installed_year")
                                                                        }
                                                                    }}
                                                                    onChange={values => {
                                                                        const { value } = values;
                                                                        let data = {
                                                                            ...assetFormData,
                                                                            installed_year: value,
                                                                            usefull_life_remaining: getUsefullLifeRemaining(
                                                                                value,
                                                                                assetFormData.service_life
                                                                            ),
                                                                            current_age: getCurrentAge(value)
                                                                        };
                                                                        this.props.setAssetFormData(data);
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                        RestFields={
                                                            <>
                                                                <FormField
                                                                    label="Current Age"
                                                                    fieldKey="current_age"
                                                                    fieldParam="current_age"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.current_age}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            read_only: true
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Service Life"
                                                                    placeholder="Service Life"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={assetFormData.service_life}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e => {
                                                                        const { value } = e;
                                                                        let data = {
                                                                            ...assetFormData,
                                                                            service_life: value,
                                                                            usefull_life_remaining: getUsefullLifeRemaining(
                                                                                assetFormData.installed_year,
                                                                                value
                                                                            )
                                                                        };
                                                                        this.props.setAssetFormData(data);
                                                                    }}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="End of Service Life"
                                                                    fieldKey="end_of_service_life"
                                                                    fieldParam="end_of_service_life"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={
                                                                        assetFormData.usefull_life_remaining ||
                                                                        assetFormData.usefull_life_remaining === 0
                                                                            ? `${new Date().getFullYear() + assetFormData.usefull_life_remaining}`
                                                                            : ""
                                                                    }
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Useful Life Remaining"
                                                                    fieldKey="usefull_life_remaining"
                                                                    fieldParam="usefull_life_remaining"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.usefull_life_remaining}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            read_only: true
                                                                        }
                                                                    }}
                                                                    dataTip={
                                                                        assetFormData.usefull_life_remaining
                                                                            ? `Year= ${
                                                                                  new Date().getFullYear() + assetFormData.usefull_life_remaining
                                                                              }`
                                                                            : ""
                                                                    }
                                                                    isAsset
                                                                    isFieldLocked={isFieldLocked}
                                                                />
                                                                <FormField
                                                                    label="CSP Unit"
                                                                    fieldKey="csp_unit"
                                                                    fieldParam="csp_unit"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.csp_unit}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="CRV"
                                                                    placeholder="CRV"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={parseInt(assetFormData.crv) || ""}
                                                                    bandId="band3"
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            prefix: "$ ",
                                                                            read_only: isFieldLocked("crv")
                                                                        }
                                                                    }}
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    thousandSeparator={true}
                                                                    onChange={values => {
                                                                        const { value } = values;
                                                                        let data = {
                                                                            ...assetFormData,
                                                                            crv: value
                                                                        };
                                                                        this.props.setAssetFormData(data);
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Refrigerant"
                                                                    fieldKey="refrigerant"
                                                                    fieldParam="refrigerant"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.refrigerant}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={
                                                                        showErrorBorder &&
                                                                        miscellaneous?.refrigerant_required &&
                                                                        !assetFormData?.refrigerant?.trim()?.length
                                                                    }
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: miscellaneous?.refrigerant_required
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />

                                                                {/* ------------------------------ */}
                                                                <div className="txt-rcm">
                                                                    <div className="content-inp-card">
                                                                        <div className="form-group">
                                                                            <label>Warranty Start</label>
                                                                            <DatePicker
                                                                                autoComplete={"nope"}
                                                                                placeholderText="MM/DD/YYYY"
                                                                                className={`custom-input form-control ${
                                                                                    isFieldLocked("warranty_start") ? "grayed-out" : ""
                                                                                }`}
                                                                                disabled={isFieldLocked("warranty_start")}
                                                                                selected={
                                                                                    assetFormData.warranty_start
                                                                                        ? new Date(assetFormData.warranty_start)
                                                                                        : ""
                                                                                }
                                                                                onChange={date => {
                                                                                    let data = {
                                                                                        ...assetFormData,
                                                                                        warranty_start: date ? new Date(date) : null
                                                                                    };
                                                                                    this.props.setAssetFormData(data);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="txt-rcm">
                                                                    <div className="content-inp-card">
                                                                        <div className="form-group">
                                                                            <label>Warranty End</label>
                                                                            <DatePicker
                                                                                autoComplete={"nope"}
                                                                                placeholderText="MM/DD/YYYY"
                                                                                className={`custom-input form-control ${
                                                                                    isFieldLocked("warranty_end") ? "grayed-out" : ""
                                                                                }`}
                                                                                disabled={isFieldLocked("warranty_end")}
                                                                                selected={
                                                                                    assetFormData.warranty_end
                                                                                        ? new Date(assetFormData.warranty_end)
                                                                                        : ""
                                                                                }
                                                                                onChange={date => {
                                                                                    let data = {
                                                                                        ...assetFormData,
                                                                                        warranty_end: date ? new Date(date) : null
                                                                                    };
                                                                                    this.props.setAssetFormData(data);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="txt-rcm">
                                                                    <div className="content-inp-card">
                                                                        <div className="form-group">
                                                                            <label>Install Date</label>
                                                                            <DatePicker
                                                                                autoComplete={"nope"}
                                                                                placeholderText="MM/DD/YYYY"
                                                                                className={`custom-input form-control ${
                                                                                    isFieldLocked("install_date") ? "grayed-out" : ""
                                                                                }`}
                                                                                disabled={isFieldLocked("install_date")}
                                                                                selected={
                                                                                    assetFormData.install_date
                                                                                        ? new Date(assetFormData.install_date)
                                                                                        : ""
                                                                                }
                                                                                onChange={date => {
                                                                                    let data = {
                                                                                        ...assetFormData,
                                                                                        install_date: date ? new Date(date) : null
                                                                                    };
                                                                                    this.props.setAssetFormData(data);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="txt-rcm">
                                                                    <div className="content-inp-card">
                                                                        <div className="form-group">
                                                                            <label>Startup Date</label>
                                                                            <DatePicker
                                                                                autoComplete={"nope"}
                                                                                placeholderText="MM/DD/YYYY"
                                                                                className={`custom-input form-control ${
                                                                                    isFieldLocked("startup_date") ? "grayed-out" : ""
                                                                                }`}
                                                                                disabled={isFieldLocked("startup_date")}
                                                                                selected={
                                                                                    assetFormData.startup_date
                                                                                        ? new Date(assetFormData.startup_date)
                                                                                        : ""
                                                                                }
                                                                                onChange={date => {
                                                                                    let data = {
                                                                                        ...assetFormData,
                                                                                        startup_date: date ? new Date(date) : null
                                                                                    };
                                                                                    this.props.setAssetFormData(data);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* ------------------------------ */}
                                                                <FormField
                                                                    label="Uniformat Level 1"
                                                                    fieldKey="uniformat_level_1_id"
                                                                    fieldParam="uniformat_level_1_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.uniformat_level_1_id}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "uniformat_level_1_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: dropDownList?.uniformat_level_1s || []
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Uniformat Level 2"
                                                                    fieldKey="uniformat_level_2_id"
                                                                    fieldParam="uniformat_level_2_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.uniformat_level_2_id}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "uniformat_level_2_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: assetFormData.uniformat_level_1_id
                                                                            ? dropDownList?.uniformat_level_2s
                                                                            : []
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Uniformat Level 3"
                                                                    fieldKey="uniformat_level_3_id"
                                                                    fieldParam="uniformat_level_3_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.uniformat_level_3_id}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "uniformat_level_3_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: assetFormData.uniformat_level_2_id
                                                                            ? dropDownList?.uniformat_level_3s
                                                                            : []
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Uniformat Level 4"
                                                                    fieldKey="uniformat_level_4_id"
                                                                    fieldParam="uniformat_level_4_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.uniformat_level_4_id}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "uniformat_level_4_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: assetFormData.uniformat_level_3_id
                                                                            ? dropDownList?.uniformat_level_4s
                                                                            : []
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Uniformat Level 5"
                                                                    fieldKey="uniformat_level_5_id"
                                                                    fieldParam="uniformat_level_5_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.uniformat_level_5_id}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "uniformat_level_5_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: assetFormData.uniformat_level_4_id
                                                                            ? dropDownList?.uniformat_level_5s
                                                                            : []
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Uniformat Level 6"
                                                                    fieldKey="uniformat_level_6_id"
                                                                    fieldParam="uniformat_level_6_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.uniformat_level_6_id}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "uniformat_level_6_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: assetFormData.uniformat_level_5_id
                                                                            ? dropDownList?.uniformat_level_6s
                                                                            : []
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Uniformat Level 6 Description"
                                                                    fieldKey="uniformat_level_6_description"
                                                                    fieldParam="uniformat_level_6_description"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={
                                                                        assetFormData.uniformat_level_6_id &&
                                                                        assetFormData?.uniformat_level_6_description
                                                                            ? assetFormData.uniformat_level_6_description || ""
                                                                            : ""
                                                                    }
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            read_only: true
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Uniformat Level 3 Custom Description"
                                                                    fieldKey="uniformat_level_3_custom_description"
                                                                    fieldParam="uniformat_level_3_custom_description"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.uniformat_level_3_custom_description}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Main Category"
                                                                    fieldKey="main_category_id"
                                                                    fieldParam="main_category_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.main_category_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "main_category_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: assetFormData?.client_id ? dropDownList?.main_categories : []
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Sub Category 1 "
                                                                    fieldKey="sub_category_1_id"
                                                                    fieldParam="sub_category_1_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.sub_category_1_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "sub_category_1_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: assetFormData.main_category_id
                                                                            ? dropDownList?.sub_category_1s
                                                                            : []
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Sub Category 2 "
                                                                    fieldKey="sub_category_2_id"
                                                                    fieldParam="sub_category_2_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.sub_category_2_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "sub_category_2_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: assetFormData.sub_category_1_id
                                                                            ? dropDownList?.sub_category_2s
                                                                            : []
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Sub Category 2 Description"
                                                                    fieldKey="subcategory2_description"
                                                                    fieldParam="subcategory2_description"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={
                                                                        assetFormData?.subcategory2_description
                                                                            ? assetFormData?.subcategory2_description || ""
                                                                            : ""
                                                                    }
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            read_only: true
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                    isFieldLocked={isFieldLocked}
                                                                />

                                                                <FormField
                                                                    label="Sub Category 3 "
                                                                    fieldKey="sub_category_3_id"
                                                                    fieldParam="sub_category_3_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.sub_category_3_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "sub_category_3_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: assetFormData.sub_category_2_id
                                                                            ? dropDownList?.sub_category_3s
                                                                            : []
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Trade"
                                                                    fieldKey="trade_id"
                                                                    fieldParam="trade_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.trade_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "trade_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: tradeList || [],
                                                                        rules: {
                                                                            mandatory: true,
                                                                            read_only: true
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="System"
                                                                    fieldKey="system_id"
                                                                    fieldParam="system_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.system_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "system_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: systemList || [],
                                                                        rules: { read_only: true, mandatory: true }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Sub-System"
                                                                    fieldKey="sub_system_id"
                                                                    fieldParam="sub_system_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.sub_system_id}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "sub_system_id" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: subSystemList || [],
                                                                        rules: { read_only: true, mandatory: true }
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                    />
                                                    <RecommendationBand
                                                        label="Asset QA/QC Status"
                                                        bandId="band4"
                                                        handleBandClick={this.handleBandClick}
                                                        showBand={showBand}
                                                        MainFields={
                                                            <>
                                                                <FormField
                                                                    label="Asset Survey Id"
                                                                    fieldKey="survey_id"
                                                                    fieldParam="survey_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.survey_id}
                                                                    bandId="band4"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Asset Survey Property Notes"
                                                                    fieldKey="survey_property_note"
                                                                    fieldParam="survey_property_note"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.survey_property_note}
                                                                    bandId="band4"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Capacity Status"
                                                                    fieldKey="capacity_status"
                                                                    fieldParam="capacity_status"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.capacity_status}
                                                                    bandId="band4"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isFieldLocked={isFieldLocked}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                        RestFields={
                                                            <>
                                                                <FormField
                                                                    label="Install Year Status"
                                                                    fieldKey="installed_year_status"
                                                                    fieldParam="installed_year_status"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.installed_year_status}
                                                                    bandId="band4"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Name Plate Status"
                                                                    fieldKey="name_plate_status"
                                                                    fieldParam="name_plate_status"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.name_plate_status}
                                                                    bandId="band4"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                    isFieldLocked={isFieldLocked}
                                                                />
                                                                <FormField
                                                                    label="Asset Survey QA/QC Notes"
                                                                    fieldKey="qa_notes"
                                                                    fieldParam="qa_notes"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.qa_notes}
                                                                    bandId="band4"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                    isFieldLocked={isFieldLocked}
                                                                />
                                                                <FormField
                                                                    label="Asset Survey Additional QA/QC Notes"
                                                                    fieldKey="additional_qa_notes"
                                                                    fieldParam="additional_qa_notes"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.additional_qa_notes}
                                                                    bandId="band4"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                    isFieldLocked={isFieldLocked}
                                                                />

                                                                <div className="txt-rcm">
                                                                    <div className="content-inp-card">
                                                                        <div className="form-group">
                                                                            <label>Asset Survey Date Created</label>
                                                                            <DatePicker
                                                                                autoComplete={"nope"}
                                                                                placeholderText="MM/DD/YYYY"
                                                                                className={`custom-input form-control ${
                                                                                    isFieldLocked("survey_date_created") ? "grayed-out" : ""
                                                                                }`}
                                                                                disabled={isFieldLocked("survey_date_created")}
                                                                                selected={
                                                                                    assetFormData.survey_date_created
                                                                                        ? new Date(assetFormData.survey_date_created)
                                                                                        : ""
                                                                                }
                                                                                onChange={date => {
                                                                                    let data = {
                                                                                        ...assetFormData,
                                                                                        survey_date_created: date ? new Date(date) : null
                                                                                    };
                                                                                    this.props.setAssetFormData(data);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <FormField
                                                                    label="Asset Survey Surveyor"
                                                                    fieldKey="surveyor"
                                                                    fieldParam="surveyor"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.surveyor}
                                                                    bandId="band4"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />

                                                                <div className="txt-rcm">
                                                                    <div className="content-inp-card">
                                                                        <div className="form-group">
                                                                            <label>Asset Survey Date Edited</label>
                                                                            <DatePicker
                                                                                autoComplete={"nope"}
                                                                                placeholderText="MM/DD/YYYY"
                                                                                className={`custom-input form-control ${
                                                                                    isFieldLocked("survey_date_edited") ? "grayed-out" : ""
                                                                                }`}
                                                                                disabled={isFieldLocked("survey_date_edited")}
                                                                                selected={
                                                                                    assetFormData.survey_date_edited
                                                                                        ? new Date(assetFormData.survey_date_edited)
                                                                                        : ""
                                                                                }
                                                                                onChange={date => {
                                                                                    let data = {
                                                                                        ...assetFormData,
                                                                                        survey_date_edited: date ? new Date(date) : null
                                                                                    };
                                                                                    this.props.setAssetFormData(data);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <FormField
                                                                    label="Asset Survey Editor"
                                                                    fieldKey="editor"
                                                                    fieldParam="editor"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.editor}
                                                                    bandId="band4"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isFieldLocked={isFieldLocked}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Parent Global ID"
                                                                    fieldKey="parent_global_id"
                                                                    fieldParam="parent_global_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.parent_global_id}
                                                                    bandId="band4"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Is This a New Asset"
                                                                    fieldKey="new_asset"
                                                                    fieldParam="new_asset"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData.new_asset}
                                                                    bandId="band1"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "new_asset" }
                                                                        })
                                                                    }
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "yes", name: "Yes" },
                                                                            { id: "no", name: "No" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                    />

                                                    {/* ------------------------------------------------ */}
                                                    <RecommendationBand
                                                        label="Asset Parent/Child Hierarchy"
                                                        bandId="band5"
                                                        handleBandClick={this.handleBandClick}
                                                        showBand={showBand}
                                                        MainFields={
                                                            <>
                                                                <FormField
                                                                    label="Area Served"
                                                                    fieldKey="area_served"
                                                                    fieldParam="area_served"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.area_served}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Upstream Asset Barcode Numbers"
                                                                    fieldKey="upstream_asset_barcode_number"
                                                                    fieldParam="upstream_asset_barcode_number"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.upstream_asset_barcode_number}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Linked Asset Barcode Numbersd"
                                                                    fieldKey="linked_asset_barcode_number"
                                                                    fieldParam="linked_asset_barcode_number"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.linked_asset_barcode_number}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                        RestFields={
                                                            <>
                                                                <FormField
                                                                    label="Source Panel Name"
                                                                    fieldKey="source_panel_name"
                                                                    fieldParam="source_panel_name"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.source_panel_name}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isFieldLocked={isFieldLocked}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Source Panel Barcode Number"
                                                                    fieldKey="source_panel_barcode_number"
                                                                    fieldParam="source_panel_barcode_number"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.source_panel_barcode_number}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isFieldLocked={isFieldLocked}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Source Panel Emergency/ Normal"
                                                                    fieldKey="source_panel"
                                                                    fieldParam="source_panel"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.source_panel}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isFieldLocked={isFieldLocked}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Skysite Hyperlink"
                                                                    fieldKey="skysite_hyperlink"
                                                                    fieldParam="skysite_hyperlink"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.skysite_hyperlink}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isFieldLocked={isFieldLocked}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Asset Note"
                                                                    fieldKey="asset_note"
                                                                    fieldParam="asset_note"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.asset_note}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isFieldLocked={isFieldLocked}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Quantity"
                                                                    placeholder="Quantity"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={parseInt(assetFormData.quantity) || ""}
                                                                    thousandSeparator
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            thousandSeparator: true,
                                                                            read_only: isFieldLocked("quantity")
                                                                        }
                                                                    }}
                                                                    bandId="band3"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={values => {
                                                                        const { value } = values;
                                                                        let data = {
                                                                            ...assetFormData,
                                                                            quantity: value
                                                                        };
                                                                        this.props.setAssetFormData(data);
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                    />
                                                    <RecommendationBand
                                                        label="Asset Parent/Child Hierarchy"
                                                        bandId="band5"
                                                        handleBandClick={this.handleBandClick}
                                                        showBand={showBand}
                                                        MainFields={
                                                            <>
                                                                <FormField
                                                                    label="Area Served"
                                                                    fieldKey="area_served"
                                                                    fieldParam="area_served"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.area_served}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Upstream Asset Barcode Numbers"
                                                                    fieldKey="upstream_asset_barcode_number"
                                                                    fieldParam="upstream_asset_barcode_number"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.upstream_asset_barcode_number}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Linked Asset Barcode Numbersd"
                                                                    fieldKey="linked_asset_barcode_number"
                                                                    fieldParam="linked_asset_barcode_number"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.linked_asset_barcode_number}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                        RestFields={
                                                            <>
                                                                <FormField
                                                                    label="Source Panel Name"
                                                                    fieldKey="source_panel_name"
                                                                    fieldParam="source_panel_name"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.source_panel_name}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Source Panel Barcode Number"
                                                                    fieldKey="source_panel_barcode_number"
                                                                    fieldParam="source_panel_barcode_number"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.source_panel_barcode_number}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Source Panel Emergency/ Normal"
                                                                    fieldKey="source_panel"
                                                                    fieldParam="source_panel"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.source_panel}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Skysite Hyperlink"
                                                                    fieldKey="skysite_hyperlink"
                                                                    fieldParam="skysite_hyperlink"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.skysite_hyperlink}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Quantity"
                                                                    placeholder="Quantity"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={parseInt(assetFormData.quantity) || ""}
                                                                    thousandSeparator
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            thousandSeparator: true
                                                                        }
                                                                    }}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={values => {
                                                                        const { value } = values;
                                                                        this.setState({
                                                                            assetFormData: {
                                                                                ...assetFormData,
                                                                                quantity: value
                                                                            }
                                                                        });
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Parent Global ID"
                                                                    fieldKey="parent_global_id"
                                                                    fieldParam="parent_global_id"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData.parent_global_id}
                                                                    bandId="band5"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                    />
                                                    <RecommendationBand
                                                        label="Asset Additional"
                                                        bandId="band6"
                                                        handleBandClick={this.handleBandClick}
                                                        showBand={showBand}
                                                        MainFields={
                                                            <>
                                                                <FormField
                                                                    label="Discharge"
                                                                    fieldKey="discharge"
                                                                    fieldParam="discharge"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.discharge}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "discharge" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Down", name: "Down" },
                                                                            { id: "Side", name: "Side" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Return"
                                                                    fieldKey="return"
                                                                    fieldParam="return"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.return}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "return" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Down", name: "Down" },
                                                                            { id: "Side", name: "Side" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Is Unit Properly Labeled"
                                                                    fieldKey="unit_property_labeled"
                                                                    fieldParam="unit_property_labeled"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.unit_property_labeled}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "unit_property_labeled" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                        RestFields={
                                                            <>
                                                                <FormField
                                                                    label="Factory Mounted Disconnect"
                                                                    fieldKey="factory_mounted_disconnect"
                                                                    fieldParam="factory_mounted_disconnect"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.factory_mounted_disconnect}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "factory_mounted_disconnect" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Unit Voltage"
                                                                    fieldKey="unit_voltage"
                                                                    fieldParam="unit_voltage"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.unit_voltage}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "unit_voltage" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "480-3PH", name: "480-3PH" },
                                                                            { id: "230-3PH", name: "230-3PH" },
                                                                            { id: "230-1PH", name: "230-1PH" },
                                                                            { id: "208-1PH", name: "208-1PH" },
                                                                            { id: "110-1PH", name: "110-1PH" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="AHU Voltage"
                                                                    fieldKey="ahu_voltage"
                                                                    fieldParam="ahu_voltage"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.ahu_voltage}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "ahu_voltage" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "480-3PH", name: "480-3PH" },
                                                                            { id: "230-3PH", name: "230-3PH" },
                                                                            { id: "230-1PH", name: "230-1PH" },
                                                                            { id: "208-1PH", name: "208-1PH" },
                                                                            { id: "110-1PH", name: "110-1PH" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Wire Type"
                                                                    fieldKey="wire_type"
                                                                    fieldParam="wire_type"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.wire_type}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Wire Size"
                                                                    fieldKey="wire_size"
                                                                    fieldParam="wire_size"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.wire_size || ""}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Estimated Wire Run Length"
                                                                    fieldKey="estimated_wire_run_length"
                                                                    fieldParam="estimated_wire_run_length"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.estimated_wire_run_length || ""}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Breaker Size"
                                                                    fieldKey="breaker_size"
                                                                    fieldParam="breaker_size"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.breaker_size || ""}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="MOCP"
                                                                    fieldKey="mocp"
                                                                    fieldParam="mocp"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={parseInt(assetFormData.asset_additional?.mocp) || ""}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={values => {
                                                                        const { value } = values;
                                                                        this.handleChange({
                                                                            target: { value: value, name: "mocp" }
                                                                        });
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="MCA"
                                                                    fieldKey="mca"
                                                                    fieldParam="mca"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={assetFormData?.asset_additional?.mca || ""}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            decimal_scale: 6
                                                                        }
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={values => {
                                                                        const { value } = values;
                                                                        this.handleChange({
                                                                            target: { value: value, name: "mca" }
                                                                        });
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Liquid Line"
                                                                    fieldKey="liquid_line"
                                                                    fieldParam="liquid_line"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.liquid_line}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Suction Line"
                                                                    fieldKey="suction_line"
                                                                    fieldParam="suction_line"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.suction_line}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Hot Gas By-Pass"
                                                                    fieldKey="hot_gas_by_pass"
                                                                    fieldParam="hot_gas_by_pass"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.hot_gas_by_pass}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "hot_gas_by_pass" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Number of Circuits"
                                                                    fieldKey="number_of_circuits"
                                                                    fieldParam="number_of_circuits"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={parseInt(assetFormData.asset_additional?.number_of_circuits) || ""}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={values => {
                                                                        const { value } = values;
                                                                        this.handleChange({
                                                                            target: { value: value, name: "number_of_circuits" }
                                                                        });
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Length of Line Run"
                                                                    fieldKey="length_of_line"
                                                                    fieldParam="length_of_line"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.length_of_line || ""}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Damper Type"
                                                                    fieldKey="damper_type"
                                                                    fieldParam="damper_type"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.damper_type}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "damper_type" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Manual", name: "Manual" },
                                                                            { id: "Modulating", name: "Modulating" },
                                                                            { id: "Economizer", name: "Economizer" },
                                                                            { id: "Power Exhaust", name: "Power Exhaust" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Gas Heating"
                                                                    fieldKey="gas_heating"
                                                                    fieldParam="gas_heating"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.gas_heating}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "gas_heating" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Natural Gas", name: "Natural Gas" },
                                                                            { id: "LP Gas", name: "LP Gas" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Gas Output BTU"
                                                                    fieldKey="gas_output_btu"
                                                                    fieldParam="gas_output_btu"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.gas_output_btu || ""}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Electric Heating"
                                                                    fieldKey="electric_heating"
                                                                    fieldParam="electric_heating"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.electric_heating}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "electric_heating" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Electric Heating KW"
                                                                    fieldKey="electric_heating_kw"
                                                                    fieldParam="electric_heating_kw"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.electric_heating_kw || ""}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Heat Pump - Backup Heat KW"
                                                                    fieldKey="pump_backup_heat_kw"
                                                                    fieldParam="pump_backup_heat_kw"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.pump_backup_heat_kw || ""}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "pump_backup_heat_kw" }
                                                                        })
                                                                    }
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Cooling Only"
                                                                    fieldKey="cooling_only"
                                                                    fieldParam="cooling_only"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.cooling_only}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "cooling_only" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Controls"
                                                                    fieldKey="controls"
                                                                    fieldParam="controls"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.controls}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "controls" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Thermostat", name: "Thermostat" },
                                                                            { id: "Remote Sensor", name: "Remote Sensor" },
                                                                            { id: "DDC/EMS Controls", name: "DDC/EMS Controls" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Controls Type"
                                                                    fieldKey="control_type"
                                                                    fieldParam="control_type"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.control_type}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Smoke Detector"
                                                                    fieldKey="smoke_detector"
                                                                    fieldParam="smoke_detector"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.smoke_detector}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "smoke_detector" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Central Alarm Panel", name: "Central Alarm Panel" },
                                                                            { id: "Self Standing", name: "Self Standing" },
                                                                            { id: "Annunciator Required", name: "Annunciator Required" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Smoke Detector Mounting"
                                                                    fieldKey="smoke_detector_mounting"
                                                                    fieldParam="smoke_detector_mounting"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.smoke_detector_mounting}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "smoke_detector_mounting" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Unit", name: "Unit" },
                                                                            { id: "Duct", name: "Duct" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Smoke Detector - Supply"
                                                                    fieldKey="smoke_detector_supply"
                                                                    fieldParam="smoke_detector_supply"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.smoke_detector_supply}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "smoke_detector_supply" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Smoke Detector - Return"
                                                                    fieldKey="smoke_detector_return"
                                                                    fieldParam="smoke_detector_return"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.smoke_detector_return}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "smoke_detector_return" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Curb"
                                                                    fieldKey="curb"
                                                                    fieldParam="curb"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.curb}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "curb" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Standard", name: "Standard" },
                                                                            { id: "Seismic", name: "Seismic" },
                                                                            { id: "Pitched", name: "Pitched" },
                                                                            { id: "Other", name: "Other" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Curb Other Description"
                                                                    fieldKey="curb_description"
                                                                    fieldParam="curb_description"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.curb_description}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Vibration Isolation"
                                                                    fieldKey="vibration_isolation"
                                                                    fieldParam="vibration_isolation"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.vibration_isolation}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "vibration_isolation" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Burglar Bars"
                                                                    fieldKey="burglar_bars"
                                                                    fieldParam="burglar_bars"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.burglar_bars}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "burglar_bars" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Existing Unit Installed on an Adapter Curb?"
                                                                    fieldKey="installed_on_adapter_curb"
                                                                    fieldParam="installed_on_adapter_curb"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.installed_on_adapter_curb}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "installed_on_adapter_curb" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Existing Curb Outside Dimensions - Length"
                                                                    fieldKey="curb_outside_dimension_length"
                                                                    fieldParam="curb_outside_dimension_length"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.curb_outside_dimension_length || ""}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Existing Curb Outside Dimensions - Width"
                                                                    fieldKey="curb_outside_dimension_width"
                                                                    fieldParam="curb_outside_dimension_width"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.curb_outside_dimension_width || ""}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Will Mall, Landlord, Municipality Allow a Curb Adapter?"
                                                                    fieldKey="allow_curb_adaptor"
                                                                    fieldParam="allow_curb_adaptor"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.allow_curb_adaptor}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "allow_curb_adaptor" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Is This a Ducted or Concentric System"
                                                                    fieldKey="ducted_or_concentric_system"
                                                                    fieldParam="ducted_or_concentric_system"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.ducted_or_concentric_system}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "ducted_or_concentric_system" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Ducted", name: "Ducted" },
                                                                            { id: "Concentric", name: "Concentric" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Unit Weight"
                                                                    fieldKey="unit_weight"
                                                                    fieldParam="unit_weight"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.unit_weight}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Any Special Codes for Disconnects"
                                                                    fieldKey="codes_for_disconnects"
                                                                    fieldParam="codes_for_disconnects"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.codes_for_disconnects}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "codes_for_disconnects" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Any Special Codes for Convenience Outlets"
                                                                    fieldKey="codes_for_convenience_outlets"
                                                                    fieldParam="codes_for_convenience_outlets"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.codes_for_convenience_outlets}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "codes_for_convenience_outlets" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Any Special Codes for Roof Lighting"
                                                                    fieldKey="codes_for_roof_lighting"
                                                                    fieldParam="codes_for_roof_lighting"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.codes_for_roof_lighting}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "codes_for_roof_lighting" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Current Duct Work Attached to Bottom of Unit"
                                                                    fieldKey="duct_work_attached_to_bottom_of_unit"
                                                                    fieldParam="duct_work_attached_to_bottom_of_unit"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.duct_work_attached_to_bottom_of_unit}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "duct_work_attached_to_bottom_of_unit" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Was Aluminum Wire Used"
                                                                    fieldKey="aluminium_wire_used"
                                                                    fieldParam="aluminium_wire_used"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.aluminium_wire_used}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "aluminium_wire_used" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Are There Any Code or Safety Concerns"
                                                                    fieldKey="code_or_safety_concerns"
                                                                    fieldParam="code_or_safety_concerns"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.code_or_safety_concerns}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "code_or_safety_concerns" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Distance (in Miles) from Body of Salt Water"
                                                                    fieldKey="distance_from_salt_water_body"
                                                                    fieldParam="distance_from_salt_water_body"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.distance_from_salt_water_body || ""}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Recommend Coil Coating"
                                                                    fieldKey="recommend_coil_coating"
                                                                    fieldParam="recommend_coil_coating"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.recommend_coil_coating}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "recommend_coil_coating" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Do Electrical/Piping Roof Penetrations Need to be Resealed"
                                                                    fieldKey="need_resealed"
                                                                    fieldParam="need_resealed"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.need_resealed}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "need_resealed" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Split System - Are Line Sets in Good Condition"
                                                                    fieldKey="split_system_line_sets_good_condition"
                                                                    fieldParam="split_system_line_sets_good_condition"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.split_system_line_sets_good_condition}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: {
                                                                                value: e,
                                                                                name: "split_system_line_sets_good_condition"
                                                                            }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Split System - Are Line Sets Damaged in Any Way"
                                                                    fieldKey="split_system_line_sets_damaged"
                                                                    fieldParam="split_system_line_sets_damaged"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.split_system_line_sets_damaged}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "split_system_line_sets_damaged" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Split System - Are Line Sets Crimped"
                                                                    fieldKey="split_system_line_sets_crimped"
                                                                    fieldParam="split_system_line_sets_crimped"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.split_system_line_sets_crimped}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "split_system_line_sets_crimped" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Split System - Are Line Sets Insulated"
                                                                    fieldKey="split_system_line_sets_insulated"
                                                                    fieldParam="split_system_line_sets_insulated"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.split_system_line_sets_insulated}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "split_system_line_sets_insulated" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Split System - Can Line Sets Be Re-Used"
                                                                    fieldKey="split_system_line_sets_re_used"
                                                                    fieldParam="split_system_line_sets_re_used"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.split_system_line_sets_re_used}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "split_system_line_sets_re_used" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: [
                                                                            { id: "Yes", name: "Yes" },
                                                                            { id: "No", name: "No" },
                                                                            { id: "NA", name: "NA" }
                                                                        ]
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Line Connection Type"
                                                                    fieldKey="line_connection_type"
                                                                    fieldParam="line_connection_type"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.line_connection_type}
                                                                    bandId="band6"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                    />
                                                    <RecommendationBand
                                                        label="Boiler Fields"
                                                        bandId="band7"
                                                        handleBandClick={this.handleBandClick}
                                                        showBand={showBand}
                                                        MainFields={
                                                            <>
                                                                <FormField
                                                                    label="Boiler Type"
                                                                    fieldKey="boiler_type"
                                                                    fieldParam="boiler_type"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.boiler_type}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Boiler Burner Type"
                                                                    fieldKey="boiler_burner_type"
                                                                    fieldParam="boiler_burner_type"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.boiler_burner_type}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Boiler BTU Input"
                                                                    fieldKey="boiler_btu_input"
                                                                    fieldParam="boiler_btu_input"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.boiler_btu_input || ""}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                        RestFields={
                                                            <>
                                                                <FormField
                                                                    label="Boiler BTU Output"
                                                                    fieldParam="boiler_btu_output"
                                                                    fieldKey="boiler_btu_output"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.boiler_btu_output || ""}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Boiler HP"
                                                                    fieldParam="boiler_hp"
                                                                    fieldKey="boiler_hp"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={assetFormData?.asset_additional?.boiler_hp || ""}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={values => {
                                                                        const { value } = values;
                                                                        this.handleChange({
                                                                            target: { value: value, name: "boiler_hp" }
                                                                        });
                                                                    }}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            decimal_scale: 6
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Boiler Fuel"
                                                                    fieldKey="boiler_fuel"
                                                                    fieldParam="boiler_fuel"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.boiler_fuel}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Boiler Gas Pressure"
                                                                    fieldKey="boiler_gas_pressure"
                                                                    fieldParam="boiler_gas_pressure"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.boiler_gas_pressure || ""}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Boiler KW Input"
                                                                    fieldParam="boiler_kw_input"
                                                                    fieldKey="boiler_kw_input"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.boiler_kw_input || ""}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Boiler Power Burner Type"
                                                                    fieldKey="boiler_power_burner_type"
                                                                    fieldParam="boiler_power_burner_type"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.boiler_power_burner_type}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Boiler Burner Model"
                                                                    fieldKey="boiler_burner_model"
                                                                    fieldParam="boiler_burner_model"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.boiler_burner_model}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Boiler Burner Serial"
                                                                    fieldKey="boiler_burner_serial"
                                                                    fieldParam="boiler_burner_serial"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.boiler_burner_serial}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Boiler Operating Pressure Type"
                                                                    fieldKey="boiler_pressure_type"
                                                                    fieldParam="boiler_pressure_type"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.SELECT}
                                                                    value={assetFormData?.asset_additional?.boiler_pressure_type}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={e =>
                                                                        this.handleChange({
                                                                            target: { value: e, name: "boiler_pressure_type" }
                                                                        })
                                                                    }
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        field_options: ["Low", "Medium", "High", "NA"].map(option => ({
                                                                            id: option,
                                                                            name: option
                                                                        }))
                                                                    }}
                                                                    isAsset
                                                                />
                                                                <FormField
                                                                    label="Confirm Boiler Pressure"
                                                                    fieldKey="confirm_boiler_pressure"
                                                                    fieldParam="confirm_boiler_pressure"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.asset_additional?.confirm_boiler_pressure}
                                                                    bandId="band7"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                    />
                                                    <RecommendationBand
                                                        label="Auxiliary Equipment Fields"
                                                        bandId="band8"
                                                        handleBandClick={this.handleBandClick}
                                                        showBand={showBand}
                                                        MainFields={
                                                            <>
                                                                <FormField
                                                                    label="Equipment Name"
                                                                    fieldKey="auxiliary_equipment_name"
                                                                    fieldParam="auxiliary_equipment_name"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.auxiliary?.auxiliary_equipment_name}
                                                                    bandId="band8"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Model"
                                                                    fieldKey="auxiliary_model"
                                                                    fieldParam="auxiliary_model"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.auxiliary?.auxiliary_model}
                                                                    bandId="band8"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Serial"
                                                                    fieldKey="auxiliary_serial"
                                                                    fieldParam="auxiliary_serial"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.auxiliary?.auxiliary_serial}
                                                                    bandId="band8"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                        RestFields={
                                                            <>
                                                                <FormField
                                                                    label="Date Manufactured"
                                                                    fieldKey="auxiliary_date_manufactured"
                                                                    fieldParam="auxiliary_date_manufactured"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={assetFormData?.auxiliary?.auxiliary_date_manufactured || ""}
                                                                    bandId="band8"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={values => {
                                                                        const { value } = values;
                                                                        this.handleChange({
                                                                            target: { value: value, name: "auxiliary_date_manufactured" }
                                                                        });
                                                                    }}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Manufacturer"
                                                                    fieldKey="auxiliary_manufacturer"
                                                                    fieldParam="auxiliary_manufacturer"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.auxiliary?.auxiliary_manufacturer}
                                                                    bandId="band8"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="Condition"
                                                                    fieldKey="auxiliary_condition"
                                                                    fieldParam="auxiliary_condition"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.TEXT}
                                                                    value={assetFormData?.auxiliary?.auxiliary_condition}
                                                                    bandId="band8"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={this.handleChange}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="EUL"
                                                                    fieldKey="auxiliary_eul"
                                                                    fieldParam="auxiliary_eul"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={assetFormData?.auxiliary?.auxiliary_eul || ""}
                                                                    bandId="band8"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={values => {
                                                                        const { value } = values;
                                                                        this.handleChange({
                                                                            target: { value: value, name: "auxiliary_eul" }
                                                                        });
                                                                    }}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />

                                                                <FormField
                                                                    label="CRV"
                                                                    fieldKey="auxiliary_crv"
                                                                    fieldParam="auxiliary_crv"
                                                                    fieldType={DYNAMIC_FIELD_TYPES.NUMBER}
                                                                    value={assetFormData?.auxiliary?.auxiliary_crv || ""}
                                                                    bandId="band8"
                                                                    bandShown={showBand}
                                                                    handleBandClick={this.handleBandClick}
                                                                    onChange={values => {
                                                                        const { value } = values;
                                                                        this.handleChange({
                                                                            target: { value: value, name: "auxiliary_crv" }
                                                                        });
                                                                    }}
                                                                    showErrorBorder={showErrorBorder}
                                                                    fieldItem={{
                                                                        rules: {
                                                                            mandatory: false,
                                                                            prefix: "$ ",
                                                                            thousand_separator: true
                                                                        }
                                                                    }}
                                                                    isAsset
                                                                />
                                                            </>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { assetReducer } = state;
    return { assetReducer };
};

export default withRouter(
    connect(mapStateToProps, {
        ...actions
    })(AssetForm)
);

const InputBox = ({ label, name, value, handleChange, type, showErrorBorder, isDisabled = false, col = 3, dataTip, isFieldLocked }) => {
    const isLocked = isFieldLocked && isFieldLocked(name);
    return (
        <div className={`col-md-${col} basic-box`}>
            <div className="codeOtr" data-tip={isLocked ? "This field is locked and cannot be edited" : dataTip} data-for="asset_details">
                <h4>{label}</h4>
                <input
                    autoComplete={"nope"}
                    type={type}
                    className={`${showErrorBorder ? "error-border " : ""} ${isDisabled ? "cursor-diabled" : ""} ${
                        isLocked ? "grayed-out" : ""
                    } custom-input form-control`}
                    value={value}
                    name={name}
                    onChange={handleChange}
                    placeholder={label.replace("*", "")}
                    disabled={isDisabled || isLocked}
                />
            </div>
        </div>
    );
};

const SelectBox = ({
    label,
    name,
    value,
    handleChange,
    showErrorBorder,
    errorParams,
    optionsList,
    isDisabled = false,
    col = 3,
    isFieldLocked,
    lockKey = ""
}) => {
    const isLocked = isFieldLocked && isFieldLocked(lockKey || name);
    return (
        <div className={`col-md-${col} basic-box`}>
            <div className="codeOtr" data-tip={isLocked ? "This field is locked and cannot be edited" : ""} data-for="asset_details">
                <h4>{label}</h4>
                <div className="custom-selecbox">
                    <select
                        value={value}
                        onChange={handleChange}
                        disabled={isDisabled || isLocked}
                        name={name}
                        className={`custom-selecbox ${isDisabled ? "cursor-diabled" : ""} ${isLocked ? "grayed-out" : ""} ${
                            showErrorBorder ? "error-border" : ""
                        }`}
                    >
                        <option value="">Select</option>
                        {optionsList?.length
                            ? optionsList.map((item, i) => (
                                  <option value={item.id} key={item.id}>
                                      {item.name}
                                  </option>
                              ))
                            : null}
                    </select>
                </div>
            </div>
        </div>
    );
};
